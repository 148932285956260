import * as actions from '../actionTypes/index'

export const initialState = {
    shortlistedCandidatesList: [],
    rejectedCandidatesList: [],
    hasErrors: false,
}

export default function rejectAndShortlistedReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.SHORT_LISTED_CANDIDATES_LIST:
            return { ...state, shortlistedCandidatesList: payload }
        case actions.REJECTED_LISTED_CANDIDATES_LIST:
            return { ...state, rejectedCandidatesList: payload }
        default:
            return state
    }
}
