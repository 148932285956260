import React, { useState, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLoader, deleteLoader } from "../../redux/actions/loader";

const masterData = [
    { label: 'Job Type', response: 'job_type', value: 'Remote', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', value: '1234', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', value: '5-10 years', img: 'Y_O_Exp.svg' },
    { label: 'Type of contract', response: 'office_type', value: 'full time', img: 'type_of_contract.svg' },
]

const masterDataSkill = [
    { label: 'Skill', img: 'skills_needed.svg', response: 'skill' },
]

const masterUserData = [
    { label: 'First Name', img: 'type_of_contract.svg', response: 'firstName' },
    { label: 'Mail Id', img: 'type_of_contract.svg', response: 'emailId' },
    { label: 'Phone Number', img: 'Y_O_Exp.svg', response: 'phone_number' },
    { label: 'Created On', img: 'skills_needed.svg', response: 'createdAt' },
]
const masterSowData = [
    { label: 'Sow Status', img: 'job_code.svg', response: 'sow_po_status' },
]

const JobOfferDetails = (props) => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.loaderReducer);
    const { viewSelectedJobOfferDetails, jobOfferList, selectedRecordDataFun } = props
    const [current, setCurrent] = useState(viewSelectedJobOfferDetails?.idxValue);
    const length = jobOfferList.length;

    useEffect(() => {
        dispatch(addLoader());
        stopLoader()
    }, [])

    const stopLoader = () => {
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }

    useEffect(() => {
        setCurrent(viewSelectedJobOfferDetails?.idxValue)
    }, [viewSelectedJobOfferDetails])

    const nextPage = (job, indexVal) => {
        const selectedJob = jobOfferList.find((item, idx) => idx === indexVal + 1)
        selectedRecordDataFun(selectedJob)
        setCurrent(current === length - 1 ? 0 : current + 1);
        dispatch(addLoader());
        stopLoader()
    }

    const prevPage = (job, indexVal) => {
        const selectedJob = jobOfferList.find((item, idx) => idx === indexVal - 1)
        selectedRecordDataFun(selectedJob)
        setCurrent(current === 0 ? length - 1 : current - 1);
        dispatch(addLoader());
        stopLoader()
    }

    const editJobHandler = (job) => {
        if (job) {
            selectedRecordDataFun(job)
        }
    }

    return (
        <>
            {jobOfferList.map((page, index) => {
                return (<>
                    {index === current && <div className="col-md-12" key={index}>
                        <div className="border-bottom position-sticky top-0 footer mt-auto py-3 pb-3 text-center modal-header main-bg-dark-color">
                            <div className="col-md-12 d-flex align-item-center justify-content-between">
                                <div className="col-md-3 d-flex justify-content-start">
                                    {index !== 0 &&
                                        <button
                                            className="border-0 main-bg-dark-color mt-0 mb-0 pb-0 pt-0"
                                            onClick={() => { prevPage(page, index) }}
                                        // disabled={(index > 0)}
                                        >
                                            <img src={require('../../assets/images/prev_page.svg').default} alt="Alt content" />
                                        </button>
                                    }
                                </div>
                                <div className="col-md">
                                    <div className="pt-0.5">{page?.candidateDetails && page?.candidateDetails[0]?.firstName}</div>
                                </div>
                                <div className="col-md-3  d-flex justify-content-end">
                                    {index !== (jobOfferList.length - 1) &&
                                        <button
                                            className="border-0 main-bg-dark-color mt-0 mb-0 pb-0  pt-0"
                                            onClick={() => { nextPage(page, index) }}
                                        // disabled={!(index !== (jobOfferList.length - 1))}
                                        >
                                            <img src={require('../../assets/images/next_1_page.svg').default} alt="Alt content" />
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="overflow_y_on modal-dialog-scrollable pt-0 pb-5 ps-3 pe-3">
                            <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                {masterData.map((item, idx) => {
                                    return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                                        <div className="col-xs-12 align-middle">
                                            <div className="col-xs-6 me-3">
                                                <img
                                                    src={require(`../../assets/images/${item.img}`)}
                                                    alt="jobType"
                                                    className="img_w_h_23"
                                                />
                                            </div>
                                            <div className="col-xs-6">
                                                <div className="fw-700 font_size_16 mb-2">{item.label}</div>
                                                {/* <div className="fw-medium font_size_14">{page?.jobDetails && page?.jobDetails[0][item?.response]}</div> */}
                                                <div className="fw-medium font_size_14">{page?.jobDetails && item.response === 'office_type' ? page?.jobDetails[0][item.response]?.toString()?.split(',').join(", ") : page?.jobDetails[0][item?.response]}</div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>

                            <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                {masterDataSkill.map((item, idx) => {
                                    return (<div className="col-md-12 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                                        <div className="col-xs-12 align-middle">
                                            <div className="col-xs-6 me-3">
                                                <img
                                                    src={require(`../../assets/images/${item.img}`)}
                                                    alt="jobType"
                                                    className="img_w_h_23"
                                                />
                                            </div>
                                            <div className="col-xs-6">
                                                <div className="fw-700 font_size_16 mb-2">{item.label}</div>
                                                <div className="fw-medium font_size_14">{page?.jobDetails && page?.jobDetails[0][item?.response]?.map((item) => item?.language)}</div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>

                            <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                {masterUserData.map((item, idx) => {
                                    return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                                        <div className="col-xs-12 align-middle">
                                            <div className="col-xs-6 me-3">
                                                <img
                                                    src={require(`../../assets/images/${item.img}`)}
                                                    alt="jobType"
                                                    className="img_w_h_23"
                                                />
                                            </div>
                                            <div className="col-xs-6">
                                                <div className="fw-700 font_size_16 mb-2">{item.label}</div>
                                                <div className="fw-medium font_size_14">{page?.candidateDetails && page?.candidateDetails[0][item?.response]}</div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                            <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                {masterSowData.map((item, idx) => {
                                    return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                                        <div className="col-xs-12 align-middle">
                                            <div className="col-xs-6 me-3">
                                                <img
                                                    src={require(`../../assets/images/${item.img}`)}
                                                    alt="jobType"
                                                    className="img_w_h_23"
                                                />
                                            </div>
                                            <div className="col-xs-6">
                                                <div className="fw-700 font_size_16 mb-2">{item.label}</div>
                                                <div className="fw-medium font_size_14 orange_color">{page?.sow_po_status === 'onboarded' ? 'Submitted' : 'pending'}</div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>

                            {/* {[{ title: 'Job description', img: 'job_description.svg', value: 'job_desciption' }, 
                            { title: 'Skill needed', img: 'skills_needed.svg', value: 'skill' }].map((item, idx) => <div>
                                <div className="col-md-12 d-flex justify-content-start align-content-center pt-4 p-2">
                                    <div className="col-xs-12 align-middle">
                                        <div className="col-xs-1 me-3">
                                            <img
                                                src={require(`../../assets/images/${item.img}`)}
                                                alt="jobType"
                                                className="img_w_h_23"
                                            />
                                        </div>
                                        <div className="col-xs-11">
                                            <div className="fw-700 font_size_16 mb-2">{item.title}</div>
                                            <div className="fw-medium font_size_14 mb-2">
                                                <b> {`${page.year_of_exp}`} </b>  throughout the project lifecycle.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fw-medium font_size_14 ms-5">
                                    {page[item.value]}
                                </div>
                            </div>)} */}
                        </div>

                        {/* </div> */}
                        {/* <div className="border-top position-sticky bottom-0 footer mt-auto py-3 pb-3 bg-white text-center d-flex justify-content-center"> */}
                        <div className="border-top position-sticky bottom-0 footer mt-auto py-3 px-3 bg-white  d-flex align-item-center justify-content-center">
                            {/* <div className="col-md-12 d-flex align-item-center justify-content-center"> */}
                            <button
                                type="close"
                                className="mt-1 mb-1 fw-medium_imp btn btn-outline-primary bg-white btn_height_36 padding_top_6 pb-2 pe-4 ps-4 font_size_14 orange_color_border orange_color"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    document?.location?.reload()
                                }}
                            >
                                Cancel
                            </button>
                            {/* </div>
                            <div className="col-md-3 d-flex align-item-center justify-content-center"> */}
                            {/* <button
                                    type="button"
                                    className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 mt-0 me-3 padding_top_6"
                                    data-bs-target="#editJobsDetails"
                                    data-bs-toggle="modal"
                                    onClick={() => { editJobHandler(page) }}
                                >
                                    Edit
                                </button> */}
                            {/* <button
                                    type="button"
                                    className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 mt-0 padding_top_6"
                                    data-bs-target="#deleteJobOfferDetailsModal"
                                    data-bs-toggle="modal"
                                >
                                    Delete
                                </button>
                            </div> */}
                        </div>
                        {/* </div> */}
                    </div>}
                </>)
            }
            )}
        </>
    );
}

export default JobOfferDetails