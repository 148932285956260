import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { downloadPDF } from '../../../services/axios-common'
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../../hoc/adminLayout";
import Pagination from '../../../common/pagination/Pagination';
import RangeDatePicker from '../../../components/RangeDatePicker';
import data from '../../../common/pagination/examples/data/mock-data.json';
import { returnArryOfObjects } from '../../../Utils/utils'
import { emptyJobList, PageSize } from '../../../Utils/masterData/staticContent';
import CustomSingleSelect from '../../../components/CustomSelectComponent';
import ModalComponent from "../../../components/ModalComponent";
import AddCandidate from "../Candidate/AddCandidate";
// import EditJobOffer from "./EditJobOffer";
// import JobOfferDetails from "./JobOfferDetails";
import { getAllApi, deleteApiWithBody, updateWithOutParamsApi, postApi } from '../../../services/apiCommonService'
import { get_all_candidates, delete_candidates_details_api_url, edit_Offer_api_url } from '../../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../../redux/actions/loader";

const CandidatesListComponent = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [candidatesList, setCandidatesList] = useState([]);
    const [oldCandidatesList, setOldCandidatesList] = useState([]);
    const [selectedCandidatesStateList, setSelectedCandidatesStateList] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedJobOfferObjects, setSelectedJobOfferObjects] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [modalPopUpCancel, setModalPopUpCancel] = useState(false);
    const [pageSize, setPageSize] = useState(PageSize);

    useEffect(() => {
        void getCandidatesListApi()
    }, []);

    const getCandidatesListApi = async () => {
        dispatch(addLoader());
        const apiReqBody = {}
        const apiResponse = await postApi(get_all_candidates, apiReqBody)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            setCandidatesList(apiResponseData)
            setOldCandidatesList(apiResponseData)
            // toast.success("Retrived all Candidates List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.value;
        const lastPageIndex = firstPageIndex + pageSize?.value;
        return candidatesList.slice(firstPageIndex, lastPageIndex);
    };

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(candidatesList, ids, 'candidate_id')
        setSelectedJobOfferObjects(finalUsersData)
    }

    const selectAll = () => {
        setSelectedCandidatesStateList([]);
        if (!isAllSelected) {
            candidatesList.forEach(jobData => {
                setSelectedCandidatesStateList(prevSelectedUsers => [...prevSelectedUsers, jobData.candidate_id]);
            });
            callUsersData(candidatesList.map((item) => item.candidate_id))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const handleUserSelect = (jobData) => {
        if (selectedCandidatesStateList.includes(jobData.candidate_id)) {
            setSelectedCandidatesStateList(prevSelectedUsers => prevSelectedUsers.filter(candidate_id => candidate_id !== jobData.candidate_id));
            callUsersData(selectedCandidatesStateList.filter(candidate_id => candidate_id !== jobData.candidate_id))
        } else {
            setSelectedCandidatesStateList(prevSelectedUsers => [...prevSelectedUsers, jobData.candidate_id]);
            callUsersData([...selectedCandidatesStateList, jobData.candidate_id])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedCandidatesStateList.includes(jobData.candidate_id);
        return isUserSelectedVal
    };

    const filterTextSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = oldCandidatesList.filter((jobData) => {
                return jobData?.candidateFirstName?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.candidateLastName?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.candidateSkillset?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.profileTitle?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.resume_status?.toLowerCase().startsWith(keyword.toLowerCase())
            });
            setCandidatesList(results);
        } else {
            setCandidatesList(oldCandidatesList);
        }
        setKeywordName(keyword);
    };

    const returnAddCandidateFormDetailsFun = (formDetails) => {
        void getCandidatesListApi()
        setCandidatesList([...candidatesList, formDetails])
    }

    const modalContent = (props) => {
        return <>
            <AddCandidate
                modalPopUpCancel={modalPopUpCancel}
                returnAddCandidateFormDetails={returnAddCandidateFormDetailsFun}
            />
        </>;
    }

    const cancelPopUpFun = (vale) => {
        setModalPopUpCancel(vale)
        document.location.reload();
    }

    const selctedFilterOption = () => {
        dispatch(addLoader());
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }

    const onChangeHandler = (event) => {
        setPageSize(event)
    }

    const onSubmit = async () => {
        const apiReqBody = {
            'candidateId': selectedCandidatesStateList.length > 0 && selectedCandidatesStateList
        }
        const apiResponse = await deleteApiWithBody(delete_candidates_details_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Candidate deleted successfully !!')
            void getCandidatesListApi()
            document.location.reload();
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const cancelModalFun = () => {
        document.location.reload();
    }
    
    const deleteModalContent = () => {
        return <>
            <ul>
                {selectedCandidatesStateList.map((item, idx) =>
                    <li key={idx}>
                        {item}
                    </li>
                )}
            </ul>
        </>;
    }

    const deleteModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={onSubmit}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={cancelModalFun}
            >
                Cancel
            </button>
        </>;
    }

    const downloadPdf = (pathName, fileName) => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(pathName, fileName)
    }

    return <>
        <div className="my-1 p-3 bg-body rounded shadow-sm rounded_20">
            <div className="d-flex justify-content-between align-items-center border-bottom pt-2">
                <div className="col-xl-4 col-sm-6 ps-3">
                    <h6 className="font_color_imp font_size_16 fw-bolded">Candidates list</h6>
                </div>
                {/* <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                    <button
                        type="button"
                        className="btn btn-primary btn_background_color pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                        data-bs-toggle="modal"
                        data-bs-target="#releaseJobOffer"
                    >
                        Add Candidate
                    </button>
                </div> */}
                {oldCandidatesList.length > 0 &&
                    <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                        <button
                            type="button"
                            className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                            disabled={oldCandidatesList.length > 0 ? false : true}
                            data-bs-target="#deleteCandidateDetailsModalId"
                            data-bs-toggle="modal"
                        >
                            Delete
                        </button>
                    </div>
                }
            </div>
            {oldCandidatesList.length > 0 &&
                <>
                    <div className="mt-3 mb-0 pe-2">
                        <div className="col-md-12 d-flex justify-content-end align-items-center gap-4">
                            <div className="me-4 col-md-4 d-flex justify-content-end align-items-center gap-1">
                                <div className="dropdown table-action-dropdown">
                                    <button
                                        className="border-0 mt-0 mb-0 pb-0 bg-white dropdown-toggle"
                                        id="dropdownMenuButtonSM"
                                        data-bs-toggle="dropdown"
                                    >
                                        <img src={require('../../../assets/images/vector_filter.svg').default} alt="Alt content" />
                                    </button>
                                    <ul className="dropdown-menu shadow-sm-dd font_size_14" aria-labelledby="dropdownMenuButtonSM">
                                        {[{ value: 'Accepted' }, { value: 'Rejected' }].map((item, idx) =>
                                            <li className={`pt-2 pb-2 ps-4 pe-2 d-flex align-items-center pointer_event opacity_dull ${2 !== idx + 1 && 'border-bottom'}`}
                                                onClick={() => { selctedFilterOption(item) }}
                                            >
                                                {item.value}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                {/* <RangeDatePicker /> */}
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="search"
                                    value={keywordName}
                                    onChange={filterTextSearch}
                                    className="searchInputCls"
                                    placeholder="Search Job type, skills etc..."
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row bd-example">
                            <div className="table-container">
                                <div className="d-flex text-muted height_job_post_table">
                                    <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                    </th>
                                                    {['firstName', 'lastName', 'profileTitle', 'experience', 'skillset', 'candidate_status', 'Resume']
                                                        .map((item, idx) =>
                                                            <th key={idx} className={`text-white`}>
                                                                {item}
                                                            </th>
                                                        )}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData().map((item, idx) => {
                                                    return (
                                                        <tr key={item.candidate_id}>
                                                            <td>
                                                                <input
                                                                    className="width_12"
                                                                    type="checkbox"
                                                                    checked={isUserSelected(item)}
                                                                    onChange={() => handleUserSelect(item)}
                                                                />
                                                            </td>
                                                            <td>{item.candidateFirstName}</td>
                                                            <td>{item.candidateLastName}</td>
                                                            <td>{item.profileTitle}</td>
                                                            <td>{item.candidateExperience}</td>
                                                            <td>{item.candidateSkillset.join()}</td>
                                                            <td>{item.resume_status}</td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6 imag_hover_white"
                                                                    onClick={() => { downloadPdf(item.file_location, item.candidateFirstName) }}
                                                                >
                                                                    <img
                                                                        src={require(`../../../assets/images/download_icon.svg`).default}
                                                                        alt="jobType"
                                                                        className="img_w_h_23 ms-1"
                                                                    />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </PerfectScrollbar>
                                </div>
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <div className="d-flex justify-content-between align-items-baseline gap-2">
                                        <div>Items per page</div>
                                        <CustomSingleSelect
                                            options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                                            selectedPage={pageSize}
                                            onChangeHandler={onChangeHandler}
                                            disabled={candidatesList?.length <= 10}
                                        />
                                        <div>{currentPage}-{pageSize?.label} of {candidatesList.length} items</div>
                                    </div>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={candidatesList.length}
                                        pageSize={pageSize?.label}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {oldCandidatesList.length === 0 &&
                <>
                    <div className="row bd-example">
                        <div className="table-container">
                            <div className="text_v_h_center vh-80 font_size_24">
                                No Data Found
                            </div>
                        </div>
                    </div>
                </>
            }
        </div >
        {/* Add candidate modals */}
        <ModalComponent
            title="Add Candidate Details"
            content={modalContent()}
            dataBsBackdrop="static"
            id="releaseJobOffer"
            // modalDialogClass="add_requirement_modal_w"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}
        />
        {/* delete Company Details modal */}
        <ModalComponent
            title="Do you want delete selected Candidates ?"
            footerContent={deleteModalFooterContent()}
            content={deleteModalContent()}
            dataBsBackdrop="static"
            id="deleteCandidateDetailsModalId"
            w_max_content={true}
        />
    </>
}

export default adminLayout(CandidatesListComponent);