import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { isMobile } from 'react-device-detect';
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import PhoneInput from 'react-phone-input-2';
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import Multiselect from "multiselect-react-dropdown";
import "../../assets/css/login.css"
import authLayout from "../../hoc/authLayout";
import { addTour, deleteTour } from "../../redux/actions/tour";
import { typesOfEmployeeContractList, typeOfCompanyList, numberOfEmployeesList } from '../../Utils/masterData/dropDownJson'
import { postApi } from '../../services/apiCommonService'
import { createQueryTicket_url, post_company_logo_api_url } from '../../services/endPointsConstant'
import { isEmpty } from '../../Utils/utils'
import { useDispatch } from "react-redux";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const RaiseTicket = () => {
    const [formData, setFormData] = useState(false);
    const [typeOfCompanyValue, setTypeOfCompanyValue] = useState(null);
    const [numberOfEmployeesValue, setNumberOfEmployeesValue] = useState(null);
    const [typesOfEmployeeContractValue, setTypesOfEmployeeContractValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const [phoneValue, setPhoneValue] = useState('');
    const [countrySelectedValue, setCountrySelectedValue] = useState('IN');
    const [countryOptions, setCountryOptions] = useState(countryList().getData())
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        setValue,
        reset,
        control,
        getValues
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        // dispatch(addTour({ steps: customSteps }))
        reset({ country: 'IN' })
    }, [])

    const errorHandler = () => {
        if (errors.email) {
            toast.error("Check businessEmailId address, It should be example@google.com")
        } else if (errors.password) {
            toast.error("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.")
        } else if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    useEffect(() => {
        if (countrySelectedValue) {
            setPhoneValue('')
        } else {
            setPhoneValue('')
        }
    }, [countrySelectedValue])

    const logoUpload = async (data, companyDetails) => {
        const baseUrl = 'http://ec2-13-60-203-249.eu-north-1.compute.amazonaws.com:5000'
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("companyId", companyDetails?.companyId)
        const apiResponse = await fetch(`${baseUrl}/${post_company_logo_api_url}`, {
            method: "POST",
            body: formData,
        }).then((res) => res.json());
        if (apiResponse?.message) {
            if (apiResponse?.data) {
                toast.success(apiResponse?.message)
            } else {
                toast.success(apiResponse?.response?.data.message || "Image uploaded successfully !!")
            }
        } else {
            toast.error(apiResponse?.response?.data?.message + '!!')
        }
    }

    const onSubmit = async (data) => {
        setFormData(data);
        const apiReqBody = {
            company_name: data?.companyName,
            buisness_email_id: data?.businessEmailId,
            phone_number: data?.phoneNumber,
            country: data?.country,
            location: data?.location,
            query: data?.query,
            comments: data?.comments,
        }
        const apiResponse = await postApi(createQueryTicket_url, apiReqBody)
        if (apiResponse?.data?.message === 'phone number already exist') {
            toast.error(apiResponse?.data?.message)
        } else {
            if (apiResponse?.data) {
                toast.success("Successfully posted query!!")
                navigate(`/ticket-success-template`)
            } else {
                toast.error(apiResponse?.response?.data.message)
            }
        }
    };

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['country', 'phoneNumber', 'typeOfCompany', 'numberOfEmployees', 'contractType'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    const validate = (value) => {
        const { file } = getValues()
        if (!file?.length === 0) return true;
        if (file?.length > 0) {
            const acceptedFormats = ['jpg', 'jpeg', 'png'];
            const fileExtension = value[0]?.name?.split('.')?.pop()?.toLowerCase();
            if (!acceptedFormats.includes(fileExtension)) {
                return 'Invalid file format. Only jpg, jpeg, png files are allowed.';
            }
            return true;
        }
    };

    $('#file-upload').change(function () {
        var i = $(this).prev('label').clone();
        var file = $('#file-upload')[0]?.files[0]?.name;
        $(this)?.prev('label')?.text(file);
    });

    return (
        <>
            <div className="form-holder login-form vh-100 rounded-0 overflow-auto pb-0 pt-1">
                <div className="d-flex justify-content-center align-items-center mt-2 mb-0">
                    <h1 className="text-center fw-normal mb-0 mt-0 me-3">Raise Your Query</h1>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`form-outline did-floating-label-content ${errors.companyName && 'did-error-input'}`}>
                        <input
                            type="text"
                            name="companyName"
                            placeholder=""
                            {...register("companyName", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9._-\s]+$/,
                                    message: "Special characters are not allowed"
                                }
                            })}
                            className="did-floating-input"
                        />
                        <label className="did-floating-label requiredTrue">{'Company Name'}</label>
                        {/* {errors.companyName && <p className="error">{errors.companyName.message}</p>} */}
                    </div>

                    <div className={`form-outline did-floating-label-content ${errors.businessEmailId && 'did-error-input'}`}>
                        <input
                            type="email"
                            name="businessEmailId"
                            placeholder=""
                            {...register("businessEmailId", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                                pattern: {
                                    value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                    message: "Invalid businessEmailId address, It should be example@google.com"
                                }
                            })}
                            className="did-floating-input"
                        />
                        <label className="did-floating-label requiredTrue">{'Business Email ID'}</label>
                        {/* {errors.businessEmailId && <p className="error">{errors.businessEmailId.message}</p>} */}
                    </div>

                    <div className="form-outline row gx-3">
                        <div className={`col-md-6 marginbottom_int did-floating-label-content'}`}>
                            {/* <div className="form-outline row did-floating-label-content"> */}
                            {/* <div className={`marginbottom_int did-floating-label-content'}`}> */}
                            <Controller
                                name="country"
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div
                                        // className="countryFlag"
                                        style={{ display: "flex", alignItems: "center", width: "100%" }}
                                    >
                                        <ReactCountryFlag
                                            countryCode={
                                                countrySelectedValue ? countrySelectedValue : ""
                                            }
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            title={countrySelectedValue ? countrySelectedValue : ""}
                                            className={`countryFlag ${errors.country && 'countryFlag_error'}`}
                                        // style={{ border: '1px solid red' }}
                                        />
                                        <div
                                            style={{ color: "black", width: "100%" }}
                                        >
                                            <Select
                                                isSearchable={true}
                                                options={countryOptions}
                                                value={countryOptions.find((c) => c.value === value)}
                                                // onChange={this.changeHandler}
                                                // value={typeOfCompanyList.find((c) => c.value === value)}
                                                onChange={(val) => { onChange(val?.value); setCountrySelectedValue(val?.value) }}
                                                defaultValue={countryOptions.find((c) => c.value === countrySelectedValue)}
                                                placeholder=""
                                                className="basic-single my-react-select"
                                                // classNamePrefix="select"
                                                components={{
                                                    ValueContainer: CustomValueContainer
                                                }}
                                                onMenuClose={() => { value && setIsSelected({ ...isSelected, 'country': true }) }}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        borderRadius: '2px',
                                                        ...errorBorderForSelectBox(errors.country && 'country'),
                                                        minHeight: !countrySelectedValue ? '40px' : '38px'
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                        marginTop: 2,
                                                    }),
                                                    valueContainer: (provided, state) => ({
                                                        ...provided,
                                                        overflow: "visible"
                                                    }),
                                                    placeholder: (provided, state) => ({
                                                        ...provided,
                                                        position: "absolute",
                                                        top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                        background: "white",
                                                        transition: "top 0.1s, font-size 0.1s",
                                                        fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                        fontWeight: 100,
                                                        padding: '0.3px 8.5px',
                                                        borderRadius: '8px',
                                                        color: 'rgba(6, 44, 80, 0.7);',
                                                        border: isSelected['country'] ? '1px solid #cccccc' : ''
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        fontSize: 14,
                                                        color: 'rgba(32, 53, 98, 1)',
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                rules={{ required: "Field is required" }}
                            />
                            {/* {errors.country && <p className="error">{errors.country.message}</p>} */}
                            {/* </div> */}
                        </div>
                        <div className="col-md-6 marginbottom_int did-floating-label-content">
                            {/* <div className="form-outline row did-floating-label-content"> */}
                            {/* <div className="marginbottom_int did-floating-label-content"> */}
                            <Controller
                                render={(props) => (
                                    <PhoneInput
                                        placeholder="Phone number"
                                        country={countrySelectedValue?.toLowerCase()}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        onChange={(e) => {
                                            props.field.onChange(e); setPhoneValue(e)
                                        }}
                                        value={props.field.value}
                                        inputClass={errors.phoneNumber ? 'error_telphone' : ''}
                                        containerClass={errors.phoneNumber ? 'error_telphone_container' : ''}
                                        countryCodeEditable={false}
                                    />
                                )}
                                defaultValue=""
                                name="phoneNumber"
                                control={control}
                                rules={{ required: "Field is required" }}
                            />
                            {/* {errors.phoneNumber && <p className="error">{errors.phoneNumber.message}</p>} */}
                            {/* </div> */}
                        </div>
                    </div>
                    {/* <div className="form-outline mt-2">
                        <Controller
                            name="typeOfCompany"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={typeOfCompanyList}
                                    value={typeOfCompanyList.find((c) => c.value === value)}
                                    onChange={(val) => onChange(val?.value)}
                                    defaultValue={typeOfCompanyList.find((c) => c.value === typeOfCompanyValue)}
                                    isSearchable={true}
                                    placeholder="Type Of Company"
                                    className="basic-single my-react-select"
                                    classNamePrefix="select"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'typeOfCompany': true }) }}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.typeOfCompany && 'typeOfCompany'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            marginTop: 5,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['typeOfCompany'] ? '1px solid #cccccc' : ''
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                            ...styles,
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            //   cursor: isDisabled ? 'not-allowed' : 'default',
                                        })
                                    }}
                                />
                            )}
                            rules={{ required: "Field is required" }}
                        />
                    </div> */}
                    <div className="form-outline row gx-3 mt-1">
                        <div className={`col-md-12 marginbottom_int did-floating-label-content ${errors.location && 'did-error-input'}`}>
                            <input
                                className="did-floating-input"
                                type="text"
                                name="location"
                                placeholder=''
                                rows="5" cols="33"
                                {...register("location", {
                                    required: "Field is required",
                                    minLength: {
                                        value: 2,
                                        message: "Min length is 2"
                                    },
                                })}
                            />
                            <label className="did-floating-label requiredTrue">{'Location'}</label>
                        </div>
                    </div>
                    <div className="form-outline row gx-3 mt-1">
                        <div className={`col-md-12 marginbottom_int did-floating-label-content ${errors.query && 'did-error-input'}`}>
                            <textarea
                                className="did-floating-input w-100 rounded-3 h-100 pt-3 text_area_border" //border_color
                                name="query"
                                placeholder=''
                                rows="10" cols="33"
                                {...register("query", {
                                    required: "Field is required",
                                    minLength: {
                                        value: 1,
                                        message: "Min length is 1"
                                    },
                                    maxlength: 30000,
                                    minlength: 1
                                })}
                            />
                            <label className="did-floating-label requiredTrue">{'Query'}</label>
                        </div>
                    </div>

                    <div className="form-outline row gx-3">
                        <div className={`col-md-12 marginbottom_int did-floating-label-content ${errors.comments && 'did-error-input'}`}>
                            <input
                                type="text"
                                name="comments"
                                placeholder=""
                                {...register("comments", {
                                    required: false,
                                    minLength: {
                                        value: 2,
                                        message: "Min length is 2"
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-\s]+$/,
                                        message: "Special characters are not allowed"
                                    }
                                })}
                                className="did-floating-input"
                            />
                            <label className="did-floating-label ms-2">{'Additional comments'}</label>
                            {/* {errors.comments && <p className="error">{errors.comments.message}</p>} */}
                        </div>
                    </div>

                    <button type="submit" disabled={isSubmitting} className="w-100 rounded-2 mt-1 mb-0" onClick={errorHandler}>
                        {isSubmitting && <span>Submitting</span>}
                        {!isSubmitting && <span>Submit Query</span>}
                    </button>
                </form>
            </div>
        </>
    );
}

export default authLayout(RaiseTicket);
