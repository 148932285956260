import { Link, useLocation } from "react-router-dom";
import storePersist from '../../Utils/storePersist'

const UnsubscribeSuccessTemplate = () => {
    const { pathname } = useLocation();
    const isAuthenticate = storePersist?.get('userData')
    return (
        <div className="page-hero link-style pt-5 pb-8 bg-white pt-2 text-center vh-100">
            {/* <h3 className="">The page was not found</h3> */}
            <div className="d-flex justify-content-center align-items-center pt-1">
                <img src={require('../../assets/images/side_bar_logo.svg').default} alt="Alt content" className="w-20 h-30" />
            </div>
            <div className="col-md-12 text-center d-flex justify-content-center pt-5">
                <span className="w-35">
                    We will verify and unsubscribe the notifications !.
                    if any techinical issue. please <b>contact Remote72 support </b>.
                </span>
            </div>
            <div className="col-md-12 text-center d-flex justify-content-center pt-4">
                <div className="col-md-3 my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20 text-center">
                    {/* <div className="gap-1">
                        <label>Name:</label>
                        <span className="ms-1">Sayed</span>
                    </div> */}
                    <div className="gap-1">
                        <label>Email ID:</label>
                        <span className="ms-1">support@remote72.com</span>
                    </div>
                    {/* <div className="gap-1">
                        <label>cell Number:</label>
                        <span className="ms-1">741914007</span>
                    </div>
                    <div className="gap-1">
                        <label>Location:</label>
                        <span className="ms-1">Kolkata</span>
                    </div> */}
                </div>
            </div>
            {/* <br/>
            <div>- OR -</div>
            <div className="d-flex justify-content-center align-items-center mt-4 pt-2">
                <Link to="/raise-ticket-query" className="btn btn-primary" >
                    Raise a ticket
                </Link>
            </div> */}
        </div>
    );
}

export default UnsubscribeSuccessTemplate