import * as actions from '../actionTypes/index'

export const initialState = {
    loading: false,
    hasErrors: false,
}

export default function loaderReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.ADD_LOADER:
            return { ...state, ...payload }
        case actions.DELETE_LOADER:
            return { ...state, ...payload }
        default:
            return state
    }
}
