import * as actions from "../actionTypes/index";

const addDefaultCompanyDetails = (resPayload) => {
  return {
    type: actions.ADD_DEFAULT_COMPANY_DETAILS,
    payload: resPayload
  };
};

const deleteDefaultCompanyDetails = (resPayload) => {
  return {
    type: actions.DELETE_DEFAULT_COMPANY_DETAILS,
    payload: resPayload
  };
};

const updatedCompanyDetails = (resPayload) => {
  return {
    type: actions.UPDATED_COMPANY_DETAILS,
    payload: resPayload
  };
};

export { addDefaultCompanyDetails, deleteDefaultCompanyDetails, updatedCompanyDetails };
