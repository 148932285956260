import React, { useState } from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import { isEmpty } from '../../Utils/utils'
import { postApi, getAllApi } from '../../services/apiCommonService'
import { create_candidate_details, get_all_candidates, upload_candidate_file, get_all_companies_list_api_url } from '../../services/endPointsConstant'
import storePersist from '../../Utils/storePersist'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLoader, deleteLoader } from "../../redux/actions/loader";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const AddCandidateDetails = (props) => {
    const dispatch = useDispatch();
    const { selectedJobDetails } = useSelector((state) => state.jobDetailsReducer);
    const [selectedJobDetailsState, setSelectedJobDetailsState] = useState({})
    const [allCompaniesList, setAllCompaniesList] = useState([]);
    const { returnAddCandidateFormDetails, modalPopUpCancel } = props
    const [formData, setFormData] = useState(false);
    const [jobList, setJobList] = useState([])
    const [allCandidatesList, setAllCandidatesList] = useState([])
    const companyDetailsStorage = storePersist.get('companyDetails')
    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        control,
        reset
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    const getAllCandidatesList = async () => {
        const apiReqBody = {}
        const apiResponse = await postApi(get_all_candidates, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData.map(({ _id, firstName }) => { return { value: _id, label: firstName } })
            setAllCandidatesList(apiResponseData)
            // toast.success("Retrived all Candidates List Successfully !!")
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    useEffect(() => {
        async function fetchData() {
            await getAllCandidatesList()
            await getAllCompaniesListApi()
        }
        fetchData()
        setSelectedJobDetailsState(selectedJobDetails)
    }, [])

    useEffect(() => {
        setSelectedJobDetailsState(selectedJobDetails)
    }, [selectedJobDetails])

    useEffect(() => {
        if (modalPopUpCancel) {
            reset();
            document.location.reload();
        }
    }, [modalPopUpCancel])

    const errorHandler = () => {
        if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const getAllCompaniesListApi = async () => {
        dispatch(addLoader());
        // const apiReqBody = { role: "COMPANY" }
        const apiResponse = await getAllApi(get_all_companies_list_api_url)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            setAllCompaniesList(apiResponseData)
            // toast.success("Retrived all Companies List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const resumeUpload = async (data, candidateDetails) => {
        const baseUrl = 'http://ec2-13-60-203-249.eu-north-1.compute.amazonaws.com:5000'
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("candidateId", candidateDetails?.candidate_id)
        const apiResponse = await fetch(`${baseUrl}/${upload_candidate_file}`, {
            method: "POST",
            body: formData,
        }).then((res) => res.json());
        if (apiResponse?.message) {
            if (apiResponse?.data) {
                toast.success(apiResponse?.message)
            } else {
                toast.success(apiResponse?.response?.data.message || "resume uploaded successfully !!")
            }
        } else {
            toast.error(apiResponse?.response?.data?.message + '!!')
        }
    }

    const onSubmit = async (data) => {
        setFormData(data);
        const { id } = storePersist.get('userData')
        const apiReqBody = {
            userid: id,
            firstName: data.firstName,
            lastName: data.lastName,
            profileTitle: data.profileTitle,
            experience: data.experience,
            skillset: data?.skillset?.split(','),
            candidate_status: 'registered',
            fileName: data.file[0]?.name,
            companyId : companyDetailsStorage && companyDetailsStorage?._id,
            jobId: selectedJobDetails?._id,
            emailId: data.emailId
        }
        const apiResponse = await postApi(create_candidate_details, apiReqBody)
        if (apiResponse?.data) {
            resumeUpload(data, apiResponse?.data?.data)
            toast.success("Successfully candidate details Added !!")
            returnAddCandidateFormDetails(apiReqBody)
            document.getElementById("candidateButtonDismiss").click();
            document.location.reload();
            reset();
        } else {
            toast.success(apiResponse?.response?.data.message || "Resume uploaded successfully !!")
        }
    };

    return (
        <div className="form-holder ps-6 pe-6 pb-0 main_bg rounded-0">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="form-outline row gx-3">
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.emailId && 'did-error-input'}`}>
                        <input
                            type="email"
                            name="emailId"
                            placeholder=""
                            {...register("emailId", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                                pattern: {
                                    value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                    message: "Invalid emailId address, It should be example@google.com"
                                }
                            })}
                            className="did-floating-input"
                        />
                        <label className="did-floating-label">{'Email ID'}</label>
                    </div>
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.jobName && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="text"
                            name="jobName"
                            placeholder=""
                            disabled={true}
                            value={selectedJobDetailsState?.job_type}
                        />
                        <label className="did-floating-label">{'Job Name'}</label>
                    </div>
                </div>

                <div className="form-outline row gx-3">
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.firstName && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="text"
                            name="firstName"
                            placeholder=""
                            {...register("firstName", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                            })}
                        />
                        <label className="did-floating-label">{'firstName'}</label>
                    </div>
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.lastName && 'did-error-input'}`}>
                        <input
                            className="did-floating-input" //mb-4 font_size_14
                            type="text"
                            name="lastName"
                            placeholder=""
                            {...register("lastName", {
                                required: false,
                                // minLength: {
                                //     value: 2,
                                //     message: "Min length is 2"
                                // }
                            })}
                        />
                        <label className="did-floating-label">{'Last Name'}</label>
                    </div>
                </div>

                <div className="form-outline row gx-3">
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.profileTitle && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="text"
                            name="profileTitle"
                            placeholder=""
                            {...register("profileTitle", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                            })}
                        />
                        <label className="did-floating-label">{'Profile Title'}</label>
                    </div>
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.experience && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="number"
                            name="experience"
                            placeholder=""
                            min = "1"
                            max = "61"
                            {...register("experience", {
                                required: "Field is required",
                                minLength: {
                                    value: 1,
                                    message: "Min length is 1"
                                },
                            })}
                        />
                        <label className="did-floating-label">{'Experience In Years'}</label>
                    </div>
                </div>

                <div className={`form-outline did-floating-label-content ${errors.skillset && 'did-error-input'}`}>
                    <textarea
                        className="did-floating-input w-100 rounded-3 h-100 pt-3 text_area_border"
                        name="skillset"
                        placeholder=''
                        rows="5" cols="33"
                        {...register("skillset", {
                            required: "Field is required",
                            minLength: {
                                value: 2,
                                message: "Min length is 25"
                            },
                            maxlength: 3000,
                            minlength: 2
                        })}
                    />
                    <label className="did-floating-label">{'Skill Set'}</label>
                </div>

                <div className="form-outline row gx-3">
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.file && 'did-error-input'}`}>
                        <input
                            className="did-floating-input" //mb-4 font_size_14
                            type="file"
                            name="file"
                            accept=".pdf"
                            placeholder=""
                            {...register("file", {
                                required: "File is required",
                                validate: (value) => {
                                    const acceptedFormats = ['pdf'];
                                    const fileExtension = value[0]?.name.split('.').pop().toLowerCase();
                                    if (!acceptedFormats.includes(fileExtension)) {
                                        return 'Invalid file format. Only PDF files are allowed.';
                                    }
                                    return true;
                                }
                            })}
                            style={{ paddingTop: '7px' }}
                        />
                    </div>
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.companyName && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="text"
                            name="companyName"
                            placeholder=""
                            disabled={true}
                            value={allCompaniesList[0]?.companyName}
                        />
                        <label className="did-floating-label">{'Company Name'}</label>
                    </div>
                </div>

                <div className="border-top position-sticky bottom-0 footer mt-auto py-5 pb-3 pt-2 main_bg text-center d-flex justify-content-center">
                    <button type="submit" className="mt-1 mb-1 fw-medium_imp" onClick={errorHandler}>
                        {isSubmitting && <span>Submitting</span>}
                        {!isSubmitting && <span>Add Candidate Details</span>}
                    </button>
                </div>
            </form>
            <button className="d-none" id="candidateButtonDismiss" data-bs-dismiss="modal"></button>
        </div>
    );
}

export default AddCandidateDetails