import * as actions from "../actionTypes/index";

const addShortlistedResume = (resPayload) => {
  return {
    type: actions.ADD_SHORTLISTED_RESUME,
    payload: resPayload
  };
};

const deleteShortlistedResume = (resPayload) => {
  return {
    type: actions.DELETE_SHORTLISTED_RESUME,
    payload: resPayload
  };
};

export { addShortlistedResume, deleteShortlistedResume };
