import React, { useState } from 'react';
import Select from 'react-select';

export default function CustomSelectComponent(props) {
  const { options, onChangeHandler, selectedPage, disabled } = props

  const onChangeSelectHandler = (e) => {
    onChangeHandler(e)
  }

  return (
    <Select
      value={selectedPage}
      onChange={(e) => { onChangeSelectHandler(e) }}
      options={options}
      isSearchable={true}
      placeholder={'Select'}
      menuPlacement="auto"
      isDisabled={disabled}
      styles={{
        control: (provided) => ({
          ...provided,
          height: 28,
          minHeight: 28,
          border: `${disabled ? '1px solid #cccccc' : '1px solid red'}`,
          margin: 0,
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: 0
        }),
        singleValue: (provided) => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 2
        }),
        dropdownIndicator: base => ({
          ...base,
          padding: 0,
          color: `${disabled ? '#cccccc' : 'red'}`
        })
      }}
    />
  );
}