import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../hoc/adminLayout";
import Pagination from '../../common/pagination/Pagination';
import RangeDatePicker from '../../components/RangeDatePicker';
import data from '../../common/pagination/examples/data/mock-data.json';
import { returnArryOfObjects } from '../../Utils/utils'
import { emptyJobList, PageSize } from '../../Utils/masterData/staticContent';
import ModalComponent from "../../components/ModalComponent";
import AddJobOffer from "../jobOffer/AddJobOffer";
import EditJobOffer from "./EditJobOffer";
import JobOfferDetails from "./JobOfferDetails";
import { getAllApi, postApi, deleteApiWithBody, updateWithOutParamsApi } from '../../services/apiCommonService'
import { get_offer_api_url, delete_offer_api_url, edit_Offer_api_url, post_comment_api_url, get_jobs_by_company_id, upload_offer_sow_file, edit_offfer_details } from '../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import CustomSingleSelect from '../../components/CustomSelectComponent';
import storePersist from '../../Utils/storePersist'
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import { isEmpty, formatBytes } from '../../Utils/utils'
import Chipcomponent from '../../components/ChipComponent';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};
const JobOfferedListComponent = (props) => {
    let navigate = useNavigate();
    const { role } = storePersist.get('userData')
    const dispatch = useDispatch();
    const [jobList, setJobList] = useState([])
    const { shortlistedResumesList } = useSelector((state) => state.shortlistedResumesReducer);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobOfferList, setJobOfferList] = useState([]);
    const [oldJobOfferList, setOldJobOfferList] = useState([]);
    const [selectedJobOffer, setSelectedJobOffer] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedJobOfferObjects, setSelectedJobOfferObjects] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [viewSelectedJobOfferDetails, setViewSelectedJobDetails] = useState('')
    const [modalPopUpCancel, setModalPopUpCancel] = useState(false);
    const [rejectSelectedJobOfferData, setRejectSelectedJobOfferData] = useState({});
    const [pageSize, setPageSize] = useState(PageSize);
    const [formData, setFormData] = useState(false);
    const [selectedUserDetails, setSelectedUserDetails] = useState();
    const [jobListSelectedValue, setJobListSelectedValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const [rejectSelectedJobOfferDataCommentText, setRejectSelectedJobOfferDataCommentText] = useState();

    const {
        register,
        formState: { errors, isDirty, isSubmitting, touched },
        handleSubmit,
        control
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        void getJobOffersListApi()
        void getJobsList()
    }, []);

    const getJobsList = async () => {
        const companyDetailsStorage = storePersist.get('companyDetails')
        const apiReqBody = { companyId: companyDetailsStorage && companyDetailsStorage?._id }
        const apiResponse = await postApi(get_jobs_by_company_id, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData.map(({ _id, job_type }) => { return { value: _id, label: job_type } })
            setJobList(apiResponseData)
            // toast.success("Retrived all Job List Successfully !!")
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const getJobOffersListApi = async () => {
        dispatch(addLoader());
        let companyDetailsStorage
        let apiReqBody
        if (role === 'ADMIN') {
            apiReqBody = {}
            const apiResponse = await postApi(get_offer_api_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
                setJobOfferList(apiResponseData)
                setOldJobOfferList(apiResponseData)
                // toast.success("Retrived all Job Offers List Successfully !!")
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 2500);
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        } else if (role === 'COMPANY') {
            companyDetailsStorage = storePersist.get('companyDetails')
            apiReqBody = { companyId: companyDetailsStorage && companyDetailsStorage?._id }
            const apiResponse = await postApi(get_offer_api_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
                setJobOfferList(apiResponseData)
                setOldJobOfferList(apiResponseData)
                // toast.success("Retrived all Job Offers List Successfully !!")
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 2500);
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        } else if (role === 'ACTMGR') {
            companyDetailsStorage = storePersist.get('actMgrData')
            apiReqBody = { actManagerId: companyDetailsStorage && companyDetailsStorage?._id }
            const apiResponse = await postApi(get_offer_api_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
                setJobOfferList(apiResponseData)
                setOldJobOfferList(apiResponseData)
                // toast.success("Retrived all Job Offers List Successfully !!")
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 2500);
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        }


    }

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.value;
        const lastPageIndex = firstPageIndex + pageSize?.value;
        return jobOfferList.slice(firstPageIndex, lastPageIndex);
    };

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(jobOfferList, ids, '_id')
        setSelectedJobOfferObjects(finalUsersData)
    }

    const selectAll = () => {
        setSelectedJobOffer([]);
        if (!isAllSelected) {
            jobOfferList.forEach(jobData => {
                setSelectedJobOffer(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            });
            callUsersData(jobOfferList.map((item) => item._id))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const handleUserSelect = (jobData) => {
        if (selectedJobOffer.includes(jobData._id)) {
            setSelectedJobOffer(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData._id));
            callUsersData(selectedJobOffer.filter(_id => _id !== jobData._id))
        } else {
            setSelectedJobOffer(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            callUsersData([...selectedJobOffer, jobData._id])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedJobOffer.includes(jobData._id);
        return isUserSelectedVal
    };

    const isAllUsersSelected = () => {
        return selectedJobOffer.length === jobOfferList.length;
    };

    const filterTextSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = oldJobOfferList.filter((jobData) => {
                return jobData?.jobDetails[0]?.job_type?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.jobDetails[0]?.job_code?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    // jobData?.jobDetails[0]?.skill?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.candidateDetails[0]?.firstName?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.candidateDetails[0]?.lastName?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.CompanyDetails[0]?.companyName?.toLowerCase().startsWith(keyword.toLowerCase())
                // jobData?.jobDetails[0]?.office_type?.toLowerCase().startsWith(keyword.toLowerCase())
            });
            setJobOfferList(results);
        } else {
            setJobOfferList(oldJobOfferList);
        }
        setKeywordName(keyword);
    };

    const viewSelectedJobOfferDetailsFun = (item, idx) => {
        selectedRecordDataFun(item)
        setViewSelectedJobDetails({ pageData: item, idxValue: idx })
        // navigate('/short-list-resumes');
    }

    const returnAddNewRequirementFormDetailsFun = (formDetails) => {
        void getJobOffersListApi()
        setJobOfferList([...jobOfferList, formDetails])
    }

    const returnEditJobRequirementFormDetailsFun = (formDetails) => {
        void getJobOffersListApi()
        setJobOfferList([...jobOfferList, formDetails])
    }

    const modalContent = (props) => {
        return <>
            <AddJobOffer
                modalPopUpCancel={modalPopUpCancel}
                returnAddNewRequirementFormDetails={returnAddNewRequirementFormDetailsFun}
            />
        </>;
    }

    const editModalContent = () => {
        return <>
            <EditJobOffer
                returnEditJobRequirementFormDetails={returnEditJobRequirementFormDetailsFun}
                modalPopUpCancel={modalPopUpCancel}
                selectedJobOfferObjects={selectedJobOfferObjects}
            />
        </>;
    }

    const viewJobDetailsModalContent = (props) => {
        return <>
            <JobOfferDetails
                viewSelectedJobOfferDetails={viewSelectedJobOfferDetails}
                jobOfferList={jobOfferList}
                selectedRecordDataFun={selectedRecordDataFun}
            />
        </>;
    }

    const editOffer = () => {
        // toast.info("Edit job Modal Popup is Opened !!")
        // navigate('/short-list-resumes');
    }

    const cancelPopUpFun = (vale) => {
        setModalPopUpCancel(vale)
        document?.location?.reload()
    }

    const selectedRecordDataFun = (selected_job_data) => {
        if (selected_job_data) {
            setSelectedJobOffer([selected_job_data?._id]);
            setSelectedJobOfferObjects([selected_job_data])
        }
    }

    const onSubmitDelete = async () => {
        const apiReqBody = {
            'offerId': selectedJobOffer.length > 0 && selectedJobOffer
        }
        const apiResponse = await deleteApiWithBody(delete_offer_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Job deleted successfully !!')
            void getJobOffersListApi()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const deleteModalContent = () => {
        return <>
            <ul>
                {selectedJobOfferObjects?.length > 0 && selectedJobOfferObjects?.map((item, idx) =>
                    <li key={idx}>
                        {item?.candidateDetails && item?.candidateDetails[0]?.firstName}
                    </li>
                )}
            </ul>
        </>;
    }

    const deleteModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={onSubmitDelete}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
            >
                Cancel
            </button>
        </>;
    }

    const deleteFun = () => {
        // toast.info("Delete Confirmation Modal Popup is Opened !!")
    }

    const rejectJobOfferFun = (item, idx) => {
        setRejectSelectedJobOfferData({ pageData: item, idxValue: idx })
    }

    const rejectModalContent = () => {
        return <>
            <textarea
                value={rejectSelectedJobOfferDataCommentText}
                onChange={(e) => { setRejectSelectedJobOfferDataCommentText(e.target.value) }}
                name="Comment"
                placeholder='Enter comment here'
                rows={4}
                cols={33}
            />
        </>
    }

    const rejectModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={rejectJobApiCallHandler}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
            >
                Cancel
            </button>
        </>;
    }

    const rejectJobApiCallHandler = async () => {

        const { id } = storePersist.get('userData')
        const apiRejectReqBody = {
            offerId: rejectSelectedJobOfferData?.pageData?._id,
            userid: id,
            candidate_id: rejectSelectedJobOfferData?.pageData?.candidate_id,
            comment: rejectSelectedJobOfferDataCommentText
        }
        const apiReqBody = {
            offerId: rejectSelectedJobOfferData?.pageData?._id,
            // interview_status: "rejected",
            interview_status: "completed",
            onboarding_status: 'rejected',
            rejectComments: rejectSelectedJobOfferDataCommentText
        }
        const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Offer rejected successfully !!')
            await getJobOffersListApi()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }

        const apiResponseComments = await postApi(post_comment_api_url, apiRejectReqBody)
        if (apiResponseComments?.data) {
            // toast.success(apiResponseComments?.data.message + ' !!')
            await getJobOffersListApi()
        } else {
            toast.error(apiResponseComments?.response?.data.message || "Api Error Response !!")
        }
    }

    const selctedFilterOption = () => {
        dispatch(addLoader());
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }

    const onChangeHandler = (event) => {
        setPageSize(event)
    }

    const powOrSowUpload = async (data, offerDetails) => {
        const baseUrl = 'http://ec2-13-60-203-249.eu-north-1.compute.amazonaws.com:5000'
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("offerId", offerDetails?._id)
        try {
            const apiResponse = await fetch(`${baseUrl}/${upload_offer_sow_file}`, {
                method: "POST",
                body: formData,
            }).then((res) => res.json());
            if (apiResponse?.message) {
                if (apiResponse?.data) {
                    toast.success(apiResponse?.message)
                } else {
                    toast.success(apiResponse?.response?.data.message || "Uploaded file successfully !!")
                }
            } else {
                toast.error(apiResponse?.response?.data?.message + '!!')
            }
        } catch (err) {
            if (err === 'TypeError: Failed to fetch') {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            } else {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            }
        }
    }

    const onClickPow = (item) => {
        setSelectedUserDetails(item)
    }

    const onSubmit = async (data) => {
        setFormData(data);
        const { id } = storePersist.get('userData')
        const apiReqBody = {
            offerId: selectedUserDetails?._id,
            // userid: id,
            // jobId: data?.jobId,
            joining_date: data.date,
            sow_po_status: "onboarded",
            offer_status: "yes",
            interview_date: new Date()?.format?.("YYYY/MM/DD"),
            interview_status: "completed",
            onboarding_status: 'onboarded'
            // candidate_id: selectedUserDetails.candidate_id
        }
        let file_size = data.file[0]?.size;
        let file_name = data.file[0]?.name;
        let file_type = data.file[0]?.type;
        if (formatBytes(file_size) < 500) {
            const apiResponse = await updateWithOutParamsApi(edit_offfer_details, apiReqBody)
            if (apiResponse?.data) {
                powOrSowUpload(data, { _id: selectedUserDetails?._id })
                toast.success("Successfully release offer and uploaded sow/pow details !!")
                document.getElementById("buttonDismissReleaseOfPurchaseOrderModalID").click();
                void getJobOffersListApi()
                // navigate(`/onboarded-candidate/${selectedUserDetails.candidate_id}`); //apiResponse?.data?.Data?.candidate_id 
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            }
        } else {
            toast.error('Failed to upload file, file size is excceded more than 500KB')
        }
    };

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['salary', 'office_type', 'currencyValue'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    const errorHandler = () => {
        if (errors.joiningDate) {
            toast.error("enter Joining Date")
        } else if (errors.purchaseOrderName) {
            toast.error("enter Purchase order name")
        } else if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const onClickPageRedirectionInterview = (item) => {
        if (item?.sow_po_status === 'onboarded') {
            navigate(`/onboarded-candidate/${item?.candidate_id}`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 3, readOnly: true, resultObject: item } });
            // stageUpdateProps(3)
            // navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 3, readOnly: true, resultObject: item } });
        } else if (item?.sow_po_status === null) {
            navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 2, readOnly: true, resultObject: item } });
        }
        // else if (item?.resumeShortlistedCount > 0 && item?.newResumeCount === 0) {
        //     navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 1 } });
        // } else if (item?.resumeShortlistedCount === 0 && item?.newResumeCount === 0) {
        //     navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 0 } });
        // } else if (item?.jobOfferedCount > 0) {
        //     navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 2 } });
        // } else {
        //     navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 0 } });
        // }
    }

    const onClickPageRedirectionOnboard = (item) => {
        if (item?.onboarding_status === 'onboarded') {
            navigate(`/onboarded-candidate/${item?.candidate_id}`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 3, readOnly: true, resultObject: item } });
            // stageUpdateProps(3)
            // navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 3, readOnly: true, resultObject: item } });
        } else if (item?.onboarding_status === 'inprogress') {
            toast.error("Please Upload Sow Forms !!")
            // navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 2, readOnly: true, resultObject: item } });
        } else if (item?.onboarding_status === 'rejected') {
            if (item?.sow_po_status === 'onboarded') {
                navigate(`/onboarded-candidate/${item?.candidate_id}`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 3, readOnly: true, resultObject: item } });
                // stageUpdateProps(3)
                // navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 3, readOnly: true, resultObject: item } });
            } else if (item?.sow_po_status === null) {
                navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 2, readOnly: true, resultObject: item } });
            }
        }
        // else if (item?.resumeShortlistedCount > 0 && item?.newResumeCount === 0) {
        //     navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 1 } });
        // } else if (item?.resumeShortlistedCount === 0 && item?.newResumeCount === 0) {
        //     navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 0 } });
        // } else if (item?.jobOfferedCount > 0) {
        //     navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 2 } });
        // } else {
        //     navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: 0 } });
        // }
    }

    const releaseOfPurchaseOrderBodyContent = () => {
        return <>
            <div className="form-holder pb-0 main_bg rounded-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`form-outline marginbottom_int did-floating-label-content ${errors.date && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            name="date"
                            type="date"
                            placeholder=""
                            {...register("date", {
                                required: "Field is required",

                            })}
                        />
                        <label className="did-floating-label">{'Joining date'}</label>
                    </div>
                    {/* <div className="form-outline marginbottom_int did-floating-label-content">
                        <Controller
                            name="jobId"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={jobList}
                                    value={jobList.find((c) => c.value === value)}
                                    onChange={(val) => onChange(val.value)}
                                    defaultValue={jobList.find((c) => c.value === jobListSelectedValue)}
                                    isSearchable={true}
                                    placeholder={'select related job'}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'jobId': true }) }}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.jobId && 'jobId'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            marginTop: 25,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['jobId'] ? '1px solid #cccccc' : ''
                                        })
                                    }}
                                />
                            )}
                            rules={{ required: "Field is required" }}
                        />
                    </div> */}

                    <div className={`form-outline did-floating-label-content ${errors.job_code && 'did-error-input'} mt-4`}>
                        <input
                            className="did-floating-input" //mb-4 font_size_14
                            type="text"
                            name="job_code"
                            placeholder=""
                            value={selectedUserDetails?.jobDetails?.length > 0 && selectedUserDetails?.jobDetails[0]?.job_code}
                            {...register("job_code", {
                                // required: "Field is required",
                                // minLength: {
                                //     value: 2,
                                //     message: "Min length is 2"
                                // }
                            })}
                            disabled={true}
                            readOnly={true}
                        />
                        <label className="did-floating-label">{'Job Code'}</label>
                    </div>

                    <div className={`form-outline did-floating-label-content ${errors.file && 'did-error-input'} mt-4`}>
                        <input
                            className="did-floating-input" //mb-4 font_size_14
                            type="file"
                            name="file"
                            accept=".pdf"
                            placeholder=""
                            {...register("file", {
                                required: "File is required",
                                validate: (value) => {
                                    const acceptedFormats = ['pdf'];
                                    const fileExtension = value[0]?.name.split('.').pop().toLowerCase();
                                    if (!acceptedFormats.includes(fileExtension)) {
                                        return 'Invalid file format. Only PDF files are allowed.';
                                    }
                                    return true;
                                }
                            })}
                            style={{ paddingTop: '7px' }}
                        />
                        <label className="did-floating-label">{'SOW'}</label>
                    </div>
                    <div className="border-top position-sticky bottom-0 footer mt-auto py-3 pb-3 pt-2 main_bg text-center d-flex justify-content-between">
                        <button
                            type="submit"
                            className="btn btn-primary btn_background_color pt-1 pb-1 mt-0 mb-0 btn_height_36 fw-medium_imp"
                            onClick={errorHandler}
                        >
                            {isSubmitting && <span>Submitting</span>}
                            {!isSubmitting && <span>Update</span>}
                        </button>
                        <button
                            className="btn btn-primary btn_background_color pt-1 pb-1 mt-0 mb-0 btn_height_36 fw-medium_imp"
                            data-bs-dismiss="modal"
                        >
                            Do it later
                        </button>
                    </div>
                </form>
                <button className="d-none" id="buttonDismissReleaseOfPurchaseOrderModalID" data-bs-dismiss="modal"></button>
                {/* )} */}
            </div>
        </>;
    }

    return <>
        <div className="my-1 p-3 bg-body rounded shadow-sm rounded_20">
            <div className="d-flex justify-content-between align-items-center border-bottom pt-2">
                <div className="col-xl-4 col-sm-6 ps-3">
                    <h6 className="font_color_imp font_size_16 fw-bolded">Finalized Candidates</h6>
                </div>
                {/* <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                    <div className="font_size_16 fw-bold text_btn_color pt-1">
                        Filters :
                    </div>
                    <div
                        className={`${keywordFilter === 'COMPANY' ? 'btn bg_color_ornage font_color_ornage_border font_color_white_c' : 'btn border_btn_color'} pb-2 font_size_14 fw-medium btn_height_36 padding_top_6`}
                        onClick={() => { filterTextSearchTemp({ target: { value: 'COMPANY' } }) }}
                    >
                        Company
                    </div>
                </div> */}
                <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                    {/* <button
                        type="button"
                        className="btn btn-primary btn_background_color pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                        data-bs-toggle="modal"
                        data-bs-target="#releaseJobOffer"
                    >
                        Add Offer
                    </button> */}
                    {oldJobOfferList.length > 0 &&
                        <>
                            {/* <button
                                type="button"
                                className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                                disabled={selectedJobOffer.length > 0 && selectedJobOffer.length < 2 ? false : true}
                                data-bs-target="#EditJobOfferDetails"
                                data-bs-toggle="modal"
                                onClick={editOffer}
                            >
                                Edit
                            </button> */}
                            <button
                                type="button"
                                className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                disabled={selectedJobOffer.length > 0 ? false : true}
                                data-bs-target="#deleteJobOfferDetailsModal"
                                data-bs-toggle="modal"
                                onClick={deleteFun}
                            >
                                Delete
                            </button>
                        </>
                    }
                </div>
            </div>
            {oldJobOfferList.length > 0 &&
                <>
                    <div className="mt-3 mb-0 pe-2">
                        <div className="col-md-12 d-flex justify-content-between align-items-center gap-4">
                            <div className="col-md-4 d-flex justify-content-start align-items-center gap-1 ms-2 margin_bottom_20">
                                <span><b>Total Offered Candidates list : </b>(<span className='orange_color'><b>{oldJobOfferList?.length}</b></span>)</span>
                                {/* <div className="dropdown table-action-dropdown">
                                    <button
                                        className="border-0 mt-0 mb-0 pb-0 bg-white dropdown-toggle"
                                        id="dropdownMenuButtonSM"
                                        data-bs-toggle="dropdown"
                                    >
                                        <img src={require('../../assets/images/vector_filter.svg').default} alt="Alt content" />
                                    </button>
                                    <ul className="dropdown-menu shadow-sm-dd font_size_14" aria-labelledby="dropdownMenuButtonSM">
                                        {[{ value: 'Accepted' }, { value: 'Rejected' }].map((item, idx) =>
                                            <li className={`pt-2 pb-2 ps-4 pe-2 d-flex align-items-center pointer_event opacity_dull ${2 !== idx + 1 && 'border-bottom'}`}
                                                onClick={() => { selctedFilterOption(item) }}
                                            >
                                                {item.value}
                                            </li>
                                        )}
                                    </ul>
                                </div> */}
                                {/* <div className="col-md-8"> */}
                                {/* <RangeDatePicker /> */}
                                {/* </div> */}
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="search"
                                    value={keywordName}
                                    onChange={filterTextSearch}
                                    className="searchInputCls"
                                    placeholder="Search Job type, skills etc..."
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row bd-example">
                            <div className="table-container">
                                <div className="d-flex text-muted height_job_post_table">
                                    <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                    </th>
                                                    {role !== 'COMPANY'
                                                        ?
                                                        ['Company Name', 'Candidate Name', 'Job Code', 'Job Type', 'Joining Date', 'SOW/PO', 'Job Status', 'Type of contract', 'View', 'Reject'].
                                                            map((item, idx) =>
                                                                <th key={idx} className={`text-white`}>
                                                                    {item}
                                                                </th>
                                                            )
                                                        :
                                                        ['Candidate Name', 'Job Code', 'Job Type', 'Joining Date', 'SOW/PO', 'Job Status', 'Type of contract', 'View', 'Reject'].
                                                            map((item, idx) =>
                                                                <th key={idx} className={`text-white`}>
                                                                    {item}
                                                                </th>
                                                            )
                                                    }
                                                    {/* <th></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData().map((item, idx) => {
                                                    return (
                                                        <tr key={item._id}>
                                                            <td>
                                                                <input
                                                                    className="width_12"
                                                                    type="checkbox"
                                                                    checked={isUserSelected(item)}
                                                                    onChange={() => handleUserSelect(item)}
                                                                />
                                                            </td>
                                                            {role !== 'COMPANY' && <td className='fw-bolder'>{item?.CompanyDetails[0]?.companyName || '--'}</td>}
                                                            <td>{item?.candidateDetails[0]?.firstName || '--'}</td>
                                                            <td>{item?.jobDetails[0]?.job_code || '--'}</td>
                                                            <td>{item?.jobDetails[0]?.job_type || '--'}</td>
                                                            <td>{new Date(item.joining_date)?.toLocaleDateString('en-GB', {
                                                                day: '2-digit', month: '2-digit', year: 'numeric'
                                                            })?.replace(/\//g, '-') || '--'}</td>
                                                            {item?.sow_po_status === 'onboarded' ?
                                                                <td>
                                                                    <b>{'Submitted'}</b>
                                                                    <br />
                                                                    <span className='orange_color hover_underline font_size_10 pt-3'
                                                                        data-bs-target="#releaseOfPurchaseOrderModalID"
                                                                        data-bs-toggle="modal"
                                                                        onClick={() => { onClickPow(item) }}
                                                                    // onClick={() => { onClickPageRedirectionInterview(item) }}
                                                                    >
                                                                        {'Modify SOW'}
                                                                    </span>
                                                                </td>
                                                                :
                                                                <td>
                                                                    {/* <span className='orange_color hover_underline'
                                                                        // data-bs-target="#releaseOfPurchaseOrderModalID"
                                                                        // data-bs-toggle="modal"
                                                                        // onClick={() => { onClickPow(item) }}
                                                                        // onClick={() => { onClickPageRedirectionInterview(item) }}
                                                                    >
                                                                        {item.sow_po_status || 'Pending'}
                                                                    </span> */}
                                                                    <b>Pending</b>
                                                                    <br />
                                                                    <span className='orange_color hover_underline font_size_10 pt-3'
                                                                        data-bs-target="#releaseOfPurchaseOrderModalID"
                                                                        data-bs-toggle="modal"
                                                                        onClick={() => { onClickPow(item) }}
                                                                    // onClick={() => { onClickPageRedirectionInterview(item) }}
                                                                    >
                                                                        {'Upload SOW'}
                                                                    </span>
                                                                </td>
                                                            }
                                                            <td>
                                                                <span className='orange_color hover_underline'
                                                                    onClick={() => { onClickPageRedirectionOnboard(item) }}
                                                                >
                                                                    {item?.onboarding_status}
                                                                    {/* <Chipcomponent
                                                                        // letter={item?.job_status?.charAt(0)}
                                                                        title={item?.onboarding_status}
                                                                    /> */}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <div className='text_ellipse_80'>
                                                                    {item?.jobDetails[0]?.office_type?.toString()?.split(',').join(", ") || '--'}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <li className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#viewJobOfferDetails"
                                                                    onClick={() => { viewSelectedJobOfferDetailsFun(item, idx) }}
                                                                >
                                                                    <img
                                                                        src={require('../../assets/images/view_account_details_nm.svg').default}
                                                                        alt="Alt content"
                                                                        style={{ height: 12, padding: '0px 6px 0px 2px' }}
                                                                    />
                                                                </li>
                                                            </td>
                                                            <td>
                                                                <li
                                                                    className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#isOpenRejectModalPopUpId"
                                                                    onClick={() => { rejectJobOfferFun(item, idx) }}
                                                                >
                                                                    <img
                                                                        src={require('../../assets/images/scheduled_resume.svg').default}
                                                                        alt="Alt content"
                                                                        style={{ padding: '0px 6px 0px 2px' }}
                                                                    />
                                                                    {/* Reject */}
                                                                </li>
                                                            </td>
                                                            {/* <td>
                                                                <div className="dropdown table-action-dropdown">
                                                                    <button
                                                                        className="btn btn-secondary btn-sm dropdown-toggle mt-0 mb-0 p-0"
                                                                        type="button"
                                                                        id="dropdownMenuButtonSM"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu shadow-sm-dd font_size_14 min_w" aria-labelledby="dropdownMenuButtonSM">
                                                                        <li className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#viewJobOfferDetails"
                                                                            onClick={() => { viewSelectedJobOfferDetailsFun(item, idx) }}
                                                                        >
                                                                            <img
                                                                                src={require('../../assets/images/view_account_details_nm.svg').default}
                                                                                alt="Alt content"
                                                                                style={{ height: 12, padding: '0px 6px 0px 2px' }}
                                                                            />
                                                                            View Full Details
                                                                        </li>
                                                                        <div className="dropdown-divider"></div>
                                                                        <li
                                                                            className='pt-1 pb-1 ps-2 pe-2 d-flex align-items-center pointer_event opacity_dull'
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#isOpenRejectModalPopUpId"
                                                                            onClick={() => { rejectJobOfferFun(item, idx) }}
                                                                        >
                                                                            <img
                                                                                src={require('../../assets/images/scheduled_resume.svg').default}
                                                                                alt="Alt content"
                                                                                style={{ padding: '0px 6px 0px 2px' }}
                                                                            />
                                                                            Reject
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td> */}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </PerfectScrollbar>
                                </div>
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <div className="d-flex justify-content-between align-items-baseline gap-2">
                                        <div>Items per page</div>
                                        <CustomSingleSelect
                                            options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                                            selectedPage={pageSize}
                                            onChangeHandler={onChangeHandler}
                                            disabled={jobOfferList?.length <= 10}
                                        />
                                        <div>{currentPage}-{pageSize?.label} of {jobOfferList.length} items</div>
                                    </div>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={jobOfferList.length}
                                        pageSize={pageSize?.label}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {oldJobOfferList.length === 0 &&
                <>
                    <div className="row bd-example">
                        <div className="table-container">
                            <div className="text_v_h_center vh-80 font_size_24">
                                No Data Found
                            </div>
                        </div>
                    </div>
                </>
            }
        </div >
        {/* Add Post Job Offer modals */}
        <ModalComponent
            title="Release Job Offer"
            content={modalContent()}
            dataBsBackdrop="static"
            id="releaseJobOffer"
            // modalDialogClass="add_requirement_modal_w"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}
        />
        {/* Edit Post Job Details modals */}
        <ModalComponent
            title="Edit Job Details"
            content={editModalContent()}
            dataBsBackdrop="static"
            id="EditJobOfferDetails"
            modalDialogClass="add_requirement_modal_w"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
        />
        {/* View Job Offer Details modals */}
        <ModalComponent
            title={viewSelectedJobOfferDetails?.candidateDetails && viewSelectedJobOfferDetails?.candidateDetails[0]?.firstName}
            content={viewJobDetailsModalContent()}
            dataBsBackdrop="static"
            id="viewJobOfferDetails"
            modalDialogClass="view_job_details_modal_w"
            modalBodyPaddingBottomCls="p-0 overflow_custom"
            isHeaderHide={true}
            cancelPopUpFun={cancelPopUpFun}
        />
        {/* delete Job Offer Details modal */}
        <ModalComponent
            title="Do you want delete selected job offer details ?"
            footerContent={deleteModalFooterContent()}
            content={deleteModalContent()}
            dataBsBackdrop="static"
            id="deleteJobOfferDetailsModal"
            w_max_content={true}
        />
        {/* Reject Offer Modal */}
        <ModalComponent
            title="Confirmation !!"
            footerContent={rejectModalFooterContent()}
            content={rejectModalContent()}
            dataBsBackdrop="static"
            id="isOpenRejectModalPopUpId"
            w_max_content={true}
        />
        <ModalComponent
            title="Release of Purchase order !!"
            // footerContent={releaseOfPurchaseOrderModalFooterContent()}
            content={releaseOfPurchaseOrderBodyContent()}
            dataBsBackdrop="static"
            id="releaseOfPurchaseOrderModalID"
            modalDialogClass="add_requirement_modal_w"
            // w_max_content={true}
            modalBodyPaddingBottomCls="p-0"
        // fullScreen={true}
        />
    </>
}

export default adminLayout(JobOfferedListComponent);