import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import adminLayout from "../../../hoc/adminLayout"
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import { getAllApi, postApi } from '../../../services/apiCommonService'
import { get_all_notifications, delete_notification_by_id } from '../../../services/endPointsConstant'
import storePersist from '../../../Utils/storePersist'
import { formatDateDDMMMYYYY, fromNowFun } from '../../../Utils/utils'

const NotificationPage = (props) => {
    const { user, email, role, id } = storePersist.get('userData')
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [allImportantNotificationsList, setAllImportantNotificationsList] = useState([])

    useEffect(() => {
        async function fetchData() {
            getAllNotificationsApiCallFun()
        }
        fetchData()
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }, [])

    const getAllNotificationsApiCallFun = async (periodVal) => {
        dispatch(addLoader());
        let apiReqBody = {}
        const apiResponse = await postApi(get_all_notifications, apiReqBody)
        if (apiResponse?.data?.Data) {
            setAllImportantNotificationsList(apiResponse?.data?.Data?.reverse())
            // toast.success(apiResponse?.response?.data.message || "Successfully retrived details of dashboard !!")
            dispatch(deleteLoader());
        } else {
            toast.error(apiResponse?.response?.data.message)
            dispatch(deleteLoader());
        }
    };

    const returnIcon = (type) => {
        switch (type) {
            case 'payment':
            case 'invoice':
            case 'resumeScreening':
                return require('../../../assets/images/payment.png');
            case 'resourceProjectQuitting':
            case 'interviewResult':
            case 'replacementRequest':
                return require('../../../assets/images/resource_quitting.svg').default;
            case 'newCandidateOnboarding':
            case 'newCandidateAdded':
            case 'interviewSchedule':
            case 'rescheduleInterview':
            case 'accountManagerAssigned':
                return require('../../../assets/images/newCandidate.jpg');
            default:
                return require('../../../assets/images/payment.png');
        }
    }


    const RedirectionNotificatiosn = (title, item) => {
        // const { user, email, role, id } = storePersist.get('userData')
        if (role === 'ACTMGR') {
            if (title === 'Account manager Unassigned') {
                navigate('/account-managers-list')
            } else if (title === 'New Company Onboarded') {
                navigate('/companies-list');
            } else if (title === 'Candidate  Added') {
                navigate(`/companyjoblist/${item?.companyId}`);
                // navigate('/onboard-candidates-list', { state: { companyId: item._id } })
            } else if (title === 'New Job Added') {
                navigate(`/companyjoblist/${item?.companyId}`);
            } else if (title === 'Interview Proposed') {
                navigate(`/companyjoblist/${item?.companyId}`);
            } else if (title === 'Interview scheduled') {
                navigate(`/companyjoblist/${item?.companyId}`);
            } else if (title === 'Candidate Onboarding') {
                navigate('/job-offers-list');
            } else if (title === 'SOW Uploaded') {
                navigate('/job-offers-list');
            } else if (title === 'Candidate Interview Result') {
                navigate(`/companyjoblist/${item?.companyId}`);
            } else if (title === 'Account manager assigned') {
                navigate('/account-managers-list')
            } else if (title === 'Reschedule Interview') {
                navigate(`/companyjoblist/${item?.companyId}`);
            } else if (title === 'Resume Screening Update') {
                navigate(`/companyjoblist/${item?.companyId}`);
            }
        } else {
            if (title === 'Account manager Unassigned') {
                toast.error("you Don'have a Persmission for the Page !!")
            } else if (title === 'New Company Onboarded') {
                toast.error("you Don'have a Persmission for the Page !!")
            } else if (title === 'Candidate  Added') {
                toast.error("you Don'have a Persmission for the Page !!")
            } else if (title === 'New Job Added') {
                navigate(`/jobs-list`);
            } else if (title === 'Interview Proposed') {
                navigate(`/jobs-list`);
            } else if (title === 'Interview scheduled') {
                navigate(`/jobs-list`);
            } else if (title === 'Candidate Onboarding') {
                navigate('/job-offers-list');
            } else if (title === 'SOW Uploaded') {
                navigate('/job-offers-list');
            } else if (title === 'Candidate Interview Result') {
                navigate(`/jobs-list`);
            } else if (title === 'Account manager assigned') {
                toast.error("you Don'have a Persmission for the Page !!")
            } else if (title === 'Reschedule Interview') {
                navigate(`/jobs-list`);
            } else if (title === 'Resume Screening Update') {
                navigate(`/jobs-list`);
            }
        }
    }

    const deleteNotificationById = async (item) => {
        const { _id, title } = item
        let apiReqBody = {
            notifyId: [_id]
        }
        RedirectionNotificatiosn(title, item)
        const apiResponse = await postApi(delete_notification_by_id, apiReqBody)
        if (apiResponse?.data) {
            toast.success(apiResponse?.data?.message || "deleted Successfully !!")
            dispatch(deleteLoader());
            await getAllNotificationsApiCallFun()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const getColorFun = (type) => {
        switch (type) {
            case 'resourceProjectQuitting':
            case 'interviewResult':
            case 'replacementRequest':
                return 'resource_quitting_cls';
            case 'newCandidateOnboarding':
            case 'newCandidateAdded':
            case 'interviewSchedule':
            case 'rescheduleInterview':
            case 'accountManagerAssigned':
                return 'resource_notification';
            default:
                return require('../../../assets/images/payment.png');
        }
    }

    return (
        <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
            <div className="d-flex justify-content-between align-items-center border-bottom ">
                <h6 className="pb-2 mb-2 font_color_imp fw-semibold">Important Notifications : 
                    <span> (<span className='orange_color'><b>{allImportantNotificationsList?.length}</b></span>)</span>
                </h6>
            </div>
            <div>
                {/* height_notification_table */}
                <div className="row bd-example">
                    {allImportantNotificationsList?.length > 0 &&
                        <PerfectScrollbar className="sidebar-items pb-0">
                            {allImportantNotificationsList?.map((item, idx) =>
                                <div className="d-flex justify-content-center align-items-center pt-2 border-bottom pb-3">
                                    <div className="col-md-1">
                                        <span className="px-3 py-3 rounded_30">
                                            <img alt="Alt content" src={returnIcon(item?.type || 'newCandidateOnboarding')} className="pb_2" />
                                        </span>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="pt-2">
                                            <h6 className="pb-2 mb-0 font_color_imp fw-medium font_size_14">{item?.title || 'New candidates onboarding'}</h6>
                                            <span className="mr-5 font_color_imp fw-medium font_size_14">{item?.description || item?.notification}</span>
                                        </div>
                                        <div className="pt-1">
                                            <span className="font_color_imp fw-medium font_size_12 me-3">{formatDateDDMMMYYYY(item?.createdAt)}</span>
                                            <span className="border_bg_label">
                                                <i className="fa fa-circle font_size_5 font_color_ornage me-1" aria-hidden="true"></i>
                                                <span className="">{fromNowFun(item?.createdAt)}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-2 d-flex justify-content-end pe-3">
                                        <button
                                            type="submit"
                                            className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width ${getColorFun(item.type)}`}
                                            //btn_background_color
                                            onClick={() => { deleteNotificationById(item) }}
                                        >
                                            {item?.action || 'Send Wishes'}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </PerfectScrollbar>
                    }
                    {allImportantNotificationsList?.length === 0 &&
                        <div className="disply_block_t_center">
                            <div className="vh-50 font_size_24 disply_block_t_center padding_15_p">
                                <div>
                                    No Data Found
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default adminLayout(NotificationPage);
