import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment'
// import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import swal from "sweetalert";
import { toast } from 'react-toastify';
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import adminLayout from "../../hoc/adminLayout";
import { downloadPDF } from '../../services/axios-common'
import ModalComponent from "../../components/ModalComponent";
import storePersist from '../../Utils/storePersist';
import { addShortlistedResume } from "../../redux/actions/shortlistedResumes";
import { postApi, getAllApi, deleteApiWithBody, updateWithOutParamsApi } from '../../services/apiCommonService'
import { get_all_candidates, get_Shortlisted_api_url, reschedule_Interview, edit_Offer_api_url, get_comments_api_url, post_comment_api_url, existed_job_data_url, get_jobs_by_company_id } from '../../services/endPointsConstant'
import { formatDate, getDayFun, getISOStringWithoutSecsAndMillisecs } from '../../Utils/utils'

const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const dateVal = new Date();
let dayCurrent = weekday[dateVal.getDay()];

const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
};

const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
    return `${user[0]?.toUpperCase()}${user[1]?.toUpperCase()}`
}

const setValue = (functionFor) => {
    return (e) => {
        const value = parseInt(e.target.value);
        functionFor(value);
    }
};

const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
}

const ShortListedResumesComponent = (props) => {
    const { stageUpdateProps } = props
    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        control,
        reset,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    const {
        register: registerDoc,
        formState: {
            errors: errorsDoc,
            isSubmitting: isSubmittingDoc
        },
        handleSubmit: handleSubmitDoc,
        control: controlDoc,
        reset: resetDoc,
        getValues: getValuesDoc,
        setValue: setValueDoc
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    // dayjs.extend(utc)
    // dayjs.extend(tz)
    // dayjs.tz.setDefault('Asia/Kolkata');

    // const timeZone = dayjs.tz.guess()
    const location = useLocation()
    const formattedDate = 'YYYY-MM-DD HH:mm';
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { shortlistedResumesList } = useSelector((state) => state.shortlistedResumesReducer);
    const [selectedUserDetails, setSelectedUserDetails] = useState();
    const [shortListedCandidatesList, setShortListedCandidatesList] = useState([])
    const [formData, setFormData] = useState(false);
    const [commentsListState, setCommentsList] = useState([])
    const isAuthenticate = storePersist.get('shortListedCandidatesList')
    const [rejectSelectedJobOfferData, setRejectSelectedJobOfferData] = useState();
    const [rejectSelectedJobOfferDataCommentText, setRejectSelectedJobOfferDataCommentText] = useState();
    const { role } = storePersist.get('userData')
    const [enterComment, setenterComment] = useState('')
    const [enterCommentFalse, setEnterCommentFalse] = useState(false)
    const [currentJobData, setCurrentJobData] = useState([])

    const [range, setRange] = React.useState(10);
    const [saturation, setSaturation] = React.useState(50);
    const [lightness, setLightness] = React.useState(50);
    const [theme, setTheme] = React.useState('Light');

    const saturationRange = getRange(saturation, range);
    const lightnessRange = getRange(lightness, range);

    useEffect(() => {
        dispatch(addLoader());
        async function fetchData() {
            await getAllCandidatesList()
            await getJobListApi()
        }
        // fetchData()
        setTimeout(() => {
            dispatch(deleteLoader());
            fetchData()
        }, 3000);
    }, [])

    const getJobListApi = async () => {
        dispatch(addLoader());
        if (location?.state && location?.state?.jobId && location?.state?.companyId) {
            const apiReqBody = { companyId: location?.state?.companyId, jobId: location?.state?.jobId }
            const apiResponse = await postApi(existed_job_data_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                const apiResponseData = [...apiResponse?.data?.Data]?.reverse()?.filter(({ _id }) => _id === location?.state?.jobId)
                setCurrentJobData(apiResponseData && apiResponseData[0] || {})
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 2500);
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        }
    }

    const getAllCandidatesList = async () => {
        dispatch(addLoader());
        const jobDetailsStorage = storePersist.get('jobDetails')
        // const apiReqBody = { jobId: jobDetailsStorage && jobDetailsStorage[0]?._id }

        // const companyDetailsStorage = storePersist.get('companyDetails')
        // const apiReqBody = { companyId: companyDetailsStorage && companyDetailsStorage?._id }
        if (location?.state && location?.state?.jobId && location?.state?.companyId) {
            const apiReqBody = { jobId: location?.state?.jobId }
            // const apiReqBody = { }
            const apiResponse = await postApi(get_Shortlisted_api_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
                apiResponseData = apiResponseData?.map((item) => {
                    return {
                        ...item,
                        interview_date: item?.interview_date || new Date().toISOString().split('T')[0],
                        dayValue: item?.dayValue || dayCurrent
                    }
                })
                setShortListedCandidatesList(apiResponseData)
                setSelectedUserDetails(apiResponseData[0])
                reset({ ...apiResponseData[0] });
                resetDoc({ ...apiResponseData[0] });
                setFormData({ ...apiResponseData[0] })
                // setValueDoc('interview_date', new Date())
                // setValue('interview_date', new Date())
                // toast.success("Retrived all Candidates List Successfully !!")
                dispatch(deleteLoader());
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        }
    }

    const userDetailsOnClikHandler = (shortListedCandidatesList) => {
        dispatch(addLoader());
        setSelectedUserDetails(shortListedCandidatesList)
        reset({ ...shortListedCandidatesList });
        resetDoc({ ...shortListedCandidatesList });
        setFormData({ ...shortListedCandidatesList })
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 2000);
    }

    const onClickPageRedirection = () => {
        stageUpdateProps(0)
        // navigate('/short-list-resumes');
        navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 0 } });
    }

    const onClickRedirectToJobOfferedPage = () => {
        dispatch(addShortlistedResume([selectedUserDetails]))
        stageUpdateProps(2)
        // navigate(`/onboarded-candidate/${selectedUserDetails?.candidate_id}`, { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 3, readOnly: true } });
        if (role === 'COMPANY') {
            navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 2, readOnly: true } });
        } else {
            navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 2 } });
        }
        // navigate('/job-offered');
    }

    const onClickRedirectToJobOfferedPagenext = () => {
        // navigate('/job-offered');
        stageUpdateProps(2)
        navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 2 } });
    }

    const downloadPdf = () => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(selectedUserDetails?.file_location, selectedUserDetails?.candidateFirstName)
        // downloadPDF('https://arxiv.org/pdf/quant-ph/0410100.pdf', 'shortListed')
    }

    const acceptedInterviewProfile = async (event) => {
        if ((selectedUserDetails?.interview_status === 'scheduled' || selectedUserDetails?.interview_status === 'confirmed')) {
            swal({
                title: "Accept !!",
                text: "Are you sure you want to accept the profile?",
                icon: "success",
                buttons: [
                    'No, cancel it!',
                    'Yes, I am sure!'
                ],
                dangerMode: true,
            }).then(function (isConfirm) {
                if (isConfirm) {
                    swal({
                        title: 'Accepted!',
                        text: 'Candidate interview successfully Accepted!',
                        icon: 'success'
                    }).then(async function () {
                        dispatch(addLoader());
                        const apiReqBody = {
                            offerId: selectedUserDetails?.offer_id,
                            interview_status: "accepted",
                            onboarding_status: 'inprogress'
                        }
                        const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
                        if (apiResponse?.data) {
                            toast.success('Accepted interview !!')
                            // await getAllCandidatesList()
                            await onClickRedirectToJobOfferedPage()
                        } else {
                            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                            dispatch(deleteLoader());
                        }
                    });
                } else {
                    swal("Cancelled", "Your imaginary file is safe :)", "error");
                }
            });
        } else {
            toast.error("Interview is to be scheduled !!")
        }
    }

    const rejectJobOfferFun = (item) => {
        setRejectSelectedJobOfferData(item)
    }

    const rejectModalContent = () => {
        return <>
            <textarea
                value={rejectSelectedJobOfferDataCommentText}
                onChange={(e) => { setRejectSelectedJobOfferDataCommentText(e.target.value) }}
                name="Comment"
                placeholder='Enter comment here'
                rows={4}
                cols={33}
            />
        </>
    }

    const cancelFun = () => {
        setRejectSelectedJobOfferDataCommentText('')
    }

    const rejectModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={rejectProfileApiCallHandler}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={cancelFun}
            >
                Cancel
            </button>
        </>;
    }

    const rejectProfileApiCallHandler = async () => {
        const { id } = storePersist.get('userData')
        const apiRejectReqBody = {
            offerId: selectedUserDetails?.offer_id,
            userid: id,
            candidate_id: selectedUserDetails?.candidate_id,
            comment: rejectSelectedJobOfferDataCommentText
        }
        const apiReqBody = {
            offerId: selectedUserDetails?.offer_id,
            interview_status: "rejected",
            rejectComments: rejectSelectedJobOfferDataCommentText
        }
        const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Profile rejected successfully!!')
            await getAllCandidatesList()
            await getJobListApi()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }

        const apiResponseComments = await postApi(post_comment_api_url, apiRejectReqBody)
        if (apiResponseComments?.data) {
            // toast.success('Profile Updated Successfully !!')
            await getAllCandidatesList()
        } else {
            toast.error(apiResponseComments?.response?.data.message || "Api Error Response !!")
        }

    }

    const activityLogModalBodyContent = () => {
        return <div className='d-flex flex-column'>
            {commentsListState.map((item) =>
                // <div className={`d-flex align-items-center m-2 ${item?.userId === isAuthenticate?.id ? 'text-right justify-content-end' : 'text-left justify-content-start'}`}>
                <div className={`${item?.role === 'Company' ? 'bubble_top right' : 'bubble_top text-left'}`}>
                    {/* d-flex align-content-between */}
                    {/* <div className={`${item?.userId !== isAuthenticate?.id && 'd-flex'}`}> */}
                    {/* <div className={`d-flex ${item?.userId === isAuthenticate?.id ? 'justify-content-end' : 'text-left justify-content-start'}`}> */}
                    <div className={`d-flex font_size_12 gap-4  ${item?.role === 'Company' ? 'justify-content-end me-5' : 'ms-5'}`}>
                        <span className='fw-bold'>{item?.role}</span>
                        <span className='fw-bold'>{formatDate(item?.createdAt)}</span>
                    </div>
                    <div className={`d-flex ${item?.role === 'Company' ? 'flex-row-reverse' : ''}`}>
                        <span
                            className={`btn btn_disabled_color m-1 d-flex ${item?.role === 'Company' ? "ms-2" : 'me-2'}  font_size_12 px-2 py-2 rounded-circle h-50`}
                            onClick={() => { }}
                        >
                            {item?.role === "ActMgr" ? 'AM' : 'C'}
                        </span>
                        <span className='d-flex flex-column'>
                            {item?.messages?.map((msg) =>
                                // border_cls p-1 mb-1 bg_header_shortlist_resume w-100 border_radius_10  
                                //className={` ${item?.userId === isAuthenticate?.id ? 'bubble right' : 'bubble'}`}
                                <p className={` ${item?.role === 'Company' ? 'bubble right' : 'bubble'}`}>
                                    {msg}
                                </p>
                            )}
                        </span>
                    </div>
                    {/* <span className='font_size_12'>{item?.date}</span> */}
                    {/* </div> */}
                </div>
            )}
        </div>
    }

    const activityLogModalFooterContent = (props) => {
        return <></>;
    }

    const activityLogsFun = async () => {
        const apiReqBody = { offerId: selectedUserDetails && selectedUserDetails?.offer_id }
        const apiResponse = await postApi(get_comments_api_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            setCommentsList(apiResponseData)
            // toast.success("Retrived all Commentes List Successfully !!")
            dispatch(deleteLoader());
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );

    const confirmScheduleInterview = () => {
        swal({
            title: "Confirm Interview",
            text: "Are you sure you want to Confirm this interview ?",
            icon: "warning",
            buttons: [
                'No',
                'Yes, Confirm it!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Confirmed!',
                    text: 'Candidate interview successfully Confirmed!',
                    icon: 'success'
                }).then(async function () {
                    // form.submit();
                    const apiReqBody = {
                        offerId: selectedUserDetails && selectedUserDetails?.offer_id,
                        interview_status: "confirmed",
                    }
                    const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
                    if (apiResponse?.data) {
                        toast.success('Interview scheduled successfully !!')
                        await getAllCandidatesList()
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                    }
                });
            } else {
                swal("Confirmed", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const onSubmit = async (data) => {
        const dateOneObj = new Date(data?.startTime);
        const dateTwoObj = new Date(data?.endTime);
        const milliseconds = Math.abs(dateTwoObj - dateOneObj);
        const hours = milliseconds / 36e5;

        if(hours > 2){
            toast.error('Interview timeslot exceeded more than 2 hours')
            return 
        }

        setFormData(data);
        // if (role === 'COMPANY' && selectedUserDetails?.interview_status === 'scheduled') {
        //     reSendInvite(data)
        // } else {
        swal({
            title: "Schedule Interview",
            text: "Are you sure you want to schedule this interview ?",
            icon: "success",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Scheduled!',
                    text: 'Candidate interview successfully scheduled!',
                    icon: 'success'
                }).then(async function () {
                    // form.submit();
                    let apiReqBody
                    if (formData?.reschd_interview_date === null && formData?.reschd_dayValue === null && formData?.reschd_startTime == null && formData?.reschd_endTime == null) {
                        apiReqBody = {
                            offerId: selectedUserDetails && selectedUserDetails?.offer_id,
                            interview_date: data?.interview_date,
                            interview_status: "scheduled",
                            startTime: data?.startTime?.$d ? moment(data?.startTime?.$d)?.subtract({ 'hours': 5, 'minutes': 30 }).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z' : data?.startTime,
                            endTime: data?.endTime?.$d ? moment(data?.endTime?.$d)?.subtract({ 'hours': 5, 'minutes': 30 }).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z' : data?.endTime,
                            dayValue: data?.dayValue
                        }
                    } else {
                        apiReqBody = {
                            offerId: selectedUserDetails && selectedUserDetails?.offer_id,
                            interview_date: data?.interview_date,
                            interview_status: "confirmed",
                            // startTime: data?.startTime,
                            // endTime: data?.endTime,
                            startTime: data?.startTime?.$d ? moment(data?.startTime?.$d)?.subtract({ 'hours': 5, 'minutes': 30 }).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z' : data?.startTime,
                            endTime: data?.endTime?.$d ? moment(data?.endTime?.$d)?.subtract({ 'hours': 5, 'minutes': 30 }).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z' : data?.endTime,
                            dayValue: data?.dayValue,
                            reschd_interview_date: null,
                            reschd_dayValue: null,
                            reschd_startTime: null,
                            reschd_endTime: null,
                        }
                    }

                    const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
                    if (apiResponse?.data) {
                        toast.success('Scheduled interview successfully !!')
                        await getAllCandidatesList()
                        document.location.reload();
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                    }
                });
            } else {
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
        // }
    }

    const reSendInvite = (data) => {
        const dateOneObj = new Date(data?.startTime);
        const dateTwoObj = new Date(data?.endTime);
        const milliseconds = Math.abs(dateTwoObj - dateOneObj);
        const hours = milliseconds / 36e5;

        if(hours > 2){
            toast.error('Interview timeslot exceeded more than 2 hours')
            return 
        }
        
        setFormData(data);
        swal({
            title: "Re-Schedule Interview",
            text: "Are you sure you want to Re-Schedule this interview ?",
            icon: "success",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Re-Schedule !',
                    text: 'Candidate interview successfully Re-Scheduled !',
                    icon: 'success'
                }).then(async function () {
                    // form.submit();
                    const apiReqBody = {
                        offerId: selectedUserDetails && selectedUserDetails?.offer_id,
                        interview_date: data?.interview_date,
                        interview_status: "scheduled",
                        startTime: data?.startTime,
                        endTime: data?.endTime,
                        dayValue: data?.dayValue
                    }
                    const apiResponse = await postApi(reschedule_Interview, apiReqBody)
                    if (apiResponse?.data) {
                        document.getElementById("reScheduledModalCloseId").click();
                        toast.success('Interview res-scheduled successfully !!')
                        await getAllCandidatesList()
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                    }
                });
            } else {
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const saveFeedBackPopup = () => {
        if (enterComment) {
            setEnterCommentFalse(false)
            swal({
                title: "Interview Feedback",
                text: "Are you sure you want to save the interview feedback?",
                icon: "success",
                buttons: [
                    'No, cancel it!',
                    'Yes, I am sure!'
                ],
                dangerMode: true,
            }).then(function (isConfirm) {
                if (isConfirm) {
                    swal({
                        title: 'Interview Feedback !',
                        text: 'Candidate interview feedback successfully Saved !',
                        icon: 'success'
                    }).then(async function () {
                        const apiReqBody = {
                            offerId: selectedUserDetails && selectedUserDetails?.offer_id,
                            interview_feedback: enterComment,
                        }
                        const apiResponse = await updateWithOutParamsApi(edit_Offer_api_url, apiReqBody)
                        if (apiResponse?.data) {
                            toast.success('Interview feedback updated successfully !!')
                            await getAllCandidatesList()
                        } else {
                            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                        }
                    });
                } else {
                    swal("Cancelled", "Your imaginary file is safe :)", "error");
                }
            });
        } else {
            setEnterCommentFalse(true)
            toast.error("Feedback contenet can not be empty !!")
        }
    }

    const modifyingSlotsByMngr = (data) => {
        setFormData(data);
        swal({
            title: "Re-Scheduling Interview",
            text: "Are you sure you want to Re-Schedule this interview ?",
            icon: "success",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Re-Schedule !',
                    text: 'Candidate interview successfully Re-Scheduled !',
                    icon: 'success'
                }).then(async function () {
                    // form.submit();
                    const apiReqBody = {
                        offerId: selectedUserDetails && selectedUserDetails?.offer_id,
                        reschd_interview_date: null,
                        reschd_dayValue: null,
                        reschd_startTime: null,
                        reschd_endTime: null,
                    }
                    const apiResponse = await postApi(reschedule_Interview, apiReqBody)
                    if (apiResponse?.data) {
                        toast.success('Profile Updated Successfully !!')
                        await getAllCandidatesList()
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                    }
                });
            } else {
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const onClickPageRedirectionInterviewScreen = () => {
        stageUpdateProps(0)
        // navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 0 } });
    }
    const onClickPageRedirectionInterviewShort = () => {
        stageUpdateProps(1)
        // navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 1 } });
    }
    const onClickPageRedirectionInterviewOffer = () => {
        stageUpdateProps(2)
        // navigate('/short-list-resumes', { state: { jobId: selectedUserDetails?.jobId, companyId: selectedUserDetails?.companyId, stage: 2 } });
    }

    const reScheduleInterviewModal = () => {
        return (
            <div className='p-2 pt-3 mb-2 py-3 px-3'>
                <span className='font_size_14 fw-bold font_size_14'>Re-Schedule interview of this candidate</span>
                {/* <div className='col-md-12 font_blue font_size_14'> */}
                <div className="form-holder pb-0 main_bg rounded-0">
                    <form onSubmit={handleSubmitDoc(reSendInvite)} className='d-flex align-items-center justify-content-start gap-1'>
                        {/* <div className='col-md-8 bg_lite_blue border_cls p-3 pb-0 margin_bottom d-flex align-items-end justify-content-start gap-1'> */}
                        <div className={`col-md-4 marginbottom_int did-floating-label-content ${errorsDoc.interview_date && 'did-error-input'}`}>
                            <input
                                className="did-floating-input input_box_custom"
                                name="interview_date"
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                                max={new Date(new Date().setDate(new Date().getDate() + 14))?.toISOString().split('T')[0]}
                                placeholder="DD-MM-YYYY"
                                {...registerDoc("interview_date", {
                                    required: "Field is required",
                                    onChange: (e) => {
                                        resetDoc({ ...getValues(), dayValue: getDayFun(e?.target?.value) })
                                    }
                                })}
                            />
                        </div>
                        <div className={`col-md-3 marginbottom_int did-floating-label-content ${errorsDoc.dayValue && 'did-error-input'}`}>
                            <select
                                disabled
                                className="custom-select select_custom_h"
                                id="selectmethod"
                                defaultValue=""
                                name="dayValue"
                                {...registerDoc("dayValue", { required: true })}
                            >
                                <option value="" disabled>Select Option</option>
                                <option value="Sunday">Sunday</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                            </select>
                        </div>
                        <div className={`col-md-2_5 margin_b_10`}>
                            <Controller
                                control={control}
                                name="startTime"
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileTimePicker',
                                                ]}
                                            >
                                                <MobileTimePicker
                                                    sx={{ minWidth: '100px!important', height: '42px' }}
                                                    inputProps={{ sx: { padding: '3px ! important' } }}
                                                    value={dayjs(field?.value)}
                                                    // value={dayjs(field?.value || (getValuesDoc('startTime') && getISOStringWithoutSecsAndMillisecs(getValuesDoc('startTime'))))}
                                                    onChange={(date) => {
                                                        field.onChange(date);
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    )
                                }}
                            />
                        </div>

                        <div className={`col-md-2_5 margin_b_10`}>
                            <Controller
                                control={control}
                                name="endTime"
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileTimePicker',
                                                ]}
                                            >
                                                <MobileTimePicker
                                                    sx={{ minWidth: '100px!important', height: '42px' }}
                                                    inputProps={{ sx: { padding: '3px ! important' } }}
                                                    value={dayjs(field?.value)}
                                                    // value={dayjs(field?.value || (getValuesDoc('endTime') && getISOStringWithoutSecsAndMillisecs(getValuesDoc('endTime'))))}
                                                    onChange={(date) => {
                                                        field.onChange(date);
                                                    }}
                                                // format='hh:mm:ss'
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    )
                                }}
                            />
                        </div>
                        {/* <div className={`col-md-2_5 marginbottom_int did-floating-label-content ${errorsDoc.startTime && 'did-error-input'}`}>
                            <input
                                type="time"
                                min="00:00"
                                max="23:59"
                                className="did-floating-input input_box_custom"
                                name="startTime"
                                placeholder=""
                                {...registerDoc("startTime", {
                                    required: "Field is required",
                                })}
                            />
                        </div>
                        <div className={`col-md-2 marginbottom_int did-floating-label-content ${errorsDoc.endTime && 'did-error-input'}`}>
                            <input
                                type="time"
                                min="00:00"
                                max="23:59"
                                className="did-floating-input input_box_custom"
                                name="endTime"
                                placeholder=""
                                {...registerDoc("endTime", {
                                    required: "Field is required",
                                })}
                            />
                        </div> */}
                        {/* </div> */}

                        <div className='reshedule_interview_modal'>
                            <button
                                type="submit"
                                className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                            // onClick={() => { scheduleInterview() }}
                            >
                                {isSubmittingDoc && <span>Submitting</span>}
                                {!isSubmittingDoc && <span>Re-Schedule</span>}
                            </button>
                        </div>
                    </form>
                </div>
                {selectedUserDetails?.interview_status === 'scheduled' &&
                    <div className={`col-md-12 p-3 ${(role === 'ACTMGR' || role === 'ADMIN') ? 'mt-1' : 'mt-4'} font_blue font_size_12 bg_lite_green_color border_dark_green_color`}>
                        <i class="fa fa-check-circle" style={{ color: 'green', fontSize: '18px', paddingRight: '5px' }}></i>
                        <span className='font_dark_green_color'>Please wait. Your Account Manager will confirm the time slot shortly. </span>
                    </div>
                }
                <div className={`col-md-12 p-4 mt-4 border-top`}>

                </div>
                <button className="d-none" id="reScheduledModalCloseId" data-bs-dismiss="modal"></button>
            </div>
        )
    }

    return (
        <div className="my-1 py-3 bg-body rounded shadow-sm rounded_20 height_870">
            {/* <div className="d-flex justify-content-between align-items-center border-bottom pt-2"> */}
            <div className="col-xl-5 col-sm-12 ps-3 pb-2 border-bottom d-flex justify-content-start">
                <h6 className="font_color_imp font_size_16 fw-bolded hover_underline font_color_ornage_imp" onClick={() => { onClickPageRedirection() }}> {'Screen Resumes'}</h6>
                <div className='ms-1 me-1 mb-1 fw-bolded'> <b>{'>'} </b></div>
                <h6 className="font_color_imp font_size_16 fw-bolded"> {' Sheduled for interview'} </h6>
            </div>
            {/* </div> */}

            {shortListedCandidatesList?.length === 0 &&
                <div className="d-flex align-content-center ps-2 pt-1 pb-3 margin_left_right_15 main_bg_interview_sheduled_card">
                    {masterData.map((item, idx) => {
                        return (<div className={`col-md-3 d-flex justify-content-center align-content-center`} key={idx}>
                            <div className="col-xs-12 align-middle">
                                <div className="col-xs-6 me-3">
                                    <img
                                        src={require(`../../assets/images/${item.img}`)}
                                        alt="jobType"
                                        className="img_w_h_23"
                                    />
                                </div>
                                <div className="col-xs-6">
                                    <div className="fw-700 font_size_14 mb-1">{item.label}</div>
                                    <div className="fw-medium font_size_14">{(location?.state?.jobDetails && location?.state?.jobDetails[item.response] || currentJobData && currentJobData[item.response])}</div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            }

            {shortListedCandidatesList.length > 0 &&
                <div className="left-sidebar ps-2">
                    <div className='pt-2'>
                        <div className="d-flex align-content-center p-2 bg_header_shortlist_resume vw-59 ps-4 mb-2">
                            {masterData.map((item, idx) => {
                                return (<div className="col-md-3 d-flex justify-content-start align-content-center" key={idx}>
                                    <div className="col-xs-12 align-middle">
                                        <div className="col-xs-6 me-3">
                                            <img
                                                src={require(`../../assets/images/${item.img}`)}
                                                alt="jobType"
                                                className="img_w_h_23"
                                            />
                                        </div>
                                        <div className="col-xs-6">
                                            <div className="fw-700 font_size_14 mb-1">{item.label}</div>
                                            <div className="fw-medium font_size_14">{selectedUserDetails && selectedUserDetails[item.response]}</div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                        <div className='vw-59 pt-1'>
                            <div className="col-md-12 bg-white border_cls">
                                {/* <div className='pb-2 fw-bolded'>Candidate Scheduled interview date :</div> */}
                                {
                                    ['scheduled', null, 'cancelled', 'confirmed'].includes(selectedUserDetails?.interview_status)
                                        ? <>
                                            <div className='d-flex align-item-center justify-content-between border_dark_bottom py-3 px-3 pb-2'>
                                                <div className='p-2 pt-0 gap-4 d-flex'>
                                                    <div className="col-xs-6">
                                                        <div className="fw-700 font_size_14 mb-1">{selectedUserDetails && selectedUserDetails?.candidateFirstName}</div>
                                                        <div className="fw-medium font_size_14">{selectedUserDetails && selectedUserDetails?.profileTitle}</div>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                                                    onClick={() => { downloadPdf() }}
                                                >
                                                    View Resume
                                                    <img
                                                        src={require(`../../assets/images/download_icon.svg`).default}
                                                        alt="jobType"
                                                        className="img_w_h_23 ms-1"
                                                    />
                                                </button>
                                            </div>
                                            <div className='p-2 pt-3 mb-2 py-3 px-3'>
                                                <span className='font_size_14 fw-bold font_size_14'>Schedule interview of this candidate</span>

                                                <div className='col-md-12 font_blue font_size_14'>
                                                    <form onSubmit={handleSubmit(onSubmit)} className='d-flex align-items-center justify-content-start gap-1'>
                                                        {(((selectedUserDetails?.interview_status === 'scheduled' || selectedUserDetails?.interview_status === 'confirmed') && (role === 'ACTMGR' || role === 'ADMIN')) || selectedUserDetails?.interview_status === null) ?
                                                            <div className='col-md-8 bg_lite_blue border_cls p-3 pb-0 margin_bottom d-flex align-items-end justify-content-start gap-1'>
                                                                <div className={`col-md-4 marginbottom_int did-floating-label-content ${errors.interview_date && 'did-error-input'}`}>
                                                                    <input
                                                                        className="did-floating-input input_box_custom"
                                                                        name="interview_date"
                                                                        type="date"
                                                                        min={new Date().toISOString().split('T')[0]}
                                                                        max={new Date(new Date().setDate(new Date().getDate() + 14))?.toISOString().split('T')[0]}
                                                                        placeholder=""
                                                                        {...register("interview_date", {
                                                                            required: "Field is required",
                                                                            onChange: (e) => {
                                                                                reset({ ...getValues(), dayValue: getDayFun(e?.target?.value) })
                                                                            }
                                                                        })}
                                                                    />
                                                                </div>
                                                                <div className={`col-md-3 marginbottom_int did-floating-label-content ${errors.dayValue && 'did-error-input'}`}>
                                                                    <select
                                                                        disabled
                                                                        className="custom-select select_custom_h"
                                                                        id="selectmethod"
                                                                        defaultValue=""
                                                                        name="dayValue"
                                                                        {...register("dayValue", { required: true })}
                                                                    >
                                                                        <option value="" disabled>Select Option</option>
                                                                        <option value="Sunday">Sunday</option>
                                                                        <option value="Monday">Monday</option>
                                                                        <option value="Tuesday">Tuesday</option>
                                                                        <option value="Wednesday">Wednesday</option>
                                                                        <option value="Thursday">Thursday</option>
                                                                        <option value="Friday">Friday</option>
                                                                        <option value="Saturday">Saturday</option>
                                                                    </select>
                                                                </div>
                                                                {/* <div className={`col-md-2_5 marginbottom_int did-floating-label-content ${errors.startTime && 'did-error-input'}`}> */}
                                                                <div className={`col-md-2_5 margin_b_10`}>
                                                                    <Controller
                                                                        control={control}
                                                                        name="startTime"
                                                                        rules={{ required: true }}
                                                                        render={({ field }) => {
                                                                            return (
                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                    <DemoContainer
                                                                                        components={[
                                                                                            'MobileTimePicker',
                                                                                        ]}
                                                                                    >
                                                                                        <MobileTimePicker
                                                                                            sx={{ minWidth: '100px!important', height: '42px' }}
                                                                                            inputProps={{ sx: { padding: '3px ! important' } }}
                                                                                            // field?.value ? field?.value : getValues('startTime') &&
                                                                                            // defaultValue={getValues('startTime') && getISOStringWithoutSecsAndMillisecs(getValues('startTime'))}
                                                                                            value={dayjs(field?.value)}
                                                                                            onChange={(date) => {
                                                                                                field.onChange(date);
                                                                                            }}
                                                                                        // format='hh:mm:ss'
                                                                                        />
                                                                                    </DemoContainer>
                                                                                </LocalizationProvider>
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>
                                                                {/* <input
                                                                        type="time"
                                                                        min="00:00"
                                                                        max="23:59"
                                                                        className="did-floating-input input_box_custom"
                                                                        name="startTime"
                                                                        placeholder=""
                                                                        {...register("startTime", {
                                                                            required: "Field is required",
                                                                        })}
                                                                    /> */}
                                                                {/* </div> */}
                                                                <div className={`col-md-2_5 margin_b_10`}>
                                                                    <Controller
                                                                        control={control}
                                                                        name="endTime"
                                                                        rules={{ required: true }}
                                                                        render={({ field }) => {
                                                                            return (
                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                    <DemoContainer
                                                                                        components={[
                                                                                            'MobileTimePicker',
                                                                                        ]}
                                                                                    >
                                                                                        <MobileTimePicker
                                                                                            sx={{ minWidth: '100px!important', height: '42px' }}
                                                                                            inputProps={{ sx: { padding: '3px ! important' } }}
                                                                                            // defaultValue={getValues('endTime') && dayjs(getISOStringWithoutSecsAndMillisecs(getValues('endTime')))}
                                                                                            // value={getValues('endTime') && dayjs(getISOStringWithoutSecsAndMillisecs(getValues('endTime')))}
                                                                                            // defaultValue={getValues('endTime') && dayjs(getISOStringWithoutSecsAndMillisecs(getValues('endTime')))}
                                                                                            // value={dayjs(field?.value || (getValues('endTime') && getISOStringWithoutSecsAndMillisecs(getValues('endTime'))))?.subtract(5, 'hour')}
                                                                                            value={dayjs(field?.value)}//          , "Asia/Kolkata"
                                                                                            onChange={(date) => {
                                                                                                field.onChange(date);
                                                                                            }}
                                                                                        // format='hh:mm:ss'
                                                                                        />
                                                                                    </DemoContainer>
                                                                                </LocalizationProvider>
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>
                                                                {/* <div className={`col-md-2 marginbottom_int did-floating-label-content ${errors.endTime && 'did-error-input'}`}>
                                                                    <input
                                                                        type="time"
                                                                        min="00:00"
                                                                        max="23:59"
                                                                        className="did-floating-input input_box_custom"
                                                                        name="endTime"
                                                                        placeholder=""
                                                                        {...register("endTime", {
                                                                            required: "Field is required",
                                                                        })}
                                                                    />
                                                                </div> */}
                                                            </div> :
                                                            <div className='col-md-8 bg_lite_blue border_cls p-3 d-flex align-items-end justify-content-start'>
                                                                <div className='border_right_blue p-2 main-bg-dark-color white_font_color border-end'>{formData?.interview_date}</div>
                                                                <div className='border_right_blue p-2 main-bg-dark-color white_font_color border-end'>{formData?.dayValue}</div>
                                                                <div className='border_right_blue p-2 main-bg-dark-color white_font_color border-end'>{moment(formData?.startTime)?.format('LT')}</div>
                                                                <div className='border_right_blue p-2 main-bg-dark-color white_font_color border-end'>{moment(formData?.endTime)?.format('LT')}</div>
                                                            </div>
                                                        }
                                                        <div className='col-md-4 ms-3'>
                                                            {(selectedUserDetails?.interview_status === 'scheduled' || selectedUserDetails?.interview_status === 'confirmed') ?
                                                                <div className='d-flex align-items-baseline justify-content-start gap-1'>
                                                                    {(role === 'ACTMGR' || role === 'ADMIN') && selectedUserDetails?.interview_status === 'scheduled' && <button
                                                                        type="button"
                                                                        className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                                                        onClick={() => { confirmScheduleInterview() }}
                                                                    >
                                                                        Confirm
                                                                    </button>}
                                                                    {(role === 'ACTMGR' || role === 'ADMIN') && <button
                                                                        type="submit"
                                                                        className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                                                                    // onClick={() => { modifyingSlotsByMngr() }}
                                                                    >
                                                                        Modify
                                                                    </button>}
                                                                </div>
                                                                :
                                                                <>
                                                                    {
                                                                        (role === 'ACTMGR' || role === 'ADMIN') ?
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                                                                            // onClick={() => { scheduleInterview() }}
                                                                            >
                                                                                Schedule Interview
                                                                            </button>
                                                                            :
                                                                            <>
                                                                                {(selectedUserDetails?.interview_status === 'scheduled' || selectedUserDetails?.interview_status === 'confirmed')
                                                                                    ?
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                                                                                    // onClick={() => { scheduleInterview() }}
                                                                                    >
                                                                                        Save
                                                                                    </button>
                                                                                    :
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                                                                                    // onClick={() => { scheduleInterview() }}
                                                                                    >
                                                                                        Schedule Interview
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                        {/* {(role === 'COMPANY' || role === 'USER') (role === 'ACTMGR' || role === 'ADMIN') && <button
                                                            type="submit"
                                                            className='font_dark_color fw-bold font_dark_color_underline hover_underline hover_orange reshedule_interview reshedule_interview'
                                                        // onClick={reSendInvite}
                                                        >
                                                            Send Request to Re-Schedule this Interview
                                                        </button>
                                                        } */}
                                                    </form>
                                                </div>
                                                {(selectedUserDetails?.interview_status === 'scheduled' || selectedUserDetails?.interview_status === 'confirmed') &&
                                                    <div className={`col-md-8 p-3 ${(role === 'ACTMGR' || role === 'ADMIN') ? 'mt-1' : 'mt-4'} font_blue font_size_12 bg_lite_green_color border_dark_green_color`}>
                                                        <i class="fa fa-check-circle" style={{ color: 'green', fontSize: '18px', paddingRight: '5px' }}></i>
                                                        {(formData?.reschd_interview_date !== null || formData?.reschd_dayValue !== null || formData?.reschd_startTime != null || formData?.reschd_endTime != null)
                                                            ?
                                                            <>
                                                                {(role === 'ACTMGR' || role === 'ADMIN')
                                                                    ?
                                                                    <span className='font_dark_green_color'>customer has requested to reschedule the interview to
                                                                        <span className='orange_color'>
                                                                            {formData?.reschd_interview_date} , {formData?.reschd_dayValue} , {formData?.reschd_startTime} , {formData?.reschd_endTime}
                                                                        </span>
                                                                    </span>
                                                                    :
                                                                    <>
                                                                        {(role === 'COMPANY' || role === 'USER')
                                                                            &&
                                                                            <div>Interview modification request is sent to account manager, please wait until we confirm candidate the availabilty.</div>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {selectedUserDetails?.interview_status === 'confirmed' &&
                                                                    <>{(role === 'ACTMGR' || role === 'ADMIN') ?
                                                                        <>
                                                                            {/* <div className={`col-md-12 p-3 ${(role === 'ACTMGR' || role === 'ADMIN') ? 'mt-1' : 'mt-4'} font_blue font_size_12 bg_lite_green_color border_dark_green_color`}>
                                                                            <i class="fa fa-check-circle" style={{ color: 'green', fontSize: '18px', paddingRight: '5px' }}></i> */}
                                                                            <span className='font_dark_green_color'>Candidate confirmed his availability and this Interview is scheduled.</span>
                                                                            {/* </div> */}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {/* <div className={`col-md-12 p-3 ${(role === 'COMPANY') ? 'mt-1' : 'mt-4'} font_blue font_size_12 bg_lite_green_color border_dark_green_color`}>
                                                                            <i class="fa fa-check-circle" style={{ color: 'green', fontSize: '18px', paddingRight: '5px' }}></i> */}
                                                                            <span className='font_dark_green_color'>AccountManger confirmed this Interview for above scheduled date </span>
                                                                            {/* </div> */}
                                                                        </>
                                                                    }
                                                                    </>
                                                                }
                                                                {selectedUserDetails?.interview_status === 'scheduled' &&
                                                                    <>{(role === 'ACTMGR' || role === 'ADMIN') ?
                                                                        <>
                                                                            {/* <div className={`col-md-12 p-3 ${(role === 'ACTMGR' || role === 'ADMIN') ? 'mt-1' : 'mt-4'} font_blue font_size_12 bg_lite_green_color border_dark_green_color`}>
                                                                            <i class="fa fa-check-circle" style={{ color: 'green', fontSize: '18px', paddingRight: '5px' }}></i> */}
                                                                            <span className='font_dark_green_color'>customer has selected this time slot please confirm.</span>
                                                                            {/* </div>  */}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {/* <div className={`col-md-12 p-3 ${(role === 'COMPANY') ? 'mt-1' : 'mt-4'} font_blue font_size_12 bg_lite_green_color border_dark_green_color`}>
                                                                            <i class="fa fa-check-circle" style={{ color: 'green', fontSize: '18px', paddingRight: '5px' }}></i> */}
                                                                            <span className='font_dark_green_color'>Please wait. Your Account Manager will confirm the time slot shortly.</span>
                                                                            {/* </div> */}
                                                                        </>
                                                                    }
                                                                    </>
                                                                }
                                                                {/* {(role === 'ACTMGR' || role === 'ADMIN') ?
                                                                    <span className='font_dark_green_color'>customer has selected this time slot please confirm </span>
                                                                    :
                                                                    <span className='font_dark_green_color'>Please wait. Your Account Manager will confirm the time slot shortly. </span>
                                                                } */}
                                                            </>
                                                        }
                                                    </div>
                                                }

                                            </div>
                                        </>
                                        :
                                        selectedUserDetails?.interview_status === 'rejected'
                                            ?
                                            <span className='font_color_ornage ms-2'>Candidate rejected interview</span>
                                            :
                                            <div className='d-flex align-item-center justify-content-between'>
                                                {/* <div className='border_cls p-2 gap-4 d-flex'>
                                                    <span>
                                                        05-Aug-2023
                                                    </span>
                                                    <span>
                                                        Thursday
                                                    </span>
                                                    <span>
                                                        10.00 AM
                                                    </span>
                                                    <span>
                                                        11.00 AM
                                                    </span>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                                                    onClick={() => { downloadPdf() }}
                                                >
                                                    Download Resume
                                                    <img
                                                        src={require(`../../assets/images/download_icon.svg`).default}
                                                        alt="jobType"
                                                        className="img_w_h_23 ms-1"
                                                    />
                                                </button> */}
                                            </div>
                                }
                                <div className='pt-3 py-3 px-3'>
                                    <div className='pb-2 fw-bolded font_size_14'>Interview Feedback for acceptance/Rejection :</div>
                                    {/* <div className='border_cls p-4 bg_header_shortlist_resume'> */}
                                    <textarea value=
                                        {selectedUserDetails?.interview_status === null
                                            ?
                                            null
                                            :
                                            selectedUserDetails?.interview_status === 'rejected'
                                                ?
                                                'Candidate rejected interview'
                                                :
                                                selectedUserDetails?.interview_status === 'accepted'
                                                    ?
                                                    'Candidate accepted interview'
                                                    : selectedUserDetails?.interview_status === "cancelled" ?
                                                        'Interview canceled due to xyz reason x Due to package some issue happned'
                                                        :
                                                        enterComment
                                        }
                                        rows={5}
                                        cols={20}
                                        className='col-md-12'
                                        onChange={(e) => { setenterComment(e.target.value); setEnterCommentFalse(e.target.value ? false : true) }}
                                        style={{ border: enterCommentFalse ? '1px solid red' : '' }}
                                    />
                                    {/* </div> */}
                                </div>
                            </div>
                            <div className="col-md-12 border_cls footer mt-auto py-2 px-3 bg-white  d-flex align-item-center justify-content-between">
                                {/* <div className='d-flex align-item-center justify-content-between'> */}
                                <div className='d-flex align-item-center justify-content-center'>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 me-3 padding_top_6"
                                        onClick={() => { saveFeedBackPopup() }}
                                    >
                                        Save
                                    </button>
                                    {/* <button
                                    type="button"
                                    className="btn btn-outline-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6 me-3"
                                // onClick={() => { downloadPdf() }}
                                >
                                    Edit Comment
                                </button> */}
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6 me-3"
                                        onClick={() => { activityLogsFun() }}
                                        data-bs-target="#activityLogModal"
                                        data-bs-toggle="modal"
                                    >
                                        Activity Log of Interview Feedback
                                    </button>
                                </div>
                            </div>
                            <div className={`d-flex align-items-baseline ${(selectedUserDetails?.interview_status === 'scheduled' || selectedUserDetails?.interview_status === 'confirmed') ? (role === 'ACTMGR' || role === 'ADMIN') ? 'justify-content-end' : 'justify-content-between' : 'justify-content-end'} mt-2`}>
                                {(role === 'COMPANY' || role === 'USER') && (selectedUserDetails?.interview_status === 'scheduled' || selectedUserDetails?.interview_status === 'confirmed') &&
                                    <div className='font_dark_color fw-bold font_dark_color_underline hover_underline hover_orange'
                                        // onClick={reSendInvite}
                                        data-bs-target="#reScheduledModalId"
                                        data-bs-toggle="modal"
                                    >
                                        Send Request to Re-Schedule this Interview
                                    </div>
                                }
                                {/* showLoaderOnConfirm: true, */}
                                <div className='d-flex align-item-center justify-content-end'>
                                    <button
                                        type="button"
                                        className="btn btn-primary bg_color_ornage pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6 me-3"
                                        onClick={() => { rejectJobOfferFun(selectedUserDetails) }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#isOpenShortListedRejectModalPopUpId"
                                    >
                                        Reject
                                    </button>
                                    {(role === 'ACTMGR' || role === 'COMPANY' || role === 'USER' || role === 'ADMIN') && selectedUserDetails?.interview_status === 'confirmed' &&
                                        <button
                                            type="button"
                                            className="btn btn-primary btn_border pb-2 pe-2 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                                            // onClick={() => { onClickPageRedirection() }}
                                            onClick={() => { acceptedInterviewProfile() }}
                                            disabled={!(selectedUserDetails?.interview_status === 'confirmed')}
                                        >
                                            Accepted
                                            <i className="fa fa-angle-right ps-2"></i>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pt-1_8 vw-22'>
                        <strong>Profiles Updated :</strong> {new Date().toLocaleDateString('en-GB', {
                            day: 'numeric', month: 'short', year: 'numeric'
                        }).replace(/ /g, '-')}
                        {/* ${idx === 0 ? 'mt-4_5' : 'mt-2'} */}
                        <PerfectScrollbar
                            className="vh-90"
                            options={{ suppressScrollX: true }}
                        >
                            {Object.keys(groupBy(shortListedCandidatesList, 'job_code'))?.map((key, id) =>
                                <>
                                    <div className={`font_size_12 fw-bold ${id === 0 ? 'mt-4' : 'mt-2'}`}>{key} :</div>
                                    {groupBy(shortListedCandidatesList, 'job_code')[key]?.map((item, idx) => {
                                        const userName = `${item?.name} ${item?.candidateFirstName}`;
                                        const color = generateColorHsl(userName, saturationRange, lightnessRange);
                                        const initials = getInitials(item?.candidateFirstName);
                                        return (
                                            <div
                                                className={`border_cls mt-2 pb-1 pt-3 main_bg_interview_sheduled_card pointer_event ${selectedUserDetails?.offer_id === item?.offer_id && 'selected_card_border'} ${item?.interview_status === 'accepted' ? 'bg_color_user_details_selected_item' : item?.interview_status === 'rejected' ? 'bg_color_shortlisted_resume_list_cls' : ''}`}
                                                key={idx}
                                                onClick={() => { userDetailsOnClikHandler(item) }}
                                            >
                                                <div className="d-flex justify-content-center align-items-center border-bottom_2">
                                                    <div className="col-md-2 ps-4_5 mb-2">
                                                        <div className="row1" key={idx}>
                                                            <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
                                                        </div>
                                                        {/* <div className="position-relative rounded-circle overflow-hidden mx-auto custom-circle-image border blackcircle">
                                                            <img className="w-100 h-100 whitecircle rounded-circle" alt="Alt content" src={require('../../assets/images/user-profile-icon.svg').default} />
                                                        </div> */}
                                                    </div>
                                                    <div className="col-md-10 ps-5">
                                                        <div>
                                                            <h6 className={`pb-1 mb-0 font_color_imp fw-medium font_size_14 ${selectedUserDetails?.offer_id === item?.offer_id && 'red_color'}`}>{item.candidateFirstName}</h6>
                                                            <span className={`font_color_imp fw-medium font_size_14 ${selectedUserDetails?.offer_id === item?.offer_id && 'red_color'}`}>{item.profileTitle}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 d-flex justify-content-start align-items-center margin_top_neg_60 pt-3">
                                                        <img
                                                            src={require(`../../assets/images/scheduledInterviewRibbon.svg`).default}
                                                            alt="jobType"
                                                            className="img_w_h_18"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className='fw-medium font_size_14'>Years of experience</div>
                                                        <div>{item.candidateExperience}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className='fw-medium font_size_14'>Profile match your requirement</div>
                                                        <div>{item.profileMatch || 'Yes'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </>
                            )}
                        </PerfectScrollbar>
                    </div>
                </div >
            }
            {
                shortListedCandidatesList && shortListedCandidatesList.length === 0 &&
                <>
                    {/* <div className="row bd-example">
                        <div className="table-container"> */}
                    <div className="disply_block_t_center">
                        <div className={`vh-60 font_size_24 disply_block_t_center ${Object.keys(currentJobData)?.length > 0 ? 'p-4' : 'padding_15_p'}`}>
                            {Object.keys(currentJobData)?.length > 0 &&
                                <div className='p-3'>
                                    <img
                                        src={require(`../../assets/images/no_cv_img.svg`).default}
                                        alt="Sow"
                                        className="w-20"
                                    />
                                    {/* {!currentJobData?.newResumeCount === 0 && !currentJobData?.jobOfferedCount === 0 && !currentJobData?.resumeShortlistedCount === 0 && */}
                                    <>
                                        <div className='fw_bold font_dark_color p-2 pb-1 font_size_16'>Candidate resumes is on below state please click on it</div>
                                        <div className='fw_bold font_dark_color p-2 pb-1 font_size_16'> to go to current stage of candidate</div>
                                    </>
                                    {/* } */}
                                </div>
                            }
                            {Object.keys(currentJobData)?.length > 0 &&
                                <div className='font_color_ornage hover_underline'>
                                    {(currentJobData?.resumeShortlistedCount > 0)
                                        &&
                                        <div className='font_dark_green_color hover_underline pb-1 font_size_18' onClick={() => { onClickPageRedirectionInterviewShort(currentJobData) }}>{`Shortlisted (${currentJobData.resumeShortlistedCount})`}</div>
                                    }
                                    {currentJobData?.newResumeCount > 0
                                        &&
                                        <div className='orange_color hover_underline pb-1 font_size_18' onClick={() => { onClickPageRedirectionInterviewScreen(currentJobData) }}>{`Screening (${currentJobData.newResumeCount})`}</div>
                                    }
                                    {currentJobData?.newResumeCount === 0 && currentJobData?.jobOfferedCount === 0 && currentJobData?.resumeShortlistedCount === 0 &&
                                        <div className="font_orange_color_underline pb-1 font_size_18" onClick={() => { onClickPageRedirectionInterviewScreen(currentJobData) }}>No resumes available</div>
                                    }
                                    {currentJobData?.jobOfferedCount > 0
                                        &&
                                        <>
                                            <div className='orange_color hover_underline pb-1 font_size_18' onClick={() => { onClickPageRedirectionInterviewOffer(currentJobData) }}>Job Offered</div>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {/* <div className="col-md-12 footer mt-auto py-2 px-3 bg-white  d-flex align-item-center justify-content-end">
                        <button
                            type="button"
                            className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                            onClick={() => { onClickRedirectToJobOfferedPagenext() }}
                        >
                            Next
                            <i className="fa fa-angle-right ps-2"></i>
                        </button>
                    </div> */}
                </>
            }
            <ModalComponent
                title="Activity Log!!"
                // footerContent={activityLogModalFooterContent()}
                content={activityLogModalBodyContent()}
                dataBsBackdrop="static"
                id="activityLogModal"
                // w_max_content={true}
                // modalBodyPaddingBottomCls="p-0"
                fullScreen={true}
            />
            {/* Reject Offer Modal */}
            <ModalComponent
                title="Confirmation !!"
                footerContent={rejectModalFooterContent()}
                content={rejectModalContent()}
                dataBsBackdrop="static"
                id="isOpenShortListedRejectModalPopUpId"
                w_max_content={true}
            />
            <ModalComponent
                title="Re-Schedule Interview !!"
                content={reScheduleInterviewModal()}
                dataBsBackdrop="static"
                id="reScheduledModalId"
                modalDialogClass="add_requirement_modal_w"
                // w_max_content={true}
                modalBodyPaddingBottomCls="p-0"
            // fullScreen={true}
            />
        </div >
    )
}

// export default adminLayout(ShortListedResumesComponent)
export default ShortListedResumesComponent

const masterData = [
    { label: 'Company Name', response: 'companyName', img: 'skills_needed.svg' },
    { label: 'Job Type', response: 'job_type', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', img: 'Y_O_Exp.svg' },
]
