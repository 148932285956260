export const emptyJobList = 'You have no requirement added. Please add the Job listing as per your requirements.';
export let PageSize = { value: 10, label: 10 };
export const addNewRequirementPlaceholders = {
    jobTypePlaceHolder: "Job Type: Position you are hiring for?",
    jobCodePlaceHolder: "Job Code: Custom Code",
    yearsOfExperiencePlaceHolder: "Experience In Years",
    skillsNeededPlaceHolder: 'Skills needed',
    remoteByHybridDDPlaceHolder: 'Remote / Hybrid',
    salaryByNotDisclosedPlaceHolder: 'Salary / Not disclosed or Can update approximate salary',
    jobDescriptionPlaceHolder: 'Job Description',
    responsibilitiesPlaceHolder: 'Responsibilities',
    min1500WordsPlaceHolder: 'Min 1500 words',
    currencyPlaceHolder: 'currency symbol'
}
