import * as actions from "../actionTypes/index";

const addTour = (payload) => {
    return {
        type: actions.ADD_TOUR,
        payload: payload
    };
};

const deleteTour = (payload) => {
    return {
        type: actions.DELETE_TOUR,
        payload: payload
    };
};

export { addTour, deleteTour };
