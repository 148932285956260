import React, { useState } from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { isEmpty } from '../../../Utils/utils'
import { postApi } from '../../../services/apiCommonService'
import { create_job_api_url } from '../../../services/endPointsConstant'
import storePersist from '../../../Utils/storePersist'
import { useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon"
import AssignedCompaniesList from './AssignCompaniesList'

const ViewAccountManagerDetails = (props) => {
    const navigate = useNavigate();
    const { returnAddAccountManagerFormDetails, modalPopUpCancel, viewSelectedAccountManagerDetails } = props
    const [showPassword, setShowPassword] = useState(false);
    const [confShowPassword, setConfShowPassword] = useState(false);
    const [formData, setFormData] = useState(false);
    const [stageCompleted, setStageCompleted] = useState()
    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        control,
        reset,
        getValues
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    return (
        <div className="form-holder pt-4 ps-8 pe-8 pb-0 main_bg rounded-0">

            {/* <div class="stepper-wrapper">
                <div class={`stepper-item completed`}>
                    <div class="step-counter">1</div>
                    <div class="step-name">Create Account</div>
                </div>
                <div class={`stepper-item completed`}>
                    <div class="step-counter">2</div>
                    <div class="step-name">Assign Companies</div>
                </div>
                <div class="stepper-item completed">
                    <div class="step-counter">3</div>
                    <div class="step-name">Summary</div>
                </div>
            </div> */}

            <section class="py-1">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Account Manger Details
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body ms-5">
                                            <div class="row">
                                                <div className="col-md-12 d-flex align-content-center flex-wrap pb-2">
                                                    {AccountManagerMasterData.map((item, idx) => {
                                                        return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2" key={idx}>
                                                            <div className="col-xs-6 me-2">
                                                                <img
                                                                    src={require(`../../../assets/images/${item.img}`)}
                                                                    alt="jobType"
                                                                    className="img_w_h_11"
                                                                />
                                                            </div>
                                                            <div className="col-xs-6">
                                                                <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                                <div className="fw-medium font_size_14">{viewSelectedAccountManagerDetails?.pageData[item.response]}</div>
                                                            </div>
                                                        </div>)
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            User Name Details
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body ms-5">
                                            <div class="container">
                                                <div class="row">
                                                    <div className="col-md-12 d-flex align-content-center flex-wrap pb-2">
                                                        {userDetailsMasterData.map((item, idx) => {
                                                            return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2" key={idx}>
                                                                <div className="col-xs-6 me-2">
                                                                    <img
                                                                        src={require(`../../../assets/images/${item.img}`)}
                                                                        alt="jobType"
                                                                        className="img_w_h_11"
                                                                    />
                                                                </div>
                                                                <div className="col-xs-6">
                                                                    <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                                    <div className="fw-medium font_size_14">{viewSelectedAccountManagerDetails?.pageData[item.response]}</div>
                                                                </div>
                                                            </div>)
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2 ms-4">
                                                {/* <div className="col-xs-6 me-2">
                                                    <input type="checkbox" name="" />
                                                </div>
                                                <div className="col-xs-6">
                                                    <div className="font_size_14">{'Allow Change password for first time login'}</div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Companies Assigned
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <AssignedCompaniesList
                                                isUnAssign={false}
                                                isCheckboxOff={true}
                                                isAssign={false}
                                                selectedManagerDetails={viewSelectedAccountManagerDetails?.pageData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="border-top position-sticky bottom-0 footer py-3 main_bg float-end">
                {/* <button type="submit"
                    className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6 mt-0 mb-0 pb-2"
                >
                    <span>Cancel</span>
                </button> */}
            </div>
            <button className="d-none mt-0 mb-0 pb-0" id="buttonDismiss" data-bs-dismiss="modal"></button>
        </div>
    );
}

export default ViewAccountManagerDetails

const AccountManagerMasterData = [
    { label: 'Account Manger', response: 'name', value: 'Remote', img: 'ellipse_icon.svg' },
    { label: 'Email ID', response: 'emailId', value: '1234', img: 'ellipse_icon.svg' },
    { label: 'Mobile No', response: 'phoneNumber', value: '5-10 years', img: 'ellipse_icon.svg' },
    { label: 'Date Of Birth', response: 'dob', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Location', response: 'location', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Joining', response: 'joiningDate', value: 'full time', img: 'ellipse_icon.svg' },
]

const userDetailsMasterData = [
    { label: 'User name', response: 'email', value: 'Remote', img: 'ellipse_icon.svg' },
    { label: 'Total Employees Assigned', response: 'totalEmployeeAssigned', value: '1234', img: 'ellipse_icon.svg' },
    { label: 'Total Companies Assigned', response: 'totalCompanyAssignedCount', value: '5-10 years', img: 'ellipse_icon.svg' },
]