import * as actions from '../actionTypes/index'

export const initialState = {}

export default function logOutReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.REST_ALL_STORES:
            return { ...state, ...payload }
        default:
            return state
    }
}
