import React, { useEffect } from 'react';
import adminLayout from "../../hoc/adminLayout";

const viewContractsComponent = () => {
    return (
        <div>
            Development is in progress !!
        </div>
    )
}

export default adminLayout(viewContractsComponent)