import { Link, useLocation } from "react-router-dom";
import storePersist from '../Utils/storePersist'

const NotFound = () => {
    const { pathname } = useLocation();
    const isAuthenticate = storePersist?.get('userData')
    return (
        <div className="page-hero link-style pt-5 pb-8 bg-white pt-2 text-center">
            <h3 className="">The page was not found</h3>
            <div className="d-flex justify-content-center align-items-center pt-1">
                <img src={require('../assets/images/404_page_not_found.png')} alt="Alt content" className="w-30 h-30" />
            </div>
            <div className="col-md-12 text-center d-flex justify-content-center pt-4">
                <span className="w-35">
                    Oops! your <b>Remote72</b> endpoint can't find anything.
                    if it's a new endpoint, it might not be ready yet.
                    You should check again in a few minutes. if the problem persists, please <b>contact Remote72 support </b>.
                </span>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4 pt-2">
                <Link to={isAuthenticate ? "/dashboard" : "/login"} className="btn btn-primary" >
                    Go Back Home
                </Link>
            </div>
        </div>
    );
}

export default NotFound