import * as actions from '../actionTypes/index'

export const initialState = {
    defaultCompanyDetails: '',
    updatedCompanyDetails: null,
    hasErrors: false,
}

export default function defaultCompanyDetailsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.ADD_DEFAULT_COMPANY_DETAILS:
            return { ...state, defaultCompanyDetails: payload }
        case actions.UPDATED_COMPANY_DETAILS:
            return { ...state, updatedCompanyDetails: payload }
        case actions.DELETE_DEFAULT_COMPANY_DETAILS:
            return { ...state, ...payload }
        default:
            return state
    }
}
