import { combineReducers } from 'redux'
import loaderReducer from './loaderReducer'
import shortlistedResumesReducer from './shortlistedResumesReducer'
import jobDetailsReducer from './jobDetailsReducer'
import defaultCompanyDetailsReducer from './defaultCompanyDetailsReducer'
import rejectAndShortlistedReducer from './rejectAndShortListedReducer'
import tourReducer from './tourReducer'
import employeeStepsBarReducer from './employeeStepsBarReducer'
import employeeJobsReducer from './employeeJobsReducer'
import logOutReducer from './logOutReducer'

const rootReducer = combineReducers({
  loaderReducer: loaderReducer,
  shortlistedResumesReducer: shortlistedResumesReducer,
  jobDetailsReducer: jobDetailsReducer,
  defaultCompanyDetailsReducer: defaultCompanyDetailsReducer,
  rejectAndShortlistedReducer: rejectAndShortlistedReducer,
  tourReducer: tourReducer,
  employeeStepsBarReducer: employeeStepsBarReducer,
  employeeJobsReducer: employeeJobsReducer,
  logOutReducer: logOutReducer
})

export default rootReducer
