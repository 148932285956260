import React, { useState, useEffect } from "react";
import Stepper from "react-stepper-horizontal";
import InputIcon from "react-multi-date-picker/components/input_icon"
import { toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import adminLayout from "../../hoc/adminLayout";
import ShortListResumesPage from "./ShortListResumesPage";
import ShortListedResumePage from "./ShortListedResumePage";
import OnBoardedPage from "./onboard_candidate";
import JobOffered from "./JobOffered";

const TabsView_Scheduled_Interview = () => {
    const location = useLocation()
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [stageCompleted, setStageCompleted] = useState(0)
    // location?.state && location?.state?.jobId && location?.state?.companyId
    const stageUpdate = (val) => {
        setStageCompleted(val)
    }

    useEffect(() => {
        setStageCompleted(location?.state?.stage)
    }, [location?.state?.stage])

    return (<>
        {/* <Stepper steps={[{ title: 'Step One' }, { title: 'Step Two' }, { title: 'Step Three' }, { title: 'Step Four' }]} activeStep={2} /> */}
        <div className="stepper-wrapper_tabView">
            {/* <div className={`stepper-item ${stageCompleted === 0 ? 'active' : [1, 2, 3].includes(stageCompleted) && 'completed'}`} onClick={() => location?.state?.readOnly ? '' : (location?.state?.stage === 0 || location?.state?.stage > 0) ? stageUpdate(0) : ''}> */}
            <div className={`stepper-item ${stageCompleted === 0 ? 'active' : [1, 2, 3].includes(stageCompleted) && 'completed'}`} onClick={() => location?.state?.readOnly ? '' : (location?.state?.stage === 0 || location?.state?.stage > 0) ? stageUpdate(0) : ''}>
                <div className="step-counter">1</div>
                <div className="step-name">Screen/Shortlist Resumes</div>
            </div>
            <div className={`stepper-item ${stageCompleted === 1 ? 'active' : [2, 3].includes(stageCompleted) && 'completed'}`} onClick={() => location?.state?.readOnly ? '' : (location?.state?.stage === 1 || location?.state?.stage > 1) ? stageUpdate(1) : ''}>
                <div className="step-counter">2</div>
                <div className="step-name">Schedule Interview</div>
            </div>
            <div className={`stepper-item ${stageCompleted === 2 ? 'active' : [3, 4].includes(stageCompleted) && 'completed'}`} onClick={() => location?.state?.readOnly ? '' : (location?.state?.stage === 2 || location?.state?.stage > 2) ? stageUpdate(2) : ''}>
                <div className="step-counter">3</div>
                <div className="step-name">Job Offered</div>
            </div>
            <div className={`stepper-item ${stageCompleted === 3 ? 'active completed' : [3].includes(stageCompleted) && 'completed'}`} onClick={() => location?.state?.readOnly ? '' : (location?.state?.stage === 3 || location?.state?.stage > 3) ? stageUpdate(3) : ''}>
                <div className="step-counter">4</div>
                <div className="step-name">On Boarded</div>
            </div>
        </div>

        {stageCompleted === 0 && <>
            <ShortListResumesPage stageUpdateProps={stageUpdate} />
        </>}
        {stageCompleted === 1 && <>
            <ShortListedResumePage stageUpdateProps={stageUpdate} />
        </>}
        {stageCompleted === 2 && <>
            <JobOffered stageUpdateProps={stageUpdate} />
        </>}
        {stageCompleted === 3 && <>
            <OnBoardedPage stageUpdateProps={stageUpdate} />
        </>}
    </>)
}

export default adminLayout(TabsView_Scheduled_Interview)
