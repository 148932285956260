export const ADD_LOADER = "ADD_LOADER";
export const DELETE_LOADER = "DELETE_LOADER";
export const UPDATE_LOADER = "UPDATE_LOADER";
export const COMPLETE_LOADER = "COMPLETE_LOADER";
export const ADD_SHORTLISTED_RESUME = "ADD_SHORTLISTED_RESUME";
export const DELETE_SHORTLISTED_RESUME = "DELETE_SHORTLISTED_RESUME";
export const ADD_JOB_DETAILS = "ADD_JOB_DETAILS";
export const DELETE_JOB_DETAILS = "DELETE_JOB_DETAILS";
export const DELETE_DEFAULT_COMPANY_DETAILS = 'DELETE_DEFAULT_COMPANY_DETAILS';
export const ADD_DEFAULT_COMPANY_DETAILS = 'ADD_DEFAULT_COMPANY_DETAILS';
export const UPDATED_COMPANY_DETAILS = 'UPDATED_COMPANY_DETAILS';
export const SHORT_LISTED_CANDIDATES_LIST = 'SHORT_LISTED_CANDIDATES_LIST';
export const REJECTED_LISTED_CANDIDATES_LIST = 'REJECTED_LISTED_CANDIDATES_LIST';
export const ADD_TOUR = "ADD_TOUR";
export const DELETE_TOUR = "DELETE_TOUR";
export const ADD_UPDATE_EMPLOYEE = "ADD_UPDATE_EMPLOYEE";
export const REST_ALL_STORES = "REST_ALL_STORES"
export const ADD_UPDATE_EMPLOYEE_JOB_DETAILS = 'ADD_UPDATE_EMPLOYEE_JOB_DETAILS'