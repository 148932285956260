import React from "react";
import PropTypes from 'prop-types';

const ModalComponent = (props) => {

    const cancelPopUp = () => {
        props?.cancelPopUpFun(true)
    }

    const RenderFooter = (props) => {
        const { isFooterbtnCenter, footerContent, footerRight } = props
        if (footerContent) {
            return <>
                <div className={`modal-footer main_bg ${isFooterbtnCenter ? 'justify-content-center' : footerRight ? 'justify-content-end' : 'justify-content-between'}`}>
                    {footerContent}
                </div>
            </>;
        }
    }

    return <>
        <div
            className={`modal fade ${props.className}`}
            id={props.id}
            tabIndex="-1"
            aria-labelledby={props.exampleModalLabel}
            data-bs-backdrop={props.dataBsBackdrop}
            aria-hidden={props.ariaHidden}
            style={{ display: "none" }}
        >
            <div
                className={`${props.fullScreen ? 'modal-fullscreen' : ''} modal-dialog modal-dialog-scrollable ${props.modalDialogClass} ${props.w_max_content && 'w_max_content'}`}
            >
                <div className="modal-content">
                    {!props.isHeaderHide &&
                        <div className="col-md-12 modal-header d-flex justify-content-between align-items-center">
                            <div
                                className="col-md-11 modal-title fw-medium font_size_18 white_font_color text-center"
                            // id="exampleModalLabel"
                            >
                                {props.title}
                            </div>
                            <div className="col-md-1 d-flex justify-content-end align-items-end">
                                {/* position_absolute right_absolute_6 */}
                                <button
                                    // type="button" padding_6
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props?.cancelPopUpFun && cancelPopUp}
                                >
                                    {/* <img
                                        alt="Alt content"
                                        className="base_img_w_h"
                                        src={require('./../assets/images/modal_close circle.svg').default}
                                    /> */}
                                </button>
                            </div>
                        </div>}

                    <div className={`modal-body main_bg ${props.modalBodyPaddingBottomCls}`}>
                        {props.content}
                    </div>

                    {props?.footerContent && <RenderFooter
                        footerContent={props?.footerContent}
                        isFooterbtnCenter={props?.isFooterbtnCenter}
                        footerRight={props?.footerRight}
                    />}

                </div>
            </div>
        </div>
    </>;
}

ModalComponent.propTypes = {
    title: PropTypes.string.isRequired
};

export default ModalComponent;