import React, { useState, useLayoutEffect, useEffect } from "react";
import { toast } from 'react-toastify';
import swal from "sweetalert";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import { downloadPDF } from '../../../services/axios-common'
import storePersist from '../../../Utils/storePersist'
import { isEmpty } from '../../../Utils/utils'
import { postApi, deleteApiWithBody } from '../../../services/apiCommonService'
import { create_company_url, post_company_logo_api_url, delete_offer_api_url, upload_company_files, upload_candidate_file, create_candidate_details, offer_getShortlisted } from '../../../services/endPointsConstant'
import { returnArryOfObjects } from '../../../Utils/utils'
import { emptyJobList, PageSize } from '../../../Utils/masterData/staticContent'
import CustomSingleSelect from '../../../components/CustomSelectComponent';
import Pagination from '../../../common/pagination/Pagination';

const masterData = [
    { label: 'Job Type', response: 'job_type', value: 'Remote', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', value: '1234', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', value: '5-10 years', img: 'Y_O_Exp.svg' },
]

const ShortlistedCVResumesList = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const d = new Date()
    const newDate = d.toISOString().substring(0, 10);
    const { loading } = useSelector((state) => state.loaderReducer);
    const { shortlistedCandidatesList, rejectedCandidatesList } = useSelector((state) => state.rejectAndShortlistedReducer);
    const { viewSelectedJobDetails, jobList, selectedRecordDataFun, modalPopUpCancel, isOpenViewResume } = props
    const [cmpyDocumentValue, setCmpyDocumentValue] = useState(null);
    const [current, setCurrent] = useState(viewSelectedJobDetails?.idxValue);
    const [formDataDoc, setFormDataDoc] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [allCandidatesList, setAllCandidatesList] = useState([])
    const [selectedCompanyDetailsObject, setSelectedCompanyDetailsObject] = useState([]);
    const [selectedCompanyDetailsRowList, setSelectedCompanyDetailsRowList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(PageSize);

    useEffect(() => {
        // void getCandidatesApiCallFun()
        setAllCandidatesList(shortlistedCandidatesList)
    }, [isOpenViewResume, viewSelectedJobDetails])

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.value;
        const lastPageIndex = firstPageIndex + pageSize?.value;
        return allCandidatesList.slice(firstPageIndex, lastPageIndex);
    };

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(allCandidatesList, ids, 'offer_id')
        setSelectedCompanyDetailsObject(finalUsersData)
    }

    const handleUserSelect = (jobData) => {
        if (selectedCompanyDetailsRowList.includes(jobData.offer_id)) {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData.offer_id));
            callUsersData(selectedCompanyDetailsRowList.filter(_id => _id !== jobData.offer_id))

        } else {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData.offer_id]);
            callUsersData([...selectedCompanyDetailsRowList, jobData.offer_id])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedCompanyDetailsRowList.includes(jobData.offer_id);
        return isUserSelectedVal
    };

    const selectAll = () => {
        setSelectedCompanyDetailsRowList([]);
        if (!isAllSelected) {
            allCandidatesList.forEach(jobData => {
                setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData.offer_id]);
            });
            callUsersData(allCandidatesList.map((item) => item.offer_id))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const getCandidatesApiCallFun = async () => {
        dispatch(addLoader());
        const apiReqBody = { companyId: viewSelectedJobDetails?.companyId }
        const apiResponse = await postApi(offer_getShortlisted, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData.filter((item) => item.jobId === viewSelectedJobDetails?._id)
            setAllCandidatesList(apiResponseData)
            // toast.success("Retrived all Candidates List Successfully !!")
            dispatch(deleteLoader());
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    };

    const downloadPdf = (downloadPath, name) => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(downloadPath, name)
    }

    const cancelModalFunction = () => {
        props.cancelModalFun()
    }

    const onChangeHandler = (event) => {
        setPageSize(event)
    }


    return (
        <>
            <div className="overflow_y_on modal-dialog-scrollable pt-0 pb-5 ps-3 pe-3">
                <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                    {masterData.map((item, idx) => {
                        return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                            <div className="col-xs-12 align-middle">
                                <div className="col-xs-6 me-3">
                                    <img
                                        src={require(`../../../assets/images/${item.img}`)}
                                        alt="jobType"
                                        className="img_w_h_23"
                                    />
                                </div>
                                <div className="col-xs-6">
                                    <div className="fw-700 font_size_15 mb-2">{item.label}</div>
                                    <div className="fw-medium font_size_14">{viewSelectedJobDetails && viewSelectedJobDetails[item.response] || ''}</div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
                <div className="pt-2 font_size_14">
                    <>
                        <div className="table-container">
                            <div className="d-flex text-muted">
                                <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                </th>
                                                {['Name', 'Contact email', 'Phone', 'Interview Status', 'Date uploaded', 'Resume'].
                                                    map((item, idx) =>
                                                        <th key={idx} className={`text-white`}>
                                                            {item}
                                                        </th>
                                                    )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentTableData()?.map((item, idx) => {
                                                return (
                                                    <tr key={item._id}>
                                                        <td>
                                                            <input
                                                                className="width_12"
                                                                type="checkbox"
                                                                checked={isUserSelected(item)}
                                                                onChange={() => handleUserSelect(item)}
                                                            />
                                                        </td>
                                                        <td>{item?.firstName}</td>
                                                        <td>{item?.candidateEmailId}</td>
                                                        <td>{item?.phone_number?.substr(0,2)+"-"+item?.phone_number?.substr(2) || '--'}</td>
                                                        <td>{item?.interview_status || '--'}</td>
                                                        <td>{new Date(item?.createdAt).toLocaleString() || '--'}</td>
                                                        <td>
                                                            <span
                                                                className="d-flex justify-content-between align-items-center gap-2 border_color py-1 px-2"
                                                                onClick={() => {
                                                                    downloadPdf(item?.file_location, item?.firstName)
                                                                }}
                                                            >
                                                                <img
                                                                    src={require(`../../../assets/images/pdf-file-icon.svg`).default}
                                                                    alt="Sow"
                                                                    className="img_w_h_18"
                                                                />
                                                                <div
                                                                    className="fw-medium font_size_12 border-1"
                                                                >
                                                                    {item?.candidateCvfileName}
                                                                </div>
                                                                <img
                                                                    src={require(`../../../assets/images/download_gray_edge.svg`).default}
                                                                    alt="Sow"
                                                                    className="img_w_h_15"
                                                                />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </PerfectScrollbar>
                            </div>
                            {allCandidatesList.length > 0 ?
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <CustomSingleSelect
                                        options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                                        selectedPage={pageSize}
                                        onChangeHandler={onChangeHandler}
                                    />
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={allCandidatesList.length}
                                        pageSize={pageSize?.label}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                                :
                                <div className="row bd-example">
                                    <div className="table-container">
                                        <div className="text_v_h_center vh-40 font_size_24">
                                            No Data Found
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                </div>
            </div>
            {/* <div className="border-top position-sticky bottom-0 footer mt-auto py-3 pb-3 bg-white text-center d-flex justify-content-center"> */}
            <div className="col-md-12 border-top position-sticky bottom-0 footer mt-auto py-3 px-3 bg-white  d-flex align-item-center justify-content-center">
                {/* <div className="col-md-8"> */}
                <button
                    type="close"
                    className="mt-1 mb-1 fw-medium_imp btn btn-outline-primary bg-white btn_height_36 padding_top_6 pb-2 pe-4 ps-4 font_size_14 orange_color_border orange_color"
                    data-bs-dismiss="modal"
                    onClick={() => { cancelModalFunction() }}
                >
                    Cancel
                </button>
            </div>
            <button className="d-none" id="UploadResumeJobDetailsModalIdDismiss" data-bs-dismiss="modal"></button>
        </>
    );
}

export default ShortlistedCVResumesList