export const unversities_colleges = [
'Allahabad College Of Engineering And Management, Allahabad',
'Allahabad degree college, Allahabad',
'Allahabad University Institute of professional studies, Allahabad',
'Allahabad University, Allahabad',
'CMP Degree College, Allahabad',
'Ewing Christian College, Allahabad',
'Government Girls Polytechnic, Allahabad',
'HMFA Memorial Institute of Engineering and Technology, Allahabad',
'IICS College, Allahabad',
'Indian Institute of Information Technology, Allahabad',
'INS Engineering & Management College, Allahabad',
'Institute of Engineering and Rural Technology, Allahabad',
'J.K Institute of Applied Physics and Technology, Allahabad',
'Madhu Vachaspati Institute of Engineering and Technology, Allahabad',
'MNNIT, Allahabad',
'Motilal Nehru Institute of Research and Business Administration, Allahabad',
'Sam Higginbottom University of Agriculture,Technology and Sciences, Allahabad',
'Shambhunath Institute of Engineering and Technology, Allahabad',
'United College of Engineering and Research, Allahabad',
'United College of Engineering and Management, Allahabad',
'United Institute of Technology, Allahabad',
'NIT Agartala, Agartala',
'Ahmedabad University, Ahmedabad',
'AMRUT MODI SCHOOL OF MANAGEMENT, Ahmedabad',
'Babaria institute of technology,vadodara, Ahmedabad',
'Dhirubhai Ambani Institute of Information and Communication Technology, Ahmedabad',
'Gandhinagar Institute of Technology, Ahmedabad',
'L.J. Institute Of Business Administration, Ahmedabad',
'L.J.I.E.T, Ahmedabad',
'Pandit Deendayal Petroleum University, Ahmedabad',
'Sal College of Engineering, Ahmedabad',
'Aligarh Muslim University, Aligarh',
'G.H.Raisoni College of Engineering and Management, Amravati',
'Government College of Engineering Amravati (GCOEA), Amravati',
'HVPM College Of Engineering And Technology, Amravati',
'Prof.Ram Meghe Institute of Technology & Research, Amravati',
'Shri Shivaji Science College, Amravati',
'Intell Engineering College, Ananthapur',
'Balasore College of Engineering and Technology, Balasore',
'Rajkiya Engineering College, Banda',
'Acharya Institute of Graduate Studies, Bangalore',
'AMC City Engineering College, Bangalore',
'Christ University, Bangalore',
'iNSTITUTE OF FINANCE AND INTERNATIONAL MANAGEMENT, Bangalore',
'International Institute of Information Technology, Bangalore, Bangalore',
'International school of Management excellence, Bangalore',
'Kalpataru Institute of Technology , Bangalore',
'M S Ramaiah Institute of Technology, Bangalore',
'National Institute of Business Excellence, Bangalore',
'New Horizon College of Engineering , Bangalore',
'NMIMS - Narsee Monjee Institute of Management Studies, Bangalore',
'Polytechnic, Bangalore',
'R. V. College of Engineering, Bangalore',
'RNS Institute of Technology, Bangalore',
'Purulia Polytechnic, Bankura',
'Giani Zail Singh Punjab Technical University Campus, Bathinda',
'C.V. Raman College of Engineering, Behramour',
'National Institute Of Science And Technology, Behrampur',
'BIHAR AGRICULTURAL UNIVERSITY, Bhagalpur',
'Manakya Lal Verma Textile and Engineering College, Bhilwara',
'MLV TEXTILE AND ENGINEERING COLLEGE, Bhilwara',
'Bansal Group Of Institutions, Bhopal',
'ICSI, Bhopal',
'IES College of Technology, Bhopal',
'Institute of Professional Education and Research (IPER), Bhopal',
'Jagran lakecity University City, Bhopal',
'Lakshmi Narain College of Technology, Bhopal',
'Malhotra Technical Research Institute, Bhopal',
'Maulana Azad National Institute Of Technology, Bhopal, Bhopal',
'Millennium Group Of Institutions, Bhopal',
'NRI Group of Institutions, Bhopal',
'Orential College of Engineering, Bhopal',
'Oriental Institute of Science and Technology, Bhopal',
'Patel Institute of Technology, Bhopal',
'Radharaman Engineering College , Bhopal',
'Radharaman Institute of Technology and Science, Bhopal',
'RKDF University, Bhopal',
'S.V Polytechnic College, Bhopal',
'Sagar Institute of Science Technology & Engineering, Bhopal',
'Samrat Ashok Technological Institute, Bhopal',
'SIRT, Bhopal',
'SISTEC-E, Bhopal',
'Technocrats Institute of Technology, Bhopal',
'truba institute of engineering and information technology. bhopal, Bhopal',
'University Institute of Technology, RGPV, Bhopal',
'BJB Autonomous College, Bhubaneswar',
'C. V. Raman College of Engineering, Bhubaneshwar, Bhubaneswar',
'College of Engineering and Technology, Bhubaneswar',
'College of Engineering Bhubaneswar, Bhubaneswar',
'Gandhi Engineering College, Bhubaneswar',
'Gandhi Institute for Technological Advance, Bhubaneswar',
'GANDHI INSTITUTE FOR TECHNOLOGICAL ADVANCEMENT, Bhubaneswar',
'GANDHI INSTITUTE FOR TECHNOLOGY, Bhubaneswar',
'Gandhi Institute of Technology & Management, Bhubaneswar',
'Hi tech Institute of Technology, Bhubaneswar',
'Hitech institute of technology, Bhubaneswar',
'Indian Institute of Technology Bhubaneswar, Bhubaneswar',
'Institute of Technical Education & Research, Bhubaneswar',
'International Institute of Information Technology, Bhubaneswar, Bhubaneswar',
'KIIT School of Management , Bhubaneswar',
'KIIT University, Bhubaneswar',
'Koustuva Institute of Self Domain , Bhubaneswar',
'Orissa Engineering College , Bhubaneswar',
'Prananath college, Bhubaneswar',
'Siksha O Anusandhan University, Bhubaneswar',
'Silicon Institute of Technology, Bhubaneswar',
'Trident Academy of Technology, Bhubaneswar',
'Xavier Institute of Management, Bhubaneswar, Bhubaneswar',
'Government College of Engineering and Technology, Bikaner',
'Government Engineering College, Bilaspur',
'Guru Ghasidas Vishwavidyalaya, Bilaspur',
'Veer Surendra Sai University Of Technology, Burla',
'Guru Nanak Institute of Technology, Mullana, Chandigarh',
'Panjab University SSG Regional Center, Chandigarh',
'PUIET, Chandigarh',
'University Business School, Chandigarh',
'Annamalai University , Chennai',
'Dr. M.G.R. Educational, Chennai',
'Dr. Magalingam college of Engineering & Technology, Pollachi. Coimbatore.(Tamil Nadu), Chennai',
'Great Lakes Institute of Management, Chennai',
'Hindustan University, Chennai',
'Karunya University, Chennai',
'Loyola Institute of Business Administration, Chennai',
'P.S.G College of Technology , Chennai',
'PANDIAN SARASWATHI YADAV ENGINEERING COLLEGE, Chennai',
'Sri Sairam Engineering College,Chennai, Chennai',
'Sri Sairam Institute of Technology, Chennai',
'SRM University, Chennai',
'St Jospehps college of engineering, Chennai',
'VIT University, Chennai',
'Ravenshaw University, Cuttack',
'Soa University, ITER, Cuttack',
'Sri Sri University, Cuttack',
'DIT University, Dehradun',
'Graphic Era University, Dehradun',
'Guru Ram Das Institute of Management and Technology Ã¢Â€Âƒ, Dehradun',
'TulaÃ¢Â€Â™s Institute, Dehradun',
'University of Petroleum and Energy Studies , Dehradun',
'Uttaranchal institute of management, Dehradun',
'Uttaranchal Institute of Technology, Dehradun',
' Jamia Millia Islamia, New Delhi , Delhi',
'Bhagwan Parshuram Institute of Technology, Delhi',
'Bhaskaracharya College of Applied Sciences,University of Delhi, Delhi',
'Ch. Brahm Prakash Government Engineering College, Delhi',
'College of vocational studies Delhi University, Delhi',
'Delhi Technological University, Delhi',
'Department of Business Economics, Delhi University , Delhi',
'Faculty of Management Studies, Delhi',
'IIT Delhi, Delhi',
'Indira Gandhi Institute of Technology, Delhi',
'Jamia Hamdard University, Delhi',
'Jawaharlal Nehru University, Delhi',
'Jesus and Mary College, Delhi',
'Kirori Mal College, Delhi',
'Maharaja agrasen institute of technology, Delhi',
'Netaji Subhas Institute of Technology, Delhi',
'Northern India Engineering College, Delhi',
'Shaheed Bhagat Singh College, Delhi',
'Shri Ram College of  Commerce, Delhi',
'Sri Aurobindo College , Delhi',
'Sri Aurobindo College (Evening), Delhi',
'Sri Venkateswara College, Delhi',
'The Institute of Chartered Accountants of India, Delhi',
'Translational Health Science and Technology Institute, Delhi',
'Vivekananda Institute of Advanced Studies, Delhi',
'Vivekananda Institute of Professional Studies, Delhi, Delhi',
'BIT Sindri, Dhanbad',
'Indian Institute of Technology, Dhanbad, Dhanbad',
'Bhilai Institute of Technology Ã¢Â€Â“ Durg , Durg',
'Christian College of Engineering and Technology, Durg',
'dignity college of Architecture Durg, Durg',
'Rungta College of Engineering & Technology, Durg',
'Shri Shankaracharya Institute of Technology and Management, Durg',
'B.C.Roy Engineering College, Durgapur',
'BCET Durgapur, Durgapur',
'Bengal Institute of Technology and Management Delete rows, Durgapur',
'DIATM, Durgapur',
'NATIONAL POWER TRAINING INSTITUTE DURGAPUR, Durgapur',
'NIT Durgapur, Durgapur',
'Manav Rachna International University, Faridabad',
'MVN University, Faridabad',
'YMCA University of Science and Technology, Faridabad',
'Leelaben Dashrathbhai Ramdas Patel Institute Of Technology And Research, Gandhinagar',
'Shankersinh Vaghela Bapu Institute of Technology, Gandhinagar, Gandhinagar',
'Birla Institute of Technology and Science, Goa',
'Birla Institute of Technology and Science, Pilani K.K. Birla Goa Campus, Goa',
'NATIONAL INSTITUTE OF TECHNOLOGY, GOA, Goa',
'Acharya Nagarjuna University, Guntur',
'Bapatla Engineering College, Guntur',
'K L University, Guntur',
'KKR and KSR Institute of Technology, Guntur',
'LIFE, Guntur',
'RVR & JC College of Engineering, Guntur',
'Vasireddy Venkatadri Institute of Technology, Guntur',
'Vignan University, Guntur',
'Gandhi Institute of Engineering and Teachnology, Gunupur',
'Ansal University, Gurugram',
'BML MUNJAL UNIVERSITY, Gurugram',
'Dronacharya College of Engineering, Gurugram',
'IILM Institute for Business and Management, Gurugram',
'Assam Engineering College, Jalukbari, Guwahati',
'IIT Guwahati, Guwahati',
'Indian Institute of Information Technology and Management, Gwalior',
'ITM University Gwalior, Gwalior',
'Madhav Institute of Technology and Science, Gwalior',
'Prestige Institute of Management, Gwalior',
'Dr. Meghnand Saha Institute of Technology, Haldia',
'Haldia Institute of Technology, Haldia',
'BIT Institute of Technology, Hindupur',
'Guru Jambheshwar University of Technology, Hisar',
'Al Habeeb College of Engineering and Technology, Hyderabad',
'AZAD COLLEGE OF ENGINEERING AND TECHNOLOGY, Hyderabad',
'B.V Raju Institute of Technology, Hyderabad',
'Chaitanya Bharathi Institute of Technology, Hyderabad',
'CMR ENGINERRING COLLEGE, Hyderabad',
'CMR Institutions , Hyderabad',
'CMR Technical Campus, Hyderabad',
'CVR College of Engineering, Hyderabad',
'CVSR College of Engineering, Hyderabad',
'Deccan College of Engineering and Technology, Hyderabad',
'GITAM University, Hyderabad',
'Gokaraju Rangaraju Institute of Engineering and Technology, Hyderabad',
'Guru Nanak Institute of Technology, Hyderabad',
'Guru Nanak Institutions Technical Campus, Hyderabad',
'Hyderabad Institute of Technology and Management, Hyderabad',
'ICFAI, Hyderabad',
'IIT Hyderabad , Hyderabad',
'JNTUH College of Engineering Hyderabad, Hyderabad',
'KMIT, Hyderabad',
'Mahatma Gandhi Institute of Technology, Hyderabad',
'Mahindra Ecole Centrale, Hyderabad',
'Malla Reddy Engineering College, Hyderabad',
'Matrusri Engineering College, Hyderabad',
'Maturi Venkata Subba Rao Engineering College, Hyderabad',
'Methodist College of Engineering & Technology, Hyderabad',
'National Institute of Fashion Technology (NIFT), Hyderabad',
'RGUKT IIIT, Hyderabad',
'RVR Group of Institutions, Hyderabad',
'Shadan College of Engineering and Technology, Hyderabad',
'Sreenidhi Institute of Science and technology , Hyderabad',
'Sreyas Institute of Science & Technology, Hyderabad',
'Sri Indu College of Engineering and Technology, Hyderabad',
'Swami Vivekananda Institute of Technology, Hyderabad',
'TKR College of Engineering & Technology, Hyderabad',
'University of Hyderabad, Hyderabad',
'Vardhaman College of Engineering, Hyderabad',
'Vasavi College of Engineering, Hyderabad',
'Vignan University, Hyderabad',
'Vignana Bharati Institute of Technology, Hyderabad',
'Vijaya School of Business Management , Hyderabad',
'Visiontech College Of Engineering, Hyderabad',
'VNR Vignana Jyothi Institute of Engineering and Technology, Hyderabad',
'Warangal Institute of Technology, Hyderabad',
'Acropolis Institute of Technology and Research, Indore',
'CDGI, Indore',
'Government Engineering College of Ujjain, Indore',
'IET DAVV, Indore',
'Indore Institute of science and technology, Indore',
'Institute of engineering and science IPS academy, Indore',
'International Institute of Professional Studies, Indore',
'LNCT Indore, Indore',
'Lord krishna college of technology, Indore',
'Malwa Institute of Technology, Indore',
'Medi-Caps Institute of Science &Technology, Indore',
'Medi-Caps Institute of Technology & Management, Indore',
'MIT MANDSAUR, Indore',
'Oriental University, Indore',
'Prestige institute of engineering, management and research, Indore',
'Prestige Institute of Management and Research, Indore',
'Sanghvi Institute of management and science, Indore',
'Shri Govindram Seksaria Institute of Technology and Science , Indore',
'Shri Vaishnav Institute of Technology and Science, Indore',
'Sir aurobindo Institute of technology, Indore',
'St. Paul Institute of Professional Studies , Indore',
'Sushila devi Bansal college of technology, Indore',
'Swami vivekanand college of engineering, Indore',
'Global College of Engineering and Technology, Jabalpur',
'GS College of Commerce and Management, Jabalpur',
'Guru Ramdas khalsa Institute of Science and Technology, Jabalpur',
'Gyan Ganga College Of Technology (GGCT), Jabalpur',
'Gyan Ganga Institute of Technology & Sciences (GGITS), Jabalpur',
'Hitkarini College of Engineering and Technology, Jabalpur',
'ICFAI National College College of Materials Management, Jabalpur',
'Indian Institute of Information Technology, Design and Manufacturing(IIIT-DM), Jabalpur',
'Jabalpur Engineering College (JEC), Jabalpur',
'Lakshmi Narain College of Technology (LNCT), Jabalpur',
'Mata Gujri Mahila Mahavidyalaya, Jabalpur',
'Millennium College of Professional Education, Jabalpur',
'Oriental Engineering College, Jabalpur',
'Oriental Institute Of Science & Technology (OIST), Jabalpur',
'Prakash Institute of Technology, Jabalpur',
'Radhaswami Institute of Engineering, Jabalpur',
'Rani Durgavati Vishwavidyalaya, Jabalpur',
'Saraswati Institute of Technology, Jabalpur',
'SGBM Institute of Technology & Science, (SGBMITS), Jabalpur',
'Shri Ram Institute of Management, Jabalpur',
'Shri Ram Institute of Technology, Jabalpur',
'St. Aloysius College, Jabalpur',
'Takshila College of Engineering and Technology, Jabalpur',
'University Institute of Management, Jabalpur',
'Vindhya Institute of Technology & science (VITS), Jabalpur',
'Xavier Institute of Management, Jabalpur',
'Government Engineering College, Jagdalpur',
'Amity University Rajasthan, Jaipur',
'Apex Group of Institutions, Jaipur',
'Banasthali University, Jaipur',
'Birla Institute of Technology and Science, Jaipur',
'BIT Jaipur, Jaipur',
'BIT Mesra Jaipur Campus, Jaipur',
'Biyani Group Of Colleges, Jaipur',
'Chanakya teacnical campus, Jaipur',
'Engineers Academy, Jaipur',
'Global Institute of Technology , Jaipur',
'International College for Girls, Jaipur',
'Jaipur National University, Jaipur',
'JECRC UDML College of Engineering , Jaipur',
'JECRC University, Jaipur',
'LNMIIT, Jaipur',
'Maharishi Arvind College of Engineering & Research Center, Jaipur',
'Maharishi Arvind Institute of Engineering & Technology, Jaipur',
'Malaviya National Institute of Technology, Jaipur',
'Manipal University Jaipur, Jaipur',
'PIET, Jaipur',
'Poonima Group of Institutions, Jaipur',
'Poornima College of Engineering, Jaipur',
'Poornima University, Jaipur, Jaipur',
'Suresh Gyan Vihar School of Engineering & Technology, Jaipur',
'Swami Keshvanand Institute Of Technology, Jaipur',
'University of Engineering and Management, Jaipur, Jaipur',
'Yagyavalkya Insitute of Technology , Jaipur',
'Yagyavalkya Institute of Technology, Jaipur',
'Dr. B. R. Ambedkar National Institute of Technology Jalandhar, Jalandhar',
'Lovely Professional University, Jalandhar',
'Jalpaiguri Government Engineering College, Jalpaiguri',
'MBS College Of Engineering And Technology (MBSCET), Jammu',
'Shri Vaishno Devi University, Jammu',
'Jain College Jamshedhpur, Jamshedhpur',
'National Institute of Technology, Jamshedpur, Jamshedhpur',
'Netaji Subhas Institute of Business Management, Jamshedhpur',
'XLRI - Xavier School of Management, Jamshedhpur',
'R.V.S COLLEGE OF ENGINEERING AND TECHNOLOGY, Jamshedpur',
'JIET, Jodhpur',
'MBM, Jodhpur',
'Mulungushi University, Kabwe',
'Allenhouse Institute of Technology, Kanpur',
'Apollo Institute of Technology, Kanpur',
'Axis college, Kanpur',
'Dr. Gaur Hari Singhania Institute Of Management & Research, Kanpur',
'Dr. Virendra Swarup Group of Institutions, Kanpur',
'Dr. Virendra Swarup Institute of Computer Studies (VSICS), Kanpur',
'Harcourt Butler Technical Institute, Kanpur',
'Indian institute of technology kanpur, Kanpur',
'Jagran College Of Arts, Science And Commerce, Kanpur',
'Kanpur Institute of Technology, Kanpur',
'Krishna Institute of Technology, Kanpur',
'Maharana Pratap Engineering College, Kanpur',
'Pranveer Singh Institute of Technology, Kanpur',
'Jyothismathi Institute of Technological Sciences, Karimnagar',
'IIT Kharagpur, Kharagpur',
' SWAMI VIVEKANANDA INSTITUTE OF SCIENCE AND TECHNOLOGY. , Kolkata',
'Academy of Technology , Kolkata',
'Adamas Institute of Technology, Kolkata',
'Adamas University, Kolkata',
'Aliah University, Kolkata',
'Amity University, Kolkata',
'Army Institute of Management, Kolkata, Kolkata',
'B. P. Poddar Institute of Management & Technology , Kolkata',
'Calcutta Business School, Kolkata',
'Calcutta Institute of Engineering and Management, Kolkata',
'Calcutta Institute of Technology, Kolkata',
'FUTURE INSTITUTE OF ENGINEERING AND MANAGEMENT, Kolkata',
'Globsyn Business School, Kolkata',
'Heritage Institute of Technology, Kolkata',
'hooghly engineering and technology college , Kolkata',
'I LEAD, Kolkata',
'Indian Institute of Engineering Science and Technology, Shibpur, Kolkata',
'Institute Of Engineering & Management, Kolkata',
'International Management Institute Kolkata, Kolkata',
'J.D.Birla Institute, Kolkata',
'Jadavpur University, Kolkata',
'JIS COLLEGE OF ENGINEERING, Kolkata',
'Kalyani Government Engineering College, Kolkata',
'Meghnad Saha Institute of Technology, Kolkata',
'Narula Institute of Technology , Kolkata',
'Neotia Institute of Technology, Management and Science, Kolkata',
'Netaji Subhash Engineering College, Kolkata',
'NLSIU, Bangalore, Kolkata',
'Prafulla Chandra College, Kolkata',
'Presidency University, Kolkata',
'RCC IIT, Kolkata',
'Scottish Church College, kolkata, Kolkata',
'Shri Shikshayatan College , Kolkata',
'St. Thomas College of Engineering and Technology, Kolkata',
'Techno , Kolkata',
'Techno India College of Technology, Kolkata',
'Techno India Salt Lake - B.Tech, Kolkata',
'Techno India Salt Lake - MBA Section, Kolkata',
'Techno India School of Management Studies, Kolkata',
'Techno India University, Kolkata',
'The Bhawanipur Education Society College, Kolkata',
'University of Engineering & Management,Kolkata(UEMK), Kolkata',
'Institute of Management Studies(IMS),kurukshetra university, Kurukshetra',
'NIT Kurukshetra, Kurukshetra',
'Amity University, Lucknow',
'Babu Banarasi Das Northern India Institute of Technology, Lucknow',
'BBAU Lucknow, Lucknow',
'City Montessori,  Lucknow, Lucknow',
'Indian Institute of Management Lucknow, Lucknow',
'Institute of Engineering and Technology, Lucknow',
'Integral University, Lucknow',
'Jaipuria Institute of Management Ã¢Â€Âƒ, Lucknow',
'S V M Inter College, Lucknow',
'Sri Ramswaroop Memorial College of Engineering and Management Lucknow, Lucknow',
'Manipal Institute of Technology',
', Manipal',
'Manipal Institute of Technology, Manipal',
'GLA University Mathura, Mathura',
'Lions School, Mirzapur',
'Chandigarh University, Mohali',
' K.J. Somaiya college, Mumbai',
'Aditya Institute of Management Studies, Mumbai',
'Atharva College of Engineering , Mumbai',
'BT Institute Of Journalism, Mumbai',
'Durgadevi Saraf Institute of Management Studies, Mumbai',
'K. J. Somaiya College of Engineering, Mumbai',
'K. J. Somaiya Institute of Management Studies and Research, Mumbai',
'K.J. Somaiya college of engineering, Mumbai',
'Nagindas Khandwala College, Mumbai',
'Sardar Patel College of Engineering, Mumbai',
'Sardar Patel Institute of Technology, Mumbai',
'St. Francis Institute of Technology, Mumbai',
'Sydenham Institute of Management Studies, Research and Entrepreneurship Education, Mumbai',
'Tata Institute Of Social Science, Mumbai',
'Thadomal Shahani Engineering College, Mumbai',
'Thakur Institute of Management Studies & Research, Mumbai',
'Vartak College of Engineering and Technology, Mumbai',
'Veermata Jijabai Technological Institute, Mumbai',
'Vidyalankar School of Information , Mumbai',
'Anjuman College, Nagpur',
'Dr Ambedkar Institute of Management and Research, Nagpur',
'G.H Raisoni College Of Engineering, Nagpur',
'GNIET, Nagpur',
'GS College of Commerce, Nagpur',
'Hislop College, Nagpur',
'IMT, Nagpur',
'Indian Institute of Management, Nagpur, Nagpur',
'Kavi Kulguru Institute Of Technology & Science , Nagpur',
'MGI Group of Institutions, Nagpur',
'Nagpur Institute of Technology, Nagpur',
'Priyadarshini College of Engineering, Nagpur',
'Priyadarshini Institute of engineering and Technology, Nagpur',
'Priyadarshini Lokmanya Tilak Institute of Management Studies & Research, Nagpur',
'SB Jain, Nagpur',
'Shatayu College of Professional Studies, Nagpur',
'Shri Ramdeobaba College of Engineering and Management, Nagpur',
'St. Vincent Palloti College, Nagpur',
'Tirpude Institute of Management Education, Nagpur',
'VNIT, Nagpur',
'Yashoda Public Higher Secondary School, Nagpur',
'Amity University, Noida',
'Army Institute Of Management And Technology, Noida',
'Galgotias College of Engineering and Technology, Noida',
'GL Bajaj Institute of technology and management, Noida',
'ITS Engineering College, Noida',
'JRE Group of Institutions, Noida',
'JSS Academy Of Technical Education, Noida, Noida',
'MGM College of Engineering and Technology , Noida',
'Noida Institute of Engineering and Technology, Noida',
'Shiv Nadar University , Noida',
'CENTURION UNIVERSITY OF TECHNOLOGY AND MANAGEMENT, PARALAKHEMUNDI',
'Lakshay Chopra studying at Thapar University, Patiala',
'Admerit College, Patna',
'Amity Global Business School, Patna',
'Arcade Business College, Patna, Patna',
'Bihar National College, Patna',
'Birla Institute of Technology, Patna, Patna',
'CIMAGE, Patna',
'Indian Institute of Technology Patna, Patna',
'INTERNATIONAL BUSINESS COLLEGE, Patna',
'LNM Institute of Economic Development and Social Change, Patna',
'Magadh Mahila College, Patna',
'National Institute of Technology, Patna, Patna',
'Patna Science College, Patna',
'Patna Womens College, Patna',
'R.P. Sharma Institute of Technology, Patna',
'Usha Martin Academy, Patna',
'Vanijya Mahavidyaleya, Patna university, Patna',
'Birla Institute of Technology and Science, Pilani',
'BITS Pilani, Pilani',
'Pondicherry University, Pondicherry',
'School of Management, Pondicherry University, Pondicherry',
'.MKSSSÃ¢Â€Â™s Cummins College of Engineering for Women, Pune',
'AISSMS College of Engineering, Pune',
'Allana institute of management sciences,Pune, Pune',
'College of Engineering, Pune, Pune',
'Dhole Patil  College of Engineering, Pune',
'DPSS Foresight College of Commerce, Pune, Pune',
'DY Patil College of Engineeriing, Pune',
'International Institute of Information Technology, Pune, Pune',
'Maharashtra Institute of Technology, Pune',
'Pimpri Chinchwad College of Engineering, Pune',
'Pune Institute of Computer Technology, Pune',
'Shahu College, Pune',
'Sinhgad College of Engineering, Pune',
'Sinhgad Institute of Management, Pune',
'Smt. Kashibai Navale College of Engineering, Pune',
'Symbiosis Institute of International Business, Pune',
'TSSM Bhivarabai Sawant College of Engineering and Research, Pune, Pune',
'Vishwakarma Institute of Management, Pune',
'Vishwakarma Institute of Technology, Pune',
'Kirodimal Institute of Technology, Raigarh',
'O.P JINDAL UNIVERSITY, Raigarh',
'AVS Presidency International College, Raipur',
'Bhilai Institute Of Technology, Raipur',
'Disha college of Engg. & Management, Raipur',
'Government Engineering College, Raipur',
'IIM Raipur, Raipur',
'IIT Bhilai, Raipur',
'ITM University, Raipur',
'Kruti Institute of Technology and Engineering, Raipur',
'MATS University, Raipur',
'National Institute of Technology, Raipur, Raipur',
'Raipur Institute of Technology, Raipur',
'Rungta College of Engg., Raipur',
'Rungta College of Engg. & Technology, Raipur',
'SHRI SHANKARACHARYA TECHNICAL CAMPUS , Raipur',
'GIET college of engineering, Rajamundry',
'LATE SHREE S.G DHOLAKIYA MEMO. HIGH SCHOOL, Rajkot',
'Chitkara Institute of Engineering and Technology , Rajpura',
'Amity University Ranchi, Ranchi',
'BIRLA INSTITUTE OF TECHNOLOGY, Ranchi',
'Birla Institute of Technology, Mesra, Ranchi',
'Birsa Institute of Technology Sindri , Ranchi',
'Central University of Jharkhand, Ranchi',
'Doranda College, Ranchi',
'Gossner College, Ranchi',
'ICFAI university, jharkhand (IUJ), Ranchi',
'IIM Ranchi, Ranchi',
'Institute of Management Studies, Ranchi University, Ranchi',
'Marwari College Ã¢Â€Âƒ, Ranchi',
'Ranchi College, Ranchi',
'Usha Martin Academy, Ranchi',
'Xavier Institute of Social Service, Ranchi',
'Indian Institute of Technology, Ropar, Ropar',
'IIT Rorkee, Rorkee',
'NIT Rourkela, Rourkela',
'Surendra Institute of Engineering & Management, Siliguri',
'National Institute of Food Technology Entrepreneurship and Management, Sonipat',
' aditya institute of technology and management, Srikakulam',
'Aditya Institute of technology and management, Srikakulam',
'JNTU College of Engineering , Sultanpur',
'Kamla Nehru Institute of Technology, Sultanpur',
'Kmala Nehru Institute of Technology, Sultanpur',
'AURO University, Surat',
'C. K. Pithawala College of Engineering and Technology, Surat',
'Sardar Vallabhbhai National Institute of Technology, Surat',
'Sarvajanik College of Engineering and Technology, Surat',
'Tamralipta Mahavidyalay, Tamluk',
'Siddaganga Institute of Technology, Tumkur, Tumkur',
'Udalguri College, Udalguri',
'Manipal Institute of Technology',
', Udupi',
'BHU Banaras, Varanasi',
'School of Management Sciences, Varanasi, Varanasi',
'Andhra Loyola Institute, Vijayawada',
'Dhanekula Institute of Engineering and Technology, Vijayawada',
'Gudlavalleru Engineering College is actually, Vijayawada',
'Lakireddy Bali Reddy College of Engineering , Vijayawada',
'NRI Institute of Technology, Vijayawada',
'Pb Siddhartha College of Arts and Sciences, Vijayawada',
'Prasad V Potluri Siddhartha Institute of Technology, Vijayawada',
'PSCMR College of Engineering & Technology, Vijayawada',
'SRK Institute of Technology, Vijayawada',
'V R Siddhartha College of Engineering, Vijayawada',
'Aditya Degree College, Visakhapatnam',
'Andhra University College of Engineering, Visakhapatnam',
'Anil Neerukonda Institute of Technology and Sciences, Visakhapatnam',
'AQJ PG College, Visakhapatnam',
'B.V. K. College, Visakhapatnam',
'BITS, Vizag, Visakhapatnam',
'Dr.L.Bullayya College, Visakhapatnam',
'Gandhi Institute of Technology and Management, Visakhapatnam',
'Gayatri Vidya Parishad, Visakhapatnam',
'Gonna institute of Information Technology and sciences(GIITS), Visakhapatnam',
'Prism College, Visakhapatnam',
'Raghu Engineering College, Visakhapatnam',
'Raghu Jr. College, Visakhapatnam',
'Sanketika Vidya Parishad College, Visakhapatnam',
'Vignan Institute Of Information Technology. Duvvada , Visakhapatnam',
'Lendi institute of Engineering and Technology, Vizianagaram',
'M.V.G.R.College Of Engineering, Vizianagaram',
'Alluri Institute Of Management Sciences, Warangal',
'CHRISTHU JYOTHI INSTITUTE OF TECHNOLOGY & SCIENCES, Warangal',
'National Institute of Technology, Warangal, Warangal',
'S R Engineering College, Warangal',
'SVS Engineering College, Warangal',
'LDC institute of technical studies, Allahabad',
'B.B.S. College of Engineering and Technology, Allahabad',
'Vatsalya Institute of Nursing and Para Medical Sciences, Allahabad',
'Chatrapati Shivaji Maharaj college of engineering , Allahabad',
'motilal nehru medical college, Allahabad',
'Devprayag institute of technical studies, Allahabad',
'UPRTOU School of Computer and Information Sciences, Allahabad',
'Sadanlal Sanwaldas Khanna Girls degree college, Allahabad',
'xyz inst of technology, Allahabad',
'Shambhunath institute of pharmacy, Allahabad',
'Shambhunath institute of management, Allahabad',
'Maharana Pratap Polytechnic, Gorakhpur',
'Dr. Samuel george institute of engg & tech, Markapur',
'united institute of management, Allahabad',
'KIET GROUP OF INSTITUTIONS ., Ghaziabad',
'United institute of management(FUGS), Allahabad',
'Institute of Management Studies(IMS), Ghaziabad',
'ICSI, Hyderabad',
'SMSIT, Lucknow',
'Muzaffarpur institute of technology, Muzaffarpur',
'SCRIET, CCS University, Meerut',
'Techno India NJR, Udaipur',
'Noida International University, Noida',
'Allahabad Public School, Allahabad',
'Symbiosis Centre for Management Studies, Noida',
'HARI INSTITUTE OF TECHNOLOGY, NAKUR, SAHARANPUR',
'Sanskar International School, Allahabad',
'Vishnu Bhagwan Public School and College, Jhalwa, Allahabad',
'Vaagdevi Engineering College, Bollikunta, Warangal',
'Neerja Modi School, Jaipur',
'Jaypee Institute of Information Technology, Noida',
'TALLA PADMAVATHI COLLEGE OF ENGINEERING, Warangal',
'Institute of Management Sciences (IMS), Lucknow',
'IIT BHU, Varanasi',
'Techno India University, Kolkata',
'VIT University, Vellore',
'BIET, Jhansi',
'Galaxy Global Group of Institutions, Ambala',
'SRIMT, Lucknow',
'Regent Education and Research Foundation, Barrackpore',
'Isabella Thoburn college, Lucknow',
'Professional, Agartala',
'Professional, Professional',
'Professional, Allahabad',
'Galgotia Institute of management and technology, Noida',
'Gautam Buddha University, Noida',
'Central University of South Bihar, Gaya',
'Ramjas College, Delhi',
'ABES Engineering College, Ghaziabad',
'Rajiv Gandhi Institute of Petroleum Technology, Rae Bareli',
'Kamla Nehru Institute of physical and social sciences, Sultanpur',
'Others'
]
