export const emptyJobList = 'You have no requirement added, please add the Job listing as per your requirement'
export const typeOfCompanyList = [
    {
        label: "Product company",
        value: "Product company",
    },
    {
        label: "Service company",
        value: "Service company",
    },
    {
        label: "start Up",
        value: "Start Up",
    },
    {
        label: "Sass Based",
        value: "Sass Based",
    },
    {
        label: "Others",
        value: "Others",
    },
];

export const cmpyDocumentList = [
    {
        label: "SOW Document",
        value: "SOW Document",
    },
    {
        label: "NDA Document",
        value: "NDA Document",
    },
    {
        label: "Demo Document",
        value: "Demo Document",
    }
];

export const typesOfEmployeeContractList = [
    {
        label: "Remote",
        name: "Remote",
        id: 1,
        value: "Remote",
    },
    {
        label: "Hybrid",
        name: "Hybrid",
        id: 2,
        value: "Hybrid",
    },
    {
        label: "Short term",
        name: "Short term",
        id: 3,
        value: "Short term",
    },
    {
        label: "Long term",
        name: "Long term",
        id: 4,
        value: "Long term",
    },
    {
        label: "Others",
        name: "Others",
        id: 5,
        value: "Others",
    },
];

export const numberOfEmployeesList = [
    {
        label: '1 to 10',
        value: '1 to 10',
    },
    {
        label: '11 to 50',
        value: '11 to 50',
    },
    {
        label: '51 to 200',
        value: '51 to 200',
    },
    {
        label: '201 to 500',
        value: '201 to 500',
    },
    {
        label: '501 to 1000',
        value: '501 to 1000',
    },
    {
        label: 'above 1000',
        value: 'above 1000',
    }
];

export const salaryRange = [
    {
        label: 10000,
        value: 10000,
    },
    {
        label: 50000,
        value: 50000,
    },
    {
        label: 100000,
        value: 100000,
    },
    {
        label: 200000,
        value: 200000,
    },
    {
        label: 300000,
        value: 300000,
    },
];

export const currencyInputintlConfig = [
    {
        locale: 'de-DE',
        currency: 'EUR',
        label: 'de-DE',
        value: 'EUR',
    },
    {
        locale: 'en-US',
        currency: 'USD',
        label: 'en-US',
        value: 'USD',
    },
    {
        locale: 'en-GB',
        currency: 'GBP',
        label: 'en-GB',
        value: 'GBP',
    },
    {
        locale: 'ja-JP',
        currency: 'JPY',
        label: 'ja-JP',
        value: 'JPY',
    },
    {
        locale: 'en-IN',
        currency: 'INR',
        label: 'en-IN',
        value: 'INR',
    },
];

export const timeZonesList = [
    "Etc/GMT+12(GMT-12:00) International Date Line West",
    "Pacific/Midway(GMT-11:00) Midway Island, Samoa",
    "Pacific/Honolulu(GMT-10:00) Hawaii",
    "US/Alaska(GMT-09:00) Alaska",
    "America/Los_Angeles(GMT-08:00) Pacific Time (US & Canada)",
    "America/Tijuana(GMT-08:00) Tijuana, Baja California",
    "US/Arizona(GMT-07:00) Arizona",
    "America/Chihuahua(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    "US/Mountain(GMT-07:00) Mountain Time (US & Canada)",
    "America/Managua(GMT-06:00) Central America",
    "US/Central(GMT-06:00) Central Time (US & Canada)",
    "America/Mexico_City(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    "Canada/Saskatchewan(GMT-06:00) Saskatchewan",
    "America/Bogota(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
    "US/Eastern(GMT-05:00) Eastern Time (US & Canada)",
    "US/East-Indiana(GMT-05:00) Indiana (East)",
    "Canada/Atlantic(GMT-04:00) Atlantic Time (Canada)",
    "America/Caracas(GMT-04:00) Caracas, La Paz",
    "America/Manaus(GMT-04:00) Manaus",
    "America/Santiago(GMT-04:00) Santiago",
    "Canada/Newfoundland(GMT-03:30) Newfoundland",
    "America/Sao_Paulo(GMT-03:00) Brasilia",
    "America/Argentina/Buenos_Aires(GMT-03:00) Buenos Aires, Georgetown",
    "America/Godthab(GMT-03:00) Greenland",
    "America/Montevideo(GMT-03:00) Montevideo",
    "America/Noronha(GMT-02:00) Mid-Atlantic",
    "Atlantic/Cape_Verde(GMT-01:00) Cape Verde Is.",
    "Atlantic/Azores(GMT-01:00) Azores",
    "Africa/Casablanca(GMT+00:00) Casablanca, Monrovia, Reykjavik",
    "Etc/Greenwich(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    "Europe/Amsterdam(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "Europe/Belgrade(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "Europe/Brussels(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    "Europe/Sarajevo(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "Africa/Lagos(GMT+01:00) West Central Africa",
    "Asia/Amman(GMT+02:00) Amman",
    "Europe/Athens(GMT+02:00) Athens, Bucharest, Istanbul",
    "Asia/Beirut(GMT+02:00) Beirut",
    "Africa/Cairo(GMT+02:00) Cairo",
    "Africa/Harare(GMT+02:00) Harare, Pretoria",
    "Europe/Helsinki(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "Asia/Jerusalem(GMT+02:00) Jerusalem",
    "Europe/Minsk(GMT+02:00) Minsk",
    "Africa/Windhoek(GMT+02:00) Windhoek",
    "Asia/Kuwait(GMT+03:00) Kuwait, Riyadh, Baghdad",
    "Europe/Moscow(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    "Africa/Nairobi(GMT+03:00) Nairobi",
    "Asia/Tbilisi(GMT+03:00) Tbilisi",
    "Asia/Tehran(GMT+03:30) Tehran",
    "Asia/Muscat(GMT+04:00) Abu Dhabi, Muscat",
    "Asia/Baku(GMT+04:00) Baku",
    "Asia/Yerevan(GMT+04:00) Yerevan",
    "Asia/Kabul(GMT+04:30) Kabul",
    "Asia/Yekaterinburg(GMT+05:00) Yekaterinburg",
    "Asia/Karachi(GMT+05:00) Islamabad, Karachi, Tashkent",
    "Asia/India(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "Asia/Katmandu(GMT+05:45) Kathmandu",
    "Asia/Almaty(GMT+06:00) Almaty, Novosibirsk",
    "Asia/Dhaka(GMT+06:00) Astana, Dhaka",
    "Asia/Rangoon(GMT+06:30) Yangon (Rangoon)",
    "Asia/Bangkok(GMT+07:00) Bangkok, Hanoi, Jakarta",
    "Asia/Krasnoyarsk(GMT+07:00) Krasnoyarsk",
    "Asia/Hong_Kong(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "Asia/Kuala_Lumpur(GMT+08:00) Kuala Lumpur, Singapore",
    "Asia/Irkutsk(GMT+08:00) Irkutsk, Ulaan Bataar",
    "Australia/Perth(GMT+08:00) Perth",
    "Asia/Taipei(GMT+08:00) Taipei",
    "Asia/Tokyo(GMT+09:00) Osaka, Sapporo, Tokyo",
    "Asia/Seoul(GMT+09:00) Seoul",
    "Asia/Yakutsk(GMT+09:00) Yakutsk",
    "Australia/Adelaide(GMT+09:30) Adelaide",
    "Australia/Darwin(GMT+09:30) Darwin",
    "Australia/Brisbane(GMT+10:00) Brisbane",
    "Australia/Canberra(GMT+10:00) Canberra, Melbourne, Sydney",
    "Australia/Hobart(GMT+10:00) Hobart",
    "Pacific/Guam(GMT+10:00) Guam, Port Moresby",
    "Asia/Vladivostok(GMT+10:00) Vladivostok",
    "Asia/Magadan(GMT+11:00) Magadan, Solomon Is., New Caledonia",
    "Pacific/Auckland(GMT+12:00) Auckland, Wellington",
    "Pacific/Fiji(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
    "Pacific/Tongatapu(GMT+13:00) Nuku'alofa"
    
];


export const masterDatacountryList = [
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan (Province of China)",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ];


  export const statesMasterList = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"
]
