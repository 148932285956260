import * as actions from "../actionTypes/index";

const shortListedCandidatesList = (resPayload) => {
  return {
    type: actions.SHORT_LISTED_CANDIDATES_LIST,
    payload: resPayload
  };
};

const rejectedListCandidatesList = (resPayload) => {
  return {
    type: actions.REJECTED_LISTED_CANDIDATES_LIST,
    payload: resPayload
  };
};

export { shortListedCandidatesList, rejectedListCandidatesList };
