import axios from "axios";

export default axios.create({
  baseURL: "http://ec2-13-60-203-249.eu-north-1.compute.amazonaws.com:5000/",//"https://51.21.128.63/", //"http://ec2-13-60-203-249.eu-north-1.compute.amazonaws.com:5000/",//"http://ec2-16-16-56-121.eu-north-1.compute.amazonaws.com:5000", //"http://localhost:5000/",//
  //baseURL: "http://localhost:5000/",
  headers: {
    "Content-type": "application/json"
  }
});

export const downloadPDF = async (url, fileName) => {

  try {
    const instance = axios.create();
    instance({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      console.log("======wwee=====", fileName)
      link.setAttribute('download', fileName && fileName?.split('.')?.[1] === 'pdf' ? `${fileName}` : `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
    });

  } catch (err) {
    if (err) {
      console.log(err)
    }
  }
}

export const downloadPDFNew = async (url, fileName) => {

  try {
    const instance = axios.create();
    instance({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      console.log("==fileName====", fileName)
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
    });

  } catch (err) {
    if (err) {
      console.log(err)
    }
  }
}