import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon"
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import CurrencyInput from 'react-currency-input-field';
import Select, { components } from "react-select";
import { addNewRequirementPlaceholders } from '../../Utils/masterData/staticContent'
import { typesOfEmployeeContractList, salaryRange, currencyInputintlConfig } from '../../Utils/masterData/dropDownJson'
import { isEmpty } from '../../Utils/utils'
import { postApi, getAllApi } from '../../services/apiCommonService'
import { create_offer_details, get_all_candidates, get_job_list_api_url } from '../../services/endPointsConstant'
import storePersist from '../../Utils/storePersist'
import { useEffect } from "react";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};


const AddJobOffer = (props) => {
    const { returnAddNewRequirementFormDetails, modalPopUpCancel } = props
    const [formData, setFormData] = useState(false);
    const [jobList, setJobList] = useState([])
    const [allCandidatesList, setAllCandidatesList] = useState([])
    const [salary, setSalaryValue] = useState(null);
    const [currencyValue, setCurrencyValue] = useState(null);
    const [typesOfEmployeeContractValue, setTypesOfEmployeeContractValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const navigate = useNavigate();
    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        control,
        reset
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    const getJobsList = async () => {
        const apiResponse = await getAllApi(get_job_list_api_url)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData.map(({ _id, job_type }) => { return { value: _id, label: job_type } })
            setJobList(apiResponseData)
            toast.success("Retrived all Job List Successfully !!")
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const getAllCandidatesList = async () => {
        const apiReqBody = {}
        const apiResponse = await postApi(get_all_candidates, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData.map(({ _id, firstName }) => { return { value: _id, label: firstName } })
            setAllCandidatesList(apiResponseData)
            // toast.success("Retrived all Candidates List Successfully !!")
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    useEffect(() => {
        async function fetchData() {
            getJobsList()
            getAllCandidatesList()
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (modalPopUpCancel) {
            reset();
        }
    }, [modalPopUpCancel])

    const errorHandler = () => {
        if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const handleOnValueChange = (event) => {
        setSalaryValue(event.target.value)
    }

    const onSubmit = async (data) => {
        setFormData(data);
        const { id } = storePersist.get('userData')
        const apiReqBody = {
            userid: id,
            job_type: data?.job_type,
            job_code: data?.job_code,
            year_of_exp: data?.year_of_exp,
            skill: data?.skill,
            office_type: data?.office_type,
            salary: data?.salary,
            job_desciption: data?.job_desciption,
            job_responsibility: data?.responsibilities,
            currencyValue: data?.currencyValue
        }

        const apiResponse = await postApi(create_offer_details, apiReqBody)
        if (apiResponse?.data) {
            toast.success("Successfully Job details Added !!")
            returnAddNewRequirementFormDetails(apiReqBody)
            document.getElementById("buttonDismiss").click();
            reset();
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['salary', 'office_type', 'currencyValue'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    const currencyHandler = (val) => {
        setCurrencyValue(val)
    }

    return (
        <div className="form-holder pt-5 ps-8 pe-8 pb-0 main_bg rounded-0">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="form-outline row gx-3">
                    <div className="col-md-6 marginbottom_int did-floating-label-content">
                        <Controller
                            name="office_type"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={typesOfEmployeeContractList}
                                    value={typesOfEmployeeContractList.find((c) => c.value === value)}
                                    onChange={(val) => onChange(val.value)}
                                    defaultValue={typesOfEmployeeContractList.find((c) => c.value === typesOfEmployeeContractValue)}
                                    isSearchable={true}
                                    placeholder={addNewRequirementPlaceholders.remoteByHybridDDPlaceHolder}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'office_type': true }) }}
                                    styles={{
                                        // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.office_type && 'office_type'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            // marginTop: 25,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['office_type'] ? '1px solid #cccccc' : ''
                                        })
                                    }}
                                />
                            )}
                            rules={{ required: "Field is required" }}
                        />
                        {/* <label className="did-floating-label">{addNewRequirementPlaceholders.remoteByHybridDDPlaceHolder}</label> */}
                        {/* {errors.office_type && <p className="error">{errors.office_type.message}</p>} */}
                    </div>
                    <div className="col-md-6 marginbottom_int did-floating-label-content">
                        <Controller
                            name="office_type"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={typesOfEmployeeContractList}
                                    value={typesOfEmployeeContractList.find((c) => c.value === value)}
                                    onChange={(val) => onChange(val.value)}
                                    defaultValue={typesOfEmployeeContractList.find((c) => c.value === typesOfEmployeeContractValue)}
                                    isSearchable={true}
                                    placeholder={addNewRequirementPlaceholders.remoteByHybridDDPlaceHolder}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'office_type': true }) }}
                                    styles={{
                                        // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.office_type && 'office_type'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            // marginTop: 25,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['office_type'] ? '1px solid #cccccc' : ''
                                        })
                                    }}
                                />
                            )}
                            rules={{ required: "Field is required" }}
                        />
                        {/* <label className="did-floating-label">{addNewRequirementPlaceholders.remoteByHybridDDPlaceHolder}</label> */}
                        {/* {errors.office_type && <p className="error">{errors.office_type.message}</p>} */}
                    </div>
                </div>

                <div className="form-outline row gx-3">
                    <div className={`col-md-6 did-floating-label-content'}`}>
                        <Controller
                            name="currencyValue"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={currencyInputintlConfig}
                                    value={currencyInputintlConfig.find((c) => c.value === value)}
                                    onChange={(val) => { onChange(val?.value); currencyHandler(val) }}
                                    defaultValue={currencyInputintlConfig.find((c) => c.value === currencyValue)}
                                    isSearchable={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder={addNewRequirementPlaceholders.currencyPlaceHolder}
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'currencyValue': true }) }}
                                    styles={{
                                        // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.currencyValue && 'currencyValue'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            // marginTop: 24,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['currencyValue'] ? '1px solid #cccccc' : ''
                                        })
                                    }}
                                />
                            )}
                            rules={{ required: "Field is required" }}
                        />
                    </div>
                    <div className={`col-md-6`}>
                        <Controller
                            control={control}
                            name="date"
                            rules={{ required: "Field is required" }} //optional
                            render={({
                                field: { onChange, name, value },
                                fieldState: { invalid, isDirty }, //optional
                                formState: { errors }, //optional, but necessary if you want to show an error message
                            }) => (
                                <>
                                    <DatePicker
                                        render={<InputIcon />}
                                        value={value || ""}
                                        containerClassName={errors && errors.date ? "custom-input-error" : "custom-container"}
                                        onChange={(date) => {
                                            onChange(date?.isValid ? date : "");
                                        }}
                                        format={"YYYY/MM/DD"}
                                    />
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="form-outline row gx-3 ">
                    <div className={`form-outline did-floating-label-content ${errors.file && 'did-error-input'} mt-4`}>
                        <input
                            className="did-floating-input" //mb-4 font_size_14
                            type="file"
                            name="file"
                            placeholder=""
                            {...register("file", {
                                required: "File is required",
                            })}
                            style={{ paddingTop: '7px' }}
                        />
                    </div>
                </div>
                <div className="form-outline row gx-3 vh-30"></div>

                <div className="border-top position-sticky bottom-0 footer mt-2 py-5 pb-3 pt-2 main_bg text-center d-flex justify-content-center">
                    <button type="submit" className="mt-1 mb-1 fw-medium_imp" onClick={errorHandler}>
                        {isSubmitting && <span>Submitting</span>}
                        {!isSubmitting && <span>Save Job Offer</span>}
                    </button>
                </div>
            </form>
            <button className="d-none" id="buttonDismiss" data-bs-dismiss="modal"></button>
        </div>
    );
}

export default AddJobOffer