export const masterListFieldOfStudies = ['Business Administration & Management ',
    'International Business/Trade/Commerce ',
    'Business/Managerial Economics ',
    'Marketing Management & Research ',
    'Business Management/Administration, General',
    'Finance ',
    'Management Information Systems/Business Statistics ',
    'Business Management/Administration, Other',
    'Human Resources Development ',
    'Operations Research (also in ENGINEERING & in MATHEMATICS)',
    'Communication Research ',
    'Film, Radio, TV & Digital Communication ',
    'Communication, General',
    'Communication Theory ',
    'Mass Communication/Media Studies ',
    'Communication, Other',
    'Computer Science ',
    'Information Science & Systems ',
    'Computer & Information Sciences, General',
    'Robotics ',
    'Computer & Information Sciences, Other',
    'Counseling Education/Counseling & Guidance ',
    'Educational/Instructional Media Design ',
    'Higher Education/Evaluation & Research',
    'Curriculum & Instruction ',
    'Educational/Instructional Technology ',
    'International Education',
    'Educational Administration & Supervision ',
    'Educational Leadership ',
    'School Psychology (also in PSYCHOLOGY)',
    'Educational Assessment/Testing/Measurement ',
    'Educational Psychology (also in PSYCHOLOGY) ',
    'Social/Philosophical Foundations of Education',
    'Educational & Human Resource Studies/Development ',
    'Educational Policy Analysis 835 Special Education',
    'Educational Statistics/Research Methods ',
    'Urban Education and Leadership',
    'Adult & Continuing Teacher Education ',
    'Pre-elementary/Early Childhood Teacher Education ',
    'Secondary Teacher Education',
    'Elementary Teacher Education',
    'Agricultural Education ',
    'Family & Consumer/Human Science ',
    'Music Education',
    'Art Education Fields Not Elsewhere Classified) ',
    'Nursing Education',
    'Bilingual & Multilingual Education ',
    'Foreign Languages Education ',
    'Physical Education & Coaching',
    'English Education ',
    'Health Education ',
    'Science Education',
    'English as a Second or Foreign Language ',
    'Literacy & Reading Education ',
    'Social Science Education',
    'Mathematics Education ',
    'Teacher Education & Professional Development, Other',
    'Workforce Education and Development ',
    'Education, General ',
    'Education, Other',
    'Aerospace, Aeronautical & Astronautical Engineering ',
    'Engineering Physics ',
    'Operations Research (also in MATHEMATICS',
    'Agricultural Engineering ',
    'Engineering Science & in BUSINESS MANAGEMENT)',
    'Bioengineering & Biomedical Engineering ',
    'Environmental/Environmental Health Engineering ',
    'Petroleum Engineering',
    'Chemical Engineering ',
    'Geotechnical & Geoenvironmental Engineering ',
    'Polymer & Plastics Engineering',
    'Civil Engineering ',
    'Industrial & Manufacturing Engineering ',
    'Structural Engineering',
    'Communications Engineering ',
    'Materials Science Engineering ',
    'Systems Engineering',
    'Computer Engineering ',
    'Mechanical Engineering ',
    'Transportation & Highway Engineering',
    'Electrical, Electronics & Communications Engineering ',
    'Metallurgical Engineering 398 Engineering, General',
    'Engineering Management & Administration 357 Nuclear Engineering ',
    'Engineering, Other',
    'Engineering Mechanics ',
    'Ocean Engineering',
    'African History ',
    'European History ',
    'Middle/Near East Studies',
    'American History (U.S. & Canada) ',
    'History, Science & Technology & Society ',
    'History, General',
    'Asian History ',
    'Latin American History ',
    'History, Other',
    'Arabic Literature',
    'German Literature',
    'Russian Literature',
    'Chinese  Literature',
    'Italian Literature',
    'Spanish Literature',
    'French Literature',
    'Japanese Literature',
    'Other Languages & Literature',
    'Latin American Literature',
    'American Literature (U.S. & Canada) ',
    'English Language ',
    'English Literature (British & Commonwealth) ',
    'Comparative Literature ',
    'Rhetoric & Composition',
    'Creative Writing ',
    'Speech & Rhetorical Studies',
    'American/U.S. Studies ',
    'Ethics ',
    'Musicology/Ethnomusicology',
    'Archaeology ',
    'Film/Cinema/Video Studies ',
    'Music, Other',
    'Art History/Criticism/Conservation ',
    'Jewish/Judaic Studies & History ',
    'Philosophy',
    'Bible/Biblical Studies ',
    'Music ',
    'Religion/Religious Studies',
    'Dance ',
    'Music Theory & Composition ',
    'Humanities, General',
    'Drama/Theater Arts ',
    'Music Performance ',
    'Humanities, Other',
    'Agricultural Economics ',
    'Food Science ',
    'Plant Pathology/Phytopathology (also in',
    'Agricultural & Horticultural Plant Breeding ',
    'Food Science & Technology, Other BIOLOGICAL/BIOMEDICAL SCIENCES)',
    'Agronomy & Crop Science ',
    'Forest Sciences & Biology ',
    'Plant Sciences, Other',
    'Animal Nutrition ',
    'Forest/Resources Management ',
    'Soil Chemistry/Microbiology',
    'Animal Science, Poultry (or Avian)',
    'Forestry & Related Science, Other ',
    'Soil Sciences, Other',
    'Animal Science, Other ',
    'Horticulture Science ',
    'Wildlife/Range Management',
    'Environmental Science ',
    'Natural Resources/Conservation ',
    'Wood Science & Pulp/Paper Technology',
    'Fishing & Fisheries Sciences/Management ',
    'Natural Resource/Environmental Economics ',
    'Agricultural Sciences/Natural Resources, Other',
    'Anatomy ',
    'Ecology ',
    'Pathology, Human & Animal',
    'Bacteriology ',
    'Endocrinology ',
    'Pharmacology, Human & Animal',
    'Biochemistry  ',
    'Environmental Toxicology ',
    'Plant Genetics',
    'Bioinformatics ',
    'Epidemiology ',
    'Plant Pathology/Phytopathology (also in',
    'Biomedical Sciences ',
    'Evolutionary Biology AGRICULTURAL SCIENCES)',
    'Biometrics & Biostatistics ',
    'Genetics/Genomics, Human & Animal ',
    'Plant Physiology',
    'Biophysics (also in PHYSICS) ',
    'Immunology ',
    'Structural Biology',
    'Biotechnology ',
    'Marine Biology & Biological Oceanography ',
    'Toxicology',
    'Botany/Plant Biology ',
    'Microbiology ',
    'Virology',
    'Cancer Biology ',
    'Molecular Biology ',
    'Wildlife Biology',
    'Cell/Cellular Biology & Histology',
    'Molecular Medicine ',
    'Zoology',
    'Computational Biology ',
    'Neurosciences & Neurobiology ',
    'Biology/Biomedical Sciences, General',
    'Developmental Biology/Embryology ',
    'Nutrition Sciences ',
    'Biology/Biomedical Sciences, Other',
    'Parasitology',
    'Environmental Health ',
    'Kinesiology/Exercise Physiology ',
    'Rehabilitation/Therapeutic Services',
    'Gerontology (also in SOCIAL SCIENCES) ',
    'Pharmaceutical Sciences ',
    'Speech-Language Pathology & Audiology',
    'Health and Behavior ',
    'Nursing Science ',
    'Veterinary Sciences',
    'Health Policy Analysis ',
    'Oral Biology/Oral Pathology ',
    'Health Sciences, General',
    'Health Services Research ',
    'Public Health ',
    'Health Sciences, Other',
    'Health Systems/Service Administration',
    'Applied Mathematics ',
    'Operations Research (also in ENGINEERING & in ',
    'Mathematics/Statistics, General',
    'Computing Theory & Practice BUSINESS MANAGEMENT) ',
    'Mathematics/Statistics, Other',
    'Geometry/Geometric Analysis',
    'Behavioral Analysis ',
    'Developmental & Child Psychology ',
    'Marriage & Family Therapy/Counseling',
    'Clinical Psychology ',
    'Educational Psychology (also in EDUCATION) ',
    'Neuropsychology/Physiological Psychology',
    'Cognitive Neuroscience ',
    'Personality Psychology',
    'Family Psychology ',
    'Psychometrics & Quantitative Psychology',
    'Cognitive Psychology & Psycholinguistics ',
    'Health & Medical Psychology ',
    'School Psychology (also in EDUCATION)',
    'Community Psychology ',
    'Human Development & Family Studies ',
    'Social Psychology',
    'Counseling ',
    'Industrial & Organizational Psychology ',
    'Psychology, Other',
    'Anthropology, Cultural ',
    'Gender and Women’s Studies ',
    'Political Science & Government',
    'Anthropology, General ',
    'Geography ',
    'Public Policy Analysis',
    'Anthropology, Physical and Biological ',
    'Gerontology (also in HEALTH SCIENCES) 686 Sociology',
    'Area/Ethnic/Cultural Studies ',
    'International Relations/Affairs ',
    'Statistics (also in MATHEMATICS)',
    'Criminal Justice & Corrections ',
    'Applied Linguistics ',
    'Urban Affairs/Studies',
    'Criminology ',
    'Linguistics ',
    'Urban/City, Community & Regional Planning',
    'Demography/Population Studies ',
    'Natural Resource/Environmental Economics (also in 698 Social Sciences, General',
    'Econometrics AGRICULTURAL SCIENCES) ',
    'Social Sciences, Other',
    'Economics ',
    'Natural Resource/Environmental Policy',
    'Architecture/Environmental Design ',
    'Library Science ',
    'Theology/Religious Education (see also OTHER',
    'Family/Consumer Science/Human Science ',
    'Parks/Sports/Rec./Leisure/Fitness ',
    'Public Administration ',
    'Other Fields, Not Elsewhere Classified',
    'Law ',
    'Social Work',
    'Astronomy ',
    'Astrophysics ',
    'Astronomy, Other',
    'Atmospheric Chemistry & Climatology ',
    'Meteorology ',
    'Atmospheric Science/Meteorology, Other',
    'Atmospheric Physics & Dynamics ',
    'Atmospheric Science/Meteorology, General',
    'Analytical Chemistry ',
    'Organic Chemistry ',
    'Theoretical Chemistry',
    'Chemical Biology ',
    'Physical Chemistry ',
    'Chemistry, General',
    'Inorganic Chemistry ',
    'Polymer Chemistry ',
    'Chemistry, Other (see also BIOLOGICAL/BIOMEDICAL',
    'Medicinal Chemistry SCIENCES: Biochemistry)',
    'Geochemistry ',
    'Geophysics & Seismology ',
    'Stratigraphy & Sedimentation',
    'Geology ',
    'Mineralogy & Petrology ',
    'Geological & Earth Sciences, General',
    'Geomorphology & Glacial Geology ',
    'Paleontology ',
    'Geological & Earth Sciences, Other',
    'Hydrology & Water Resources ',
    'Marine Sciences',
    'Oceanography, Chemical & Physical ',
    'Ocean/Marine, Other',
    'Acoustics ',
    'Condensed Matter/Low Temperature Physics ',
    'Plasma/Fusion Physics',
    'Applied Physics ',
    'Medical Physics/Radiological Science ',
    'Polymer Physics',
    'Atomic/Molecular/Chemical Physics ',
    'Nuclear Physics ',
    'Physics, General',
    'Biophysics (also in BIOLOGICAL/BIOMEDICAL SCIENCES) ',
    'Optics/Photonics 579 Physics, Other',
    'Particle (Elementary) Physics ',
    'Others'
]
