import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import adminLayout from "../../hoc/adminLayout";
import { downloadPDF } from '../../services/axios-common'
import ModalComponent from "../../components/ModalComponent";
import storePersist from '../../Utils/storePersist'
import { isEmpty } from '../../Utils/utils';
import { postApi } from '../../services/apiCommonService'
import { edit_Offer_api_url, api_getOfferByCandidate, candidates_onboarded_report_url } from '../../services/endPointsConstant';

const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
};

const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
    return `${user[0]?.toUpperCase()}${user[1]?.toUpperCase()}`
}

const setValue = (functionFor) => {
    return (e) => {
        const value = parseInt(e.target.value);
        functionFor(value);
    }
};

const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
}

const ScheduledForInterviewComponent = () => {
    const location = useLocation()
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { query_candidate_id } = useParams();
    const { shortlistedResumesList } = useSelector((state) => state.shortlistedResumesReducer);
    const [selectedUserDetails, setSelectedUserDetails] = useState([]);
    const [offerDetails, setOfferDetails] = useState([]);
    const [finalizedCandidateData, setFinalizedCandidateData] = useState([])
    const [formData, setFormData] = useState(false);
    const isAuthenticate = storePersist.get('userData')


    const [range, setRange] = React.useState(10);
    const [saturation, setSaturation] = React.useState(50);
    const [lightness, setLightness] = React.useState(50);
    const [theme, setTheme] = React.useState('Light');

    const saturationRange = getRange(saturation, range);
    const lightnessRange = getRange(lightness, range);

    const {
        register,
        formState: { errors, isDirty, isSubmitting, touched },
        handleSubmit
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        async function fetchData() {
            await getSowLinkApiCallHandler()
        }
        fetchData()
    }, [])

    const getSowLinkApiCallHandler = async () => {
        if (query_candidate_id || selectedUserDetails?.candidate_id) {
            dispatch(addLoader());
            const apiReqBody = {
                candidate_id: query_candidate_id || selectedUserDetails?.candidate_id || location?.state?.candidate_id,
                jobId: selectedUserDetails?.jobId || location?.state?.jobId
            }
            const apiResponse = await postApi(api_getOfferByCandidate, apiReqBody)
            if (apiResponse?.data?.Data) {
                setOfferDetails(apiResponse?.data?.Data)
                setSelectedUserDetails(apiResponse?.data?.Data[0])
                if (apiResponse?.data?.Data[0]?.file_Name_sow && apiResponse?.data?.Data[0]?.file_location_sow) {
                    setFinalizedCandidateData([...apiResponse?.data?.Data,
                    {
                        ...apiResponse?.data?.Data[0], yearsOfExperience: 'Yes', profileMatch: 'Yes/No',
                        file_location: apiResponse?.data?.Data[0]?.file_location_sow,
                        fileName: apiResponse?.data?.Data[0]?.file_Name_sow,
                        candidateFirstName: 'Contact Details',
                        profileTitle: 'PO/SOW'
                    }])
                } else {
                    setFinalizedCandidateData(apiResponse?.data?.Data)
                }
                toast.success('Profile Updated Successfully !!')
            } else {
                toast.error(apiResponse?.response?.data?.message + '!!')
            }
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2000);
        } else {
            dispatch(addLoader());
            const companyDetailsStorage = storePersist.get('companyDetails')
            const apiReqBody = {
                companyId: companyDetailsStorage && companyDetailsStorage?._id
            }
            const apiResponse = await postApi(candidates_onboarded_report_url, apiReqBody)
            if (apiResponse?.data?.Data) {
                const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
                setOfferDetails(apiResponseData)
                setSelectedUserDetails(apiResponseData[0])
                setFinalizedCandidateData(apiResponseData)
                // toast.success("Retrived all On Boarded List Successfully !!")
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 2500);
            } else {
                toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                dispatch(deleteLoader());
            }
        }
    }

    const userDetailsOnClikHandler = (finalizedCandidate) => {
        dispatch(addLoader());
        setSelectedUserDetails(finalizedCandidate)
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 2000);
    }

    const onClickRedirectToScheduledForInterviewPage = () => {
        navigate('/job-offered');
    }

    const downloadPdf = () => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(selectedUserDetails?.file_location, selectedUserDetails?.candidateFirstName || selectedUserDetails?.firstName)
    }

    const onClickPageRedirection = () => {
        navigate('/job-offers-list');
    }


    return (
        <div className="my-1 py-3 bg-body rounded shadow-sm rounded_20 height_870">
            {/* <div className="d-flex justify-content-between align-items-center border-bottom pt-2"> */}
            <div className="col-xl-12 col-sm-12 ps-3 pb-2 border-bottom">
                <h6 className="font_color_imp font_size_16 fw-bolded"> {'Onboarded Candidate'} </h6>
            </div>
            {/* </div> */}
            <div className="d-flex align-content-center justify-content-start p-2 bg_header_shortlist_resume ps-4 m-2 ms-4 me-2_0 mt-3">
                {masterData.map((item, idx) => {
                    return (<div className="col-md-3 d-flex justify-content-start align-content-center" key={idx}>
                        <div className="col-xs-12 align-middle">
                            <div className="col-xs-6 me-3">
                                <img
                                    src={require(`../../assets/images/${item.img}`)}
                                    alt="jobType"
                                    className="img_w_h_23"
                                />
                            </div>
                            <div className="col-xs-6">
                                <div className="fw-700 font_size_14 mb-1">{item.label}</div>
                                <div className="fw-medium font_size_14">{selectedUserDetails && selectedUserDetails[item.response]}</div>
                            </div>
                        </div>
                    </div>)
                })}
            </div>
            <div className="m-4 mt-3 me-2_0 border_cls">
                <div className="border_cls d-flex align-content-center p-2 ps-3 d-flex align-item-center justify-content-between">
                    {/* <div className='d-flex align-item-center justify-content-between'> */}
                    <div className='pt-2'>
                        <span className="font_color_ornage fw_bold">
                            Congratulation! You have selected candidate for this Position.
                        </span>
                    </div>
                    {finalizedCandidateData.length > 0 && <div className='d-flex align-item-center justify-content-end'>
                        <button
                            type="button"
                            className="btn btn-outline-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6"
                            onClick={() => { downloadPdf() }}
                        >
                            Download Resume
                            <img
                                src={require(`../../assets/images/download_icon.svg`).default}
                                alt="jobType"
                                className="img_w_h_23 ms-1"
                            />
                        </button>
                    </div>}
                    {/* </div> */}
                </div>
                <div className="left-sidebar mt-0">
                    {!offerDetails ?
                        <div className='pt-1 vw-57 d-flex align-items-center justify-content-center flex-column'>
                            <div className="pb-2">
                                <div className='pt-2  font_size_16 fw-bolded'>Please upload the PO/SOW documets !!</div>
                            </div>
                            <div className="pb-2">
                                <div className='pt-2  font_size_16 fw-bolded'>It's maditory for next process !!</div>
                            </div>
                        </div>
                        :
                        <div className='border_cls vh-90 vw-57'>
                            <iframe
                                // style={{ width: '100%', height: '100%' }}
                                // src={selectedUserDetails?.file_location}//{'https://arxiv.org/pdf/quant-ph/0410100.pdf'} offerDetails?.length > 0 ? offerDetails[0].file_location :
                                title="candidate resume"
                                style={{ width: '100%', height: '100%', border: 'none' }}
                                src={selectedUserDetails?.file_location}
                                // src={`https://docs.google.com/viewer?url=${selectedUserDetails?.file_location}&embedded=true`}
                                frameborder="0"
                                toolbar="0"
                                allowfullscreen
                                type="application/pdf"
                            >
                            </iframe>
                        </div>
                    }
                    <div className='vw-22 col-md-4'>
                        {finalizedCandidateData.map((item, idx) => {
                            const userName = `${item?.name} ${item?.candidateFirstName}`;
                            const color = generateColorHsl(userName, saturationRange, lightnessRange);
                            const initials = getInitials(item?.candidateFirstName);
                            return (
                                <div
                                    className={`${idx === 0 ? 'mt-0' : 'mt-2'}  pb-1 pt-3 border_radius_10 bg_color_green_lite font_color_green pointer_event ${selectedUserDetails?.yearsOfExperience === item?.yearsOfExperience && 'border_green'}`}
                                    key={idx}
                                    onClick={() => { userDetailsOnClikHandler(item) }}
                                >
                                    <div className="d-flex justify-content-center align-items-center border-bottom_2">
                                        {/* <div className="col-md-2 ps-4_5 mb-2">
                                            <div className="position-relative rounded-circle overflow-hidden mx-auto custom-circle-image border blackcircle">
                                                {idx === 0 && <img className="w-100 h-100 whitecircle rounded-circle" alt="Alt content" src={require('../../assets/images/user-profile-icon.svg').default} />}
                                            </div>
                                        </div> */}
                                        {item.profileMatch !== 'Yes/No' &&
                                            <div className="col-md-2 ps-4_5 mb-2">
                                                <div className="row1" key={idx}>
                                                    <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
                                                </div>
                                                {/* <div className="position-relative rounded-circle overflow-hidden mx-auto custom-circle-image border blackcircle">
                                                            <img className="w-100 h-100 whitecircle rounded-circle" alt="Alt content" src={require('../../assets/images/user-profile-icon.svg').default} />
                                                        </div> */}
                                            </div>
                                        }
                                        <div className={`${item.profileMatch === 'Yes/No' ? 'col-md-12' : 'col-md-10'} ps-5`}>
                                            <div>
                                                <h6 className={`pb-1 mb-0 font_color_imp fw-medium font_size_13 ${selectedUserDetails?.offer_id === item?.offer_id && 'red_color'}`}>{item.candidateFirstName}</h6>
                                                <span className={`font_color_imp fw-medium font_size_13 ${selectedUserDetails?.offer_id === item?.offer_id && 'red_color'}`}>{item.profileTitle}</span>
                                            </div>
                                        </div>
                                        <div className={`col-md-2 d-flex justify-content-start align-items-center margin_top_neg_60 ${item.profileMatch === 'Yes/No' ? 'pt-4' : 'pt-3'}`}>
                                            {/* <img
                                            src={require(`../../assets/images/scheduledInterviewRibbon.svg`).default}
                                            alt="jobType"
                                            className="img_w_h_18"
                                        /> */}
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className='fw-medium font_size_13'>{item.yearsOfExperience === 'Yes' ? 'SOW Updated' : 'Years of experience'}</div>
                                            <div className="font_size_13">{item?.yearsOfExperience || item?.candidateExperience || item?.year_of_exp}</div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className='fw-medium font_size_13'>{item.profileMatch === 'Yes/No' ? 'Offer given' : 'Profile match your requirement'}</div>
                                            <div className="font_size_13">{item.profileMatch || 0}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
                <div className="col-md-12 border_cls footer mt-auto py-2 px-3 bg-white  d-flex align-item-center justify-content-end">
                    <div className='d-flex align-item-center justify-content-end'>
                        <button
                            type="button"
                            className="btn btn-primary bg_color_ornage pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6 me-3"
                            onClick={() => { onClickPageRedirection() }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduledForInterviewComponent
// export default adminLayout(ScheduledForInterviewComponent)

const masterData = [
    { label: 'Company Name', response: 'companyName', img: 'skills_needed.svg' },
    { label: 'Job Type', response: 'job_type', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', img: 'Y_O_Exp.svg' },
]