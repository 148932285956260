import * as actions from '../actionTypes/index'

export const initialState = {
    steps: [
        {
            selector: '.my-1-step',
            content: 'This is awesome Signup feature!',
        }
    ],
    isOpenTour: false,
}

export default function tourReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.ADD_TOUR:
            return { ...state, ...payload }
        case actions.DELETE_TOUR:
            return { ...state, ...payload }
        default:
            return state
    }
}
