import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import LoadingOverlayLoader from 'react-loading-overlay';
import { useDispatch, useSelector } from "react-redux";
import Header from './../common/header';
import Sidebar from './../common/sidebar';

const adminLayout = (ChildComponent) => {
    const AdminLayout = (props) => {
        const { loading } = useSelector((state) => state?.loaderReducer);
        const [saveLeadClickEvent, setSaveLeadClickEvent] = useState("")
        let navigate = useNavigate();

        const renderHtml = () => {
            const isAuthenticated = window.localStorage.getItem('userData');
            if (isAuthenticated) {
                return <div className="d-flex" id="wrapper">
                    {/* <!-- Sidebar--> */}
                    <Sidebar />
                    {/* <!-- Page content wrapper--> */}
                    <div className="main" id="page-content-wrapper">
                        {/* <!-- Top navigation--> */}
                        <Header />
                        {/* <!-- Page content--> */}
                        <div className="container-fluid content-container pt-2">
                            <ChildComponent {...props} />
                        </div>
                    </div>
                </div>
            } else {
                navigate('/login')
            }
        }

        return <>
            <LoadingOverlayLoader
                active={loading}
                spinner={true}
                text="Loading your content..."
                styles={{
                    overlay: (base) => ({
                        ...base,
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        zIndex: 9999
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: '70px',
                        '& svg circle': {
                            stroke: '#FE5244',//rgba(255, 0, 0, 0.5)
                        }
                    }),
                    content: (base) => ({
                        ...base,
                        top: "40%",
                        left: '45%',
                        color: '#FE5244',
                    })
                }}
            >
                {renderHtml()}
            </LoadingOverlayLoader>
        </>
    }

    return AdminLayout;
}

export default adminLayout;
