import * as employeeActions from "../actionTypes/index";

const addUpdateEmployeeStages = (payload) => {
  return {
    type: employeeActions.ADD_UPDATE_EMPLOYEE,
    payload: payload
  };
};

export { addUpdateEmployeeStages };
