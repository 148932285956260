import * as actions from '../actionTypes/index'

export const initialState = {
    selectedJobDetails: '',
    hasErrors: false,
}

export default function jobDetailsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.ADD_JOB_DETAILS:
            return { ...state, selectedJobDetails: payload }
        case actions.DELETE_JOB_DETAILS:
            return { ...state, ...payload }
        default:
            return state
    }
}
