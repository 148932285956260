import { Link, useLocation } from "react-router-dom";
import storePersist from '../../Utils/storePersist'

const TicketSuccessTemplate = () => {
    const { pathname } = useLocation();
    const isAuthenticate = storePersist?.get('userData')
    return (
        <div className="page-hero link-style pt-5 pb-8 bg-white pt-2 text-center vh-100">
            {/* <h3 className="">The page was not found</h3> */}
            <div className="d-flex justify-content-center align-items-center pt-1">
                <img src={require('../../assets/images/side_bar_logo.svg').default} alt="Alt content" className="w-20 h-30" />
            </div>
            <div className="col-md-12 text-center d-flex justify-content-center pt-5">
                <span className="w-35">
                    Your Qury is submitted successfully.
                    <b>Remote72 support team </b> will reslove it in 24 hours.
                    And you will reslove confirmation mail after ticket is reolved!
                </span>
            </div>
        </div>
    );
}

export default TicketSuccessTemplate