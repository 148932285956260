export const languageArr = ['HTML',
    'CSS',
    'JavaScript',
    'ReactJS',
    'AngularJS',
    'VueJS',
    'NextJS',
    'Bootstrap',
    'Sass',
    'Webpack',
    'Flutter',
    'Three.js',
    'Tailwind CSS',
    'Bulma',
    'jQuery',
    'Meteor',
    'Typescript',
    'NodeJS',
    'ExpressJS',
    'Django',
    'Spring Boot',
    'PHP',
    'Flask',
    'Ruby on Rails',
    'Spring',
    'Hibernate',
    'AI & Machine Learning',
    'Cloud Computing',
    'Data Science & Data Analytics',
    'Mobile App Development',
    'React Native',
    'GatsbyJS',
    'Monorepo',
    'GraphQL',
    'NPM ',
    'Python',
    'Laravel',
    'Django',
    'Mongodb',
    'SQL',
    'Power Bi',
    'C',
    'C++',
    'Azure',
    'Devops',
    'CICD',
    'PostgreSQL',
    'SQL Server',
    'MySQL',
    'C#',
    'Kotlin',
    'ASP .net',
    'Dotnet',
    'VB .Net',
    'VB6',
    'Micro Frontends',
    '.Net Core',
    'Java',
    'GO',
    'R',
    'Perl',
    'COBOL',
    'JCL',
    'Ios',
    'Android']