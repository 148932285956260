import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import swal from "sweetalert";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import adminLayout from "../../../hoc/adminLayout";
import Pagination from '../../../common/pagination/Pagination';
import RangeDatePicker from '../../../components/RangeDatePicker';
import { returnArryOfObjects } from '../../../Utils/utils'
import { emptyJobList, PageSize } from '../../../Utils/masterData/staticContent'
import ModalComponent from "../../../components/ModalComponent";
import EditCompanyDetails from "./EditCompanyDetails";
import AddCompanyDetails from "./AddCompanyDetails";
import ViewCompanyDetails from "./ViewCompanyDetails";
import { getAllApi, deleteApiWithBody, updateWithOutParamsApi, postApi } from '../../../services/apiCommonService'
import { getEmpApplnByCompany_api, delete_company_details, get_all_applied_list, update_share_job_acct, post_acct_manager_companies } from '../../../services/endPointsConstant'
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import CustomSingleSelect from '../../../components/CustomSelectComponent';
import storePersist from '../../../Utils/storePersist'

const CmpMappedCandidatesList = (props) => {
    let navigate = useNavigate();
    const params = useParams();
    const { _id } = storePersist?.get('actMgrData')
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [resourcesDetailsList, setResourcesDetailsList] = useState([]);
    const [companyName, setCompanyName] = useState("")
    const [oldResourcesDetailsList, setOldResourcesDetailsList] = useState([]);
    const [selectedCompanyDetailsRowList, setSelectedCompanyDetailsRowList] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedCompanyDetailsObject, setSelectedCompanyDetailsObject] = useState([]);
    const [keywordName, setKeywordName] = useState('');
    const [viewSelectedCompaniesDataDetails, setViewSelectedCompaniesDataDetails] = useState('')
    const [modalPopUpCancel, setModalPopUpCancel] = useState(false);
    const [pageSize, setPageSize] = useState(PageSize);

    useEffect(() => {
        setTimeout(() => {
            void getAccountManagerListApi()
        }, 3500);
    }, []);


    const getAccountManagerListApi = async () => {
        dispatch(addLoader());
        const paramsData = params?.id?.split('-')
        const apiReqBody = {
            companyId: paramsData[1],
            jobId: paramsData[0]
        }
        const apiResponse = await postApi(getEmpApplnByCompany_api, apiReqBody)
        // const apiResponse = await getAllApi(get_all_companies_list_api_url)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            if (apiResponseData?.length > 0) {
                setCompanyName(apiResponseData && apiResponseData[0]?.CompanyDetails[0] || {})
            }
            const prepared = []
            const newApiResponseData = apiResponseData.map((item) => {
                let temp;
                if (item?.employeeDetails?.length > 0) {
                    temp = item?.employeeDetails[0]
                    if (item?.jobDetails?.length > 0) {
                        prepared.push({ ...temp, job_type: item.jobDetails[0]?.job_type, job_code: item.jobDetails[0]?.job_code, applicationId: item?._id, applicationStatus: item?.applicationStatus, employeeId: item?.employeeId })
                    }
                }
                return temp
            })
            setResourcesDetailsList(prepared)
            setOldResourcesDetailsList(prepared)
            // toast.success("Retrived all Companies List Successfully !!")
            setTimeout(() => {
                dispatch(deleteLoader());
            }, 2500);
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const currentTableData = () => {
        const firstPageIndex = (currentPage - 1) * pageSize?.value;
        const lastPageIndex = firstPageIndex + pageSize?.value;
        return resourcesDetailsList.slice(firstPageIndex, lastPageIndex);
    };

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(resourcesDetailsList, ids, '_id')
        setSelectedCompanyDetailsObject(finalUsersData)
    }

    const selectAll = () => {
        setSelectedCompanyDetailsRowList([]);
        if (!isAllSelected) {
            resourcesDetailsList.forEach(jobData => {
                setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            });
            callUsersData(resourcesDetailsList.map((item) => item._id))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const handleUserSelect = (jobData) => {
        if (selectedCompanyDetailsRowList.includes(jobData._id)) {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData._id));
            callUsersData(selectedCompanyDetailsRowList.filter(_id => _id !== jobData._id))
        } else {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData._id]);
            callUsersData([...selectedCompanyDetailsRowList, jobData._id])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedCompanyDetailsRowList.includes(jobData._id);
        return isUserSelectedVal
    };

    const isAllUsersSelected = () => {
        return selectedCompanyDetailsRowList.length === resourcesDetailsList.length;
    };

    const filterTextSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = oldResourcesDetailsList.filter((jobData) => {
                return jobData?.name?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                    jobData?.emailId?.toLowerCase().startsWith(keyword.toLowerCase())
                // jobData?.companyPhone?.toString().toLowerCase().startsWith(keyword?.toLowerCase()) ||
                // jobData?.location?.toLowerCase().startsWith(keyword.toLowerCase()) ||
                // jobData?.actManagerName?.toLowerCase().startsWith(keyword.toLowerCase())
            });
            setResourcesDetailsList(results);
        } else {
            setResourcesDetailsList(oldResourcesDetailsList);
        }
        setKeywordName(keyword);
    };

    const viewSelectedAccountManagerDetailsFun = (item, idx) => {
        selectedRecordDataFun(item)
        setViewSelectedCompaniesDataDetails({ pageData: item, idxValue: idx })
    }

    const returnAddNewRequirementFormDetailsFun = (formDetails) => {
        void getAccountManagerListApi()
        setResourcesDetailsList([...resourcesDetailsList, formDetails])
    }

    const returnEditJobRequirementFormDetailsFun = (formDetails) => {
        void getAccountManagerListApi()
        setResourcesDetailsList([...resourcesDetailsList, formDetails])
    }

    const returnedEditCompanyFormDetails = () => {
        // cancelPopUpFun(false)
        void getAccountManagerListApi()
    }

    const addAccountManagerModalContent = (props) => {
        return <>
            <AddCompanyDetails
                modalPopUpCancel={modalPopUpCancel}
                returnAddCompanyFormDetails={returnAddNewRequirementFormDetailsFun}
            />
        </>;
    }

    const editAccountManagerModalContent = () => {
        return <>
            <EditCompanyDetails
                modalPopUpCancel={modalPopUpCancel}
                selectedCompanyDetailsObject={selectedCompanyDetailsObject}
                returnEditJobRequirementFormDetails={returnEditJobRequirementFormDetailsFun}
                returnedEditCompanyFormDetails={returnedEditCompanyFormDetails}
            />
        </>;
    }

    const ViewAccountManagerDetailsModalBodyFun = (props) => {
        return <>
            <ViewCompanyDetails
                viewSelectedCompanyDetails={viewSelectedCompaniesDataDetails || undefined}
                allCompaniesOldList={resourcesDetailsList}
                selectedRecordDataFun={selectedRecordDataFun}
            />
        </>;
    }

    const editAccountManagerModal = () => {
        // toast.info("Edit job Modal Popup is Opened !!")
    }

    const cancelPopUpFun = (vale) => {
        setModalPopUpCancel(vale)
    }

    const selectedRecordDataFun = (selected_job_data) => {
        if (selected_job_data) {
            setSelectedCompanyDetailsRowList([selected_job_data?._id]);
            setSelectedCompanyDetailsObject([selected_job_data])
        }
    }

    const onSubmit = async () => {
        const apiReqBody = {
            'companyId': selectedCompanyDetailsRowList.length > 0 && selectedCompanyDetailsRowList
        }
        const apiResponse = await deleteApiWithBody(delete_company_details, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Deleted company successfully !!')
            void getAccountManagerListApi()
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const deleteModalContent = () => {
        return <>
            <ul>
                {selectedCompanyDetailsObject?.map((item, idx) =>
                    <li key={idx}>
                        <img src={item?.logo} alt="logo" className='img_w_h_100' />
                        <span className='pe-2 ps-2'>
                            -
                        </span>
                        <span>{item.companyName}</span>
                    </li>
                )}
            </ul>
        </>;
    }

    const deleteModalFooterContent = (props) => {
        return <>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
                onClick={onSubmit}
            >
                Yes
            </button>
            <button
                className="btn btn-primary btn_background_color mt-0 mb-0"
                data-bs-dismiss="modal"
            >
                Cancel
            </button>
        </>;
    }

    const deleteFun = () => {
        // toast.info("Delete Confirmation Modal Popup is Opened !!")
    }

    const onChangeHandler = (event) => {
        setPageSize(event)
    }

    const redirectToCompany = (item) => {
        navigate(`/companyjoblist/${item._id}`);
    }

    const redirectToCompanyAssociates = (item) => {
        navigate('/act-mgr-onboard-candidates-list', { state: { companyId: item._id } })
    }

    const onClickshare = async (item) => {
        dispatch(addLoader());
        swal({
            title: "Share !!",
            text: "Are you sure you want to share?",
            // icon: "info",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Shared!',
                    text: 'Shared candidate details successfully!',
                    // icon: 'success'
                }).then(async function () {
                    const apiReqBody = {
                        applicationId: item?.applicationId,
                        applicationStatus: "mapped",
                        actManagerId: _id
                    }
                    const apiResponse = await postApi(update_share_job_acct, apiReqBody)
                    if (apiResponse?.data) {
                        toast.success('Shared successfully !!')
                        void getAccountManagerListApi()
                    } else {
                        toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
                        dispatch(deleteLoader());
                    }
                });
            } else {
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 500);
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const viewDetailsPage = (id) => {
        navigate(`/Employee-static-steps-bar/${id}`)
    }

    return <>
        <div className="my-1 p-3 bg-body rounded shadow-sm rounded_20">
            <div className="d-flex justify-content-between align-items-center border-bottom pt-2">
                <div className="col-xl-4 col-sm-6 ps-3">
                    <h6 className="font_color_imp font_size_16 fw-bolded"> Company : <span className='font_color_ornage'>{companyName?.companyName}</span></h6>
                </div>
                <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                    <>
                        {/* <button
                            type="button"
                            className="btn btn-primary btn_background_color pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                            data-bs-toggle="modal"
                            data-bs-target="#addCompanyTestId"
                        >
                            Add Company
                        </button> */}
                        {/* {oldResourcesDetailsList.length > 0 &&
                            <>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                                    disabled={selectedCompanyDetailsRowList.length > 0 && selectedCompanyDetailsRowList.length < 2 ? false : true}
                                    data-bs-target="#editCompanyTestId"
                                    data-bs-toggle="modal"
                                    onClick={editAccountManagerModal}
                                >
                                    Edit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                    disabled={selectedCompanyDetailsRowList.length > 0 ? false : true}
                                    data-bs-target="#deleteCompanyDetailsModalID"
                                    data-bs-toggle="modal"
                                    onClick={deleteFun}
                                >
                                    Delete
                                </button>
                            </>
                        } */}
                    </>
                </div>
            </div>
            {oldResourcesDetailsList.length > 0 &&
                <>
                    <div className="mt-3 mb-0 pe-2">
                        <div className="col-md-12 d-flex justify-content-between align-items-center gap-4">
                            <div className="col-md-4 d-flex justify-content-start align-items-center gap-1 ms-2 margin_bottom_20">
                                <span><b>  Total Mapped Resources list : </b>(<span className='orange_color'><b>{oldResourcesDetailsList?.length}</b></span>)</span>
                                {/* <button className="border-0 mt-0 mb-0 pb-0 bg-white">
                                    <img src={require('../../../assets/images/vector_filter.svg').default} alt="Alt content" />
                                </button> */}
                                {/* <div className="col-md-8"> */}
                                {/* <RangeDatePicker /> */}
                                {/* </div> */}
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="search"
                                    value={keywordName}
                                    onChange={filterTextSearch}
                                    className="searchInputCls"
                                    placeholder="Search name & email"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row bd-example">
                            <div className="table-container">
                                <div className="d-flex text-muted height_job_post_table">
                                    <PerfectScrollbar className="sidebar-items w-100 pt-0 pb-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    {/* <th>
                                                        <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                    </th> */}
                                                    {['Name', 'Email Id', 'Phone Number', 'Job Code', 'Job title', 'Country', 'Application Status', 'view'].
                                                        map((item, idx) =>
                                                            <th key={idx} className={`text-white`} style={{ zIndex: 99 }}>
                                                                {item}
                                                            </th>
                                                        )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData().map((item, idx) => {
                                                    return (
                                                        <tr key={item._id} className=''>
                                                            {/* hover_orange hover_underline */}
                                                            {/* <td>
                                                                <input
                                                                    className="width_12"
                                                                    type="checkbox"
                                                                    checked={isUserSelected(item)}
                                                                    onChange={() => handleUserSelect(item)}
                                                                />
                                                            </td> */}
                                                            {/* <td>
                                                                <img src={item?.logo} alt="logo" className='img_w_h_100' />
                                                            </td> */}
                                                            <td className='fw-medium'>
                                                                {item?.name}
                                                            </td>
                                                            <td className='fw-medium'>
                                                                {item?.emailId}
                                                            </td>
                                                            <td className='fw-medium'>
                                                                {item?.phoneNumber}
                                                            </td>
                                                            <td className='fw-medium'>
                                                                {item?.job_type}
                                                            </td>
                                                            <td className='fw-medium'>
                                                                {item?.job_code}
                                                            </td>
                                                            <td className='fw-medium'>
                                                                {item?.country}
                                                            </td>
                                                            <td className='fw-medium'>
                                                                {item?.applicationStatus === 'mapped' ?
                                                                    <span class="badge rounded-pill bg-success">{item?.applicationStatus}</span>
                                                                    :
                                                                    <span class="badge rounded-pill bg-warning text-dark">{item?.applicationStatus}</span>
                                                                }
                                                            </td>
                                                            <td className='fw-medium'>
                                                                <img
                                                                    src={require('../../../assets/images/view_account_details_nm.svg').default}
                                                                    alt="Alt content"
                                                                    style={{ height: 12, padding: '0px 6px 0px 2px' }}
                                                                    onClick={() => { viewDetailsPage(item?.employeeId) }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </PerfectScrollbar>
                                </div>
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <div className="d-flex justify-content-between align-items-baseline gap-2">
                                        <div>Items per page</div>
                                        <CustomSingleSelect
                                            options={[{ value: 10, label: 10 }, { value: 20, label: 20 }, { value: 30, label: 30 }, { value: 40, label: 40 }, { value: 50, label: 50 }]}
                                            selectedPage={pageSize}
                                            onChangeHandler={onChangeHandler}
                                            disabled={resourcesDetailsList?.length <= 10}
                                        />
                                        <div>{currentPage}-{pageSize?.label} of {resourcesDetailsList.length} items</div>
                                    </div>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={resourcesDetailsList.length}
                                        pageSize={pageSize?.label}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {oldResourcesDetailsList.length === 0 &&
                <>
                    <div className="row bd-example">
                        <div className="table-container">
                            <div className="text_v_h_center vh-80 font_size_24">
                                No Data Found
                            </div>
                        </div>
                    </div>
                </>
            }
        </div >

        {/* Add Account Manager Details modals */}
        <ModalComponent
            title="Add Company Details"
            content={addAccountManagerModalContent()}
            dataBsBackdrop="static"
            id="addCompanyTestId"
            // modalDialogClass="add_requirement_modal_w"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}
        />
        {/* Edit Account Manager Details modals */}
        <ModalComponent
            title="Edit company Details"
            content={editAccountManagerModalContent()}
            dataBsBackdrop="static"
            id="editCompanyTestId"
            modalBodyPaddingBottomCls="p-0"
            cancelPopUpFun={cancelPopUpFun}
            fullScreen={true}
        />
        {/* View Account Manager Details modals */}
        <ModalComponent
            title={viewSelectedCompaniesDataDetails?.pageData?.companyName}
            content={ViewAccountManagerDetailsModalBodyFun()}
            dataBsBackdrop="static"
            id="viewCompanyDetailsModalID"
            modalBodyPaddingBottomCls="p-0 overflow_custom"
            cancelPopUpFun={cancelPopUpFun}
            modalDialogClass="view_job_details_modal_w"
            isHeaderHide={true}
        />

        {/* delete Account Manager Details modal */}
        <ModalComponent
            title="Do you want delete selected Companies list ?"
            footerContent={deleteModalFooterContent()}
            content={deleteModalContent()}
            dataBsBackdrop="static"
            id="deleteCompanyDetailsModalID"
            w_max_content={true}
        />
    </>
}

export default adminLayout(CmpMappedCandidatesList);