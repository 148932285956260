import React, { useEffect, useState } from "react";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link, useLocation } from 'react-router-dom';
import storePersist from '../Utils/storePersist';
import { toast } from 'react-toastify';
import { postApi, deleteApiWithBody, getAllApi } from '../services/apiCommonService'
import { approved_by_admin_url, usergetAllQueryTickets_url, delete_user_url, get_all_account_managers, get_verify_all_user_url, upload_company_files, upload_candidate_file, create_candidate_details, get_all_candidates } from '../services/endPointsConstant'
const mainRoles = ['ADMIN']

const Sidebar = (props) => {
    const { pathname } = useLocation();
    const { role } = storePersist.get('userData')
    const [activateUsers, setActivateUsers] = useState([])
    const [ticketsList, setTicketsList] = useState([])

    useEffect(() => {
        void getCandidatesApiCallFun()
        void getTicketsApiCallFun()
    }, [])

    const getCandidatesApiCallFun = async () => {
        const apiReqBody = {}
        const apiResponse = await getAllApi(get_verify_all_user_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData?.filter(({ role }) => role !== "EMPLOYEE")
            setActivateUsers(apiResponseData)
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const getTicketsApiCallFun = async () => {
        const apiReqBody = {}
        const apiResponse = await getAllApi(usergetAllQueryTickets_url, apiReqBody)
        if (apiResponse?.data?.Data) {
            let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            apiResponseData = apiResponseData?.filter((item) => item.isTicketResolved === false)
            setTicketsList(apiResponseData)
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const RepeatListItems = (props) => {
        const { listItems } = props
        return (
            <>
                {listItems.map((item) => {
                    const imgUrl = require(`./../assets/images/${item?.imgName}.svg`)
                    return (<li className={`${pathname === item.routeUrl && 'bg_color_side_bar_selected_item'}`}>
                        <Link tag="a" to={item?.routeUrl} className={`${pathname === item.routeUrl && "side_bar_active_color"}`}>
                            <div className="row gx-2">
                                <div className="col-md-1">
                                    <img alt="Alt content" className="custom_img_fluid" src={imgUrl} />
                                </div>
                                <div className="col-md-11 d-flex justify-content-start align-items-baseline">
                                    <span className="text_xs ms-3 pt-1 col-sm">{item?.title}</span>
                                    {['Pending Requests'].includes(item?.title) && activateUsers?.length > 0 &&
                                        <span class="button__badge_sidebar">{activateUsers?.length}</span>
                                    }
                                    {['Technical support tickets'].includes(item?.title) && ticketsList?.length > 0 &&
                                        <span class="button__badge_sidebar">{ticketsList?.length}</span>
                                    }
                                    {pathname === item.routeUrl &&
                                        <span className="float-right text-right">
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                                    }
                                </div>
                            </div>
                        </Link>
                    </li>)
                })}
            </>
        )
    }

    return <div className="border-end sidenav" id="sidebar-wrapper">
        <div className="sidebar-heading border-bottom bg-white pb-3">
            <Link tag="a" to={mainRoles.includes(role) ? "/admin-dashboard" : '/dashboard'}>
                <img alt="Alt content" src={require('./../assets/images/side_bar_logo.svg').default}  style={{width: '154px'}}/>
            </Link>
        </div>
        <PerfectScrollbar className="sidebar-items bgColor_side_bar pt-0">
            {mainRoles.includes(role) &&
                <>
                    <ul className="list-unstyled ps-0">
                        <li className={`mb-1 ps-1 mt-3 ${pathname === '/admin-dashboard' && 'bg_color_side_bar_selected_item'}`}>
                            {/* focus_on_hover  */}
                            <Link tag="a" className={`d-flex justify-content-start align-items-center ${pathname === '/admin-dashboard' && 'side_bar_active_color'}`} to="/admin-dashboard">
                                <i className="fa fa-circle-o" style={{ color: "#F57C00", fontSize: 30 }} ></i>
                                <span className="text_xs ms-2 col-sm">Dashboard</span>
                                {pathname === '/admin-dashboard' &&
                                    <span className="float-right text-right col-sm">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                }
                            </Link>
                        </li>
                    </ul>
                    <h1 className="nav_side_bar_header"><b>{role}</b></h1>
                    <ul className="list-unstyled ps-0">
                        <RepeatListItems
                            listItems={[
                                { routeUrl: '/companies-list', imgName: 'postJobs', title: 'Companies' },
                                // { routeUrl: '/candidates-list', imgName: 'Finalized', title: 'Candidates List' },
                                { routeUrl: '/account-managers-list', imgName: 'Team', title: 'Account Managers' },
                                { routeUrl: '/job-offers-list', imgName: 'interview_feedback', title: 'Job Offered' },
                                { routeUrl: '/activate-users-list', imgName: 'scheduleInterview', title: 'Pending Requests' },
                                { routeUrl: '/active-users-list', imgName: 'onboarding', title: 'Active Users' },
                                { routeUrl: '/rejected-companies-list', imgName: 'timings', title: 'Rejected Requests' },
                                { routeUrl: '/resolved-tickets-list', imgName: 'accountManger', title: 'Technical support tickets' },
                                { routeUrl: '/active-employee-list', imgName: 'Team', title: 'Active Employees' },
                                // { routeUrl: '/status-position', imgName: 'Finalized', title: 'Status-Positions' },
                            ]}
                        />
                    </ul>
                </>
            }
            {role === 'COMPANY' &&
                <>
                    <ul className="list-unstyled ps-0">
                        <li className={`mb-1 ps-1 mt-3 ${pathname === '/dashboard' && 'bg_color_side_bar_selected_item'}`}>
                            {/* focus_on_hover  */}
                            <Link tag="a" className={`d-flex justify-content-start align-items-center ${pathname === '/dashboard' && 'side_bar_active_color'}`} to="/dashboard">
                                <i className="fa fa-circle-o" style={{ color: "#F57C00", fontSize: 30 }} ></i>
                                <span className="text_xs ms-2 col-sm">Dashboard</span>
                                {pathname === '/dashboard' &&
                                    <span className="float-right text-right col-sm">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                }
                            </Link>
                        </li>
                    </ul>
                    <h1 className="nav_side_bar_header"><b>{role}</b></h1>
                    <ul className="list-unstyled ps-0">
                        <RepeatListItems
                            listItems={[
                                // { routeUrl: '/companies-list', imgName: 'postJobs', title: 'Companies' },
                                // { routeUrl: '/candidates-list', imgName: 'Finalized', title: 'Candidates List' },
                                // { routeUrl: '/account-managers-list', imgName: 'scheduled_interview_1', title: 'Account Managers' },
                                { routeUrl: '/jobs-list', imgName: 'postJobs', title: 'Jobs list' },
                                // { routeUrl: '/short-list-resumes', imgName: 'scheduled_interview_1', title: 'Scheduled Interviews' },
                                { routeUrl: '/job-offers-list', imgName: 'Finalized', title: 'Job Offered' },
                                // { routeUrl: '/assigned-details', imgName: 'Finalized', title: 'Assigned Details' },
                                // { routeUrl: '/status-position', imgName: 'Finalized', title: 'Status-Positions' },
                            ]}
                        />
                    </ul>
                </>
            }
            {role === 'ACTMGR' &&
                <>
                    <ul className="list-unstyled ps-0">
                        <li className={`mb-1 ps-1 mt-3 ${pathname === '/dashboard' && 'bg_color_side_bar_selected_item'}`}>
                            {/* focus_on_hover  */}
                            <Link tag="a" className={`d-flex justify-content-start align-items-center ${pathname === '/dashboard' && 'side_bar_active_color'}`} to="/dashboard">
                                <i className="fa fa-circle-o" style={{ color: "#F57C00", fontSize: 30 }} ></i>
                                <span className="text_xs ms-2 col-sm">Dashboard</span>
                                {pathname === '/dashboard' &&
                                    <span className="float-right text-right col-sm">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                }
                            </Link>
                        </li>
                    </ul>
                    <h1 className="nav_side_bar_header"><b>{role}</b></h1>
                    <ul className="list-unstyled ps-0">
                        <RepeatListItems
                            listItems={[
                                { routeUrl: '/act-manager-cmpy-list', imgName: 'postJobs', title: 'Companies' },
                                // { routeUrl: '/candidates-list', imgName: 'Finalized', title: 'Candidates List' },
                                // { routeUrl: '/account-managers-list', imgName: 'scheduled_interview_1', title: 'Account Managers' },
                                // { routeUrl: '/jobs-list', imgName: 'postJobs', title: 'Jobs list' },
                                // { routeUrl: '/short-list-resumes', imgName: 'scheduled_interview_1', title: 'Scheduled Interviews' },
                                { routeUrl: '/job-offers-list', imgName: 'Finalized', title: 'Job Offered' },
                                // { routeUrl: '/assigned-details', imgName: 'Finalized', title: 'Assigned Details' },
                                // { routeUrl: '/status-position', imgName: 'Finalized', title: 'Status-Positions' },
                            ]}
                        />
                    </ul>
                </>
            }
            {role === 'USER' &&
                <>
                    <ul className="list-unstyled ps-0">
                        <li className={`mb-1 ps-1 mt-3 ${pathname === '/dashboard' && 'bg_color_side_bar_selected_item'}`}>
                            {/* focus_on_hover  */}
                            <Link tag="a" className={`d-flex justify-content-start align-items-center ${pathname === '/dashboard' && 'side_bar_active_color'}`} to="/dashboard">
                                <i className="fa fa-circle-o" style={{ color: "#F57C00", fontSize: 30 }} ></i>
                                <span className="text_xs ms-2 col-sm">Dashboard</span>
                                {pathname === '/dashboard' &&
                                    <span className="float-right text-right col-sm">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                }
                            </Link>
                        </li>
                    </ul>
                    <h1 className="nav_side_bar_header">REQUIREMENT</h1>
                    <ul className="list-unstyled ps-0">
                        <RepeatListItems
                            listItems={[
                                { routeUrl: '/jobs-list', imgName: 'postJobs', title: 'Apply Jobs' },
                                // { routeUrl: '/candidates-list', imgName: 'Finalized', title: 'Candidates List' },
                                // { routeUrl: '/typography', imgName: 'shortListed', title: 'Short listed' },
                                // { routeUrl: '/short-list-resumes', imgName: 'scheduled_interview_1', title: 'Scheduled Interviews' },
                                { routeUrl: '/job-offers-list', imgName: 'Finalized', title: 'Job Offered' },
                                // { routeUrl: '/typography', imgName: 'Finalized', title: 'Finalized' },
                            ]}
                        />

                        {/* <i className="fa fa-edit"></i> */}
                        {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/blank-page"> 
                    <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/postJobs.svg').default} />
                        <span className="text_xs ms-2">Post Jobs</span>
                    </Link> 
                </li> */}

                        {/* <i className="fa fa-building-o" aria-hidden="true"></i> */}
                        {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/shortListed.svg').default} />
                        <span className="text_xs ms-2">Short listed</span>
                    </Link>
                </li> */}

                        {/* <i className="fa fa-building-o" aria-hidden="true"></i> */}
                        {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/scheduled_interview_1.svg').default} />
                        <span className="text_xs ms-2">Scheduled Interviews</span>
                    </Link>
                </li> */}

                        {/* <i className="fa fa-building-o" aria-hidden="true"></i> */}
                        {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/Finalized.svg').default} />
                        <span className="text_xs ms-2">Finalized</span>
                    </Link>
                </li> */}
                    </ul>

                    {/*             
            <h1 className="nav_side_bar_header">PEOPLE</h1>
            <ul className="list-unstyled ps-0">
                <RepeatListItems
                    listItems={[
                        { routeUrl: '/blank-page', imgName: 'Team', title: 'Team' },
                        { routeUrl: '/typography', imgName: 'accountManger', title: 'Account Manger' },
                        { routeUrl: '/typography', imgName: 'scheduleInterview', title: 'Schedule Interview' },
                        { routeUrl: '/typography', imgName: 'interview_feedback', title: 'Interview Feedback' },
                        { routeUrl: '/typography', imgName: 'onboarding', title: 'Onboarding' },
                        { routeUrl: '/typography', imgName: 'leaveDetails', title: 'Leave Details' },
                        { routeUrl: '/typography', imgName: 'timings', title: 'Time Tracking & Attendance' },

                    ]}
                /> */}

                    {/* <i className="fa fa-users"></i> */}
                    {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/blank-page">
                        <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/Team.svg').default} />
                        <span className="text_xs ms-2">Team</span>
                    </Link>
                </li> */}

                    {/* <i className="fa fa-user-secret" aria-hidden="true"></i> */}
                    {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/accountManger.svg').default} />
                        <span className="text_xs ms-2">Account Manger</span>
                    </Link>
                </li> */}

                    {/* <i className="fa fa-building-o" aria-hidden="true"></i> */}
                    {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/scheduleInterview.svg').default} />
                        <span className="text_xs ms-2">Schedule Interview</span>
                    </Link>
                </li> */}

                    {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                    {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/interview_feedback.svg').default} />
                        <span className="text_xs ms-2">Interview Feedback</span>
                    </Link>
                </li> */}

                    {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                    {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/onboarding.svg').default} />
                        <span className="text_xs ms-2">Onboarding</span>
                    </Link>
                </li> */}

                    {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                    {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/leaveDetails.svg').default} />
                        <span className="text_xs ms-2">Leave Details</span>
                    </Link>
                </li> */}

                    {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                    {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <div className="row">
                            <div className="col-md-1">
                                <img alt="Alt content" className="custom_img_fluid" src={require('./../assets/images/timings.svg').default} />
                            </div>
                            <div className="col-md-9 d-flex justify-content-start">
                                <span className="text_xs ms-3">Time Tracking & Attendance</span>
                            </div>
                        </div>
                    </Link>
                </li> */}

                    {/* </ul> */}

                    {/* <h1 className="nav_side_bar_header">PAYMENT</h1>
            <ul className="list-unstyled ps-0">
                <RepeatListItems
                    listItems={[
                        { routeUrl: '/blank-page', imgName: 'shortListed', title: 'Payroll' },
                        { routeUrl: '/typography', imgName: 'shortListed', title: 'Pay Contractor' },
                        { routeUrl: '/typography', imgName: 'scheduled_interview_1', title: 'Invoices' },
                        { routeUrl: '/typography', imgName: 'shortListed', title: 'Billing Reports' },
                    ]}
                /> */}

                    {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/blank-page">
                        <i className="fa fa-edit"></i>
                        <span className="text_xs ms-2">Post Jobs</span>
                    </Link>
                </li>
                <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <i className="fa fa-building-o" aria-hidden="true"></i>
                        <span className="text_xs ms-2">Short listed</span>
                    </Link>
                </li>

                <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <i className="fa fa-building-o" aria-hidden="true"></i>
                        <span className="text_xs ms-2">Scheduled Interviews</span>
                    </Link>
                </li>

                <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <i className="fa fa-building-o" aria-hidden="true"></i>
                        <span className="text_xs ms-2">Finalized</span>
                    </Link>
                </li> */}

                    {/* </ul> */}

                    <h1 className="nav_side_bar_header">CONTRACTS</h1>
                    <ul className="list-unstyled ps-0">
                        <RepeatListItems
                            listItems={[
                                { routeUrl: '/view-contracts', imgName: 'viewContracts', title: 'View Contracts' },
                                // { routeUrl: '/blank-page', imgName: 'shortListed', title: 'Formats' },
                                // { routeUrl: '/typography', imgName: 'shortListed', title: 'Short listed' },
                                // { routeUrl: '/typography', imgName: 'scheduled_interview_1', title: 'Scheduled Interviews' },
                                // { routeUrl: '/typography', imgName: 'Finalized', title: 'Finalized' },
                            ]}
                        />
                        {/* <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/blank-page">
                        <i className="fa fa-edit"></i>
                        <span className="text_xs ms-2">Post Jobs</span>
                    </Link>
                </li>
                <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <i className="fa fa-building-o" aria-hidden="true"></i>
                        <span className="text_xs ms-2">Short listed</span>
                    </Link>
                </li>

                <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <i className="fa fa-building-o" aria-hidden="true"></i>
                        <span className="text_xs ms-2">Scheduled Interviews</span>
                    </Link>
                </li>

                <li className="mb-1 ms-2">
                    <Link tag="a" className="" to="/typography">
                        <i className="fa fa-building-o" aria-hidden="true"></i>
                        <span className="text_xs ms-2">Finalized</span>
                    </Link>
                </li> */}
                    </ul>
                </>
            }
            {
                role === 'EMPLOYEE' &&
                <>
                    <ul className="list-unstyled ps-0">
                        <li className={`mb-1 ps-1 mt-3 ${pathname === '/employee-dashboard' && 'bg_color_side_bar_selected_item'}`}>
                            {/* focus_on_hover  */}
                            <Link tag="a" className={`d-flex justify-content-start align-items-center ${pathname === '/employee-dashboard' && 'side_bar_active_color'}`} to="/employee-dashboard">
                                <i className="fa fa-circle-o" style={{ color: "#F57C00", fontSize: 30 }} ></i>
                                <span className="text_xs ms-2 col-sm">Dashboard</span>
                                {pathname === '/dashboard' &&
                                    <span className="float-right text-right col-sm">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                }
                            </Link>
                        </li>
                    </ul>
                    <h1 className="nav_side_bar_header">JOBS</h1>
                    <ul className="list-unstyled ps-0">
                        <RepeatListItems
                            listItems={[
                                { routeUrl: '/employee-jobs-list', imgName: 'postJobs', title: 'Apply Jobs' },
                                // { routeUrl: '/candidates-list', imgName: 'Finalized', title: 'Candidates List' },
                                // { routeUrl: '/typography', imgName: 'shortListed', title: 'Short listed' },
                                // { routeUrl: '/short-list-resumes', imgName: 'scheduled_interview_1', title: 'Scheduled Interviews' },
                                // { routeUrl: '/job-offers-list', imgName: 'Finalized', title: 'Job Offered' },
                                // { routeUrl: '/typography', imgName: 'Finalized', title: 'Finalized' },
                            ]}
                        />
                    </ul>
                    {/* <h1 className="nav_side_bar_header">PROFILE</h1>
                    <ul className="list-unstyled ps-0">
                        <RepeatListItems
                            listItems={[
                                { routeUrl: '/view-contracts', imgName: 'viewContracts', title: 'List companies' },
                                { routeUrl: '/Employee-scheduled-interviews', imgName: 'shortListed', title: 'Scheduled Interviews' },
                                { routeUrl: '/Employee-interview-feedback', imgName: 'shortListed', title: 'Interview Feedback' },
                                { routeUrl: '/Employee-onboarding', imgName: 'shortListed', title: 'Onboarding' },
                                // { routeUrl: '/typography', imgName: 'shortListed', title: 'Short listed' },
                                // { routeUrl: '/typography', imgName: 'scheduled_interview_1', title: 'Scheduled Interviews' },
                                // { routeUrl: '/typography', imgName: 'Finalized', title: 'Finalized' },
                            ]}
                        />
                    </ul> */}
                    {/* <h1 className="nav_side_bar_header">Mange</h1>
                    <ul className="list-unstyled ps-0">
                        <RepeatListItems
                            listItems={[
                                { routeUrl: '/Employee-time-sheet', imgName: 'viewContracts', title: 'Time Sheet' },
                                { routeUrl: '/Employee-leave-details', imgName: 'shortListed', title: 'Leave Details' },
                                // { routeUrl: '/typography', imgName: 'shortListed', title: 'Short listed' },
                                // { routeUrl: '/typography', imgName: 'scheduled_interview_1', title: 'Scheduled Interviews' },
                                // { routeUrl: '/typography', imgName: 'Finalized', title: 'Finalized' },
                            ]}
                        />
                    </ul> */}
                </>
            }
        </PerfectScrollbar>
        {/* <div className="dropdown fixed-bottom-dropdown">
            <a href="#" className="d-flex align-items-center text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="https://via.placeholder.com/50" alt="" width="32" height="32" className="rounded-circle me-2" />
                <span>Tarun Dhiman</span>
            </a>
            <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                <li><Link className="dropdown-item" to="/profile"><i className="fa fa-user-circle" aria-hidden="true"></i> Profile</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li><Link className="dropdown-item" to="/login"><i className="fa fa-sign-out" aria-hidden="true"></i> Sign out</Link></li>
            </ul>
        </div> */}
    </div >
}

export default Sidebar;