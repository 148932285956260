import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import adminLayout from "../../hoc/adminLayout"
import { addLoader, deleteLoader } from "../../redux/actions/loader";
import { get_offer_report, get_all_candidates, get_all_notifications, delete_notification_by_id, get_Shortlisted_api_url, get_Today_Interview_url } from '../../services/endPointsConstant';
import { postApi, getAllApi } from '../../services/apiCommonService'
import { toast } from 'react-toastify';
import storePersist from '../../Utils/storePersist'
import { formatDateDDMMMYYYY, fromNowFun, toPascalCase } from '../../Utils/utils';

const getHashOfString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line: no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};

const HSLtoString = (hsl) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
  return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
  if (user) {
    return `${user[0]?.toUpperCase()}${user[user?.length - 1]?.toUpperCase()}`
  }
}

const setValue = (functionFor) => {
  return (e) => {
    const value = parseInt(e.target.value);
    functionFor(value);
  }
};

const getRange = (value, range) => {
  return [Math.max(0, value - range), Math.min(value + range, 100)];
}

const DashboardPage = (props) => {
  const { user, email, role, id } = storePersist.get('userData')
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [dashboardDetails, setDashboardDetails] = useState([])
  const [allCandidatesList, setAllCandidatesList] = useState([])
  const [selectedPeriod, setSelectedPeriod] = useState(null)
  const [allImportantNotificationsList, setAllImportantNotificationsList] = useState([])

  const [range, setRange] = React.useState(10);
  const [saturation, setSaturation] = React.useState(50);
  const [lightness, setLightness] = React.useState(50);
  const [theme, setTheme] = React.useState('Light');

  const saturationRange = getRange(saturation, range);
  const lightnessRange = getRange(lightness, range);

  useEffect(() => {
    async function fetchData() {
      setTimeout(() => {
        getReportApiCallFun()
        getAllNotificationsApiCallFun()
      }, 3000);
    }
    fetchData()
    setSelectedPeriod(null)
  }, [])

  const getAllCandidatesList = async () => {
    dispatch(addLoader());
    // const apiReqBody = {} actManagerId
    if (role === 'ACTMGR') {
      const companyDetailsStorage = storePersist.get('actMgrData')
      if (companyDetailsStorage && companyDetailsStorage?._id) {
        let apiReqBody = {
          actManagerId: companyDetailsStorage && companyDetailsStorage?._id
        }
        const apiResponse = await postApi(get_Today_Interview_url, apiReqBody)
        if (apiResponse?.data?.Data) {
          let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
          // apiResponseData = apiResponseData.map(({ _id, firstName }) => { return { value: _id, label: firstName } })
          setAllCandidatesList(apiResponseData)
          // toast.success("Retrived all Candidates List Successfully !!")
          dispatch(deleteLoader());
        } else {
          toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
          dispatch(deleteLoader());
        }
      } else {
        setAllCandidatesList([])
      }
    } else {
      const companyDetailsStorage = storePersist.get('companyDetails')
      if (companyDetailsStorage && companyDetailsStorage?._id) {
        let apiReqBody = {
          companyId: companyDetailsStorage && companyDetailsStorage?._id
        }
        const apiResponse = await postApi(get_Today_Interview_url, apiReqBody)
        if (apiResponse?.data?.Data) {
          let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
          // apiResponseData = apiResponseData.map(({ _id, firstName }) => { return { value: _id, label: firstName } })
          setAllCandidatesList(apiResponseData)
          // toast.success("Retrived all Candidates List Successfully !!")
          dispatch(deleteLoader());
        } else {
          toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
          dispatch(deleteLoader());
        }
      } else {
        setAllCandidatesList([])
      }
    }

  }

  const deleteNotificationById = async (item) => {
    const { _id, title } = item
    let apiReqBody = {
      notifyId: [_id]
    }
    RedirectionNotificatiosn(title, item)
    const apiResponse = await postApi(delete_notification_by_id, apiReqBody)
    if (apiResponse?.data) {
      toast.success('Navigated successfully' || "deleted Successfully !!")
      dispatch(deleteLoader());
      await getAllNotificationsApiCallFun()
    } else {
      toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
      dispatch(deleteLoader());
    }
  }

  const getReportApiCallFun = async (periodVal) => {
    dispatch(addLoader());
    if (role === 'ACTMGR') {
      const companyDetailsStorage = storePersist.get('actMgrData')
      let apiReqBody = {
        actManagerId: companyDetailsStorage && companyDetailsStorage?._id
      }
      if (periodVal) {
        apiReqBody = {
          actManagerId: companyDetailsStorage && companyDetailsStorage?._id,
          period: periodVal
        }
      }

      if (companyDetailsStorage && companyDetailsStorage?._id) {
        const apiResponse = await postApi(get_offer_report, apiReqBody)
        if (apiResponse?.data?.Data) {
          setDashboardDetails(apiResponse?.data?.Data)
          // toast.success(apiResponse?.response?.data.message || "Successfully retrived details of dashboard !!")
          getAllCandidatesList()
          dispatch(deleteLoader());
        } else {
          toast.error(apiResponse?.response?.data.message)
          dispatch(deleteLoader());
        }
      }
    } else {
      const companyDetailsStorage = storePersist.get('companyDetails')
      let apiReqBody = {
        companyId: companyDetailsStorage && companyDetailsStorage?._id
      }
      if (periodVal) {
        apiReqBody = {
          companyId: companyDetailsStorage && companyDetailsStorage?._id,
          period: periodVal
        }
      }

      if (companyDetailsStorage && companyDetailsStorage?._id) {
        const apiResponse = await postApi(get_offer_report, apiReqBody)
        if (apiResponse?.data?.Data) {
          setDashboardDetails(apiResponse?.data?.Data)
          // toast.success(apiResponse?.response?.data.message || "Successfully retrived details of dashboard !!")
          getAllCandidatesList()
          dispatch(deleteLoader());
        } else {
          toast.error(apiResponse?.response?.data.message)
          dispatch(deleteLoader());
        }
      }
    }

  };

  const getAllNotificationsApiCallFun = async (periodVal) => {
    dispatch(addLoader());

    if (role === 'ACTMGR') {
      const companyDetailsStorage = storePersist.get('actMgrData')
      if (companyDetailsStorage && companyDetailsStorage?._id) {
        let apiReqBody = {
          actManagerId: companyDetailsStorage && companyDetailsStorage?._id
        }
        const apiResponse = await postApi(get_all_notifications, apiReqBody)
        if (apiResponse?.data?.Data) {
          setAllImportantNotificationsList(apiResponse?.data?.Data?.reverse())
          // toast.success(apiResponse?.response?.data.message || "Successfully retrived details of dashboard !!")
          dispatch(deleteLoader());
        } else {
          toast.error(apiResponse?.response?.data.message)
          dispatch(deleteLoader());
        }
      }
    } else {
      const companyDetailsStorage = storePersist.get('companyDetails')
      if (companyDetailsStorage && companyDetailsStorage?._id) {
        let apiReqBody = {
          companyId: companyDetailsStorage && companyDetailsStorage?._id
        }
        const apiResponse = await postApi(get_all_notifications, apiReqBody)
        if (apiResponse?.data?.Data) {
          setAllImportantNotificationsList(apiResponse?.data?.Data?.reverse())
          // toast.success(apiResponse?.response?.data.message || "Successfully retrived details of dashboard !!")
          dispatch(deleteLoader());
        } else {
          toast.error(apiResponse?.response?.data.message)
          dispatch(deleteLoader());
        }
      }
    }
  };

  const returnIcon = (type) => {
    switch (type) {
      case 'payment':
      case 'invoice':
      case 'resumeScreening':
        return require('../../assets/images/payment.png');
      case 'resourceProjectQuitting':
      case 'interviewResult':
      case 'replacementRequest':
        return require('../../assets/images/resource_quitting.svg').default;
      case 'newCandidateOnboarding':
      case 'newCandidateAdded':
      case 'interviewSchedule':
      case 'rescheduleInterview':
      case 'accountManagerAssigned':
        return require('../../assets/images/newCandidate.jpg');
      default:
        return require('../../assets/images/payment.png');
    }
  }

  const getColorFun = (type) => {
    switch (type) {
      case 'resourceProjectQuitting':
      case 'interviewResult':
      case 'replacementRequest':
        return 'resource_quitting_cls';
      case 'newCandidateOnboarding':
      case 'newCandidateAdded':
      case 'interviewSchedule':
      case 'rescheduleInterview':
      case 'accountManagerAssigned':
        return 'resource_notification';
      default:
        return require('../../assets/images/payment.png');
    }
  }

  const onClickPageRedirection = (item) => {
    // navigate('/short-list-resumes');
    navigate(`/short-list-resumes`, { state: { jobId: item?.jobId, companyId: item?.companyId, stage: item?.resume_status === 'shortlisted' ? 1 : 0 } });
  }

  const onClickViewAllResumesPageRedirection = () => {
    navigate('/short-listed-resume')
  }

  const notificationFunRedirect = () => {
    navigate('/notifications-list')
  }

  const onClickHandlerNavigation = (type) => {
    switch (type) {
      case 'noticePeriod':
        navigate('/noticeperiod-candidates-list')
        return
      case 'resigned':
        navigate('/resigned-candidates-list')
        return
      case 'onboarded':
        navigate('/onboard-candidates-list')
        return
      case 'inprogress':
        navigate('/inprogress-candidates-list')
        return
      case 'interviewed':
        navigate('/interviewed-candidates-list')
        return
      case 'hired':
        navigate('/hired-candidates-list')
        return
      case 'rejected':
        navigate('/reject-candidates-list')
        return
      default:
        return
    }
  }

  const onClickSortHandler = (item) => {
    // dispatch(addLoader());
    setSelectedPeriod(item)
    getReportApiCallFun(item)
    // setTimeout(() => {
    //   dispatch(deleteLoader());
    // }, 3000);
  }

  const RedirectionNotificatiosn = (title, item) => {
    // const { user, email, role, id } = storePersist.get('userData')
    if (role === 'ACTMGR') {
      if (title === 'Account manager Unassigned') {
        navigate('/account-managers-list')
      } else if (title === 'New Company Onboarded') {
        navigate('/companies-list');
      } else if (title === 'Candidate  Added') {
        navigate(`/companyjoblist/${item?.companyId}`);
        // navigate('/onboard-candidates-list', { state: { companyId: item._id } })
      } else if (title === 'New Job Added') {
        navigate(`/companyjoblist/${item?.companyId}`);
      } else if (title === 'Interview Proposed') {
        navigate(`/companyjoblist/${item?.companyId}`);
      } else if (title === 'Interview scheduled') {
        navigate(`/companyjoblist/${item?.companyId}`);
      } else if (title === 'Candidate Onboarding') {
        navigate('/job-offers-list');
      } else if (title === 'SOW Uploaded') {
        navigate('/job-offers-list');
      } else if (title === 'Candidate Interview Result') {
        navigate(`/companyjoblist/${item?.companyId}`);
      } else if (title === 'Account manager assigned') {
        navigate('/account-managers-list')
      } else if (title === 'Reschedule Interview') {
        navigate(`/companyjoblist/${item?.companyId}`);
      } else if (title === 'Resume Screening Update') {
        navigate(`/companyjoblist/${item?.companyId}`);
      }
    } else {
      if (title === 'Account manager Unassigned') {
        toast.error("you Don'have a Persmission for the Page !!")
      } else if (title === 'New Company Onboarded') {
        toast.error("you Don'have a Persmission for the Page !!")
      } else if (title === 'Candidate  Added') {
        toast.error("you Don'have a Persmission for the Page !!")
      } else if (title === 'New Job Added') {
        navigate(`/jobs-list`);
      } else if (title === 'Interview Proposed') {
        navigate(`/jobs-list`);
      } else if (title === 'Interview scheduled') {
        navigate(`/jobs-list`);
      } else if (title === 'Candidate Onboarding') {
        navigate('/job-offers-list');
      } else if (title === 'SOW Uploaded') {
        navigate('/job-offers-list');
      } else if (title === 'Candidate Interview Result') {
        navigate(`/jobs-list`);
      } else if (title === 'Account manager assigned') {
        toast.error("you Don'have a Persmission for the Page !!")
      } else if (title === 'Reschedule Interview') {
        navigate(`/jobs-list`);
      } else if (title === 'Resume Screening Update') {
        navigate(`/jobs-list`);
      }
    }
  }

  return <>
    {/* <h3 className="font_size_24">Good Morning ! Welcome Back, Beeutech</h3> */}
    <div className="row d-flex justify-content-center align-items-start gx-3 margintop">
      {/* Left side card */}
      <div className="col-md-8 width_col_69">
        {/* card-1 */}
        <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
          <div className="d-flex justify-content-between align-items-center border-bottom ">
            <h6 className="pb-2 mb-0 font_color_imp fw-semibold">Overview</h6>
            <div className="col-md-8 d-flex align-content-center justify-content-end flex-wrap">
              {['week', 'month', 'year'].map((item, idx) => <>
                <div className="w_max_content me-3 mb-1 " key={idx} onClick={() => { onClickSortHandler(item) }}>
                  <div className={`font_color_imp ${selectedPeriod === item && 'bg_color_ornage_lite px-2 pt-1 rounded-2 fw-bold'} fw-medium font_size_14 px-2 pt-1`}>
                    {/* hover_underline */}
                    {(item?.charAt(0).toUpperCase() + item.slice(1))?.replace(/([A-Z])/g, ' $1').trim()}
                  </div>
                </div>
              </>)}
            </div>
          </div>
          <div>
            <div className="row bd-example">
              <div className="col-xl-4 col-sm-6 mb-3">
                <div className="mr-5 mt-3 mb-3 font_color_imp fw-semibold">Status</div>
                <div className="card text-white bg-status-color o-hidden">
                  <div className="card-body">
                    <div className="card-body-icon">
                      <div className="card-body-icon d-flex justify-content-between align-items-center">
                        <span className="bg-white px-1 py-1 rounded_15">
                          <img alt="Alt content" src={require('../../assets/images/status_group_dashboard.svg').default} className="rounded-circle" />
                        </span>
                        <div className="d-flex flex-column">
                          <img alt="Alt content" src={require('../../assets/images/status_vector_dashboard.svg').default} />
                          {/* Growth:40% */}
                          <span className="ps-5 ms-4 font_color_imp fw-medium font_size_12">{(dashboardDetails['interviewed'] || dashboardDetails['hired'] || dashboardDetails['rejected']) ? '' : '' }</span> 
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex align-content-center justify-content-between gap-2">
                      {['interviewed', 'hired', 'rejected'].map((item, idx) => <>
                        <div className="mt-3 mb-1 text-center" key={idx}>
                          <div
                            className={`${idx === 2 ? 'font_color_ornage' : 'font_color_imp'} fw-semibold font_size_14 hover_underline`}
                            onClick={() => { onClickHandlerNavigation(item) }}
                          >
                            {(item?.charAt(0).toUpperCase() + item.slice(1))?.replace(/([A-Z])/g, ' $1').trim()}
                          </div>
                          <div className={`${idx === 2 ? 'font_color_ornage' : 'font_color_imp'} mt-2 fw-semibold font_size_24`}>{dashboardDetails[item] || 0}</div>
                        </div>
                      </>)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 mb-3">
                <div className="mr-5 mt-3 mb-3 font_color_imp fw-semibold">Onboarding</div>
                <div className="card text-white bg-onboarding-color o-hidden">
                  <div className="card-body">
                    <div className="card-body-icon">
                      <div className="card-body-icon d-flex justify-content-between align-items-center">
                        <span className="bg-white px-1 py-1 rounded_15 mt_19">
                          <img alt="Alt content" src={require('../../assets/images/onboarding_dashboard_icon.svg').default} className="rounded-circle" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex align-content-center justify-content-between gap-2">
                      {['onboarded', 'inprogress'].map((item, idx) => <>
                        <div className="mt-2 pt-2 mb-1 text-center" key={idx}>
                          <div
                            className={`${idx === 1 ? 'font_color_ornage' : 'font_color_imp'} fw-semibold font_size_14 hover_underline`}
                            onClick={() => { onClickHandlerNavigation(item) }}
                          >
                            {(item?.charAt(0).toUpperCase() + item.slice(1))?.replace(/([A-Z])/g, ' $1').trim()}
                          </div>
                          <div className={`${idx === 1 ? 'font_color_ornage' : 'font_color_imp'} mt-2 fw-semibold font_size_24`}>{dashboardDetails[item] || 0}</div>
                        </div>
                      </>)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 mb-3">
                <div className="mr-5 mt-3 mb-3 font_color_imp fw-semibold">Resigning</div>
                <div className="card text-white bg-leaves o-hidden">
                  <div className="card-body">
                    <div className="card-body-icon">
                      <div className="card-body-icon d-flex justify-content-between align-items-center">
                        <span className="bg-white px-1 py-1 rounded_15">
                          <img alt="Alt content" src={require('../../assets/images/leavers_dashboard_icon.svg').default} className="rounded-circle" />
                        </span>
                        <div className="d-flex flex-column">
                          <img alt="Alt content" src={require('../../assets/images/dashboard_leavers_vec.svg').default} />
                          <span className="ps-5 ms-4 font_color_imp fw-medium font_size_12">
                            {(dashboardDetails['noticePeriod'] || dashboardDetails['resigned']) ? 'Change:20%' : ''}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex align-content-center justify-content-between gap-2">
                      {['noticePeriod', 'resigned'].map((item, idx) => <>
                        <div className="mt-3 mb-1 text-center" key={idx}>
                          <div
                            className={`${idx === 1 ? 'font_color_ornage' : 'font_color_imp'} fw-semibold font_size_14 hover_underline`}
                            onClick={() => { onClickHandlerNavigation(item) }}
                          >
                            {(item?.charAt(0).toUpperCase() + item.slice(1))?.replace(/([A-Z])/g, ' $1').trim()}
                          </div>
                          <div className={`${idx === 1 ? 'font_color_ornage' : 'font_color_imp'} mt-2 fw-semibold font_size_24`}>{dashboardDetails[item] || 0}</div>
                        </div>
                      </>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* card-2 */}
        <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
          <div className="d-flex justify-content-between align-items-center border-bottom ">
            <h6 className="pb-2 mb-2 font_color_imp fw-semibold">Important Notifications : 
              <span> (<span className='orange_color'><b>{allImportantNotificationsList?.length}</b></span>)</span>
            </h6>
            <span className="mr-5 font_color_ornage fw-medium font_size_14 me-2 hover_underline" onClick={() => { notificationFunRedirect() }}>View all<i className="fa fa-angle-right ms-2"></i></span>
          </div>
          <div>
            {/* height_notification_table */}
            <div className="row bd-example">
              {allImportantNotificationsList?.length > 0 &&
                <PerfectScrollbar className="sidebar-items pb-0">
                  {allImportantNotificationsList?.map((item, idx) =>
                    idx < 4 && <div className="d-flex justify-content-center align-items-center pt-2 border-bottom pb-3">
                      <div className="col-md-1">
                        <span className="px-3 py-3 rounded_30">
                          <img alt="Alt content" src={returnIcon(item?.type || 'newCandidateOnboarding')} className="pb_2" />
                        </span>
                      </div>
                      <div className="col-md-9  ms-2">
                        <div className="pt-2">
                          <h6 className="pb-2 mb-0 font_color_imp fw-900 font_size_14">{item?.title || 'New candidates onboarding'}</h6>
                          <span className="mr-5 font_color_imp fw-medium font_size_14">{item?.description || item?.notification}</span>
                        </div>
                        <div className="pt-1">
                          <span className="font_color_imp fw-normal font_size_12 me-3">{formatDateDDMMMYYYY(item?.createdAt)}</span>
                          <span className="border_bg_label">
                            <i className="fa fa-circle font_size_5 font_color_ornage me-1" aria-hidden="true"></i>
                            <span className="fw-normal">{fromNowFun(item?.createdAt)}</span>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-2 d-flex justify-content-end pe-3">
                        <button
                          type="submit"
                          className={`btn btn-primary pt-1 pb-1 font_size_11 fw-medium min_width ${getColorFun(item.type)}`}
                          //btn_background_color
                          onClick={() => { deleteNotificationById(item) }}
                        >
                          {item?.action || 'Send Wishes'}
                        </button>
                      </div>
                    </div>
                  )}
                </PerfectScrollbar>
              }
              {allImportantNotificationsList?.length === 0 &&
                <div className="disply_block_t_center">
                  <div className="vh-50 font_size_24 disply_block_t_center padding_15_p">
                    <div>
                      No Data Found
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {/* right side cards */}
      <div className="col-md-4 width_col_30">
        {/* card-1 */}
        <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
          <div className="d-flex justify-content-between align-items-center border-bottom ">
            <h6 className="pb-2 mb-0 font_color_imp fw-semibold">Scheduled Interviews : 
              <span> (<span className='orange_color'><b>{allCandidatesList?.length}</b></span>)</span>
            </h6>
            {allCandidatesList.length > 0 &&
              <span
                className="mr-5 font_color_ornage fw-medium font_size_14 me-2 pointer_event"
                onClick={() => { onClickPageRedirection(allCandidatesList[0]) }}//onClickViewAllResumesPageRedirection}
              >
                View all
                <i className="fa fa-angle-right ms-2"></i>
              </span>
            }
          </div>
          <div>
            {/* height_scheduled_int_table */}
            <div className="row bd-example vh-100">
              <PerfectScrollbar className="sidebar-items">
                <h6 className="pb-1 mb-0 font_color_imp fw-semibold font_size_16">Today's</h6>
                {allCandidatesList.length > 0 && allCandidatesList.map((item, idx) => {
                  const userName = `${item?.candidateFirstName} ${item?.candidateFirstName}`;
                  const color = generateColorHsl(userName, saturationRange, lightnessRange);
                  const initials = getInitials(item?.candidateFirstName);
                  return (<>
                    {idx < 8 && <div className="border-bottom pt-4 pb-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="col-md-2 ps-2 margin_top_neg_10">
                          <div className="row1" key={idx}>
                            <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
                          </div>
                          {/* <div className="position-relative rounded-circle overflow-hidden mx-auto custom-circle-image border blackcircle">
                        <img className="w-100 h-100 whitecircle rounded-circle" alt="Alt content" src={require('../../assets/images/user-profile-icon.svg').default} />
                      </div> */}
                        </div>
                        <div className="col-md-6 ps-3 me-2">
                          <div>
                            <h6 className="pb-1 mb-0 font_color_imp fw-900 font_size_14">{toPascalCase(item.candidateFirstName)}</h6>
                            <span className="font_color_imp fw-medium font_size_14">{toPascalCase(item.profileTitle)}</span>
                          </div>
                          <div className="pt-0">
                            {
                              item?.startTime ?
                                <span className="font_color_imp fw-normal font_size_12"><b>{moment(item?.startTime).format('LT')}</b> to <b>{moment(item?.endTime).format('LT')}</b></span>
                                :
                                <span className="font_color_imp fw-normal font_size_12"><b>{'Not yet scheduled'}</b></span>
                            }
                          </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-center align-items-center">
                          {/* margin_top_neg_60 */}
                          <button
                            type="button"
                            className="btn-outline-primary main_bg_white pt-1 pb-1 pe-2 ps-2 font_size_12 fw-medium btn_font_color btn_bg_color border_btn_color"
                            onClick={() => { onClickPageRedirection(item) }}
                          >
                            View details
                          </button>
                        </div>
                      </div>
                      {/* <div className="row d-flex justify-content-start pt-1 font_size_12 fw-semibold mb-2">
                    <div className="col-md-2">
                      <span>Panel</span>
                    </div>
                    <div className="col-md-10 d-flex align-content-center flex-wrap">
                      {['venu - Product Manager', 'Rohit - HR lead'].map((item) =>
                        <span className="schedule_int_border_bg_label me-1 mb-1">{item}</span>
                      )}
                    </div>
                  </div> */}
                      {/* <div className="row d-flex justify-content-start pt-1 font_size_12 fw-semibold">
                    <div className="col-md-2">
                      <span>Round</span>
                    </div>
                    <div className="col-md-10 d-flex align-content-center flex-wrap">
                      {["Induction", "Technical round"].map((item) =>
                        <span className="schedule_int_round_bg_label ps-2 pe-2 pt-1 pb-1 me-1 mb-1">
                          {item}
                          <img alt="Alt content" src={require('../../assets/images/induction_circlr_mark.svg').default} className="ps-2" />
                        </span>
                      )}
                    </div>
                  </div> */}
                    </div>
                    }
                  </>)
                })}
                {allCandidatesList.length === 0 &&
                  <div className="row bd-example">
                    <div className="table-container">
                      <div className="text_v_h_center vh-50 font_size_24">
                        No Data Found
                      </div>
                    </div>
                  </div>
                }
              </PerfectScrollbar>
            </div>
          </div>
        </div>
        {/* card-2 */}
        {/* <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
          <h6 className="border-bottom pb-2 mb-0 font_color_imp fw-semibold">Other Interviews Scheduled</h6>
          <div>
            <div className="row bd-example height_int_scheduled_table">
              <PerfectScrollbar className="sidebar-items">
                {arr.map((item) => <div className="d-flex justify-content-center align-items-center border-bottom pt-3 pb-3">
                  <div className="col-md-2 margin_top_neg_10">
                    <div className="position-relative rounded-circle overflow-hidden mx-auto custom-circle-image border blackcircle">
                      <img className="w-100 h-100 whitecircle rounded-circle" alt="Alt content" src={require('../../assets/images/user-profile-icon.svg').default} />
                    </div>
                  </div>
                  <div className="col-md-6 ps-3">
                    <div>
                      <h6 className="pb-1 mb-0 font_color_imp fw-medium font_size_14">Rahul Dev</h6>
                      <span className="font_color_imp fw-medium font_size_14">Reactjs Developer</span>
                    </div>
                    <div className="pt-0">
                      <span className="font_color_imp fw-medium font_size_12">10.00 AM to 11.00 AM</span>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-center align-items-center margin_top_neg_60">
                    <button type="button" className="btn btn-outline-primary border pt-1 pb-1 pe-2 ps-2 font_size_12 fw-medium">View details</button>
                  </div>
                </div>)}
              </PerfectScrollbar>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </>
}

export default adminLayout(DashboardPage);