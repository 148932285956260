import * as loaderActions from "../actionTypes/index";

const addLoader = () => {
  return {
    type: loaderActions.ADD_LOADER,
    payload:{loading:true}
  };
};

const deleteLoader = () => {
  return {
    type: loaderActions.DELETE_LOADER,
    payload:{loading:false}
  };
};

export { addLoader, deleteLoader };
