import http from "./axios-common";

export const getAllApi = (endPointUrl) => {
    return http.get(endPointUrl).then(response => {
        return response
    }).catch(error => {
        console.log(error);
        return error
    });
}

export const getApi = async (endPointUrl, params) => {
    return http.get(endPointUrl, params).then(response => {
        return response
    }).catch(error => {
        console.log(error);
        return error
    });
}

export const postApi = async (endPointUrl, data) => {
    return http.post(endPointUrl, data).then(response => {
        return response
    }).catch(error => {
        console.log(error);
        return error
    });
}

export const updateWithOutParamsApi = (endPointUrl, data) => {
    return http.put(`${endPointUrl}`, data).then(response => {
        return response
    }).catch(error => {
        console.log(error);
        return error
    });
}

export const updateApi = (endPointUrl, id, data) => {
    return http.put(`${endPointUrl}/${id}`, data).then(response => {
        return response
    }).catch(error => {
        console.log(error);
        return error
    });
}

export const deleteApiWithBody = (endPointUrl, data) => {
    return http.delete(`${endPointUrl}`, { data: data }).then(response => {
        return response
    }).catch(error => {
        console.log(error);
        return error
    });
}
