import React, { useState, useLayoutEffect, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import ModalComponent from "../../../components/ModalComponent";
import UploadResumeJobDetails from './UploadResumeJobDetails'

const masterData = [
    { label: 'Job Type', response: 'job_type', value: 'Remote', img: 'job_type.svg' },
    { label: 'Job Code', response: 'job_code', value: '1234', img: 'job_code.svg' },
    { label: 'Years of experience', response: 'year_of_exp', value: '5-10 years', img: 'Y_O_Exp.svg' },
    { label: 'Type of contract', response: 'office_type', value: 'full time', img: 'type_of_contract.svg' },
    // { label: 'Skillset', response: 'skill', value: 'full time', img: 'skills_needed.svg' },
]

const ViewJobDetails = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.loaderReducer);
    const { viewSelectedJobDetails, jobList, selectedRecordDataFun } = props
    const [current, setCurrent] = useState(viewSelectedJobDetails?.idxValue);
    const [modalPopUpCancel, setModalPpopUpCancel] = useState(false);

    const length = jobList.length;

    useEffect(() => {
        dispatch(addLoader());
        stopLoader()
    }, [])

    const stopLoader = () => {
        setTimeout(() => {
            dispatch(deleteLoader());
        }, 3000);
    }

    useEffect(() => {
        setCurrent(viewSelectedJobDetails?.idxValue)
    }, [viewSelectedJobDetails])

    const nextPage = (job, indexVal) => {
        const selectedJob = jobList.find((item, idx) => idx === indexVal + 1)
        selectedRecordDataFun(selectedJob)
        setCurrent(current === length - 1 ? 0 : current + 1);
        dispatch(addLoader());
        stopLoader()
    }

    const prevPage = (job, indexVal) => {
        const selectedJob = jobList.find((item, idx) => idx === indexVal - 1)
        selectedRecordDataFun(selectedJob)
        setCurrent(current === 0 ? length - 1 : current - 1);
        dispatch(addLoader());
        stopLoader()
    }

    const editJobHandler = (job) => {
        if (job) {
            selectedRecordDataFun(job)
        }
    }

    const navigationPage = () => {
        document.getElementById("buttonViewJobDetailsDismissID").click();
        navigate('/short-list-resumes');
    }

    const cancelPopUpFun = (vale) => {
        setModalPpopUpCancel(vale)
    }

    const viewJobDetailsModalContentJob = (props) => {
        return <>
            <UploadResumeJobDetails
                viewSelectedJobDetails={viewSelectedJobDetails?.pageData || undefined}
                jobList={jobList}
                selectedRecordDataFun={selectedRecordDataFun}
            />
        </>;
    }

    return (
        <>
            {jobList.map((page, index) => {
                return (<>
                    {index === current && <div className="col-md-12" key={index}>
                        <div className="border-bottom position-sticky top-0 footer mt-auto py-3 pb-3 text-center modal-header main-bg-dark-color">
                            <div className="col-md-12 d-flex align-item-center justify-content-between">
                                <div className="col-md-3 d-flex justify-content-start">
                                    {index !== 0 &&
                                        <button
                                            className="border-0 main-bg-dark-color mt-0 mb-0 pb-0 pt-0"
                                            onClick={() => { prevPage(page, index) }}
                                        // disabled={(index > 0)}
                                        >
                                            <img src={require('../../../assets/images/prev_page.svg').default} alt="Alt content" />
                                        </button>
                                    }
                                </div>
                                <div className="col-md">
                                    <div className="pt-0.5">{page.job_type}</div>
                                </div>
                                <div className="col-md-3  d-flex justify-content-end">
                                    {index !== (jobList.length - 1) &&
                                        <button
                                            className="border-0 main-bg-dark-color mt-0 mb-0 pb-0  pt-0"
                                            onClick={() => { nextPage(page, index) }}
                                        // disabled={!(index !== (jobList.length - 1))}
                                        >
                                            <img src={require('../../../assets/images/next_1_page.svg').default} alt="Alt content" />
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className="overflow_y_on modal-dialog-scrollable pt-3 pb-3 ps-3 pe-3"> */}
                        <div className="col-md-12 border-bottom d-flex align-item-center justify-content-end px-3 py-3">
                            <div className="col-md-7 d-flex align-item-center justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-primary btn_background_color pb-2 ps-3 pe-3 font_size_14 fw-medium btn_height_36 padding_top_6"
                                    // onClick={navigationPage} main_bg_dark_color_20
                                    data-bs-toggle="modal"
                                    data-bs-target="#UploadResumeJobDetailsModalIds"
                                // data-bs-target="#editJobsDetails"
                                // data-bs-toggle="modal"

                                >
                                    Upload Resumes For matching this requirement
                                </button>
                            </div>

                            <div className="col-md-1_5 d-flex align-item-center justify-content-end">
                                {/* <button
                                    type="close"
                                    className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 mt-0 padding_top_6"
                                    data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button> */}
                                {/* <button
                                        type="button"
                                        className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 mt-0 me-3 padding_top_6"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 mt-0 padding_top_6"
                                    >
                                        Delete
                                    </button> */}
                            </div>
                        </div>
                        <div className="overflow_y_on modal-dialog-scrollable pt-0 pb-5 ps-3 pe-3">
                            <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                {masterData.map((item, idx) => {
                                    return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-4 p-2" key={idx}>
                                        <div className="col-xs-12 align-middle">
                                            <div className="col-xs-6 me-3">
                                                <img
                                                    src={require(`../../../assets/images/${item.img}`)}
                                                    alt="jobType"
                                                    className="img_w_h_23"
                                                />
                                            </div>
                                            <div className="col-xs-6">
                                                <div className="fw-700 font_size_16 mb-2">{item.label}</div>
                                                <div className="fw-medium font_size_14">{item.response === 'office_type' ? page[item.response]?.toString()?.split(',').join(", ") : page[item.response]}</div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                            <div className="col-md-12 border-bottom d-flex align-content-center flex-wrap pb-2">
                                <div className="col-md-12 d-flex justify-content-start align-content-center pt-4 p-2">
                                    <div className="col-xs-12 align-middle">
                                        <div className="col-xs-6 me-3">
                                            <img
                                                src={require(`../../../assets/images/skills_needed.svg`).default}
                                                alt="jobType"
                                                className="img_w_h_23"
                                            />
                                        </div>
                                        <div className="col-xs">
                                            <div className="fw-700 font_size_16 mb-2">{'Skillset'}</div>
                                            <div className="fw-medium font_size_14">{page['skill']?.map((item) => item?.language)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {[{ title: 'Job description', img: 'job_description.svg', value: 'job_desciption' }, { title: 'Skill needed', img: 'skills_needed.svg', value: 'skill' }].map((item, idx) => <div>
                                <div className="col-md-12 d-flex justify-content-start align-content-center pt-4 p-2">
                                    <div className="col-xs-12 align-middle">
                                        <div className="col-xs-1 me-3">
                                            <img
                                                src={require(`../../../assets/images/${item.img}`)}
                                                alt="jobType"
                                                className="img_w_h_23"
                                            />
                                        </div>
                                        <div className="col-xs-11">
                                            <div className="fw-700 font_size_14 mb-2">{item.title}</div>
                                            <div className="fw-medium font_size_14 mb-2">
                                                <b> {`${page.year_of_exp}`} </b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fw-medium font_size_14 ms-5">
                                    {
                                        item.value === 'skill'
                                            ?
                                            page[item.value]?.map((item) => item?.language)
                                            :
                                            page[item.value]
                                    }
                                </div>
                            </div>)}
                        </div>
                        {/* </div> */}
                        {/* <div className="border-top position-sticky bottom-0 footer mt-auto py-3 pb-3 bg-white text-center d-flex justify-content-center"> */}
                        <div className="col-md-12 border-top position-sticky bottom-0 footer mt-auto py-3 px-3 bg-white  d-flex align-item-center justify-content-center">
                            {/* <div className="col-md-8"> */}
                            <button
                                type="close"
                                className="mt-1 mb-1 fw-medium_imp btn btn-outline-primary bg-white btn_height_36 padding_top_6 pb-2 pe-4 ps-4 font_size_14 orange_color_border orange_color"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    document.location.reload();
                                }}
                            >
                                Cancel
                            </button>
                            {/* </div> */}
                            {/* <div className="col-md-3 d-flex align-item-center justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 mt-0 me-3 padding_top_6"
                                    data-bs-target="#editJobsDetails"
                                    data-bs-toggle="modal"
                                    onClick={() => { editJobHandler(page) }}
                                >
                                    Edit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 mt-0 padding_top_6"
                                    data-bs-target="#deleteJobListModal"
                                    data-bs-toggle="modal"
                                >
                                    Delete
                                </button>
                            </div> */}
                        </div>
                        {/* </div> */}
                        <button className="d-none" id="buttonViewJobDetailsDismissID" data-bs-dismiss="modal"></button>
                    </div>}
                </>)
            }
            )}
            {/* View Job Details modals */}
            {/* <ModalComponent
                modalDialogClass="view_job_details_modal_w"
                modalBodyPaddingBottomCls="p-0 overflow_custom"
                // isHeaderHide={true}
                cancelPopUpFun={cancelPopUpFun}
            /> */}
            {/* <ModalComponent
                title="Upload Candidate Details"
                content={viewJobDetailsModalContentJob()}
                dataBsBackdrop="static"
                id="UploadResumeJobDetailsModalIds"
                modalBodyPaddingBottomCls="p-0"
                cancelPopUpFun={cancelPopUpFun}
                fullScreen={true}
            /> */}
        </>
    );
}

export default ViewJobDetails