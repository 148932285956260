import React, { useState } from "react";
import { isMobile } from 'react-device-detect';
import swal from "sweetalert";
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import CurrencyInput from 'react-currency-input-field';
import Select, { components } from "react-select";
import { addNewRequirementPlaceholders } from '../../Utils/masterData/staticContent'
import { typesOfEmployeeContractList, salaryRange, currencyInputintlConfig } from '../../Utils/masterData/dropDownJson'
import { isEmpty, returnArryOfObjects } from '../../Utils/utils'
import { updateWithOutParamsApi } from '../../services/apiCommonService'
import { edit_job_api_url } from '../../services/endPointsConstant'
import storePersist from '../../Utils/storePersist'
import { useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import { languageArr } from '../employeeComponents/softWareLanguages';
import { useDispatch, useSelector } from "react-redux";
import { addLoader, deleteLoader } from "../../redux/actions/loader";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};


const EditJobRequirement = (props) => {
    const { returnEditJobRequirementFormDetails, selectedJobsObjects } = props
    const [formData, setFormData] = useState(false);
    const [salary, setSalaryValue] = useState(null);
    const [typesOfEmployeeContractValue, setTypesOfEmployeeContractValue] = useState(null);
    const [currencyValue, setCurrencyValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const [languageArrState, setlanguageArrState] = useState([])
    const [typeOfLanguageValue, settypeOfLanguageValue] = useState(null);
    const [technicalExpertizeSkills, setTechnicalExpertizeSkills] = useState(selectedJobsObjects[0]?.skill || []);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        control,
        reset,
        setValue,
        getValues
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        const arr = []
        languageArr?.map((item) => {
            arr.push({ label: item, value: item })
        })
        setlanguageArrState(arr)
    }, [])

    useEffect(() => {
        // if (selectedJobsObjects.length > 0) {
        //     reset({
        //         ...selectedJobsObjects[0],
        //         office_type: returnArryOfObjects(typesOfEmployeeContractList, (selectedJobsObjects[0]?.office_type || []), 'label')
        //     });
        //     setFormData({
        //         ...selectedJobsObjects[0],
        //         office_type: returnArryOfObjects(typesOfEmployeeContractList, (selectedJobsObjects[0]?.office_type || []), 'label')
        //     })
        //     setValue('office_type', returnArryOfObjects(typesOfEmployeeContractList, (selectedJobsObjects[0]?.office_type || []), 'label'))
        // }
        if (selectedJobsObjects.length > 0) {
            reset({
                ...selectedJobsObjects[0]
            });
            setFormData({
                ...selectedJobsObjects[0]
            })
            if (technicalExpertizeSkills?.length === 0) {
                setTechnicalExpertizeSkills(selectedJobsObjects[0]?.skill || [])
            }
        }
    }, [selectedJobsObjects])

    const errorHandler = () => {
        if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const onSubmit = async (data) => {
        setFormData(data);
        // const { id } = storePersist.get('userData')
        const apiReqBody = {
            jobId: selectedJobsObjects.length > 0 && selectedJobsObjects[0]?._id,
            id: selectedJobsObjects.length > 0 && selectedJobsObjects[0]?._id,
            job_type: data?.job_type,
            job_code: data?.job_code,
            year_of_exp: data?.year_of_exp,
            skill: technicalExpertizeSkills,
            office_type: data?.office_type?.map(({ name }) => name),
            salary: data?.salary,
            job_desciption: data?.job_desciption,
            job_responsibility: data?.job_responsibility,
            currencyValue: data?.currencyValue
        }

        const apiResponse = await updateWithOutParamsApi(edit_job_api_url, apiReqBody)
        if (apiResponse?.data) {
            toast.success('Details updated successfully !!')
            returnEditJobRequirementFormDetails(apiReqBody)
            document.getElementById("buttonEditDismiss").click();
            reset();
            document.location.reload();
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    };

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['salary', 'office_type', 'currencyValue', 'language'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    const currencyHandler = (val) => {
        setCurrencyValue(val)
    }

    const addExperiencesDetails = () => {
        const { language, minYears, maxYears } = getValues()
        if (language && minYears && maxYears) {
            const ID = technicalExpertizeSkills?.length + 1
            setTechnicalExpertizeSkills([...technicalExpertizeSkills, { language, minYears, maxYears, ID }])
            dispatch(addLoader())
            setTimeout(() => {
                setValue("language", '')
                setValue("minYears", '')
                setValue("maxYears", '')
                settypeOfLanguageValue(null)
                setIsSelected({ ...isSelected, 'language': false })
                dispatch(deleteLoader())
            }, 300);
        } else {
            toast.error("Please fill all the manditory fields")
        }
    }

    const deleteSkillsFun = async (event) => {
        dispatch(addLoader());
        swal({
            title: "Delete !!",
            text: "Are you sure you want to delete?",
            // icon: "info",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Skill deleted successfully!',
                    // icon: 'success'
                }).then(async function () {
                    const filteredData = technicalExpertizeSkills?.filter(({ ID }) => ID !== event?.ID)
                    setTechnicalExpertizeSkills(filteredData)
                    setTimeout(() => {
                        dispatch(deleteLoader());
                    }, 500);
                });
            } else {
                setTimeout(() => {
                    dispatch(deleteLoader());
                }, 500);
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    console.log("====selectedJobsObjects[0]?.skill=======", technicalExpertizeSkills)
    // console.log("========jj====", selectedJobsObjects, selectedJobsObjects[0]?.office_type, getValues('office_type'))
    // returnArryOfObjects(typesOfEmployeeContractList, (selectedJobsObjects[0]?.office_type || []), 'label'))
    return (
        <div className="form-holder pt-5 ps-8 pe-8 pb-0 main_bg rounded-0">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-outline row gx-3">
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.job_type && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="text"
                            name="job_type"
                            placeholder=""
                            {...register("job_type", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9._-\s]+$/,
                                    message: "Allow only alphabets"
                                }
                            })}
                        />
                        <label className="did-floating-label requiredTrue">{addNewRequirementPlaceholders.jobTypePlaceHolder}</label>
                        {/* {errors.job_type && <p className="error">{errors.job_type.message}</p>} */}
                    </div>
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.job_code && 'did-error-input'}`}>
                        <input
                            className="did-floating-input" //mb-4 font_size_14
                            type="text"
                            name="job_code"
                            placeholder=""
                            disabled
                            {...register("job_code", {
                                required: "Field is required",
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                }
                            })}
                        />
                        <label className="did-floating-label requiredTrue">{addNewRequirementPlaceholders.jobCodePlaceHolder}</label>
                        {/* {errors.job_code && <p className="error">{errors.job_code.message}</p>} */}
                    </div>
                </div>

                <div className="form-outline row gx-3">
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.year_of_exp && 'did-error-input'}`}>
                        <input
                            className="did-floating-input"
                            type="number"
                            name="year_of_exp"
                            placeholder=""
                            min="1"
                            max="61"
                            {...register("year_of_exp", {
                                required: "Field is required",
                                minLength: {
                                    value: 1,
                                    message: "Min length is 1"
                                },
                            })}
                        />
                        <label className="did-floating-label requiredTrue">{addNewRequirementPlaceholders.yearsOfExperiencePlaceHolder}</label>
                        {/* {errors.year_of_exp && <p className="error">{errors.year_of_exp.message}</p>} */}
                    </div>
                    <div className="col-md-6 form-outline">
                        <Controller
                            name="office_type"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { ref, ...field } }) => {
                                return (<>
                                    <span className={`requiredTrue ${(field?.value?.length > 0) ? 'selectedValuesMultiple' : 'nonSelectedValuesMultiple'}`}></span>
                                    <Multiselect
                                        {...field}
                                        inputRef={ref}
                                        displayValue="name"
                                        // showCheckbox={true}
                                        showArrow
                                        onSelect={(selected, item) => {
                                            setValue("office_type", selected);
                                        }}
                                        onRemove={(selected, item) => {
                                            setValue("office_type", selected);
                                        }}
                                        options={typesOfEmployeeContractList}
                                        // hidePlaceholder
                                        placeholder="Contract type"
                                        avoidHighlightFirstOption
                                        isSearchable={false}
                                        selectedValues={getValues('office_type') || selectedJobsObjects[0]?.office_type || []}
                                        style={{
                                            multiselectContainer: {
                                                minHeight: 30,
                                                background: 'white',
                                            },
                                            searchBox: { // To change search box element look
                                                display: 'flex',
                                                fontSize: 14,
                                                minHeight: 0,
                                                padding: field?.value?.length > 0 ? '6px 6px 1px 6px' : '8px 8px 6px 16px',
                                                border: errors?.office_type ? '1px solid #bf1650' : ''
                                                // margin: '6px 6px 1px 6px',
                                            },
                                            inputField: { // To change input field position or margin
                                                margin: 0,
                                                position: field?.value?.length > 0 ? "absolute" : 'relative',
                                                top: field?.value?.length > 0 ? -18 : "10%",
                                                background: "white",
                                                transition: "top 0.1s, font-size 0.1s",
                                                fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                fontWeight: 100,
                                                padding: field?.value?.length > 0 ? '0.3px 8.5px' : 0,
                                                borderRadius: '5px',
                                                color: 'rgba(6, 44, 80, 0.7);',
                                                // border: isSelected['office_type'] ? '1px solid #cccccc' : '',
                                                width: field?.value?.length > 0 && 152,
                                                border: field?.value?.length > 0 && '1px solid #cccccc'
                                            },
                                            chips: { // To change css chips(Selected options)
                                                background: 'rgba(6, 44, 80, 1)',
                                                color: 'white'
                                            },
                                        }}
                                    />
                                </>
                                );
                            }}
                        />
                    </div>
                    {/* <div className="col-md-6 marginbottom_int did-floating-label-content">
                        <Controller
                            name="office_type"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={typesOfEmployeeContractList}
                                    value={typesOfEmployeeContractList.find((c) => c.value === value)}
                                    onChange={(val) => onChange(val.value)}
                                    defaultValue={typesOfEmployeeContractList.find((c) => c.value === typesOfEmployeeContractValue)}
                                    // isSearchable={true}
                                    placeholder={addNewRequirementPlaceholders.remoteByHybridDDPlaceHolder}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'office_type': true }) }}
                                    styles={{
                                        // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.office_type && 'office_type'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            // marginTop: 25,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['office_type'] ? '1px solid #cccccc' : ''
                                        })
                                    }}
                                />
                            )}
                            rules={{ required: "Field is required" }}
                        /> */}
                    {/* <label className="did-floating-label">{addNewRequirementPlaceholders.remoteByHybridDDPlaceHolder}</label> */}
                    {/* {errors.office_type && <p className="error">{errors.office_type.message}</p>} */}
                    {/* </div> */}
                </div>

                <div className="form-outline row gx-3">
                    <div className={`col-md-6 marginbottom_int did-floating-label-content'}`}>
                        <Controller
                            name="currencyValue"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    // isClearable
                                    options={currencyInputintlConfig}
                                    value={currencyInputintlConfig.find((c) => c.value === value)}
                                    onChange={(val) => { onChange(val?.value); currencyHandler(val) }}
                                    defaultValue={currencyInputintlConfig.find((c) => c.value === currencyValue)}
                                    isSearchable={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder={addNewRequirementPlaceholders.currencyPlaceHolder}
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'currencyValue': true }) }}
                                    styles={{
                                        // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                        control: (styles) => ({
                                            ...styles,
                                            ...errorBorderForSelectBox(errors.currencyValue && 'currencyValue'),
                                        }),
                                        container: (provided, state) => ({
                                            ...provided,
                                            // marginTop: 24,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            overflow: "visible"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                            background: "white",
                                            transition: "top 0.1s, font-size 0.1s",
                                            fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                            fontWeight: 100,
                                            padding: '0.3px 8.5px',
                                            borderRadius: '8px',
                                            color: 'rgba(6, 44, 80, 0.7);',
                                            border: isSelected['currencyValue'] ? '1px solid #cccccc' : ''
                                        })
                                    }}
                                />
                            )}
                            rules={{ required: false }}
                        />
                    </div>
                    <div className={`col-md-6`}>
                        <div className={`form-outline did-floating-label-content ${errors.salary && 'did-error-input'}`}>
                            <Controller
                                name="salary"
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref, ...rest } }) => {
                                    return (
                                        <CurrencyInput
                                            id="input-example"
                                            name="input-name"
                                            intlConfig={currencyValue}
                                            // className={`form-control`}
                                            className="form-control did-floating-input font_size_10_color_grey"
                                            placeholder="Please enter expected package"
                                            // defaultValue={salary}
                                            decimalsLimit={6}
                                            value={value}
                                            step={1}
                                            onValueChange={(value, name) => { onChange(value, name); setSalaryValue(value) }}
                                            // onChange={(val) => { onChange(val?.value); currencyHandler(val) }}
                                            getInputRef={ref}
                                            {...rest}
                                        // styles={{
                                        //     placeholder: (provided, state) => ({
                                        //         ...provided,
                                        //         position: "absolute",
                                        //         top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                        //     })
                                        // }}
                                        />
                                    )
                                }
                                }
                                rules={{ required: false }}
                            />
                        </div>
                        {/* <label className={`did-floating-label ${salary ? 'label_margin' : 'label_margin_center'} `}>{'Please enter salary'}</label> */}
                    </div>
                    {/* <label className="did-floating-label">{addNewRequirementPlaceholders.salaryByNotDisclosedPlaceHolder}</label> */}
                    {/* {errors.salary && <p className="error">{errors.salary.message}</p>} */}
                </div>


                {/* <div className="form-outline"> */}
                {/* <Controller
                        name="salary"
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                // isClearable
                                options={salaryRange}
                                value={salaryRange.find((c) => c.value === value)}
                                onChange={(val) => onChange(val?.value)}
                                defaultValue={salaryRange.find((c) => c.value === salary)}
                                // isSearchable={true}
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder={addNewRequirementPlaceholders.salaryByNotDisclosedPlaceHolder}
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                onMenuClose={() => { value && setIsSelected({ ...isSelected, 'salary': true }) }}
                                styles={{
                                    // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                    control: (styles) => ({
                                        ...styles,
                                        ...errorBorderForSelectBox(errors.salary && 'salary'),
                                    }),
                                    container: (provided, state) => ({
                                        ...provided,
                                        marginTop: 24,
                                    }),
                                    valueContainer: (provided, state) => ({
                                        ...provided,
                                        overflow: "visible"
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        position: "absolute",
                                        top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                        background: "white",
                                        transition: "top 0.1s, font-size 0.1s",
                                        fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                        fontWeight: 100,
                                        padding: '0.3px 8.5px',
                                        borderRadius: '8px',
                                        color: 'rgba(6, 44, 80, 0.7);',
                                        border: isSelected['salary'] ? '1px solid #cccccc' : ''
                                    })
                                }}
                            />
                        )}
                        rules={{ required: "Field is required" }}
                    /> */}

                {/* <label className="did-floating-label">{addNewRequirementPlaceholders.salaryByNotDisclosedPlaceHolder}</label> */}
                {/* {errors.salary && <p className="error">{errors.salary.message}</p>} */}
                {/* </div> */}

                <div className="form-outline row gx-3">
                    {/* <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.skill && 'did-error-input'}`}>
                        <textarea
                            className="did-floating-input w-100 rounded-3 h-100 pt-3 text_area_border" // border_color 
                            name="skill"
                            placeholder=""
                            rows="7" cols="33"
                            {...register("skill", {
                                required: false,
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                }
                            })}
                        />
                        <label className="did-floating-label">{addNewRequirementPlaceholders.skillsNeededPlaceHolder}</label>
                    </div> */}
                    <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.job_desciption && 'did-error-input'}`}>
                        <textarea
                            className="did-floating-input w-100 rounded-3 h-100 pt-3 text_area_border" //border_color
                            name="job_desciption"
                            placeholder=''
                            rows="7" cols="33"
                            {...register("job_desciption", {
                                required: "Field is required",
                                minLength: {
                                    value: 1,
                                    message: "Min length is 1"
                                },
                                maxlength: 3000,
                                minlength: 1
                            })}
                        />
                        <label className="did-floating-label requiredTrue">{addNewRequirementPlaceholders.jobDescriptionPlaceHolder}</label>
                        {/* <div className="d-flex justify-content-between align-items-center"> */}
                        {/* {errors.job_desciption && <p className="error">{errors.job_desciption.message}</p>} */}
                        {/* {<span className="error">{'1500 / '+formData?.job_desciption}</span>} */}
                        {/* </div> */}
                    </div>
                </div>

                <div className={`form-outline did-floating-label-content ${errors.job_responsibility && 'did-error-input'}`}>
                    <textarea
                        className="did-floating-input w-100 rounded-3 h-100 pt-3 text_area_border"
                        name="job_responsibility"
                        placeholder=''
                        rows="5" cols="33"
                        {...register("job_responsibility", {
                            required: "Field is required",
                            minLength: {
                                value: 1,
                                message: "Min length is 1"
                            },
                            maxlength: 3000,
                            minlength: 1
                        })}
                    />
                    <label className="did-floating-label requiredTrue">{addNewRequirementPlaceholders.responsibilitiesPlaceHolder}</label>
                    {/* {errors.responsibilities && <p className="error">{errors.responsibilities.message}</p>} */}
                </div>

                <div>
                    <div className="d-flex justify-content-between align-items-center pb-2 border-bottom_2_dark mb-4">
                        <h6 className="pt-1 mb-0 font_color_imp fw-semibold">{'Skill Experience Details'}</h6>
                    </div>
                    <div className='row text-start pb-2 mt-0 d-flex justify-content-between align-items-center'>
                        <div className='col-md-4'>
                            <label className="requiredTrue mb-3">{'Technologies'}</label>
                            <div className="form-outline">
                                <Controller
                                    name="language"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            // isClearable
                                            options={languageArrState}
                                            value={getValues('language') ? languageArrState?.find((c) => c.value === value) : null}
                                            onChange={(val) => onChange(val?.value)}
                                            defaultValue={getValues('language') ? languageArrState?.find((c) => c.value === typeOfLanguageValue) : null}
                                            isSearchable={true}
                                            placeholder="Add Technologies"
                                            className="basic-single my-react-select"
                                            classNamePrefix="select"
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'language': true }) }}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    ...errorBorderForSelectBox(errors.language && 'language'),
                                                }),
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    marginTop: 5,
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    overflow: "visible"
                                                }),
                                                placeholder: (provided, state) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                    background: "white",
                                                    transition: "top 0.1s, font-size 0.1s",
                                                    fontSize: 12, //(state.hasValue || state.selectProps.inputValue) &&
                                                    fontWeight: 100,
                                                    padding: '0.3px 8.5px',
                                                    borderRadius: '8px',
                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                    border: isSelected['language'] ? '1px solid #cccccc' : ''
                                                }),
                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                    ...styles,
                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                    //   cursor: isDisabled ? 'not-allowed' : 'default',
                                                })
                                            }}
                                        />
                                    )}
                                    rules={{ required: false }}
                                />
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <label className="mb-3">{'MinExperience'}</label>
                            <div className={`marginbottom_int did-floating-label-content mb-0 ${errors.minYears && 'did-error-input'}`}>
                                <input
                                    type="number"
                                    name="minYears"
                                    placeholder=""
                                    {...register("minYears", {
                                        required: false,

                                    })}
                                    className="did-floating-input exp"
                                />
                                <label className="did-floating-label expinner1">{'Minimum Years'}</label>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <label className="mb-3">{'MaxExperience'}</label>
                            <div className={`marginbottom_int did-floating-label-content mb-0 ${errors.maxYears && 'did-error-input'}`}>
                                <input
                                    type="number"
                                    name="maxYears"
                                    placeholder=""
                                    {...register("maxYears", {
                                        required: false,
                                    })}
                                    className="did-floating-input exp"
                                />
                                <label className="did-floating-label expinner1">{'Maximum Years'}</label>
                            </div>
                        </div>

                        <div className='col-md-1'>
                            <label className="mb-3">{''}</label>
                            <div className={`did-floating-label-content mt-3 mb-0 border p-2 text-center pointer_event hover_orange_bg`} onClick={() => { addExperiencesDetails() }}>
                                <i class="fa fa-plus" style={{ fontSize: "14px" }}></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex text-muted height_step_2_employees_v_bar">
                    <PerfectScrollbar className="sidebar-items w-100 pt-2 pb-3">
                        {technicalExpertizeSkills?.map((item) =>
                            <div className='border row text-start d-flex justify-content-evenly align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                <div className='col-md-5 border-end pt-1 pb-1'>
                                    {item?.language}
                                </div>
                                <div className='col-md-4 border-end pt-1 pb-1'>
                                    {item?.minYears}
                                </div>
                                <div className='col-md-2 border-end pt-1 pb-1'>
                                    {item?.maxYears}
                                </div>
                                <div className='col-md-1 text-center pt-1 pb-1'>
                                    <i className='fa fa-trash pointer_event' style={{ color: 'red' }} onClick={() => { deleteSkillsFun(item) }}></i>
                                </div>
                            </div>)}
                        {technicalExpertizeSkills?.length === 0 &&
                            <>
                                <div className='border row text-start d-flex justify-content-center align-items-center ms-0 me-1 rounded-2 background_table_row mb-2'>
                                    <div className='col-md-12 pt-1 pb-1'>
                                        No Data Found !!
                                    </div>
                                </div>
                            </>
                        }
                    </PerfectScrollbar>
                </div>

                <div className="border-top position-sticky bottom-0 footer mt-auto py-5 pb-3 pt-2 main_bg text-center d-flex justify-content-center">
                    <button type="submit" className="mt-1 mb-1 fw-medium_imp" onClick={errorHandler}>
                        {isSubmitting && <span>Submitting</span>}
                        {!isSubmitting && <span>Update Job Details</span>}
                    </button>
                </div>
            </form>
            <button className="d-none" id="buttonEditDismiss" data-bs-dismiss="modal"></button>
        </div>
    );
}

export default EditJobRequirement