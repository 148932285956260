import React from "react";
import "../../assets/css/profile.css"
import userProfileLayout from "../../hoc/userProfileLayout";

const ProfilePage = (props) => {

    return <>
        <div className="my-3 p-3 bg-body rounded shadow-sm">
            <h6 className="border-bottom pb-2 mb-0 mb-3">Personal Info</h6>
            <form>
                <div className="row">
                    <div className="col">
                        <label htmlFor="exampleInputEmail1" className="form-label">Username</label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text" id="basic-addon2"><i className="fa fa-user"></i></span>
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Email Address" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text" id="basic-addon2">@</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="exampleInputEmail1" className="form-label">First Name</label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="First Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text" id="basic-addon2"><i className="fa fa-user"></i></span>
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="exampleInputEmail1" className="form-label">Last Name</label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Last Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text" id="basic-addon2"><i className="fa fa-user"></i></span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="exampleInputEmail1" className="form-label">Contact Number</label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Contact Number" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text" id="basic-addon2"><i className="fa fa-mobile"></i></span>
                        </div>
                    </div>
                </div>

                <button type="submit" className="btn btn-default">Submit</button>
            </form>
        </div>

    </>
}

export default userProfileLayout(ProfilePage);