import * as employeeJobActions from "../actionTypes/index";

const addUpdateEmployeeJobs = (payload) => {
  return {
    type: employeeJobActions.ADD_UPDATE_EMPLOYEE_JOB_DETAILS,
    payload: payload
  };
};

export { addUpdateEmployeeJobs };
