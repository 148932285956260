export const returnArryOfObjects = (serviceList, activeIds, key) => {
    const result = serviceList.filter((item) => activeIds.includes(item[key]));
    return result
}

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['BY', 'KB', 'MB', 'GB', 'Ti', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`
    // return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}

export const formatDate = (value) => {
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return day + '-' + month + '-' + year;
}

export const randStr = (len) => {
    let s = '';
    while (s.length < len) s += Math.random().toString(36).substr(2, len - s.length);
    return s?.toUpperCase();
}

export const formatDateDDMMMYYYY = (value) => {
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });

    return [day, month, year].join(' ');
}

export const fromNowFun = (date) => {
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    const WEEK = 7 * DAY;
    const YEAR = 365 * DAY;
    const MONTH = YEAR / 12;
    const units = [
        { max: 30 * SECOND, divisor: 1, past1: 'just now', pastN: 'just now', future1: 'just now', futureN: 'just now' },
        { max: MINUTE, divisor: SECOND, past1: 'a second ago', pastN: '# seconds ago', future1: 'in a second', futureN: 'in # seconds' },
        { max: HOUR, divisor: MINUTE, past1: 'a minute ago', pastN: '# minutes ago', future1: 'in a minute', futureN: 'in # minutes' },
        { max: DAY, divisor: HOUR, past1: 'an hour ago', pastN: '# hours ago', future1: 'in an hour', futureN: 'in # hours' },
        { max: WEEK, divisor: DAY, past1: 'yesterday', pastN: '# days ago', future1: 'tomorrow', futureN: 'in # days' },
        { max: 4 * WEEK, divisor: WEEK, past1: 'last week', pastN: '# weeks ago', future1: 'in a week', futureN: 'in # weeks' },
        { max: YEAR, divisor: MONTH, past1: 'last month', pastN: '# months ago', future1: 'in a month', futureN: 'in # months' },
        { max: 100 * YEAR, divisor: YEAR, past1: 'last year', pastN: '# years ago', future1: 'in a year', futureN: 'in # years' },
        { max: 1000 * YEAR, divisor: 100 * YEAR, past1: 'last century', pastN: '# centuries ago', future1: 'in a century', futureN: 'in # centuries' },
        { max: Infinity, divisor: 1000 * YEAR, past1: 'last millennium', pastN: '# millennia ago', future1: 'in a millennium', futureN: 'in # millennia' },
    ];
    const diff = Date.now() - (typeof date === 'object' ? date : new Date(date)).getTime();
    const diffAbs = Math.abs(diff);
    for (const unit of units) {
        if (diffAbs < unit.max) {
            const isFuture = diff < 0;
            const x = Math.round(Math.abs(diff) / unit.divisor);
            if (x <= 1) return isFuture ? unit.future1 : unit.past1;
            return (isFuture ? unit.futureN : unit.pastN).replace('#', x);
        }
    }
};


export const getDayFun = (dateInput) => {
    const week_of_day_arr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var dateString = dateInput
    var day = week_of_day_arr[new Date(dateString).getDay()];
    console.log(day);
    return day || null
}

export const getISOStringWithoutSecsAndMillisecs = (dateinput) => {
    let date = new Date(dateinput)
    if (date) {
        date = date.setHours(date.getHours() - 5);
        date = date.setMinutes(date.getMinutes() - 30);
        const dateAndTime = date?.toISOString()?.split('T')
        const time = dateAndTime[1]?.split(':')
        return dateAndTime[0] + 'T' + time[0] + ':' + time[1]
    }
    // if (date) {
    //     var now = date;
    //     now.setSeconds(0, 0);
    //     var stamp = now.toISOString().replace(/T/, " ").replace(/:00.000Z/, "");
    //     console.log("===stamp=======", stamp)
    //     return stamp
    // }
}

export const toPascalCase = (text, trimSpace = false) => text?.split(' ')?.map((t) => t[0]?.toUpperCase() + t?.slice(1)?.toLowerCase())?.join(trimSpace ? '' : ' ')

export const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

export const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

export const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
};

export const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

export const getInitials = (user) => {
    if (user) {
        return `${user[0]?.toUpperCase()}${user[1]?.toUpperCase()}`
    } else {
        return user
    }
}

export const setValue = (functionFor) => {
    return (e) => {
        const value = parseInt(e.target.value);
        functionFor(value);
    }
};

export const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
}
