import React, { useState } from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from '../../../Utils/utils'
import { postApi, getAllApi } from '../../../services/apiCommonService'
import { create_account_managers, assign_acct_mgr_api_url, get_all_companies_list_api_url, check_User_Email_url, check_User_Phone_url, unassignAcctMgr_url } from '../../../services/endPointsConstant'
import storePersist from '../../../Utils/storePersist'
import { useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon"
import AssignedCompaniesList from './AssignCompaniesList'
import { addLoader, deleteLoader } from "../../../redux/actions/loader";

const AddAccountManager = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const d = new Date()
    const newDate = d.toISOString().substring(0, 10);
    const { returnAddAccountManagerFormDetails, modalPopUpCancel } = props
    const [showPassword, setShowPassword] = useState(false);
    const [confShowPassword, setConfShowPassword] = useState(false);
    const [formData, setFormData] = useState(false);
    const [stageCompleted, setStageCompleted] = useState()
    const [assignedCmpyListStateValue, setAssignedCmpyListStateValue] = useState([])
    const [assignedCmpyListStateValueObjects, setAssignedCmpyListStateValueObjects] = useState([])
    const [isCheckLogin, setIsCheckLogin] = useState(false)

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        control,
        reset,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        if (modalPopUpCancel) {
            reset();
            reset({});
            reset(null);
            setStageCompleted(null)
            document.location.reload();
        }
    }, [modalPopUpCancel])

    const errorHandler = () => {
        if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const onClickAssignCmpyHandlerFun = () => {
        toast.success("Successfully company assigned !!")
    }

    const assignCompanies = async (accMangrDetails, userData) => {
        const apiReqBody = {
            companyId: assignedCmpyListStateValue,
            actManagerId: accMangrDetails._id,
            // userid: userData?._id
        }
        const apiResponse = await postApi(assign_acct_mgr_api_url, apiReqBody)
        if (apiResponse?.data?.message) {
            toast.success("Successfully Added details !!")
            returnAddAccountManagerFormDetails(accMangrDetails)
            document.getElementById("addAccountManagerButtonDismissId").click();
            reset();
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const onSubmit = async (data) => {
        dispatch(addLoader());
        let apiReqBody = {
            email: data?.emailId
        }
        const apiResponse = await postApi(check_User_Email_url, apiReqBody)
        if (apiResponse?.data?.Data?.length > 0) {
            dispatch(deleteLoader());
            toast.error(apiResponse?.data?.message)
        } else {
            let apiReqBody = {
                phone_number: data?.phoneNumber
            }
            const apiResponse = await postApi(check_User_Phone_url, apiReqBody)
            if (apiResponse?.data?.Data?.length > 0) {
                dispatch(deleteLoader());
                toast.error(apiResponse?.data?.message)
            } else {
                setFormData(data);
                setStageCompleted(1)
                dispatch(deleteLoader());
            }
        }
    };

    const onNextBtnHandler = (value) => {
        setStageCompleted(value)
    }

    const getAllCompaniesListApi = async () => {
        dispatch(addLoader());
        const apiResponse = await getAllApi(get_all_companies_list_api_url)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            dispatch(deleteLoader());
            return apiResponseData
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const unAssignCompanies = async (companiesList, accMangrDetails, userData) => {
        const cmpList = companiesList?.map((item) => item._id) || []
        const apiReqBody = {
            companyId: cmpList?.filter(e => assignedCmpyListStateValue.indexOf(e) > -1 ? false : true),
            actManagerId: accMangrDetails._id,
        }
        const apiResponse = await postApi(unassignAcctMgr_url, apiReqBody)
        if (apiResponse?.data?.message) {
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const finalStageCompleted = async () => {
        const { id } = storePersist.get('userData')
        const apiReqBody = {
            name: formData?.name,
            emailId: formData?.emailId,
            dob: formData?.dob,
            phoneNumber: formData?.phoneNumber,
            location: formData?.location,
            joiningDate: formData?.joiningDate,
            userEmail: formData?.emailId,
            password: formData?.password,
            createdBy: "admin",
            userid: id
        }
        const apiResponse = await postApi(create_account_managers, apiReqBody)
        if (apiResponse?.data?.data) {
            toast.success("Successfully Added details !!")
            assignCompanies(apiResponse?.data?.data, apiResponse?.data?.userCreatedData)
            const datafinal = await getAllCompaniesListApi()
            void unAssignCompanies(datafinal, apiResponse?.data?.data, apiResponse?.data?.userCreatedData)
            returnAddAccountManagerFormDetails(apiResponse?.data?.data)
            document.getElementById("addAccountManagerButtonDismissId").click();
            document.location.reload();
            reset();
        } else if (apiResponse?.data?.message === "email id already exist") {
            toast.error(apiResponse?.data?.message)
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const onPreviousBtnHandler = (value) => {
        setStageCompleted(value)
    }

    const returnAssignedCmpyListFun = (assignedCmpyList, object) => {
        setAssignedCmpyListStateValue([...assignedCmpyList])
        setAssignedCmpyListStateValueObjects([...object])
    }

    // if (getValues('emailId')) {
    //     reset({ ...getValues(), userEmail: getValues('emailId') })
    // }

    return (
        <div className="form-holder pt-4 ps-8 pe-8 pb-0 main_bg rounded-0">

            <div class="stepper-wrapper">
                <div class={`stepper-item ${!stageCompleted ? 'active' : [1, 2].includes(stageCompleted) && 'completed'}`}>
                    <div class="step-counter">1</div>
                    <div class="step-name">Create Account</div>
                </div>
                <div class={`stepper-item ${stageCompleted === 1 ? 'active' : stageCompleted === 2 && 'completed'}`}>
                    <div class="step-counter">2</div>
                    <div class="step-name">Assign Companies</div>
                </div>
                <div class="stepper-item">
                    <div class="step-counter">3</div>
                    <div class="step-name">Summary</div>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                {!stageCompleted &&
                    <>
                        <div className="my-3">
                            <img alt="Alt content" src={require('../../../assets/images/account-details-AM.svg').default} className="img_w_h_18" />
                            <span className="mx-2 fw-bolded font_size_14">Account Manger Details</span>
                        </div>

                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.name && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    type="text"
                                    name="name"
                                    placeholder=""
                                    {...register("name", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9._-\s]+$/,
                                            message: "Allow only alphabets"
                                        }
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Name'}</label>
                            </div>
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.emailId && 'did-error-input'}`}>
                                <input
                                    type="email"
                                    name="emailId"
                                    placeholder=""
                                    {...register("emailId", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                            message: "Invalid email address, It should be example@google.com"
                                        },
                                        onChange: (e) => {
                                            reset({ ...getValues(), emailId: e?.target?.value })
                                        }
                                    })}
                                    className="did-floating-input"
                                />
                                <label className="did-floating-label requiredTrue">{'Email'}</label>
                            </div>
                        </div>
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.dob && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    name="dob"
                                    type="date"
                                    placeholder=""
                                    {...register("dob", {
                                        required: "Field is required",

                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'DOB'}</label>
                            </div>
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.phoneNumber && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    type="number"
                                    name="phoneNumber"
                                    placeholder=""
                                    {...register("phoneNumber", {
                                        required: "Field is required",
                                        maxLength: {
                                            value: 10,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^[7-9][0-9]{9}$/,
                                            message: "Allow only numbers"

                                        }
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Phone Number'}</label>
                            </div>
                        </div>
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.location && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    type="text"
                                    name="location"
                                    placeholder=""
                                    {...register("location", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9._-\s]+$/,
                                            message: "Allow only alphabets"
                                        }
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Location'}</label>
                            </div>
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.joiningDate && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    name="joiningDate"
                                    type="date"
                                    placeholder=""
                                    {...register("joiningDate", {
                                        required: "Field is required",

                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Joining Date'}</label>
                            </div>
                        </div>

                        <div className="my-3">
                            <img alt="Alt content" src={require('../../../assets/images/account-password-AM.svg').default} className="img_w_h_23" />
                            <span className="mx-2 fw-bolded font_size_14">Generate User Name / Password</span>
                        </div>

                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.userEmail && 'did-error-input'}`}>
                                <input
                                    type="email"
                                    name="emailId"
                                    placeholder=""
                                    {...register("emailId", {
                                        required: false,

                                    })}
                                    //     required: "Field is required",
                                    //     defaultValue: getValues('emailId'),
                                    //     minLength: {
                                    //         value: 2,
                                    //         message: "Min length is 2"
                                    //     },
                                    //     pattern: {
                                    //         value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                    //         message: "Invalid email address, It should be example@google.com"
                                    //     }
                                    // })}
                                    className="did-floating-input"
                                    disabled
                                    readOnly
                                />
                                <label className="did-floating-label requiredTrue">{'Enter username'}</label>
                            </div>
                        </div>
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.password && 'did-error-input'}`}>
                                <input
                                    type={showPassword ? 'text' : "password"}
                                    name="password"
                                    className="did-floating-input me-2"
                                    placeholder=""
                                    {...register("password", {
                                        required: true,
                                        minLength: {
                                            value: 6,
                                            message: "Min length is 6"
                                        },
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                            message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
                                        },
                                    })}
                                />
                                {showPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                {!showPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                <label className="did-floating-label requiredTrue">{'Enter the password'}</label>
                                {errors.password && <p className="error">{errors.password.message}</p>}
                            </div>
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.confirmPassword && 'did-error-input'}`}>
                                <input
                                    type={confShowPassword ? 'text' : "password"}
                                    name="confirmPassword"
                                    className="did-floating-input pe-2 me-2"
                                    placeholder=""

                                    {...register("confirmPassword", {
                                        validate: (value) => {
                                            const { password } = getValues();
                                            return password === value || "Passwords should match!";
                                        },
                                        required: true,
                                    })}
                                />
                                {confShowPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye pe-2 me-2" onClick={() => { setConfShowPassword(!confShowPassword) }}></span>}
                                {!confShowPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye pe-2 me-2" onClick={() => { setConfShowPassword(!confShowPassword) }}></span>}
                                <label className="did-floating-label requiredTrue">{'Enter the confirmPassword'}</label>
                                {errors.confirmPassword && <p className="error">{errors.confirmPassword.message}</p>}
                            </div>
                        </div>
                    </>
                }

                {stageCompleted === 1 &&
                    <AssignedCompaniesList
                        isAssign={false}
                        isUnAssign={false}
                        returnAssignedCmpyList={returnAssignedCmpyListFun}
                        onClickAssignCmpyHandlerFunProp={onClickAssignCmpyHandlerFun}
                        AddSelectedAccountManagerDetails={assignedCmpyListStateValueObjects}
                    />
                }

                {stageCompleted === 2 &&
                    <section class="py-1">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Account Manger Details
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body ms-5">
                                                    <div class="row">
                                                        <div className="col-md-12 d-flex align-content-center flex-wrap pb-2">
                                                            {AccountManagerMasterData.map((item, idx) => {
                                                                return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2" key={idx}>
                                                                    <div className="col-xs-6 me-2">
                                                                        <img
                                                                            src={require(`../../../assets/images/${item.img}`)}
                                                                            alt="jobType"
                                                                            className="img_w_h_11"
                                                                        />
                                                                    </div>
                                                                    <div className="col-xs-6">
                                                                        <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                                        <div className="fw-medium font_size_14">{getValues()[item.response] || ''}</div>
                                                                    </div>
                                                                </div>)
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    User Name Details
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body ms-5">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div className="col-md-12 d-flex align-content-center flex-wrap pb-2">
                                                                {userDetailsMasterData.map((item, idx) => {
                                                                    return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2" key={idx}>
                                                                        <div className="col-xs-6 me-2">
                                                                            <img
                                                                                src={require(`../../../assets/images/${item.img}`)}
                                                                                alt="jobType"
                                                                                className="img_w_h_11"
                                                                            />
                                                                        </div>
                                                                        <div className="col-xs-6">
                                                                            <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                                            <div className="fw-medium font_size_14">{item.response === 'created' ? newDate : getValues()[item.response]}</div>
                                                                        </div>
                                                                    </div>)
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2 ms-4">
                                                        <div className="col-xs-6 me-2">
                                                            <input type="checkbox" className="form-check-input form-check-padding me-2" name="" checked={isCheckLogin} onChange={() => { setIsCheckLogin(!isCheckLogin) }} />
                                                            {/* getValues?.confirmPassword === getValues.password */}
                                                        </div>
                                                        <div className="col-xs-6">
                                                            <div className="font_size_14">{'Allow Change password for first time login'}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Companies Assigned
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <AssignedCompaniesList
                                                        assignedCmpyListStateValue={assignedCmpyListStateValueObjects}
                                                        isViewAssignCompanyList={true}
                                                        isUnAssign={false}
                                                        isCheckboxOff={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                <div className="border-top position-sticky bottom-0 footer py-3 main_bg text-center d-flex justify-content-between">
                    {!stageCompleted &&
                        <>
                            <div></div>
                            <button type="submit"
                                className="btn btn-primary pb-2 font_size_14 btn_height_36 padding_top_6 mt-0 mb-0 pb-2" onClick={errorHandler}>
                                {isSubmitting && <span>Submitting</span>}
                                {!isSubmitting && <span>Next</span>}
                            </button>
                        </>
                    }
                    {stageCompleted === 1 &&
                        <>
                            <button
                                type="button"
                                className="btn btn-primary btn_border pb-3 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                onClick={() => { onPreviousBtnHandler(null) }}
                            >
                                <i className="fa fa-angle-left pe-2"></i>
                                Previous
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                onClick={() => { onNextBtnHandler(2) }}
                            >
                                Next
                                <i className="fa fa-angle-right ps-2"></i>
                            </button>
                        </>
                    }
                    {stageCompleted === 2 &&
                        <>
                            <button
                                type="button"
                                className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                onClick={() => { onPreviousBtnHandler(1) }}
                            >
                                <i className="fa fa-angle-left pe-2"></i>
                                Previous
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary bg_color_ornage btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 padding_top_6"
                                onClick={() => { finalStageCompleted('finish') }}
                            >
                                Finish
                                <i className="fa fa-angle-right ps-2"></i>
                            </button>
                        </>
                    }
                </div>
            </form>
            <button className="d-none mt-0 mb-0 pb-0" id="addAccountManagerButtonDismissId" data-bs-dismiss="modal"></button>
        </div>
    );
}

export default AddAccountManager

const AccountManagerMasterData = [
    { label: 'Account Manger', response: 'name', value: 'Remote', img: 'ellipse_icon.svg' },
    { label: 'Email ID', response: 'emailId', value: '1234', img: 'ellipse_icon.svg' },
    { label: 'Mobile No', response: 'phoneNumber', value: '5-10 years', img: 'ellipse_icon.svg' },
    { label: 'Date Of Birth', response: 'dob', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Location', response: 'location', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Joining', response: 'joiningDate', value: 'full time', img: 'ellipse_icon.svg' },
]

const userDetailsMasterData = [
    { label: 'User name', response: 'emailId', value: 'Remote', img: 'ellipse_icon.svg' },
    { label: 'Created', response: 'created', value: '1234', img: 'ellipse_icon.svg' },
    { label: 'Password', response: 'password', value: '5-10 years', img: 'ellipse_icon.svg' },
]