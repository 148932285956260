import { Link, useLocation } from "react-router-dom";
import storePersist from '../../Utils/storePersist'

const HelpUs = () => {
    const { pathname } = useLocation();
    const isAuthenticate = storePersist?.get('userData')
    return (
        <div className="page-hero link-style pt-5 pb-8 bg-white pt-2 text-center vh-100">
            {/* <h3 className="">The page was not found</h3> */}
            <div className="d-flex justify-content-center align-items-center pt-1">
                <img src={require('../../assets/images/side_bar_logo.svg').default} alt="Alt content" className="w-20 h-30" />
            </div>
            <div className="col-md-12 text-center d-flex justify-content-center pt-5">
                <span className="w-35">
                    Registered company is Rejected by <b>Remote72</b> Admin.
                    for more details, please <b>contact Remote72 support </b>.
                </span>
            </div>
            <div className="col-md-12 text-center d-flex justify-content-center pt-4">
                <div className="col-md-3 my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20 text-center">
                    <div className="gap-1">
                        <label>Name:</label>
                        <span className="ms-1">Sayed</span>
                    </div>
                    <div className="gap-1">
                        <label>Email ID:</label>
                        <span className="ms-1">sayed@remote72.com</span>
                    </div>
                    <div className="gap-1">
                        <label>cell Number:</label>
                        <span className="ms-1">741914007</span>
                    </div>
                    <div className="gap-1">
                        <label>Location:</label>
                        <span className="ms-1">Kolkata</span>
                    </div>
                </div>
            </div>
            <br/>
            <div>- OR -</div>
            <div className="d-flex justify-content-center align-items-center mt-4 pt-2">
                <Link to="/raise-ticket-query" className="btn btn-primary" >
                    Raise a ticket
                </Link>
            </div>
            {/* <div className="d-flex justify-content-center align-items-center mt-4 pt-2">
                <Link to={isAuthenticate ? "/dashboard" : "/login"} className="btn btn-primary" >
                    Go Back Home
                </Link>
            </div> */}
        </div>
    );
}

export default HelpUs

// import React, { useState, useEffect } from "react";
// import { isMobile } from 'react-device-detect';
// import { toast } from 'react-toastify';
// import { Link, useNavigate } from 'react-router-dom';
// import { useForm } from "react-hook-form";
// import "../../assets/css/login.css"
// import authLayout from "../../hoc/authLayout";
// import { postApi } from '../../services/apiCommonService'
// import { forgot_password_api_url, reset_password_details } from '../../services/endPointsConstant'
// import { isEmpty } from '../../Utils/utils'

// const HelpUs = () => {
//   const [formData, setFormData] = useState(false);
//   let navigate = useNavigate();

//   const {
//     register,
//     formState: { errors, isDirty, isSubmitting, touched },
//     handleSubmit
//   } = useForm({
//     mode: "onBlur",
//     shouldFocusError: false
//   });

//   useEffect(() => {
//     if (!isEmpty(errors)) {
//       toast.error("Fill all manditatory fields")
//       return
//     }
//   }, [errors])

//   return (
//     <div className="form-holder login-form vh-100 rounded-0">
//       <div className="d-flex justify-content-center align-items-center my-4 mt-5">
//         <img src={require('./../../assets/images/login_page_logo.svg').default} alt="Alt content" />
//       </div>
//       <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
//         <div className="gap-1">
//           <label>Name:</label>
//           <span className="ms-1">Sayed</span>
//         </div>
//         <div className="gap-1">
//           <label>Email ID:</label>
//           <span className="ms-1">sayed@remote72.com</span>
//         </div>
//         <div className="gap-1">
//           <label>cell Number:</label>
//           <span className="ms-1">741914007</span>
//         </div>
//         <div className="gap-1">
//           <label>Location:</label>
//           <span className="ms-1">Kolkata</span>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default HelpUs;