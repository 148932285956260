import * as actions from '../actionTypes/index'

export const initialState = {
    step_1: 'current',
    step_2: '',
    step_3: '',
    step_4: '',
    step_5: '',
    step_6: '',
    step_7: '',
    step_8: '',
    total_completed: 0,
    resumeFileName: "",
    resume_file_location: '',
    remotefile_location: '',
    remotefileName: ''
}

export default function employeeStepsBarReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.ADD_UPDATE_EMPLOYEE:
            return { ...state, ...payload }
        default:
            return state
    }
}
