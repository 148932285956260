import * as actions from '../actionTypes/index'

export const initialState = {
    jobDetails: ''
}

export default function employeeJobsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.ADD_UPDATE_EMPLOYEE_JOB_DETAILS:
            return { ...state, jobDetails: payload}
        default:
            return state
    }
}
