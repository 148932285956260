import * as actions from '../actionTypes/index'

export const initialState = {
    shortlistedResumesList: [],
    hasErrors: false,
}

export default function shortlistedResumesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.ADD_SHORTLISTED_RESUME:
            return { ...state, shortlistedResumesList: payload }
        case actions.DELETE_SHORTLISTED_RESUME:
            return { ...state, ...payload }
        default:
            return state
    }
}
