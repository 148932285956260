import React, { useState } from "react";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
import { isEmpty } from '../../../Utils/utils'
import { postApi, updateWithOutParamsApi } from '../../../services/apiCommonService'
import { edit_company_details, post_company_logo_api_url, upload_company_files, company_getFileLink, remove_Document_url, get_company_id, reset_password_details } from '../../../services/endPointsConstant'
import storePersist from '../../../Utils/storePersist'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { downloadPDF } from '../../../services/axios-common';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Select, { components } from "react-select";
import { isMobile } from 'react-device-detect';
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import PhoneInput from 'react-phone-input-2';
import { typesOfEmployeeContractList, typeOfCompanyList, numberOfEmployeesList, cmpyDocumentList } from '../../../Utils/masterData/dropDownJson'
import { returnArryOfObjects } from '../../../Utils/utils'
import { addLoader, deleteLoader } from "../../../redux/actions/loader";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const EditCompanyDetailsComponent = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { role } = storePersist.get('userData')
    const { updatedCompanyDetails } = useSelector((state) => state.defaultCompanyDetailsReducer);
    const d = new Date()
    const newDate = d.toISOString().substring(0, 10);
    const { returnedEditCompanyFormDetails, selectedCompanyDetailsObject, modalPopUpCancel } = props
    const [showPassword, setShowPassword] = useState(false);
    const [confShowPassword, setConfShowPassword] = useState(false);
    const [formData, setFormData] = useState(false);
    const [formDataDoc, setFormDataDoc] = useState(false);
    const [stageCompleted, setStageCompleted] = useState(null)
    const [typeOfCompanyValue, setTypeOfCompanyValue] = useState(null);
    const [numberOfEmployeesValue, setNumberOfEmployeesValue] = useState(null);
    const [isSelected, setIsSelected] = useState({});
    const [phoneValue, setPhoneValue] = useState('');
    const [countrySelectedValue, setCountrySelectedValue] = useState(selectedCompanyDetailsObject[0]?.country || updatedCompanyDetails?.country);
    const [cmpySelectedVal, setCmpySelectedVal] = useState(selectedCompanyDetailsObject[0] || updatedCompanyDetails);
    const [typesOfEmployeeContractValue, setTypesOfEmployeeContractValue] = useState(null);
    const [countryOptions, setCountryOptions] = useState(countryList().getData())
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [documentDetailsList, setDocumentDetailsList] = useState([])
    const [selectedCompanyDetailsObjects, setSelectedCompanyDetailsObjects] = useState([]);
    const [selectedCompanyDetailsRowList, setSelectedCompanyDetailsRowList] = useState([]);
    const [cmpyDocumentValue, setCmpyDocumentValue] = useState(null);
    const [document_list_state, set_document_list_state] = useState([])

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        control,
        reset,
        getValues
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false,
        // defaultValues: selectedCompanyDetailsObject[0]
    });

    const {
        register: registerDoc,
        formState: {
            errors: errorsDoc,
            isSubmitting: isSubmittingDoc
        },
        handleSubmit: handleSubmitDoc,
        control: controlDoc,
        reset: resetDoc,
        getValues: getValuesDoc
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        setCountrySelectedValue(selectedCompanyDetailsObject[0]?.country)
        setCmpySelectedVal(selectedCompanyDetailsObject[0] || updatedCompanyDetails?.companyDetails);
        if (selectedCompanyDetailsObject?.length > 0) {
            reset({ ...selectedCompanyDetailsObject[0], oldcompanyEmail: selectedCompanyDetailsObject[0]?.businessEmailId });
            setTimeout(() => {
                getCompanyDocApiCallFun(selectedCompanyDetailsObject[0] || updatedCompanyDetails?.companyDetails, true)
            }, 3000);
        }

    }, [selectedCompanyDetailsObject])

    useEffect(() => {
        setCountrySelectedValue(updatedCompanyDetails?.companyDetails?.country)
        setCmpySelectedVal(selectedCompanyDetailsObject[0] || updatedCompanyDetails?.companyDetails);
        if (updatedCompanyDetails && Object.keys(updatedCompanyDetails?.companyDetails).length > 0) {
            reset({ ...updatedCompanyDetails?.companyDetails, oldcompanyEmail: updatedCompanyDetails?.companyDetails?.businessEmailId });
            if (updatedCompanyDetails?.type === 'Document') {
                setStageCompleted(1)
            }
            else if (updatedCompanyDetails?.type === 'Main') {
                setStageCompleted(null)
            }
            setTimeout(() => {
                getCompanyDocApiCallFun(selectedCompanyDetailsObject[0] || updatedCompanyDetails?.companyDetails, true)
            }, 3000);
        }
    }, [updatedCompanyDetails])

    // useEffect(() => {
    //     setStageCompleted(null)
    // }, [updatedCompanyDetails?.type === 'Main'])

    useEffect(() => {
        getCompanyDocApiCallFun(selectedCompanyDetailsObject[0] || updatedCompanyDetails, true)
        setStageCompleted(null)
    }, [])

    const errorHandler = () => {
        if (errors.email) {
            toast.error("Check businessEmailId address, It should be example@google.com")
        } else if (errors.password) {
            toast.error("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.")
        } else if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const errorHandlerDoc = () => {
        if (errorsDoc.documentName) {
            toast.error("document name can not be emepty")
        } else if (errorsDoc.file) {
            toast.error("please select file")
        } else if (!isEmpty(errorsDoc)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    useEffect(() => {
        if (countrySelectedValue) {
            setPhoneValue('')
        } else {
            setPhoneValue('')
        }
    }, [countrySelectedValue])

    const logoUpload = async (data, companyDetails) => {
        if (data.file) {
            const baseUrl = 'http://ec2-13-60-203-249.eu-north-1.compute.amazonaws.com:5000'
            const formData = new FormData();
            formData.append("file", data?.file[0]);
            formData.append("companyId", companyDetails?._id)
            const apiResponse = await fetch(`${baseUrl}/${post_company_logo_api_url}`, {
                method: "POST",
                body: formData,
            }).then((res) => res.json());
            if (apiResponse?.message) {
                returnedEditCompanyFormDetails(companyDetails)
                if (apiResponse?.data) {
                    toast.success(apiResponse?.message)
                    // document.getElementById("buttonDismissEditCmpyIdsNewOne").click();
                } else {
                    toast.success(apiResponse?.response?.data.message || "Image uploaded successfully !!")
                }
            } else {
                toast.error(apiResponse?.response?.data?.message + '!!')
            }
        }
    }

    const callUsersData = (ids) => {
        const finalUsersData = returnArryOfObjects(documentDetailsList, ids, 'docId')
        setSelectedCompanyDetailsObjects(finalUsersData)
    }

    const handleUserSelect = (jobData) => {
        if (selectedCompanyDetailsRowList.includes(jobData.docId)) {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => prevSelectedUsers.filter(_id => _id !== jobData.docId));
            callUsersData(selectedCompanyDetailsRowList.filter(_id => _id !== jobData.docId))
        } else {
            setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData.docId]);
            callUsersData([...selectedCompanyDetailsRowList, jobData.docId])
        }
    };

    const isUserSelected = (jobData) => {
        const isUserSelectedVal = selectedCompanyDetailsRowList.includes(jobData.docId);
        return isUserSelectedVal
    };

    const selectAll = () => {
        setSelectedCompanyDetailsRowList([]);
        if (!isAllSelected) {
            documentDetailsList.forEach(jobData => {
                setSelectedCompanyDetailsRowList(prevSelectedUsers => [...prevSelectedUsers, jobData.docId]);
            });
            callUsersData(documentDetailsList.map((item) => item.docId))
        }
        if (isAllSelected) {
            callUsersData([])
        }
        setIsAllSelected(!isAllSelected);
    };

    const getCompanyDocApiCallFun = async (reqData, typeVal) => {
        dispatch(addLoader());
        // const companyDetailsStorage = storePersist.get('companyDetails')
        // console.log("=====yes======", reqData?._id, formData?._id, cmpySelectedVal?._id)
        if (reqData?._id || formData?._id || cmpySelectedVal?._id) {
            let apiReqBody = {
                companyId: reqData?._id || formData?._id || cmpySelectedVal?._id//companyDetailsStorage && companyDetailsStorage[0]?._id
            }
            const apiResponse = await postApi(company_getFileLink, apiReqBody)
            if (apiResponse?.data?.Data) {
                const finalRes = apiResponse?.data?.Data
                setDocumentDetailsList(finalRes || [])
                const resultDat = finalRes?.map((item) => item.docId)
                set_document_list_state(resultDat)
                if (!typeVal) {
                    // toast.success(apiResponse?.response?.data.message || "Successfully retrived details of documents !!")
                }
                dispatch(deleteLoader());
            } else {
                toast.error(apiResponse?.response?.data.message)
                dispatch(deleteLoader());
            }
        }
    };

    const uploadApiFunCall = async (data) => {
        dispatch(addLoader());
        // console.log("==cmpySelectedVal==",cmpySelectedVal)
        // const companyDetailsStorage = storePersist.get('companyDetails')
        const formData = new FormData();
        formData.append("file", data?.file[0]);
        formData.append("companyId", cmpySelectedVal?._id)
        formData.append("fileName", data?.documentName)
        // const fileType = data.cmpyDocumentType === 'SOW Document' ? 'SOW' : data.cmpyDocumentType === 'NDA Document' ? 'NDA' : ''
        // formData.append("fileType", fileType); companyDetailsStorage && companyDetailsStorage[0]
        const baseUrl = 'http://ec2-13-60-203-249.eu-north-1.compute.amazonaws.com:5000'
        try {
            const apiResponse = await fetch(`${baseUrl}/${upload_company_files}`, {
                method: "POST",
                body: formData,
            }).then((res) => res.json());
            if (apiResponse?.message) {
                set_document_list_state([...document_list_state, apiResponse?.data?._id])
                setDocumentDetailsList([...documentDetailsList, { ...apiResponse?.data, docId: apiResponse?.data?._id, fileName: data?.documentName }] || [])
                // getCompanyDocApiCallFun()
                toast.success(apiResponse?.message || "Successfully added company document !!")
                resetDoc()
                dispatch(deleteLoader());
                setCmpyDocumentValue(null)
            }
        } catch (err) {
            if (err === 'TypeError: Failed to fetch') {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            } else {
                toast.error('Failed to upload file, file size is excceded more than 500KB')
            }
            dispatch(deleteLoader());
        }
    };

    const onSubmit = async (data) => {
        setFormData(data);
        setStageCompleted(1)
    };

    const onNextBtnHandler = (value) => {
        setStageCompleted(value)
    }

    const onPreviousBtnHandler = (value) => {
        setStageCompleted(value)
    }

    const errorBorderForSelectBox = (inputReq) => {
        let formatteData = {}
        if (['country', 'companyPhone', 'companyType', 'numberOfEmployee'].includes(inputReq)) {
            return {
                border: '1px solid #bf1650 !important',
                color: '#bf1650!important',
                borderTop: '1px solid #bf1650 !important',
                borderRight: '1px solid #bf1650 !important',
                borderBottom: '1px solid #bf1650 !important',
            }
        } else {
            return formatteData;
        }
    }

    const finishedLaststage = async () => {
        // const { id } = storePersist.get('userData')
        dispatch(addLoader())
        const apiReqBody = {
            companyId: formData?._id || updatedCompanyDetails?.companyDetails?._id,
            companyName: formData?.companyName || updatedCompanyDetails?.companyDetails?.companyName,
            onboardedDate: newDate,
            contactName: formData?.contactName || updatedCompanyDetails?.companyDetails?.contactName,
            companyType: formData?.typeOfCompany || updatedCompanyDetails?.companyDetails?.typeOfCompany,
            companyPhone: Number(formData?.companyPhone) || Number(updatedCompanyDetails?.companyDetails?.companyPhone),
            companyEmail: formData?.businessEmailId || updatedCompanyDetails?.companyDetails?.businessEmailId,
            contractMonths: 12,
            numberOfEmployee: formData?.numberOfEmployees || updatedCompanyDetails?.companyDetails?.numberOfEmployees,
            country: formData?.country || updatedCompanyDetails?.companyDetails?.country,
            totalContractValue: 0,
            aboutCompany: formData?.aboutCompany || updatedCompanyDetails?.companyDetails?.aboutCompany,
            location: formData?.location || updatedCompanyDetails?.companyDetails?.location,
            document_list: document_list_state,
            password: formData?.passwordString || updatedCompanyDetails?.companyDetails?.passwordString,
            oldcompanyEmail: formData?.oldcompanyEmail || updatedCompanyDetails?.companyDetails?.businessEmailId
        }

        const apiResponse = await updateWithOutParamsApi(edit_company_details, apiReqBody)
        if (apiResponse?.data) {
            if (formData?.file?.length > 0) {
                logoUpload(formData, formData)
            }
            toast.success("Successfully updated details !!")
            returnedEditCompanyFormDetails(formData)
            reset();
            reset({});
            setStageCompleted()
            setShowPassword(false)
            setConfShowPassword(false)
            setFormData(false)
            setTypeOfCompanyValue(null)
            setNumberOfEmployeesValue(null)
            setIsSelected({})
            setPhoneValue('')
            setCountrySelectedValue('')
            setTypesOfEmployeeContractValue(null)

            document.getElementById("buttonDismissEditCmpyIdsNewOne").click();

            $('body').removeClass('modal-open')
            $('.modal-backdrop').remove()

            let d = document.getElementById('editCompanyTestIdNewData')
            if (d) {
                d.style.display = "none"
            }

            setTimeout(() => {
                dispatch(deleteLoader())
                document.location.reload();
            }, 3000);

            // $('body').removeClass('modal-open')
            // $('.modal-backdrop').remove()

            // let d = document.getElementById('buttonDismissEditCmpyIdsNewOne')
            // d.style.display = "none"
            // $("#buttonDismissEditCmpyIdsNewOne .close").click()

            // d.close()
            // $('#buttonDismissEditCmpyIdsNewOne').click(function () {
            //     $('#editCompanyTestIdNewData').modal('hide');
            // });
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const nextStage = (value) => {
        setStageCompleted(value)
    }

    const onSubmitDoc = async (data) => {
        setFormDataDoc(data);
        uploadApiFunCall(data)
    };

    const downloadPdf = (downloadPath, name) => {
        toast.success("Successfully downloaded profile !!")
        downloadPDF(downloadPath, name)
    }

    useEffect(() => {
        if (modalPopUpCancel) {
            // window.alert("You have unsaved changes, are you sure you want to leave?")
            resetDoc()
            resetDoc({})
            reset();
            reset({});
            setStageCompleted(null)
            setShowPassword(false)
            setConfShowPassword(false)
            setFormData(false)
            setTypeOfCompanyValue(null)
            setNumberOfEmployeesValue(null)
            setIsSelected({})
            setPhoneValue('')
            setCountrySelectedValue('')
            setTypesOfEmployeeContractValue(null)
            setStageCompleted()
            document.location.reload();
        }
    }, [modalPopUpCancel])

    const deleteFun = () => {
        swal({
            title: "Delete !!",
            text: "Are you sure you want to Delete Document ?",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Deleted!',
                    text: 'Document deleted successfully !',
                    icon: 'success'
                }).then(async function () {
                    dispatch(addLoader());
                    let apiReqBody = {
                        companyId: formData?._id || cmpySelectedVal?._id || (selectedCompanyDetailsObjects?.length > 0 && selectedCompanyDetailsObjects[0]?.companyId), //companyDetailsStorage && companyDetailsStorage[0]?._id
                        documentId: selectedCompanyDetailsObjects?.length > 0 && selectedCompanyDetailsObjects?.map(({ docId }) => docId)
                    }
                    const apiResponse = await postApi(remove_Document_url, apiReqBody)
                    if (apiResponse?.data?.message) {

                        const finalListIds = document_list_state?.filter(function (item) {
                            return !selectedCompanyDetailsRowList?.includes(item);
                        })
                        set_document_list_state(finalListIds)

                        const output = documentDetailsList?.filter(item1 => selectedCompanyDetailsObjects?.some(item2 => item2?.docId !== item1?.docId))

                        if (finalListIds?.length === 0) {
                            setDocumentDetailsList([])
                        } else {
                            setDocumentDetailsList(output)
                        }
                        setSelectedCompanyDetailsRowList([])
                        toast.success('document is deleted successfully') //apiResponse?.data.message + ' !!')
                        // await getCompanyDocApiCallFun()
                        dispatch(deleteLoader());
                    } else {
                        toast.error(apiResponse?.response?.data.message)
                        dispatch(deleteLoader());
                    }
                });
            } else {
                swal("Cancelled", "Your imaginary file is safe :)", "error");
            }
        });
    }

    const onClickRestPassword = async () => {
        const apiReqBody = {
            emailId: formData?.email || formData.businessEmailId || getValues('businessEmailId') || getValues('email'),
        }
        const apiResponse = await postApi(reset_password_details, apiReqBody)
        if (apiResponse?.data?.message) {
            toast.success("Successfully send mail for rest the details!!")
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const validate = (value) => {
        const { file } = getValues()
        if (!file?.length === 0) return true;
        if (file?.length > 0) {
            const acceptedFormats = ['jpg', 'jpeg', 'png'];
            const fileExtension = value[0]?.name?.split('.')?.pop()?.toLowerCase();
            if (!acceptedFormats.includes(fileExtension)) {
                return 'Invalid file format. Only jpg, jpeg, png files are allowed.';
            }
            return true;
        }
    };

    $('#file_upload_4').on('change', function () {
        var i = $(this).prev('label').clone();
        var file = $('#file_upload_4')[0]?.files[0]?.name;
        $(this).prev('label')?.text(file);
    });

    // console.log("====123====", formData, formData?.file, getValues('file'), formData?.file[0]?.name)
    // if (getValues('file')?.length > 0 && formData?.file?.length > 0) {
    //     $(this)?.prev('label')?.text(formData?.file[0]?.name);
    //     $('#file-upload').attr("id", formData?.file[0]?.name);
    // }

    $('#button').on("click", function (event) {
        $("input[type='file']").trigger('click');
    })

    $("input[type='file']").on('change', function () {
        $('#val').text(this.value.replace(/C:\\fakepath\\/i, ''))
        $('.customform-control').hide();
    }).trigger('change');

    // console.log("==============",getValues(),
    // (selectedCompanyDetailsObject?.length > 0 && selectedCompanyDetailsObject[0]) ,
    // (selectedCompanyDetailsObject && selectedCompanyDetailsObject) ,
    // (updatedCompanyDetails && updatedCompanyDetails?.companyDetails))

    return (
        <div className="form-holder pt-4 ps-8 pe-8 pb-0 main_bg rounded-0">
            <div class="stepper-wrapper">
                <div class={`stepper-item ${!stageCompleted ? 'active' : [1, 2].includes(stageCompleted) && 'completed'}`}>
                    <div class="step-counter">1</div>
                    <div class="step-name">Create Account</div>
                </div>
                <div class={`stepper-item ${stageCompleted === 1 ? 'active' : [1, 2].includes(stageCompleted) && 'completed'}`}>
                    <div class="step-counter">2</div>
                    <div class="step-name">Upload Documents</div>
                </div>
                <div class={`stepper-item ${stageCompleted === 2 ? 'active' : [2].includes(stageCompleted) && 'completed'}`}>
                    <div class="step-counter">3</div>
                    <div class="step-name">Summary</div>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                {!stageCompleted &&
                    <>
                        <div className="my-3">
                            <img alt="Alt content" src={require('../../../assets/images/account-details-AM.svg').default} className="img_w_h_18" />
                            <span className="mx-2 fw-bolded font_size_14">Company Details</span>
                        </div>
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors?.companyName && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    type="text"
                                    name="companyName"
                                    placeholder=""
                                    {...register("companyName", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9._-\s]+$/,
                                            message: "Allow only alphabets"
                                        }
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Name'}</label>
                            </div>
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors?.contactName && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input" //mb-4 font_size_14
                                    type="text"
                                    name="contactName"
                                    placeholder=""
                                    {...register("contactName", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Contact person name'}</label>
                            </div>
                        </div>
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors?.businessEmailId && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input" //mb-4 font_size_14
                                    type="email"
                                    name="businessEmailId"
                                    placeholder=""
                                    {...register("businessEmailId", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                            message: "Invalid email address, It should be example@google.com"
                                        },
                                        onChange: (e) => {
                                            reset({ ...getValues(), email: e?.target?.value })
                                        }
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Business Email ID'}</label>
                            </div>
                            <div className={`col-md-3_5 marginbottom_int did-floating-label-content ${errors.file && 'did-error-input'}`}>
                                {/* <form className="d-flex border_c justify-content-between align-items-center bg-white rounded">
                                    <div>
                                        <label for="file_upload_4" className={`custom-file-upload color_dull_1 font_size_14 position-absolute border-top-0 border-start-0 border-bottom-0 w-85 ${errors?.file && 'error_border'}`}>
                                            {formData?.file && formData?.file?.length > 0 ? formData?.file[0]?.name : 'Upload business logo'}
                                        </label>
                                        <input
                                            className={`did-floating-input w-85 border-bottom-0 ${errors?.file && 'error_border'}`} //mb-4 font_size_14
                                            type="file"
                                            name="file"
                                            accept=".jpg, .jpeg, .png"
                                            placeholder=""
                                            {...register("file", {
                                                required: false,
                                                validate
                                            })}
                                            style={{ paddingTop: '7px' }}
                                            id="file_upload_4"
                                        />
                                    </div>
                                    <i class="fa fa-cloud-upload font_size_24 me-1"></i>
                                </form> */}

                                <div className="yes_main_div">
                                    {/* <label for="user_audio" class="customform-control">
                                        {formData?.file && formData?.file?.length > 0 ? formData?.file[0]?.name : 'Upload business logo'}
                                    </label> */}
                                    <input
                                        type='file'
                                        placeholder="Upload business logo"
                                        id="user_audio"
                                        className="input_type_file"
                                        name="file"
                                        accept=".jpg, .jpeg, .png"
                                        {...register("file", {
                                            required: false,
                                            validate
                                        })}
                                    />
                                    <span id='val'></span>
                                    <span id='button'><i className="fa fa-cloud-upload"></i> </span>
                                </div>
                                {errors.file && <p className="error">{errors.file.message}</p>}
                            </div>
                            <div className="col-md-2_4 marginbottom_int did-floating-label-content">
                                {cmpySelectedVal &&
                                    <div className="border_c main_bg_white rounded ps-2 pe-1 d-flex justify-content-between align-items-center">
                                        <label className="border-end divider_w_h_100 d-flex justify-content-between align-items-center pe-2 font_size_12">Existed</label>
                                        <span
                                            className="d-flex justify-content-start align-items-center font_color_ornage hover_underline"
                                            onClick={() => {
                                                downloadPdf(cmpySelectedVal && cmpySelectedVal?.logo, cmpySelectedVal && cmpySelectedVal?.companyName)
                                            }}
                                        >
                                            <img
                                                src={cmpySelectedVal && cmpySelectedVal?.logo}
                                                alt="Sow"
                                                className="img_w_h_100"
                                            />
                                            <div
                                                className="fw-medium font_size_12 border-1"
                                            >
                                                {/* {cmpySelectedVal && cmpySelectedVal?.logo} */}
                                            </div>
                                        </span>
                                    </div>
                                }
                            </div>

                            {/* <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors?.contractType && 'did-error-input'}`}>
                                <Controller
                                    name="contractType"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            // isClearable
                                            options={typesOfEmployeeContractList}
                                            value={typesOfEmployeeContractList.find((c) => c.value === value)}
                                            onChange={(val) => onChange(val.value)}
                                            defaultValue={typesOfEmployeeContractList.find((c) => c.value === typesOfEmployeeContractValue)}
                                            isSearchable={true}
                                            placeholder="What kind of contract are you looking for ?"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'contractType': true }) }}
                                            styles={{
                                                // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                                control: (styles) => ({
                                                    ...styles,
                                                    ...errorBorderForSelectBox(errors?.contractType && 'contractType'),
                                                }),
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    // marginTop: 22,
                                                    zIndex: 99
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    overflow: "visible"
                                                }),
                                                placeholder: (provided, state) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                    background: "white",
                                                    transition: "top 0.1s, font-size 0.1s",
                                                    fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                    fontWeight: 100,
                                                    padding: '0.3px 8.5px',
                                                    borderRadius: '8px',
                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                    border: isSelected['contractType'] ? '1px solid #cccccc' : ''
                                                })
                                            }} />
                                    )}
                                    rules={{ required: "Field is required" }}
                                />
                            </div> */}
                        </div>
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int}`}>
                                <Controller
                                    name="companyType"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            // isClearable
                                            options={typeOfCompanyList}
                                            value={typeOfCompanyList.find((c) => c.value === value)}
                                            onChange={(val) => onChange(val?.value)}
                                            defaultValue={typeOfCompanyList.find((c) => c.value === typeOfCompanyValue)}
                                            isSearchable={true}
                                            placeholder="Type of company"
                                            className="basic-single my-react-select"
                                            classNamePrefix="select"
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'companyType': true }) }}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    ...errorBorderForSelectBox(errors?.companyType && 'companyType'),
                                                }),
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    marginTop: 5,
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    overflow: "visible"
                                                }),
                                                placeholder: (provided, state) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                    background: "white",
                                                    transition: "top 0.1s, font-size 0.1s",
                                                    fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                    fontWeight: 100,
                                                    padding: '0.3px 8.5px',
                                                    borderRadius: '8px',
                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                    border: isSelected['companyType'] ? '1px solid #cccccc' : ''
                                                })
                                            }}
                                        />
                                    )}
                                    rules={{ required: false }}
                                />
                            </div>

                            <div className={`col-md-6 marginbottom_int'}`}>
                                <Controller
                                    name="numberOfEmployee"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            // isClearable
                                            options={numberOfEmployeesList}
                                            value={numberOfEmployeesList.find((c) => c.value === value)}
                                            onChange={(val) => onChange(val.value)}
                                            defaultValue={numberOfEmployeesList.find((c) => c.value === numberOfEmployeesValue)}
                                            isSearchable={true}
                                            placeholder="Number of employees"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                            onMenuClose={() => { value && setIsSelected({ ...isSelected, 'numberOfEmployee': true }) }}
                                            styles={{
                                                // control: (styles) => ({ ...styles, borderColor: '1px solid #3D85D8' }),
                                                control: (styles) => ({
                                                    ...styles,
                                                    ...errorBorderForSelectBox(errors?.numberOfEmployee && 'numberOfEmployee'),
                                                }),
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    marginTop: 5,
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    overflow: "visible"
                                                }),
                                                placeholder: (provided, state) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                    background: "white",
                                                    transition: "top 0.1s, font-size 0.1s",
                                                    fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                    fontWeight: 100,
                                                    padding: '0.3px 8.5px',
                                                    borderRadius: '8px',
                                                    color: 'rgba(6, 44, 80, 0.7);',
                                                    border: isSelected['numberOfEmployee'] ? '1px solid #cccccc' : ''
                                                })
                                            }} />
                                    )}
                                    rules={{ required: false }}
                                />
                            </div>
                        </div>

                        <div className="form-outline row gx-3 mt-4">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors?.aboutCompany && 'did-error-input'}`}>
                                <textarea
                                    className="did-floating-input w-100 rounded-3 h-100 pt-3 text_area_border"
                                    name="aboutCompany"
                                    placeholder=''
                                    rows="5" cols="33"
                                    {...register("aboutCompany", {
                                        required: false,
                                        minLength: {
                                            value: 1,
                                            message: "Min length is 1"
                                        },
                                        maxlength: 3000,
                                        minlength: 1
                                    })}
                                />
                                <label className="did-floating-label">{'About'}</label>
                            </div>

                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors?.location && 'did-error-input'}`}>
                                <textarea
                                    className="did-floating-input w-100 rounded-3 h-100 pt-3 text_area_border"
                                    name="location"
                                    placeholder=''
                                    rows="5" cols="33"
                                    {...register("location", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Location'}</label>
                            </div>
                        </div>

                        {/* <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content'}`}>
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <div
                                            // className="countryFlag"
                                            style={{ display: "flex", alignItems: "center", width: "100%" }}
                                        >
                                            <ReactCountryFlag
                                                countryCode={
                                                    countrySelectedValue ? countrySelectedValue : ""
                                                }
                                                svg
                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                cdnSuffix="svg"
                                                title={countrySelectedValue ? countrySelectedValue : ""}
                                                className={`countryFlag ${errors?.country && 'countryFlag_error'}`}
                                            // style={{ border: '1px solid red' }}
                                            />
                                            <div
                                                style={{ color: "black", width: "100%" }}
                                            >
                                                <Select
                                                    isSearchable={true}
                                                    options={countryOptions}
                                                    value={countryOptions.find((c) => c.value === value)}
                                                    // onChange={this.changeHandler}
                                                    // value={typeOfCompanyList.find((c) => c.value === value)}
                                                    onChange={(val) => { onChange(val?.value); setCountrySelectedValue(val?.value) }}
                                                    defaultValue={countryOptions.find((c) => c.value === countrySelectedValue)}
                                                    placeholder="Country"
                                                    className="basic-single my-react-select"
                                                    classNamePrefix="select"
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    onMenuClose={() => { value && setIsSelected({ ...isSelected, 'country': true }) }}
                                                    styles={{
                                                        control: (styles) => ({
                                                            ...styles,
                                                            borderRadius: '2px',
                                                            ...errorBorderForSelectBox(errors?.country && 'country'),
                                                            minHeight: !countrySelectedValue ? '40px' : '38px'
                                                        }),
                                                        container: (provided, state) => ({
                                                            ...provided,
                                                            marginTop: 2,
                                                        }),
                                                        valueContainer: (provided, state) => ({
                                                            ...provided,
                                                            overflow: "visible"
                                                        }),
                                                        placeholder: (provided, state) => ({
                                                            ...provided,
                                                            position: "absolute",
                                                            top: state.hasValue || state.selectProps.inputValue ? -18 : "10%",
                                                            background: "white",
                                                            transition: "top 0.1s, font-size 0.1s",
                                                            fontSize: 14, //(state.hasValue || state.selectProps.inputValue) &&
                                                            fontWeight: 100,
                                                            padding: '0.3px 8.5px',
                                                            borderRadius: '8px',
                                                            color: 'rgba(6, 44, 80, 0.7);',
                                                            border: isSelected['country'] ? '1px solid #cccccc' : ''
                                                        }),
                                                        option: (base, state) => ({
                                                            ...base,
                                                            fontSize: 14,
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    rules={{ required: "Field is required" }}
                                />
                            </div>
                            <div className="col-md-6 marginbottom_int did-floating-label-content">
                                <Controller
                                    render={(props) => (
                                        <PhoneInput
                                            placeholder="Phone number"
                                            country={countrySelectedValue?.toLowerCase()}
                                            id="companyPhone"
                                            name="companyPhone"
                                            onChange={(e) => {
                                                props.field.onChange(e); setPhoneValue(e)
                                            }}
                                            value={props.field.value}
                                            inputClass={errors.companyPhone ? 'error_telphone' : ''}
                                            containerClass={errors.companyPhone ? 'error_telphone_container' : ''}
                                        />
                                    )}
                                    defaultValue=""
                                    name="companyPhone"
                                    control={control}
                                    rules={{ required: "Field is required" }}
                                />
                            </div>
                        </div> */}

                        <div className="my-3">
                            <img alt="Alt content" src={require('../../../assets/images/account-password-AM.svg').default} className="img_w_h_23" />
                            <span className="mx-2 fw-bolded font_size_14">Generate User Name / Password</span>
                        </div>

                        <div className="form-outline row gx-3 d-flex justify-content-start align-items-baseline">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.email && 'did-error-input'}`}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder=""
                                    {...register("email", {
                                        // required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                            message: "Invalid email address, It should be example@google.com"
                                        },

                                    })}
                                    className="did-floating-input"
                                />
                                <label className="did-floating-label requiredTrue">{'Enter username'}</label>
                            </div>
                            {role === 'ADMIN'
                                ?
                                <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.passwordString && 'did-error-input'}`}>
                                    <input
                                        type={showPassword ? 'text' : "password"}
                                        name="passwordString"
                                        className="did-floating-input me-2"
                                        placeholder=""
                                        {...register("passwordString", {
                                            required: false,
                                            minLength: {
                                                value: 6,
                                                message: "Min length is 6"
                                            },
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                                message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
                                            },
                                            onChange: (e) => {
                                                reset({ ...getValues(), passwordString: e?.target?.value })
                                            }
                                        })}
                                    />
                                    {showPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                    {!showPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                    <label className="did-floating-label">{'Enter the password'}</label>
                                    {errors.passwordString && <p className="error">{errors.passwordString.message}</p>}
                                </div>
                                :
                                <div className="col-md-6">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn_border pb-3 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                        onClick={() => { onClickRestPassword() }}
                                    >
                                        Reset Password
                                        <i className="fa fa-angle-right ps-2"></i>
                                    </button>
                                </div>
                            }
                        </div>
                        {role === 'ADMIN' &&
                            <div className="d-flex justify-content-center align-items-baseline pb-3">OR</div>
                        }
                        {role === 'ADMIN' &&
                            <div className="form-outline d-flex justify-content-center align-items-baseline">
                                <div className="col-md-4"></div>
                                <div className="col-md-4 row gx-5">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn_border pb-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                        onClick={() => { onClickRestPassword() }}
                                    >
                                        <i className="fa fa-angle-left pe-2"></i>
                                        Reset Password
                                        <i className="fa fa-angle-right ps-2"></i>
                                    </button>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        }
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.password && 'did-error-input'}`}>
                                {/* <input
                                    type={showPassword ? 'text' : "password"}
                                    name="password"
                                    className="did-floating-input me-2"
                                    placeholder=""
                                    {...register("password", {
                                        required: false,
                                        minLength: {
                                            value: 6,
                                            message: "Min length is 6"
                                        },
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                            message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
                                        },
                                    })}
                                />
                                {showPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                {!showPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                <label className="did-floating-label">{'Enter the password'}</label>
                                {errors.password && <p className="error">{errors.password.message}</p>} */}
                            </div>
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.confirmPassword && 'did-error-input'}`}>
                                {/* <button
                                    type="button"
                                    className="btn btn-primary btn_border pb-3 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                    onClick={() => { onClickRestPassword() }}
                                >
                                    <i className="fa fa-angle-left pe-2"></i>
                                    Reset Password
                                </button> */}
                                {/* <input
                                    type={confShowPassword ? 'text' : "password"}
                                    name="confirmPassword"
                                    className="did-floating-input pe-2 me-2"
                                    placeholder=""

                                    {...register("confirmPassword", {
                                        validate: (value) => {
                                            const { password } = getValues();
                                            return password === value || "Passwords should match!";
                                        },
                                        required: false,
                                    })}
                                />
                                {confShowPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye pe-2 me-2" onClick={() => { setConfShowPassword(!confShowPassword) }}></span>}
                                {!confShowPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye pe-2 me-2" onClick={() => { setConfShowPassword(!confShowPassword) }}></span>}
                                <label className="did-floating-label">{'Enter the confirmPassword'}</label>
                                {errors.confirmPassword && <p className="error">{errors.confirmPassword.message}</p>} */}
                            </div>
                        </div>
                    </>
                }

                {stageCompleted === 2 &&
                    <section class="py-1">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Company Details
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body ms-5">
                                                    <div class="row">
                                                        <div className="col-md-12 d-flex align-content-center flex-wrap pb-2">
                                                            {companyMasterData.map((item, idx) => {
                                                                return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2" key={idx}>
                                                                    <div className="col-xs-6 me-2">
                                                                        <img
                                                                            src={require(`../../../assets/images/${item.img}`)}
                                                                            alt="jobType"
                                                                            className="img_w_h_11"
                                                                        />
                                                                    </div>
                                                                    <div className="col-xs-6">
                                                                        <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                                        {item?.label === 'Number of employees'
                                                                            ?
                                                                            <div className="fw-medium font_size_14">
                                                                                {
                                                                                    getValues()['numberOfEmployee'] ||
                                                                                    (selectedCompanyDetailsObject?.length > 0 && selectedCompanyDetailsObject[0]['numberOfEmployee']) ||
                                                                                    (selectedCompanyDetailsObject && selectedCompanyDetailsObject['numberOfEmployee']) ||
                                                                                    (updatedCompanyDetails && updatedCompanyDetails?.companyDetails['numberOfEmployee'])
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className="fw-medium font_size_14">
                                                                                {
                                                                                    getValues()[item.response] ||
                                                                                    (selectedCompanyDetailsObject?.length > 0 && selectedCompanyDetailsObject[0][item.response]) ||
                                                                                    (selectedCompanyDetailsObject && selectedCompanyDetailsObject[item.response]) ||
                                                                                    (updatedCompanyDetails && updatedCompanyDetails?.companyDetails[item.response])
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>)
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    User Name Details
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body ms-5">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div className="col-md-12 d-flex align-content-center flex-wrap pb-2">
                                                                {userDetailsMasterData.map((item, idx) => {
                                                                    return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2" key={idx}>
                                                                        {/* {
                                                                            (role === 'COMPANY' || role === 'ACTMGR') && item.label === 'Password'
                                                                                ?
                                                                                ''
                                                                                : <> */}
                                                                        <div className="col-xs-6 me-2">
                                                                            <img
                                                                                src={require(`../../../assets/images/${item.img}`)}
                                                                                alt="jobType"
                                                                                className="img_w_h_11"
                                                                            />
                                                                        </div>
                                                                        <div className="col-xs-6">
                                                                            <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                                            <div className="fw-medium font_size_14">{
                                                                                getValues()[item.response] ||
                                                                                (selectedCompanyDetailsObject?.length > 0 && selectedCompanyDetailsObject[0][item.response]) ||
                                                                                (selectedCompanyDetailsObject && selectedCompanyDetailsObject[item.response]) ||
                                                                                (updatedCompanyDetails && updatedCompanyDetails?.companyDetails[item.response])}
                                                                            </div>
                                                                        </div>
                                                                        {/* </>
                                                                        } */}
                                                                    </div>)
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2 ms-4">
                                                        {/* <div className="col-xs-6 me-2">
                                                            <input type="checkbox" name="" />
                                                        </div>
                                                        <div className="col-xs-6">
                                                            <div className="font_size_14">{'Allow Change password for first time login'}</div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                <div className={`border-top bottom-0 footer py-3 main_bg text-center d-flex justify-content-between ${stageCompleted === 1 && 'company_upload_doc_section'}`}>

                    {!stageCompleted &&
                        <>
                            <div></div>
                            <button type="submit"
                                className="btn btn-primary pb-2 font_size_14 btn_height_36 padding_top_6 mt-0 mb-0 pb-2" onClick={errorHandler}>
                                {isSubmitting && <span>Submitting</span>}
                                {!isSubmitting && <span>Next</span>}
                            </button>
                        </>
                    }
                    {stageCompleted === 1 &&
                        <>
                            <button
                                type="button"
                                className="btn btn-primary btn_border pb-3 pe-3 ps-3 font_size_14 fw-medium btn_height_36 padding_top_6"
                                onClick={() => { onPreviousBtnHandler(null) }}
                            >
                                <i className="fa fa-angle-left pe-2"></i>
                                Previous
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary bg_color_ornage btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 padding_top_6"
                                onClick={() => { nextStage(2) }}
                            >
                                Next
                                <i className="fa fa-angle-right ps-2"></i>
                            </button>
                        </>
                    }
                    {stageCompleted === 2 &&
                        <>
                            <button
                                type="button"
                                className="btn btn-primary btn_border pb-3 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                onClick={() => { onPreviousBtnHandler(1) }}
                            >
                                <i className="fa fa-angle-left pe-2"></i>
                                Previous
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary bg_color_ornage btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 padding_top_6"
                                onClick={() => { finishedLaststage() }}
                            >
                                Finish
                                <i className="fa fa-angle-right ps-2"></i>
                            </button>
                        </>
                    }
                </div>
            </form>
            {stageCompleted === 1 &&
                <>
                    <section class="py-1">
                        <form onSubmit={handleSubmitDoc(onSubmitDoc)}>
                            <div className="form-outline row gx-3 mt-4 gap-3">
                                <div className={`col-md-2`}></div>
                                <div className={`col-md-3 marginbottom_int did-floating-label-content ${errorsDoc.documentName && 'did-error-input'}`}>
                                    <input
                                        className="did-floating-input"
                                        type="text"
                                        name="documentName"
                                        placeholder=''
                                        rows="5" cols="33"
                                        {...registerDoc("documentName", {
                                            required: "Field is required",
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 2"
                                            },
                                        })}
                                    />
                                    <label className="did-floating-label requiredTrue">{'Name Documents'}</label>
                                </div>

                                <div className={`col-md-3 marginbottom_int did-floating-label-content ${errorsDoc.file && 'did-error-input'}`}>
                                    <>
                                        <input
                                            className="did-floating-input" //mb-4 font_size_14
                                            type="file"
                                            name="file"
                                            accept=".pdf"
                                            placeholder=""
                                            {...registerDoc("file", {
                                                required: "File is required",
                                                validate: (value) => {
                                                    const acceptedFormats = ['pdf'];
                                                    const fileExtension = value[0]?.name.split('.').pop().toLowerCase();
                                                    if (!acceptedFormats.includes(fileExtension)) {
                                                        return 'Invalid file format. Only PDF files are allowed.';
                                                    }
                                                    return true;
                                                }
                                            })}
                                            style={{ paddingTop: '7px' }}
                                        />
                                        <span className="btn btn-primary upload_file_css font_size_14 rounded-0 rounded-end pb-0_6">
                                            <img
                                                src={require(`../../../assets/images/upload_file.svg`).default}
                                                alt="upload file"
                                                className="img_w_h_18"
                                            />
                                        </span>
                                    </>
                                    <label className="did-floating-label requiredTrue">{'Upload Document'}</label>
                                </div>
                                <div className={`col-md-3`}>
                                    <button type="submit"
                                        className="btn btn-primary ps-4 pe-4 ms-4 font_size_14 btn_height_36 padding_top_6 mt-2 mb-0 pb-2" onClick={errorHandlerDoc}>
                                        {isSubmittingDoc && <span>Submitting</span>}
                                        {!isSubmittingDoc && <span>Add</span>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </section>
                    <div>
                        <div className="row bd-example">
                            <div className="d-flex justify-content-end align-items-center border-bottom pt-2">
                                <div className="d-flex gap-3 margin_top_neg_32 me-2 pt-4 pb-2">
                                    {/* <button
                                        type="button"
                                        className="btn btn-outline-primary btn_border pb-2 pe-4 ps-4 font_size_14 fw-medium btn_height_36 padding_top_6"
                                        disabled={selectedCompanyDetailsRowList.length > 0 && selectedCompanyDetailsRowList.length < 2 ? false : true}
                                        data-bs-target="#editCompanyTestId"
                                        data-bs-toggle="modal"
                                    // onClick={editAccountManagerModal}
                                    >
                                        Edit
                                    </button> */}
                                    <button
                                        type="button"
                                        className="btn btn-primary bg_color_ornage pb-2 font_size_14 fw-medium btn_height_36 padding_top_6"
                                        disabled={(selectedCompanyDetailsRowList.length > 0) ? false : true}
                                        onClick={deleteFun}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                            <div className="table-container">
                                <div className="d-flex text-muted">
                                    <PerfectScrollbar className="sidebar-items doc_table_height w-100 pt-0 pb-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input className="width_12 mb-0" type="checkbox" checked={isAllSelected} onChange={selectAll} />
                                                    </th>
                                                    {['Name', 'Date Uploaded', 'Document', 'Download'].
                                                        map((item, idx) =>
                                                            <th key={idx} className={`text-white`}>
                                                                {item}
                                                            </th>
                                                        )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {documentDetailsList?.length > 0 && documentDetailsList?.map((item, idx) => {
                                                    return (
                                                        <tr key={item._id}>
                                                            <td>
                                                                <input
                                                                    className="width_12"
                                                                    type="checkbox"
                                                                    checked={isUserSelected(item)}
                                                                    onChange={() => handleUserSelect(item)}
                                                                />
                                                            </td>
                                                            <td>{item?.fileName}</td>
                                                            <td>{(item?.createdAt && new Date(item?.createdAt)?.toLocaleDateString()?.replace(/\//g, '-'))}</td>
                                                            <td>
                                                                <span className="btn_border p-1 pe-3">
                                                                    {/* <i class="fa fa-file-pdf-o pe-2 ps-2" style={{ color: "red" }} aria-hidden="true" />{item?.fileName && item?.fileName?.split('.')[0]} */}
                                                                    <i className="fa fa-file-pdf-o pe-2 ps-2" style={{ color: "red" }} aria-hidden="true" />{item?.fileLocation && new URL(item?.fileLocation)?.pathname.split("/")?.pop()?.split('%20')?.join(' ')}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="btn btn-outline-primary btn_border pb-2 font_size_14 fw-medium btn_height_36 mt-1 padding_top_6 orange_color border-0"
                                                                    onClick={() => { downloadPdf(item?.fileLocation, item?.fileName) }}
                                                                >
                                                                    Download
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <button type="button" className="d-none mt-0 mb-0 pb-0" id="buttonDismissEditCmpyIdsNewOne" data-bs-dismiss="modal"></button>
        </div>
    );
}

export default EditCompanyDetailsComponent

const companyMasterData = [
    { label: 'Company name', response: 'companyName', value: 'Remote', img: 'ellipse_icon.svg' },
    { label: 'Contact person name', response: 'contactName', value: '1234', img: 'ellipse_icon.svg' },
    { label: 'Business Email ID', response: "businessEmailId", value: '5-10 years', img: 'ellipse_icon.svg' },
    // { label: "Type of employee contract", response: "contractType", value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'country', response: 'country', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Phone number', response: 'companyPhone', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Number of employees', response: 'numberOfEmployees', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'About', response: 'aboutCompany', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Location', response: 'location', value: 'full time', img: 'ellipse_icon.svg' },
]

const userDetailsMasterData = [
    { label: 'User name', response: 'email', value: 'Remote', img: 'ellipse_icon.svg' },
    // { label: 'Created', response: 'created', value: '1234', img: 'ellipse_icon.svg' },
    { label: 'Password', response: 'passwordString', value: '5-10 years', img: 'ellipse_icon.svg' },
]