import React, { useState } from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { isEmpty } from '../../../Utils/utils'
import { useDispatch, useSelector } from "react-redux";
import { create_account_managers, assign_acct_mgr_api_url, get_all_companies_list_api_url, unassignAcctMgr_url, reset_password_details } from '../../../services/endPointsConstant'
import { edit_Acct_Manager } from '../../../services/endPointsConstant'
import storePersist from '../../../Utils/storePersist'
import { useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import AssignedCompaniesList from './AssignCompaniesList'
import { postApi, deleteApiWithBody, updateWithOutParamsApi, getAllApi } from '../../../services/apiCommonService'
import { addLoader, deleteLoader } from "../../../redux/actions/loader";

const EditAccountManager = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { role } = storePersist.get('userData')
    const d = new Date()
    const newDate = d.toISOString().substring(0, 10);
    const { modalPopUpCancel, selectedAccountManagerObject, returnEditJobRequirementFormDetails, returnAddAccountManagerFormDetails } = props
    const [showPassword, setShowPassword] = useState(false);
    const [confShowPassword, setConfShowPassword] = useState(false);
    const [formData, setFormData] = useState(false);
    const [stageCompleted, setStageCompleted] = useState(null)
    const [assignedCmpyListStateValue, setAssignedCmpyListStateValue] = useState([])
    const [assignedCmpyListStateValueObjects, setAssignedCmpyListStateValueObjects] = useState([])
    const [isSelectedNew, setIsSelectedNew] = useState(false)

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        control,
        reset,
        getValues
    } = useForm({
        mode: "onBlur",
        shouldFocusError: false
    });

    useEffect(() => {
        if (selectedAccountManagerObject.length > 0) {
            reset({ ...selectedAccountManagerObject[0], oldemailId: selectedAccountManagerObject[0]?.emailId });
            setFormData({ ...selectedAccountManagerObject[0], oldemailId: selectedAccountManagerObject[0]?.emailId })
        }
        setStageCompleted(null)
    }, [selectedAccountManagerObject])

    useEffect(() => {
        if (selectedAccountManagerObject.length > 0) {
            reset({ ...selectedAccountManagerObject[0], oldemailId: selectedAccountManagerObject[0]?.emailId });
            setFormData({ ...selectedAccountManagerObject[0], oldemailId: selectedAccountManagerObject[0]?.emailId })
        }
        setStageCompleted(null)
    }, [])

    useEffect(() => {
        if (modalPopUpCancel) {
            reset();
            reset({});
            reset(null);
            setStageCompleted(null)
            setStageCompleted()
            window.sessionStorage.removeItem('selectedCompanyListObjects')
            window.sessionStorage.removeItem('selectedCompanyListValues')
            document.location.reload();
        }
    }, [modalPopUpCancel])

    const errorHandler = () => {
        if (!isEmpty(errors)) {
            toast.error("Fill all manditatory fields")
            return
        }
    }

    const onSubmit = async (data) => {
        setFormData(data);
        setStageCompleted(1)
    };

    const assignCompanies = async (accMangrDetails, userData, formData) => {
        let extractList = window.sessionStorage.getItem('selectedCompanyListValues')
            ?
            JSON.parse(window.sessionStorage.getItem('selectedCompanyListValues'))
            :
            assignedCmpyListStateValue
        const apiReqBody = {
            companyId: extractList,
            actManagerId: accMangrDetails?.actManagerId || accMangrDetails._id,
            // userid: userData?.userid
        }
        const apiResponse = await postApi(assign_acct_mgr_api_url, apiReqBody)
        if (apiResponse?.data?.message) {
            toast.success("Successfully Added details !!")
            returnAddAccountManagerFormDetails(accMangrDetails)
            // document.getElementById("viewAccountManagerButtonDismissId").click();
            // reset();

            const datafinal = await getAllCompaniesListApi()
            void unAssignCompanies(datafinal, formData, formData)
            returnAddAccountManagerFormDetails(formData)
            document.getElementById("viewAccountManagerButtonDismissId").click();
            reset();
            reset({});
            setStageCompleted(null)
            setTimeout(() => {
                window.sessionStorage.removeItem('selectedCompanyListObjects')
                window.sessionStorage.removeItem('selectedCompanyListValues')
                document.location.reload()
            }, 5000);

        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const getAllCompaniesListApi = async () => {
        dispatch(addLoader());
        const apiResponse = await getAllApi(get_all_companies_list_api_url)
        if (apiResponse?.data?.Data) {
            const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
            dispatch(deleteLoader());
            return apiResponseData
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
            dispatch(deleteLoader());
        }
    }

    const unAssignCompanies = async (companiesList, accMangrDetails, userData) => {
        const cmpList = companiesList?.map((item) => item._id) || []
        const apiReqBody = {
            companyId: cmpList?.filter(e => assignedCmpyListStateValue.indexOf(e) > -1 ? false : true),
            actManagerId: accMangrDetails?.actManagerId || accMangrDetails._id,
        }
        const apiResponse = await postApi(unassignAcctMgr_url, apiReqBody)
        if (apiResponse?.data?.message) {
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const finalStageCompleted = async () => {
        const apiReqBody = {
            acctManagerId: selectedAccountManagerObject[0]?.actManagerId,
            name: formData?.name,
            emailId: formData?.emailId,
            dob: formData?.dob,
            phoneNumber: formData?.phoneNumber,
            location: formData?.location,
            joiningDate: formData?.joiningDate,
            userEmail: formData?.userEmail,
            password: formData?.passwordString,
            oldemailId: formData?.oldemailId
        }
        const apiResponse = await updateWithOutParamsApi(edit_Acct_Manager, apiReqBody)
        if (apiResponse?.data) {
            toast.success("Successfully updated details !!")
            // assignCompanies(apiResponse?.data?.data, apiResponse?.data?.userCreatedData)
            // returnAddAccountManagerFormDetails(apiResponse?.data?.data)
            assignCompanies(formData, formData, formData)

        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    const onNextBtnHandler = (value) => {
        setStageCompleted(value)
    }

    const onPreviousBtnHandler = (value) => {
        setStageCompleted(value)
    }

    const onClickAssignCmpyHandlerFun = (item) => {
        toast.success("Successfully company assigned !!")
    }

    const returnAssignedCmpyListFun = (assignedCmpyList, object) => {
        setAssignedCmpyListStateValue(assignedCmpyList)
        let extractList = window.sessionStorage.getItem('selectedCompanyListValues')
            ?
            JSON.parse(window.sessionStorage.getItem('selectedCompanyListValues'))
            :
            []
        setAssignedCmpyListStateValueObjects(object)
        setIsSelectedNew(true)
    }



    const onClickRestPassword = async () => {
        const apiReqBody = {
            emailId: formData?.email || formData.emailId,
        }
        const apiResponse = await postApi(reset_password_details, apiReqBody)
        if (apiResponse?.data?.message) {
            toast.success("Successfully send mail for rest the details!!")
        } else {
            toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
        }
    }

    return (
        <div className="form-holder pt-4 ps-8 pe-8 pb-0 main_bg rounded-0">

            <div class="stepper-wrapper">
                <div class={`stepper-item ${!stageCompleted ? 'active' : [1, 2].includes(stageCompleted) && 'completed'}`}>
                    <div class="step-counter">1</div>
                    <div class="step-name">Create Account</div>
                </div>
                <div class={`stepper-item ${stageCompleted === 1 ? 'active' : stageCompleted === 2 && 'completed'}`}>
                    <div class="step-counter">2</div>
                    <div class="step-name">Assign Companies</div>
                </div>
                <div class="stepper-item">
                    <div class="step-counter">3</div>
                    <div class="step-name">Summary</div>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                {!stageCompleted &&
                    <>
                        <div className="my-3">
                            <img alt="Alt content" src={require('../../../assets/images/account-details-AM.svg').default} className="img_w_h_18" />
                            <span className="mx-2 fw-bolded font_size_14">Account Manger Details</span>
                        </div>

                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.name && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    type="text"
                                    name="name"
                                    placeholder=""
                                    {...register("name", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9._-\s]+$/,
                                            message: "Allow only alphabets"
                                        }
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Name'}</label>
                            </div>
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.emailId && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input" //mb-4 font_size_14
                                    type="email"
                                    name="emailId"
                                    placeholder=""
                                    {...register("emailId", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                            message: "Invalid email address, It should be example@google.com"
                                        },
                                        onChange: (e) => {
                                            reset({ ...getValues(), email: e?.target?.value })
                                        }
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Email'}</label>
                            </div>
                        </div>
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.dob && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    name="dob"
                                    type="date"
                                    placeholder=""
                                    {...register("dob", {
                                        required: false,

                                    })}
                                />
                                {/* <Controller
                                    control={control}
                                    name="dob"
                                    rules={{ required: "Field is required" }} //optional
                                    render={({
                                        field: { onChange, name, value },
                                        fieldState: { invalid, isDirty }, //optional
                                        formState: { errors }, //optional, but necessary if you want to show an error message
                                    }) => (
                                        <>
                                            <DatePicker
                                                render={<InputIcon />}
                                                value={value || ""}
                                                containerClassName={errors && errors.dob ? "custom-input-error" : "custom-container"}
                                                onChange={(dob) => {
                                                    onChange(dob?.isValid ? dob : "");
                                                }}
                                                // format={"dd/MM/yyyy"}
                                                placeholder="select"
                                            />
                                        </>
                                    )}
                                /> */}
                            </div>
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.phoneNumber && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    type="number"
                                    name="phoneNumber"
                                    placeholder=""
                                    {...register("phoneNumber", {
                                        required: "Field is required",
                                        maxLength: {
                                            value: 10,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^[7-9][0-9]{9}$/,
                                            message: "Allow only numbers"

                                        }
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Phone Number'}</label>
                            </div>
                        </div>
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.location && 'did-error-input'}`}>
                                <input
                                    className="did-floating-input"
                                    type="text"
                                    name="location"
                                    placeholder=""
                                    {...register("location", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9._-\s]+$/,
                                            message: "Allow only alphabets"
                                        }
                                    })}
                                />
                                <label className="did-floating-label requiredTrue">{'Location'}</label>
                            </div>
                        </div>

                        <div className="my-3">
                            <img alt="Alt content" src={require('../../../assets/images/account-password-AM.svg').default} className="img_w_h_23" />
                            <span className="mx-2 fw-bolded font_size_14">Generate User Name / Password</span>
                        </div>

                        <div className="form-outline row gx-3 d-flex justify-content-start align-items-baseline">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.email && 'did-error-input'}`}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder=""
                                    {...register("email", {
                                        // required: "Field is required",
                                        minLength: {
                                            value: 2,
                                            message: "Min length is 2"
                                        },
                                        pattern: {
                                            value: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                            message: "Invalid email address, It should be example@google.com"
                                        }
                                    })}
                                    className="did-floating-input"
                                />
                                <label className="did-floating-label requiredTrue">{'Enter username'}</label>
                            </div>
                            {role === 'ADMIN'
                                ?
                                <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.passwordString && 'did-error-input'}`}>
                                    <input
                                        type={showPassword ? 'text' : "password"}
                                        name="passwordString"
                                        className="did-floating-input me-2"
                                        placeholder=""
                                        {...register("passwordString", {
                                            required: false,
                                            minLength: {
                                                value: 6,
                                                message: "Min length is 6"
                                            },
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                                message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
                                            },
                                            onChange: (e) => {
                                                reset({ ...getValues(), passwordString: e?.target?.value })
                                            }
                                        })}
                                    />
                                    {showPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                    {!showPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                    <label className="did-floating-label">{'Enter the password'}</label>
                                    {errors.passwordString && <p className="error">{errors.passwordString.message}</p>}
                                </div>
                                :
                                <div className="col-md-6">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn_border pb-3 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                        onClick={() => { onClickRestPassword() }}
                                    >
                                        <i className="fa fa-angle-left pe-2"></i>
                                        Reset Password
                                    </button>
                                </div>
                            }
                        </div>
                        {role === 'ADMIN' &&
                            <div className="d-flex justify-content-center align-items-baseline pb-3">OR</div>
                        }
                        {role === 'ADMIN' &&
                            <div className="form-outline d-flex justify-content-center align-items-baseline">
                                <div className="col-md-4"></div>
                                <div className="col-md-4 row gx-5">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn_border pb-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                        onClick={() => { onClickRestPassword() }}
                                    >
                                        <i className="fa fa-angle-left pe-2"></i>
                                        Reset Password
                                        <i className="fa fa-angle-right ps-2"></i>
                                    </button>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        }
                        <div className="form-outline row gx-3">
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.password && 'did-error-input'}`}>
                                {/* <input
                                    type={showPassword ? 'text' : "password"}
                                    name="password"
                                    className="did-floating-input me-2"
                                    placeholder=""
                                    {...register("password", {
                                        // required: true,
                                        minLength: {
                                            value: 6,
                                            message: "Min length is 6"
                                        },
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                            message: "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
                                        },
                                    })}
                                />
                                {showPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                {!showPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye me-2" onClick={() => { setShowPassword(!showPassword) }}></span>}
                                <label className="did-floating-label">{'Enter the password'}</label>
                                {errors.password && <p className="error">{errors.password.message}</p>} */}
                            </div>
                            <div className={`col-md-6 marginbottom_int did-floating-label-content ${errors.confirmPassword && 'did-error-input'}`}>
                                {/* <button
                                    type="button"
                                    className="btn btn-primary btn_border pb-3 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                    onClick={() => { onClickRestPassword() }}
                                >
                                    <i className="fa fa-angle-left pe-2"></i>
                                    Reset Password
                                </button> */}
                                {/* <input
                                    type={confShowPassword ? 'text' : "password"}
                                    name="confirmPassword"
                                    className="did-floating-input pe-2 me-2"
                                    placeholder=""

                                    {...register("confirmPassword", {
                                        validate: (value) => {
                                            const { password } = getValues();
                                            return password === value || "Passwords should match!";
                                        },
                                        // required: true,
                                    })}
                                />
                                {confShowPassword && <span className="fa fa-fw fa-eye field-icon toggle-password password_eye pe-2 me-2" onClick={() => { setConfShowPassword(!confShowPassword) }}></span>}
                                {!confShowPassword && <span className="fa fa-fw fa-eye-slash field-icon toggle-password password_eye pe-2 me-2" onClick={() => { setConfShowPassword(!confShowPassword) }}></span>}
                                <label className="did-floating-label">{'Enter the confirmPassword'}</label>
                                {errors.confirmPassword && <p className="error">{errors.confirmPassword.message}</p>} */}
                            </div>
                        </div>
                    </>
                }

                {stageCompleted === 1 &&
                    <AssignedCompaniesList
                        isAssign={false}
                        viewSelectedAccountManagerDetails={selectedAccountManagerObject[0]?.totalCompanyAssigned || undefined}
                        onClickAssignCmpyHandlerFunProp={onClickAssignCmpyHandlerFun}
                        returnAssignedCmpyList={returnAssignedCmpyListFun}
                        isUnAssign={false}
                        viewSendSelectedAccountManagerDetails={assignedCmpyListStateValueObjects}
                        isSelectedNew={isSelectedNew}
                    />
                }

                {stageCompleted === 2 &&
                    <section class="py-1">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Account Manger Details
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body ms-5">
                                                    <div class="row">
                                                        <div className="col-md-12 d-flex align-content-center flex-wrap pb-2">
                                                            {AccountManagerMasterData.map((item, idx) => {
                                                                return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2" key={idx}>
                                                                    <div className="col-xs-6 me-2">
                                                                        <img
                                                                            src={require(`../../../assets/images/${item.img}`)}
                                                                            alt="jobType"
                                                                            className="img_w_h_11"
                                                                        />
                                                                    </div>
                                                                    <div className="col-xs-6">
                                                                        <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                                        <div className="fw-medium font_size_14">{getValues()[item.response] || ''}</div>
                                                                    </div>
                                                                </div>)
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    User Name Details
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body ms-5">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div className="col-md-12 d-flex align-content-center flex-wrap pb-2">
                                                                {userDetailsMasterData.map((item, idx) => {
                                                                    return (<div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2" key={idx}>
                                                                        <div className="col-xs-6 me-2">
                                                                            <img
                                                                                src={require(`../../../assets/images/${item.img}`)}
                                                                                alt="jobType"
                                                                                className="img_w_h_11"
                                                                            />
                                                                        </div>
                                                                        <div className="col-xs-6">
                                                                            <div className="fw-700 font_size_14 mb-2">{item.label}</div>
                                                                            <div className="fw-medium font_size_14">{item.response === 'created' ? newDate : getValues()[item.response]}</div>
                                                                        </div>
                                                                    </div>)
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4 d-flex justify-content-start align-content-center pt-1 p-2 ms-4">
                                                        <div className="col-xs-6 me-2">
                                                            <input type="checkbox" name="" checked={getValues?.confirmPassword === getValues.password} />
                                                        </div>
                                                        <div className="col-xs-6">
                                                            <div className="font_size_14">{'Allow Change password for first time login'}</div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Companies Assigned
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <AssignedCompaniesList
                                                        // assignedCmpyListStateValue={assignedCmpyListStateValueObjects}
                                                        isViewAssignCompanyList={true}
                                                        isUnAssign={false}
                                                        isCheckboxOff={true}
                                                        isAssign={false}
                                                        // selectedManagerDetails={assignedCmpyListStateValueObjects}
                                                        assignedCmpyListStateValue={
                                                            assignedCmpyListStateValueObjects?.length > 0
                                                                ?
                                                                assignedCmpyListStateValueObjects
                                                                :
                                                                selectedAccountManagerObject[0]?.totalCompanyAssigned || undefined}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                <div className="border-top position-sticky bottom-0 footer py-3 main_bg text-center d-flex justify-content-between">
                    {!stageCompleted &&
                        <>
                            <div></div>
                            <button type="submit"
                                className="btn btn-primary pb-2 font_size_14 btn_height_36 padding_top_6 mt-0 mb-0 pb-2" onClick={errorHandler}>
                                {isSubmitting && <span>Submitting</span>}
                                {!isSubmitting && <span>Next</span>}
                            </button>
                        </>
                    }
                    {stageCompleted === 1 &&
                        <>
                            <button
                                type="button"
                                className="btn btn-primary btn_border pb-3 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                onClick={() => { onPreviousBtnHandler(null) }}
                            >
                                <i className="fa fa-angle-left pe-2"></i>
                                Previous
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                onClick={() => { onNextBtnHandler(2) }}
                            >
                                Next
                                <i className="fa fa-angle-right ps-2"></i>
                            </button>
                        </>
                    }
                    {stageCompleted === 2 &&
                        <>
                            <button
                                type="button"
                                className="btn btn-primary btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36  padding_top_6"
                                onClick={() => { onPreviousBtnHandler(1) }}
                            >
                                <i className="fa fa-angle-left pe-2"></i>
                                Previous
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary bg_color_ornage btn_border pb-2 pe-3 ps-3 font_size_14 fw-medium btn_height_36 padding_top_6"
                                onClick={() => { finalStageCompleted('finish') }}
                            >
                                Finish
                                <i className="fa fa-angle-right ps-2"></i>
                            </button>
                        </>
                    }
                </div>
            </form>
            <button className="d-none mt-0 mb-0 pb-0" id="viewAccountManagerButtonDismissId" data-bs-dismiss="modal"></button>
        </div>
    );
}

export default EditAccountManager

const AccountManagerMasterData = [
    { label: 'Account Manger', response: 'name', value: 'Remote', img: 'ellipse_icon.svg' },
    { label: 'Email ID', response: 'emailId', value: '1234', img: 'ellipse_icon.svg' },
    { label: 'Mobile No', response: 'phoneNumber', value: '5-10 years', img: 'ellipse_icon.svg' },
    { label: 'Date Of Birth', response: 'dob', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Location', response: 'location', value: 'full time', img: 'ellipse_icon.svg' },
    { label: 'Joining', response: 'joiningDate', value: 'full time', img: 'ellipse_icon.svg' },
]

const userDetailsMasterData = [
    { label: 'User name', response: 'email', value: 'Remote', img: 'ellipse_icon.svg' },
    { label: 'Password', response: 'passwordString', value: '5-10 years', img: 'ellipse_icon.svg' },
    { label: 'Total Employees Assigned', response: 'totalEmployeeAssigned', value: '1234', img: 'ellipse_icon.svg' },
    { label: 'Total Companies Assigned', response: 'totalCompanyAssignedCount', value: '5-10 years', img: 'ellipse_icon.svg' },
]