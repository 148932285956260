import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import adminLayout from "../../../hoc/adminLayout"
import { addLoader, deleteLoader } from "../../../redux/actions/loader";
import { getAllApi, postApi } from '../../../services/apiCommonService'
import { get_all_candidates, get_offer_report, get_admin_all_report, get_all_account_managers, get_all_notifications, delete_notification_by_id } from '../../../services/endPointsConstant'
import storePersist from '../../../Utils/storePersist'
import { formatDateDDMMMYYYY, fromNowFun, toPascalCase } from '../../../Utils/utils'

const getHashOfString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line: no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};

const HSLtoString = (hsl) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
  return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const getInitials = (user) => {
  return `${user[0]?.toUpperCase()}${user[1]?.toUpperCase()}`
}

const setValue = (functionFor) => {
  return (e) => {
    const value = parseInt(e.target.value);
    functionFor(value);
  }
};

const getRange = (value, range) => {
  return [Math.max(0, value - range), Math.min(value + range, 100)];
}

const AdminDashboardPage = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [allCandidatesList, setAllCandidatesList] = useState([])
  const [dashboardDetails, setDashboardDetails] = useState([])
  const [selectedPeriod, setSelectedPeriod] = useState(null)
  const [allImportantNotificationsList, setAllImportantNotificationsList] = useState([])

  const [range, setRange] = React.useState(10);
  const [saturation, setSaturation] = React.useState(50);
  const [lightness, setLightness] = React.useState(50);
  const [theme, setTheme] = React.useState('Light');

  const saturationRange = getRange(saturation, range);
  const lightnessRange = getRange(lightness, range);

  useLayoutEffect(() => {
    dispatch(addLoader());
  }, [])

  useEffect(() => {
    async function fetchData() {
      getReportApiCallFun()
      getAllCandidatesList()
      getAccountManagerListApi()
      getAllNotificationsApiCallFun()
    }
    fetchData()
    setTimeout(() => {
      dispatch(deleteLoader());
    }, 3000);
  }, [])

  const getAllNotificationsApiCallFun = async (periodVal) => {
    dispatch(addLoader());
    let apiReqBody = {}
    const apiResponse = await postApi(get_all_notifications, apiReqBody)
    if (apiResponse?.data?.Data) {
      setAllImportantNotificationsList(apiResponse?.data?.Data?.reverse())
      // toast.success(apiResponse?.response?.data.message || "Successfully retrived details of dashboard !!")
      dispatch(deleteLoader());
    } else {
      toast.error(apiResponse?.response?.data.message)
      dispatch(deleteLoader());
    }
  };

  const getReportApiCallFun = async (periodVal) => {
    dispatch(addLoader());
    // const companyDetailsStorage = storePersist.get('companyDetails')
    // let apiReqBody = {
    //   companyId: companyDetailsStorage && companyDetailsStorage[0]?._id
    // }
    let apiReqBody = {}
    if (periodVal) {
      apiReqBody = {
        // companyId: companyDetailsStorage && companyDetailsStorage[0]?._id,
        period: periodVal
      }
    }
    const apiResponse = await postApi(get_admin_all_report, apiReqBody)
    if (apiResponse?.data?.Data) {
      setDashboardDetails(apiResponse?.data?.Data)
      // toast.success(apiResponse?.response?.data.message || "Successfully retrived details of dashboard !!")
      dispatch(deleteLoader());
    } else {
      toast.error(apiResponse?.response?.data.message)
      dispatch(deleteLoader());
    }
  };

  const getAllCandidatesList = async () => {
    // dispatch(addLoader());
    const apiReqBody = {}
    const apiResponse = await postApi(get_all_candidates, apiReqBody)
    if (apiResponse?.data?.Data) {
      let apiResponseData = [...apiResponse?.data?.Data]?.reverse()
      // apiResponseData = apiResponseData.map(({ _id, firstName }) => { return { value: _id, label: firstName } })
      // setAllCandidatesList(apiResponseData)
      // toast.success("Retrived all Candidates List Successfully !!")
      // dispatch(deleteLoader());
    } else {
      toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
      // dispatch(deleteLoader());
    }
  }

  const getAccountManagerListApi = async () => {
    dispatch(addLoader());
    const apiReqBody = {}
    const apiResponse = await postApi(get_all_account_managers, apiReqBody)
    if (apiResponse?.data?.Data) {
      const apiResponseData = [...apiResponse?.data?.Data]?.reverse()
      setAllCandidatesList(apiResponseData)
      // toast.success("Retrived all Manager List Successfully !!")
      setTimeout(() => {
        dispatch(deleteLoader());
      }, 2500);
    } else {
      toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
      dispatch(deleteLoader());
    }
  }

  const notificationFunRedirect = () => {
    navigate('/admin-notifications-list')
  }

  const returnIcon = (type) => {
    switch (type) {
      case 'payment':
      case 'invoice':
      case 'resumeScreening':
        return require('../../../assets/images/payment.png');
      case 'resourceProjectQuitting':
      case 'interviewResult':
      case 'replacementRequest':
        return require('../../../assets/images/resource_quitting.svg').default;
      case 'newCandidateOnboarding':
      case 'newCandidateAdded':
      case 'interviewSchedule':
      case 'rescheduleInterview':
      case 'accountManagerAssigned':
        return require('../../../assets/images/newCandidate.jpg');
      default:
        return require('../../../assets/images/payment.png');
    }
  }

  const getColorFun = (type) => {
    switch (type) {
      case 'resourceProjectQuitting':
      case 'interviewResult':
      case 'replacementRequest':
        return 'resource_quitting_cls';
      case 'newCandidateOnboarding':
      case 'newCandidateAdded':
      case 'interviewSchedule':
      case 'rescheduleInterview':
      case 'accountManagerAssigned':
        return 'resource_notification';
      default:
        return require('../../../assets/images/payment.png');
    }
  }

  const onClickPageRedirectionToCompanies = () => {
    navigate('/companies-list');
  }

  const onClickViewAllManagersPageRedirection = () => {
    navigate('/account-managers-list')
  }

  const onClickHandlerNavigation = (type) => {
    switch (type) {
      case 'totalCompanies':
        navigate('/companies-list');
        return
      case 'totalResources':
        navigate('/onboard-candidates-list')
        return
      case 'Onboarded':
        navigate('/onboard-candidates-list')
        return
      case 'totalInprogress':
        navigate('/inprogress-candidates-list')
        return
      case 'Interviewed':
        navigate('/interviewed-candidates-list')
        return
      case 'Hired':
        navigate('/hired-candidates-list')
        return
      case 'Rejected':
        navigate('/reject-candidates-list')
        return
      case 'totalOnboarded':
        navigate('/onboard-candidates-list')
        return
      case 'totalDealClosed':
        navigate('/deals-closed-list')
        return
      default:
        return
    }
  }

  const onClickSortHandler = (item) => {
    setSelectedPeriod(item)
    getReportApiCallFun(item)
  }

  const deleteNotificationById = async (item) => {
    const { _id, title } = item
    let apiReqBody = {
      notifyId: [_id]
    }
    RedirectionNotificatiosn(title, item)
    const apiResponse = await postApi(delete_notification_by_id, apiReqBody)
    if (apiResponse?.data) {
      toast.success('Navigated successfully' || "deleted Successfully !!")
      setTimeout(() => {
        dispatch(deleteLoader());
      })
      // await getAllNotificationsApiCallFun()
    } else {
      toast.error(apiResponse?.response?.data.message || "Api Error Response !!")
      dispatch(deleteLoader());
    }
  }
  // const res = allImportantNotificationsList?.map(({ title }) => title)
  // console.log("========", [...new Set(res)])

  const RedirectionNotificatiosn = (title, item) => {
    if (title === 'Account manager Unassigned') {
      navigate('/account-managers-list')
    } else if (title === 'New Company Onboarded') {
      navigate('/companies-list');
    } else if (title === 'Candidate  Added') {
      navigate(`/companyjoblist/${item?.companyId}`);
      // navigate('/onboard-candidates-list', { state: { companyId: item._id } })
    } else if (title === 'New Job Added') {
      navigate(`/companyjoblist/${item?.companyId}`);
    } else if (title === 'Interview Proposed') {
      navigate(`/companyjoblist/${item?.companyId}`);
    } else if (title === 'Interview scheduled') {
      navigate(`/companyjoblist/${item?.companyId}`);
    } else if (title === 'Candidate Onboarding') {
      navigate('/job-offers-list');
    } else if (title === 'SOW Uploaded') {
      navigate('/job-offers-list');
    } else if (title === 'Candidate Interview Result') {
      navigate(`/companyjoblist/${item?.companyId}`);
    } else if (title === 'Account manager assigned') {
      navigate('/account-managers-list')
    } else if (title === 'Reschedule Interview') {
      navigate(`/companyjoblist/${item?.companyId}`);
    } else if (title === 'Resume Screening Update') {
      navigate(`/companyjoblist/${item?.companyId}`);
    }
  }

  return <>
    {/* <h3 className="font_size_24">Good Morning ! Welcome Back, Beeutech</h3> */}
    <div className="row d-flex justify-content-center align-items-start gx-3 margintop">
      {/* Left side card */}
      <div className="col-md-8 width_col_69">
        {/* card-1 */}
        <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
          <div className="d-flex justify-content-between align-items-center border-bottom ">
            <h6 className="pb-2 mb-0 font_color_imp fw-semibold">Overview</h6>
            <div className="col-md-8 d-flex align-content-center justify-content-end flex-wrap">
              {['Week', 'Month', 'Year'].map((item, idx) => <>
                <div className="w_max_content me-3 mb-1 " key={idx} onClick={() => { onClickSortHandler(item) }}>
                  {/* <div className={`font_color_imp ${selectedPeriod === item && 'bg_color_ornage px-1 rounded-2'} fw-semibold font_size_14 hover_underline`}> */}
                  <div className={`font_color_imp ${selectedPeriod === item && 'bg_color_lite_green px-2 pt-1 rounded-2 fw-bold'} fw-medium font_size_14 px-2 pt-1`}>
                    {(item?.charAt(0).toUpperCase() + item.slice(1))?.replace(/([A-Z])/g, ' $1').trim()}
                  </div>
                </div>
              </>)}
            </div>
          </div>
          <div>
            <div className="row bd-example">
              <div className="col-xl-4 col-sm-6 mb-3">
                <div className="mr-5 mt-3 mb-3 font_color_imp fw-semibold">Companies</div>
                <div className="card text-white bg-status-color o-hidden">
                  <div className="card-body">
                    <div className="card-body-icon">
                      <div className="card-body-icon d-flex justify-content-center align-items-center">
                        <span className="bg-white px-1 py-1 rounded_15">
                          <img alt="Alt content" src={require('../../../assets/images/status_group_dashboard.svg').default} className="rounded-circle" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex align-content-center justify-content-between">
                      {['totalCompanies', 'totalResources'].map((item, idx) => <>
                        <div className="mt-3 mb-1 " key={idx}>
                          <div
                            className={`${idx === 2 ? 'font_color_ornage' : 'font_color_imp'} fw-semibold font_size_12 hover_underline`}
                            onClick={() => { onClickHandlerNavigation(item) }}
                          >
                            {(item?.charAt(0).toUpperCase() + item.slice(1))?.replace(/([A-Z])/g, ' $1').trim()}
                          </div>
                          <div className={`text-center ${idx === 2 ? 'font_color_ornage' : 'font_color_imp'} mt-2 fw-semibold font_size_24`}>{dashboardDetails[item] || 0}</div>
                        </div>
                      </>)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 mb-3">
                <div className="mr-5 mt-3 mb-3 font_color_imp fw-semibold">New Onboarding</div>
                <div className="card text-white bg-onboarding-color o-hidden">
                  <div className="card-body">
                    <div className="card-body-icon">
                      <div className="card-body-icon d-flex justify-content-center align-items-center">
                        <span className="bg-white px-1 py-1 rounded_15">
                          <img alt="Alt content" src={require('../../../assets/images/onboarding_dashboard_icon.svg').default} className="rounded-circle" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex align-content-center justify-content-between">
                      {['totalOnboarded', 'totalInprogress',].map((item, idx) => <>
                        <div className="mt-3 mb-1 " key={idx}>
                          <div
                            className={`${idx === 1 ? 'font_color_ornage' : 'font_color_imp'} fw-semibold font_size_12 hover_underline`}
                            onClick={() => { onClickHandlerNavigation(item) }}
                          >
                            {(item?.charAt(0).toUpperCase() + item.slice(1))?.replace(/([A-Z])/g, ' $1').trim()}
                          </div>
                          <div className={`text-center  ${idx === 1 ? 'font_color_ornage' : 'font_color_imp'} mt-2 fw-semibold font_size_24`}>{dashboardDetails[item] || 0}</div>
                        </div>
                      </>)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 mb-3">
                <div className="mr-5 mt-3 mb-3 font_color_imp fw-semibold">Deal Closed</div>
                <div className="card text-white bg-leaves o-hidden">
                  <div className="card-body">
                    <div className="card-body-icon">
                      <div className="card-body-icon d-flex justify-content-center align-items-center">
                        <span className="bg-white px-1 py-1 rounded_15">
                          <img alt="Alt content" src={require('../../../assets/images/leavers_dashboard_icon.svg').default} className="rounded-circle" />
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex align-content-center justify-content-center">
                      {['totalDealClosed'].map((item, idx) => <>
                        <div className="mt-2_8 mb-1 " key={idx}>
                          <div
                            className={`${idx === 1 ? 'font_color_ornage' : 'font_color_imp'} fw-semibold font_size_12 hover_underline`}
                            onClick={() => { onClickHandlerNavigation(item) }}
                          >
                            {(item?.charAt(0).toUpperCase() + item.slice(1))?.replace(/([A-Z])/g, ' $1').trim()}
                          </div>
                          <div className={`text-center  ${idx === 1 ? 'font_color_ornage' : 'font_color_imp'} mt-2 fw-semibold font_size_24`}>{dashboardDetails[item] || 0}</div>
                        </div>
                      </>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* card-2 */}
        <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
          <div className="d-flex justify-content-between align-items-center border-bottom ">
            <h6 className="pb-2 mb-2 font_color_imp fw-semibold">Important Notifications : 
              <span> (<span className='orange_color'><b>{allImportantNotificationsList?.length}</b></span>)</span>
            </h6>
            <span className="mr-5 font_color_ornage fw-medium font_size_14 me-2 hover_underline" onClick={() => { notificationFunRedirect() }}>View all<i className="fa fa-angle-right ms-2"></i></span>
          </div>
          <div>
            {/* height_notification_table */}
            <div className="row bd-example">
              {allImportantNotificationsList?.length > 0 &&
                <PerfectScrollbar className="sidebar-items pb-0">
                  {allImportantNotificationsList?.map((item, idx) =>
                    idx < 4 && <div className="d-flex justify-content-center align-items-center pt-2 border-bottom pb-3">
                      <div className="col-md-1">
                        <span className="px-3 py-3 rounded_30">
                          <img alt="Alt content" src={returnIcon(item?.type || 'newCandidateOnboarding')} className="pb_2" />
                        </span>
                      </div>
                      <div className="col-md-9 ms-2">
                        <div className="pt-2">
                          <h6 className="pb-2 mb-0 font_color_imp fw-900 font_size_14">{item?.title || 'New candidates onboarding'}</h6>
                          <span className="mr-5 font_color_imp fw-medium font_size_14">{item?.description || item?.notification}</span>
                        </div>
                        <div className="pt-1">
                          <span className="font_color_imp fw-normal font_size_12 me-3">{formatDateDDMMMYYYY(item?.createdAt)}</span>
                          <span className="border_bg_label">
                            <i className="fa fa-circle font_size_5 font_color_ornage me-1" aria-hidden="true"></i>
                            <span className="fw-normal">{fromNowFun(item?.createdAt)}</span>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-2 d-flex justify-content-end pe-3">
                        <button
                          type="submit"
                          className={`btn btn-primary btn_background_color pt-1 pb-1 font_size_11 fw-medium min_width ${getColorFun(item.type)}`}
                          onClick={() => { deleteNotificationById(item) }}
                        >
                          {item?.action || 'Send Wishes'}
                        </button>
                      </div>
                    </div>
                  )}
                </PerfectScrollbar>
              }
              {allImportantNotificationsList?.length === 0 &&
                <div className="disply_block_t_center">
                  <div className="vh-50 font_size_24 disply_block_t_center padding_15_p">
                    <div>
                      No Data Found
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {/* right side cards */}
      <div className="col-md-4 width_col_30">
        {/* card-1 */}
        <div className="my-3 p-3 mt-3 bg-body rounded shadow-sm rounded_20">
          <div className="d-flex justify-content-between align-items-center border-bottom ">
            <h6 className="pb-2 mb-0 font_color_imp fw-semibold">Top Account Manager : 
              <span> (<span className='orange_color'><b>{allCandidatesList?.length}</b></span>)</span>
            </h6>
            <span
              className="mr-5 font_color_ornage fw-medium font_size_14 me-2 pointer_event"
              onClick={() => { onClickViewAllManagersPageRedirection() }}
            >
              View all
              <i className="fa fa-angle-right ms-2"></i>
            </span>
          </div>
          <div>
            {/* height_scheduled_int_table */}
            <div className="row bd-example vh-100">
              <PerfectScrollbar className="sidebar-items">
                <h6 className="pb-1 mb-0 font_color_imp fw-semibold font_size_16">Today's</h6>
                {allCandidatesList.length > 0 && allCandidatesList.map((item, idx) => {
                  const userName = `${item?.name || item?.candidateFirstName} ${item?.name || item?.candidateFirstName}`;
                  const color = generateColorHsl(userName, saturationRange, lightnessRange);
                  const initials = getInitials(item?.name || item?.candidateFirstName);
                  return (<>
                    {
                      idx < 5 && <div className="border-bottom pt-4 pb-3">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="col-md-2 margin_top_neg_10">
                            {/* <div className="position-relative rounded-circle overflow-hidden mx-auto custom-circle-image border blackcircle">
                        <img className="w-100 h-100 whitecircle rounded-circle" alt="Alt content" src={require('../../../assets/images/user-profile-icon.svg').default} />
                      </div> */}
                            <div className="row1" key={idx}>
                              <div className={`avatarColor ${theme}`} style={{ backgroundColor: color }}>{initials}</div>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div>
                              <h6 className="pb-1 mb-0 font_color_imp fw-900 font_size_14">{toPascalCase(item?.name)}</h6>
                              <div className="font_color_imp fw-medium font_size_14 text_ellipse_200">{toPascalCase(item?.emailId)}</div>
                            </div>
                            <div className="pt-0 gap-1">
                              <span className="font_color_imp fw-normal font_size_12" style={{ padding: '1px 3px' }}>{toPascalCase(item?.location)}</span>
                              {/* bg_color_lite_green rounded */}
                              <span className=""> | </span>
                              <span className="font_color_imp fw-normal font_size_12">{item?.phoneNumber}</span>
                            </div>
                          </div>
                          <div className="col-md-2 d-flex justify-content-center align-items-center ">
                            {/* margin_top_neg_60 */}
                            {/* <button
                        type="button"
                        className="btn btn-outline-primary border pt-1 pb-1 pe-2 ps-2 font_size_12 fw-medium"
                        onClick={onClickViewAllManagersPageRedirection}
                      >
                        View details
                      </button> */}
                            <li
                              className='border pt-1 pb-1 pe-2 ps-2 font_size_12 rounded d-flex align-items-center justify-content-center pointer_event opacity_dull'
                              onClick={onClickViewAllManagersPageRedirection}
                            >
                              View
                              {/* <img
                          src={require('../../../assets/images/view_account_details_nm.svg').default}
                          alt="Alt content"
                          style={{ height: 12, padding: '0px 6px 0px 2px' }}
                        /> */}
                            </li>
                          </div>
                        </div>
                      </div>
                    }
                  </>)
                }
                )}
                {allCandidatesList.length === 0 &&
                  <div className="row bd-example">
                    <div className="table-container">
                      <div className="text_v_h_center vh-50 font_size_24">
                        No Data Found
                      </div>
                    </div>
                  </div>
                }
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default adminLayout(AdminDashboardPage);
